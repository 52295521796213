import { Link } from "react-router-dom"
import Filerename from "./filerename"
import { useState } from "react"
import { Tooltip } from "@mui/material"

function DocFile(prop) {
    const [nameEdit, setNameEdit] = useState(true)
    console.log("deleteJson", prop)
    console.log("deleteJson", prop)
    //document.getElementsByTagName("body").onClick(alert("body"))
    return (
        <>

            <div className="col-md-2 col-12 text-center mt-2">
                {/* <img src="assets/images/image.png" height="60"/> */}
                <img src={`assets/images/${prop.fileType == ".jpg" || prop.fileType == ".png" || prop.fileType == ".gif" || prop.fileType == ".webp" ? "image.png" :
                    prop.fileType == ".doc" ? "doc.png" :
                        prop.fileType == ".docx" ? "doc.png" :
                            // prop.fileType !== ".jpg" || prop.fileType !== ".png" || prop.fileType !== ".gif" || prop.fileType !== ".webp" || prop.fileType !== ".docx" || prop.fileType !== ".pdf" ? "other.png" :
                            prop.fileType == ".pdf" ? "pdf-file.png" :
                                prop.fileType == ".xlsx" ? "xls.png" :
                                    prop.fileType == ".txt" ? "txt-file.png" : "other.png"}`
                } height="60" />
                <p className="mb-0 mt-2">

                    <Filerename fileID={prop.fileID} oldName={prop.fileName} documentId={prop.dependent === true ? prop.dependentId : 0} caseId={prop.caseId} reload={prop.reload} role={prop.role} />
                </p>
                <p className="card-text mt-2">
                    <Tooltip title="Download" arrow>
                        <Link to={prop.filePath} target="_blank" className="badge badge-soft-success" download>
                            <i className="mdi mdi-arrow-collapse-down"></i></Link>
                    </Tooltip>

                    <span>&nbsp;</span>

                    {
                        prop.role == "3" || prop.role == "4" ?
                            <>
                                {/* <span className="badge badge-soft-danger">
                                    <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                                </span> */}

                                <span>&nbsp;</span>
                                <Tooltip title="Delete" arrow>
                                    <span className="badge badge-soft-danger" onClick={() => prop.delete({ documentId: prop.fileID, fileName: prop.fileName, dependentId: prop.dependent === true ? prop.dependentId : 0 })}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </span>
                                </Tooltip>

                            </> : null
                    }

                </p>
                {/* <button onClick={() => console.log("deleteJson", prop.dependent === true ? prop.dependentId : 0, prop)}>check</button> */}
            </div>
        </>
    )
}
export default DocFile