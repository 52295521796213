import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Link, useSearchParams } from 'react-router-dom';
import api from '../apiUrl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function Logins(prop) {


    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [load, setLoad] = useState(false)

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };

    var apis = api()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [validated, setValidated] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    var credential = {
        "email": email,
        "password": password
    }
    var emailValidate = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!email === false && !password === false) {
                prop.login(credential)
            }

        }

        setValidated(true);
    };
    // setTimeout(() => {
    //     var body = document.getElementById("userpassword");
    //     body.addEventListener("keypress", function (e) {
    //         if (e.key === 'Enter') {
    //             e.preventDefault();
    //             document.getElementById("handleSubmit").click();
    //         }
    //     })
    // }, 500)


    useEffect(() => {
        var body = document.getElementById("userpassword");
        body.addEventListener("keypress", function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
                document.getElementById("handleSubmit").click();
            }
        })
    }, [])

    const [emailfg, setEmailfg] = useState('')
    function forgetPasswordMailSent() {
        setLoad(true)
        fetch(`${apis}Auth/ForgotPassword/${emailfg}`, { method: "POST", }).then((respon) => {
            respon.json().then((result) => {
                console.log("ForgotPassword result", result)
                setShow(false)
                setLoad(false)
                if (result.data.isEmailExists) {

                    setMessage("Email sent Successfully")//set in value in tMessage useState for Toster Message
                    setOpens(true);//set in value in useState
                    setColor("success")//set in value in useState
                } else {
                    setMessage("Invalid User")//set in value in tMessage useState for Toster Message
                    setOpens(true);//set in value in useState
                    setColor("error")//set in value in useState

                }



            })
        })
    }




    const [param, setParam] = useSearchParams();
    const forgetPasswordNavigate = param.get('fg')
    const token = param.get('token')
    const fgemail = param.get('email')


    const [fgshow, setFgshow] = useState(false);
    const fghandleClose = () => setFgshow(false);
    const fghandleShow = () => setFgshow(true);

    function checkparam() {
        if (token) {
            console.log("forgetPasswordNavigate no")
            setFgshow(true)
        } else if (token === null) {
            console.log("forgetPasswordNavigate yes")
            setFgshow(false)
        }
    }

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordValidate, setPasswordValidate] = useState(false)
    function resetforgetPassword() {
        setLoad(true)
        fetch(`${apis}Auth/SaveNewPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "Email": fgemail, "Token": token, "Password": confirmNewPassword })

        }).then((respon) => {
            respon.json().then((result) => {
                console.log("ForgotPassword result", result)

                setLoad(false)
                setFgshow(false)
                if (result.data.isPasswordReset) {

                    setMessage("Password has been Changed")//set in value in tMessage useState for Toster Message
                    setOpens(true);//set in value in useState
                    setColor("success")//set in value in useState
                } else {
                    setMessage(result.status.message)//set in value in tMessage useState for Toster Message
                    setOpens(true);//set in value in useState
                    setColor("error")//set in value in useState

                }



            })
        })
    }


    function passwordValidation(e) {
        //console.log("passwordValidation", e.target.value)
        var passwordInput = document.getElementById('validationCustom11');
        var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if (e.target.value.match(paswd)) {
            // console.log("Reg Expression is good");
            passwordInput.classList.remove('passwordValidate');
            setNewPassword(e.target.value)
            setPasswordValidate(true)
            passwordInput.nextSibling.style.display = "none"
        } else {
            // console.log("Reg Expression is not good")
            setPasswordValidate(false)
            passwordInput.classList.add('passwordValidate')
            passwordInput.nextSibling.style.display = "block"
        }
    }

    function sendNewPassword() {
        if (newPassword === confirmNewPassword) {

            resetforgetPassword()
        } else {
            setMessage("Password does not match")//set in value in tMessage useState for Toster Message
            setOpens(true);//set in value in useState
            setColor("error")//set in value in useState
        }
    }


    useEffect(() => {
        console.log("forgetPasswordNavigate", forgetPasswordNavigate, token, fgemail);
        checkparam()

    }, [])


    return (
        <>
            <div className="auth-body-bg py-5">

                <div className="account-pages pt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center mt-4">
                                            <div className="">
                                                <a className="">
                                                    <img src="assets/images/logo-light.png" alt="" height="150" className="auth-logo logo-dark mx-auto" />
                                                    <img src="assets/images/logo-light.png" alt="" height="150" className="auth-logo logo-light mx-auto" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <h4 className="font-size-18 text-muted mt-2 text-center">Welcome Back !</h4>
                                            <p className="text-muted text-center mb-4">Sign in to continue to GCSL.</p>
                                            <Form noValidate validated={validated} >
                                                <Form.Group controlId="username" className='mb-3'>
                                                    <Form.Label>User Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="username"
                                                        placeholder="Enter username"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    // pattern="/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}
                                                    //     [a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/"

                                                    />
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                    <Form.Control.Feedback type="invalid">Please enter a user name</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group controlId="userpassword" className='mb-3'>
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        className="form-control"
                                                        id="userpassword"
                                                        placeholder="Enter password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                    <Form.Control.Feedback type="invalid">Please  enter a  Password.</Form.Control.Feedback>
                                                </Form.Group>

                                                <div className="mb-3 row mt-4">
                                                    <div className="col-sm-6">
                                                        {/* <div className="form-checkbox">
                                                            <input type="checkbox" className="form-check-input me-1"
                                                                id="customControlInline" />
                                                            <label className="form-label form-check-label"
                                                                for="customControlInline">Remember me</label>
                                                        </div> */}
                                                    </div>

                                                    <div className="col-sm-6 text-end">

                                                        <span onClick={handleShow} style={{ cursor: 'pointer' }} className="text-muted"><i
                                                            className="mdi mdi-lock"></i> Forgot your password?</span>
                                                    </div>

                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-12 text-center">
                                                        <Button id="handleSubmit" onClick={handleSubmit} className="btn btn-primary w-100 waves-effect waves-light" >
                                                            {
                                                                prop.load && <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>
                                                            }
                                                            &nbsp;  <span style={{ color: "#fff" }}>Log In</span>
                                                        </Button>




                                                    </div>

                                                </div>

                                            </Form>
                                            <Modal show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title> Forgot Password</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail"
                                                            onChange={(e) => setEmailfg(e.target.value)}
                                                        >
                                                            <Form.Label>User Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter your User Name" />

                                                        </Form.Group>

                                                    </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" onClick={forgetPasswordMailSent}>
                                                        {
                                                            load && <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>
                                                        }    Send
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            {/* forget Password */}
                                            <Modal show={fgshow} onHide={fghandleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title> Reset Your Password</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label>New Password <span class="redstar">*</span></Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="password"
                                                                //   id="validationCustom11"
                                                                placeholder="Ex. (Password@123)"
                                                                maxLength={15}
                                                                onChange={(e) => {
                                                                    passwordValidation(e)
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Ex. (Password@123)
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="validationCustom12">
                                                            <Form.Label>Confirm Password <span class="redstar">*</span></Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="password"
                                                                maxLength={15}
                                                                //  id="validationCustom12"
                                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please fill this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={fghandleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" onClick={sendNewPassword}>
                                                        {
                                                            load && <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>
                                                        }     Change Password
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleCloseAlert} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Logins