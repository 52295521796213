import { data } from "jquery";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import DocFile from "../docfile"
import api from "../../../apiUrl";
import jwt_decode from "jwt-decode";
function DependentEditProfile(prop) {
    const [load, setLoad] = useState(false)
    var apis = api()
    const navigate = useNavigate()
    // const param = useParams();
    //const { name } = param;
    const location = useLocation();
    console.log("location", location);
    // console.log("Doc", location.state.doc)
    var doc = location.state.doc;
    var dates = new Date(location.state.createOn)
    var split = dates.toString().split('GMT')
    var dateString = split[0].trim()

    //console.log("Date", date.substring(0, dateStringindex))

    var profileD = location.state.profileDetails

    var datemmddyy = profileD.dateOfBirth.toString().split('T')[0].trim()
    console.log("profileD", profileD)
    // const [data, setData] = useState([]);
    //Candidate Provided Details States
    const [name, setName] = useState(profileD.name);
    const [placeOfBirth, setPlaceOfBirth] = useState(profileD.placeOfBirth);
    const [dob, setDob] = useState(datemmddyy);
    const [ppId, setPpId] = useState(profileD.ppid);
    const [idCard, setidCard] = useState(profileD.idCard);
    const [netid, setNetid] = useState("");
    const [knowAddress, setKnowAddress] = useState(profileD.knowAddress);
    const [corporateAffiliations, setcorporateAffiliations] = useState(profileD.corporateAffiliations);

    //Type of Inquiries States
    //const [sourcesSearched, setSourcesSearched] = useState("");
    const [civilLitigation, setCivilLitigation] = useState(profileD.civilLitigation);
    const [criminialRecord, setcriminialRecord] = useState("");
    const [internationalEmbassiesMission, setInternationalEmbassiesMission] = useState(profileD.internationalEmbassiesMission);
    const [globalRegulatoryData, setGlobalRegulatoryData] = useState(profileD.globalRegulatoryData);
    const [bankruptcyRecords, setBankruptcyRecords] = useState(profileD.bankruptcyRecords);
    const [globalSanctionsWatchlists, setGlobalSanctionsWatchlists] = useState(profileD.globalSanctionsWatchlists);
    const [globalLawEnforcementData, setGlobalLawEnforcementData] = useState(profileD.globalLawEnforcementData);
    const [globalPEHPpFgod, setGlobalPEHPpFgod] = useState(profileD.globalPEHPpFgod);
    const [adverseMediaInternetSearches, setAdverseMediaInternetSearches] = useState(profileD.adverseMediaInternetSearches);

    //Date of Birth, Nationality, and Address Details States
    const [dob1, setFormThreeDob] = useState(profileD.formThreeDob);

    const [pob1, setFormThreePlaceOfBirth] = useState(profileD.formThreePlaceOfBirth);

    const [natId, setNatId] = useState(profileD.natId);

    const [currentAddress, setCurrentAddress] = useState(profileD.currentAddress);


    //Security Risk Matrix States
    const [civilLitigationInvestigation, setCivilLitigationInvestigation] = useState(profileD.civilLitigationInvestigation);
    const [criminalRecordInvestigation, setCriminalRecordInvestigation] = useState(profileD.criminalRecordInvestigation);
    const [bankruptcyInvestigation, setBankruptcyInvestigation] = useState(profileD.bankruptcyInvestigation);
    const [corporateInterestsInvestigation, setCorporateInterestsInvestigation] = useState(profileD.corporateInterestsInvestigation);
    const [worldComplianceInvestigation, setWorldComplianceInvestigation] = useState(profileD.worldComplianceInvestigation);
    const [blacklistInvestigation, setBlacklistInvestigation] = useState(profileD.blacklistInvestigation);
    const [mediaInternetSearches, setMediaInternetSearches] = useState(profileD.mediaInternetSearches);
    const [conclusion, setConclusion] = useState(profileD.conclusion);
    const [backgroundInformation, setBackgroundInformation] = useState(profileD.backgroundInformation);
    const [derogatoryInformation, setDerogatoryInformation] = useState(profileD.derogatoryInformation);
    const [employmentStatusSourcesIncome, setEmploymentStatusSourcesIncome] = useState(profileD.employmentStatusSourcesIncome);
    const [companiesUndisclosed, setCompaniesUndisclosed] = useState(profileD.companiesUndisclosed);
    const [mostImportantPersonsC, setMostImportantPersonsC] = useState(profileD.mostImportantPersonsC);
    const [documentsSubmitted, setDocumentsSubmitted] = useState(profileD.documentsSubmitted);
    const [internationalBansred, setInternationalBansred] = useState(profileD.internationalBansred);

    //validation
    const [natval, setNatVal] = useState("");
    const [textval, setTextVale] = useState("");
    const [birthval, setbirthtextval] = useState("");

    const numberValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.valueR", e.target.value)
            setidCard(e.target.value);
        }
    };
    const natnumberValidated = (e) => {
        // console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setNatId(e.target.value);
        }
    };

    const onlyTextValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setName(e.target.value);
        }
    };
    const birthTextValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setPlaceOfBirth(e.target.value);
        }
    };

    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    //Post Data Form JSON formate

    var postDataJson = {
        caseId: profileD.caseId,
        createBy: location.state.createBy,
        createOn: location.state.createOn,
        name: name,
        placeOfBirth: placeOfBirth,
        dateOfBirth: dob,
        ppid: ppId,
        idCard: idCard,
        natId: natId,
        knowAddress: knowAddress,
        currentAddress: currentAddress,
        corporateAffiliations: corporateAffiliations,
        civilLitigation: civilLitigation,
        bankruptcyRecords: bankruptcyRecords,
        globalSanctionsWatchlists: globalSanctionsWatchlists,
        globalLawEnforcementData: globalLawEnforcementData,
        internationalEmbassiesMission: internationalEmbassiesMission,
        globalRegulatoryData: globalRegulatoryData,
        globalPEHPpFgod: globalPEHPpFgod,
        adverseMediaInternetSearches: adverseMediaInternetSearches,
        civilLitigationInvestigation: civilLitigationInvestigation,
        criminalRecordInvestigation: criminalRecordInvestigation,
        bankruptcyInvestigation: bankruptcyInvestigation,
        corporateInterestsInvestigation: corporateInterestsInvestigation,
        worldComplianceInvestigation: worldComplianceInvestigation,
        blacklistInvestigation: blacklistInvestigation,
        mediaInternetSearches: mediaInternetSearches,
        conclusion: conclusion,
        backgroundInformation: backgroundInformation,
        derogatoryInformation: derogatoryInformation,
        employmentStatusSourcesIncome: employmentStatusSourcesIncome,
        companiesUndisclosed: companiesUndisclosed,
        mostImportantPersonsC: mostImportantPersonsC,
        documentsSubmitted: documentsSubmitted,
        internationalBansred: internationalBansred,
        "CurrentUserId": currentUserId,
    };
    //var obj = {name:"Ayush",lastName:"Sengar"}
    function postData() {
        console.log(JSON.stringify(postDataJson))
        console.log(postDataJson)
        setLoad(true)
        fetch(`${apis}Admin/UpdateProfile`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(postDataJson),
        }).then((respon) => {
            respon.json().then((result) => {
                console.log(result);
                setLoad(false)
                navigate('/')
            })
        }).catch((e) => {
            console.log("something went wrong")
        })
    }

    console.log("prop profileDetails", location.state.profileDetails)
    return (<>
        <p>Edite Profile</p>

        <div className="border-bottom px-3 pt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#!">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item">View Profile</li>
                                    <li className="breadcrumb-item active"> Edit Profile </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row align-it">
                <div className="col-md-12 py-2">
                    <div className="row align-it">
                        <div className="col-md-12 text-center d-flex">
                            <p className="card-text mb-0 px-2">Created By: {location.state.createBy} &nbsp; &nbsp; |</p>
                            <p className="card-text mb-0 px-2">Created On: {dateString}</p>
                        </div>
                    </div>
                    <div className="row align-it mt-4">
                        {/* {
                            doc.map((item) =>
                                <DocFile fileType={item.fileType} fileName={item.fileName} filePath={item.filePath} />
                            )
                        } */}
                    </div>
                </div>
            </div>
            <div id="accordion" className="custom-accordion mt-4">
                <div className="card mb-1 shadow-none">
                    <a href="#collapseOne" className="text-dark" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="collapseOne">
                        <div className="card-header" id="headingOne">
                            <h6 className="m-0">
                                Candidate Provided Details
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                            </h6>
                        </div>
                    </a>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordion">
                        <div className="card-body p-0">
                            <div className="">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={name}
                                                    maxLength="50"
                                                    onChange={(e) => onlyTextValidated(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Place of Birth</label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={placeOfBirth}
                                                    maxLength="50"
                                                    onChange={(e) => birthTextValidated(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Date of Birth</label>
                                                <div className="input-group" id="datepicker3">
                                                    <input type="date" className="form-control" placeholder="dd/mm/yyyy"
                                                        data-provide="datepicker" data-date-container="#datepicker3"
                                                        data-date-multidate="true"
                                                        value={dob}
                                                        onChange={(e) => setDob(e.target.value)}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">P.P. ID </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={ppId}

                                                    onChange={(e) => setPpId(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">ID Card </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={idCard}
                                                    maxLength="10"
                                                    onChange={(e) => {
                                                        numberValidated(e)
                                                        // setidCard(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Nat. ID </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={natId}
                                                    maxLength="10"
                                                    onChange={(e) => {
                                                        natnumberValidated(e)
                                                        // setidCard(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Known Address </label>
                                                <textarea id="textarea" className="form-control" maxlength="225" rows="1"
                                                    value={knowAddress}
                                                    maxLength="200"
                                                    placeholder="" onChange={(e) => setKnowAddress(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Current
                                                    Address </label>

                                                <textarea className="form-control"
                                                    maxLength="200"
                                                    onChange={(e) => setCurrentAddress(e.target.value)}
                                                    value={currentAddress}
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Corporate Affiliations
                                                </label>
                                                <textarea id="textarea" value={corporateAffiliations} className="form-control" maxlength="225" rows="1"
                                                    maxLength="680" placeholder="" onChange={(e) => setcorporateAffiliations(e.target.value)}></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------------------------------------------------------------------- */}
                <div className="card mb-1 shadow-none">
                    <a href="#collapseTwo" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="collapseTwo">
                        <div className="card-header" id="headingTwo">
                            <h6 className="m-0">
                                Type of Inquiries
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                            </h6>
                        </div>
                    </a>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                        <div className="card-body  p-0">
                            <div className="">
                                <div className="card-body">
                                    {/* <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Sources searched</label>
                                                <input type="text" className="form-control" id="validationCustom01" required="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Civil Litigation</label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    onChange={(e) => setSourcesSearched(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div> */}



                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Civil Litigation </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={civilLitigation}
                                                    maxLength="50"
                                                    onChange={(e) => setCivilLitigation(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Criminial Record</label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={bankruptcyRecords}
                                                    maxLength="50"
                                                    onChange={(e) => setcriminialRecord(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Bankruptcy records </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={bankruptcyRecords}
                                                    maxLength="50"
                                                    onChange={(e) => setBankruptcyRecords(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Global Sanctions and
                                                    Watchlists: </label>
                                                <select value={globalSanctionsWatchlists} className="form-select" aria-label="Default select example" onChange={(e) => setGlobalSanctionsWatchlists(e.target.value)}>
                                                    <option selected="Global Sanction Database">Global Sanction Database (GSD)</option>
                                                    <option value="Office of Foreign Access Control (OFAC)">Office of Foreign Access Control (OFAC)</option>
                                                    <option value="Excluded Parties List System (EPLS)">Excluded Parties List System (EPLS)</option>
                                                    <option value="U.S. Department of State">U.S. Department of State</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Global Law Enforcement Data
                                                </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={globalLawEnforcementData}
                                                    maxLength="50"
                                                    onChange={(e) => setGlobalLawEnforcementData(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">International Embassies/Mission
                                                </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={internationalEmbassiesMission}
                                                    maxLength="50"
                                                    onChange={(e) => setInternationalEmbassiesMission(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Global Regulatory Data
                                                </label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={globalRegulatoryData}
                                                    maxLength="50"
                                                    onChange={(e) => setGlobalRegulatoryData(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Global P.E.P/High-Profile
                                                    persons/
                                                    Foreign Government Official Data</label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""

                                                    value={globalPEHPpFgod}
                                                    maxLength="50"
                                                    onChange={(e) => setGlobalPEHPpFgod(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">


                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Adverse Media and Internet
                                                    Searches</label>
                                                <input type="text" className="form-control" id="validationCustom01" required=""
                                                    value={adverseMediaInternetSearches}
                                                    maxLength="50"
                                                    onChange={(e) => setAdverseMediaInternetSearches(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------------------------------------------------------------------- */}
                {/* <div className="card mb-0 shadow-none">
                    <a href="#collapseThree" className="text-dark collapsed" data-bs-toggle="collapse"
                        aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header" id="headingThree">
                            <h6 className="m-0">
                                Date of Birth, Nationality, and Address Details

                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                            </h6>
                        </div>
                    </a>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordion">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Date of Birth</label>
                                        <div className="input-group" id="datepicker3">
                                            <input type="date" className="form-control" placeholder="dd/mm/yyyy"
                                                data-provide="datepicker" data-date-container="#datepicker3"
                                                data-date-format="dd M, yyyy" data-date-multidate="true"
                                                onChange={(e) => setFormThreeDob(e.target.value)}
                                                value={dob1}
                                            />

                                            <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Place of Birth</label>

                                        <input type="text" className="form-control"
                                            onChange={(e) => setFormThreePlaceOfBirth(e.target.value)}
                                            value={pob1}
                                        />


                                    </div>
                                </div>
                            </div>

                            <div className="row">


                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Nat. ID </label>

                                        <input type="text" className="form-control"
                                            onChange={(e) => setNatId(e.target.value)}
                                            value={natId}
                                        />


                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Current
                                            Address </label>

                                        <input type="text" className="form-control"
                                            onChange={(e) => setCurrentAddress(e.target.value)}
                                            value={currentAddress}
                                        />


                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>

                </div> */}
                {/* ------------------------------------------------------------------------------------------- */}
                <div className="card mb-0 shadow-none mb-3">
                    <a href="#collapseFour" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="collapseFour">
                        <div className="card-header" id="headingFour">
                            <h6 className="m-0">
                                Security Risk Matrix

                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                            </h6>
                        </div>
                    </a>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                        <div className="card-body ">

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Civil Litigation
                                            Investigation</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={civilLitigationInvestigation}
                                            maxLength="680"
                                            onChange={(e) => setCivilLitigationInvestigation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Criminal Record
                                            Investigation</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={criminalRecordInvestigation}
                                            maxLength="680"
                                            onChange={(e) => setCriminalRecordInvestigation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Bankruptcy Investigation </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={bankruptcyInvestigation}
                                            maxLength="680"
                                            onChange={(e) => setBankruptcyInvestigation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Corporate Interests
                                            Investigation</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={corporateInterestsInvestigation}
                                            maxLength="680"
                                            onChange={(e) => setCorporateInterestsInvestigation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">World Compliance
                                            Investigation</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={worldComplianceInvestigation}
                                            maxLength="680"
                                            onChange={(e) => setWorldComplianceInvestigation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Blacklist Investigation </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={blacklistInvestigation}
                                            maxLength="680"
                                            onChange={(e) => setBlacklistInvestigation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Media and Internet
                                            Searches</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={mediaInternetSearches}
                                            maxLength="680"
                                            onChange={(e) => setMediaInternetSearches(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Conclusion </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={conclusion}
                                            maxLength="680"
                                            onChange={(e) => setConclusion(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Background Information </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={backgroundInformation}
                                            maxLength="680"
                                            onChange={(e) => setBackgroundInformation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Derogatory Information </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            value={derogatoryInformation}
                                            maxLength="680"
                                            onChange={(e) => setDerogatoryInformation(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Employment Status and Sources of
                                            Income</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            onChange={(e) => setEmploymentStatusSourcesIncome(e.target.value)}
                                            maxLength="680"
                                            value={employmentStatusSourcesIncome}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Companies Undisclosed </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            onChange={(e) => setCompaniesUndisclosed(e.target.value)}
                                            maxLength="680"
                                            value={companiesUndisclosed}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Most important persons/companies
                                            with whom the applicant does business</label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            onChange={(e) => setMostImportantPersonsC(e.target.value)}
                                            maxLength="680"
                                            value={mostImportantPersonsC}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">Documents Submitted </label>
                                        <textarea id="textarea" className="form-control" rows="1" placeholder=""
                                            onChange={(e) => setDocumentsSubmitted(e.target.value)}
                                            maxLength="680"
                                            value={documentsSubmitted}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label for="validationCustom01" className="form-label">International bans and “red” or
                                            “restrictive lists of international law enforcement bodies</label>
                                        <input type="text" className="form-control" id="validationCustom01" required=""
                                            maxLength="680"
                                            onChange={(e) => setInternationalBansred(e.target.value)}
                                            value={internationalBansred}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6">  </div>

                                <div className="col-md-6 text-right">
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <button onClick={postData} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                    data-qa-tag="uploader-drop-zone-button">
                    {
                        load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                    }
                    Update</button>
            </div>
        </div>

    </>)
}

export default DependentEditProfile