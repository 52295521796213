import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Link, useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function ForgetPassword(prop) {




    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [validated, setValidated] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const param = useParams()
    console.log("param", param)


    var credential = {
        "email": email,
        "password": password
    }
    var emailValidate = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            if (!email === false && !password === false) {
                prop.login(credential)
            }

        }

        setValidated(true);
    };
    // setTimeout(() => {
    //     var body = document.getElementById("userpassword");
    //     body.addEventListener("keypress", function (e) {
    //         if (e.key === 'Enter') {
    //             e.preventDefault();
    //             document.getElementById("handleSubmit").click();
    //         }
    //     })
    // }, 500)


    useEffect(() => {
        var body = document.getElementById("userpassword");
        body.addEventListener("keypress", function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
                document.getElementById("handleSubmit").click();
            }
        })
    }, [])

    return (
        <>
            <div className="auth-body-bg py-5">
                <p>forgetPassword</p>
                <div className="account-pages pt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center mt-4">
                                            <div className="">
                                                <a className="">
                                                    <img src="assets/images/logo-light.png" alt="" height="150" className="auth-logo logo-dark mx-auto" />
                                                    <img src="assets/images/logo-light.png" alt="" height="150" className="auth-logo logo-light mx-auto" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <h4 className="font-size-18 text-muted mt-2 text-center">Forget Password</h4>
                                            <p className="text-muted text-center mb-4">Sign in to continue to GCSL.</p>
                                            <Form noValidate validated={validated} >
                                                <Form.Group controlId="username" className='mb-3'>
                                                    <Form.Label>User Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="username"
                                                        placeholder="Enter username"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    // pattern="/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}
                                                    //     [a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/"

                                                    />
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                    <Form.Control.Feedback type="invalid">Please enter a user name</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group controlId="userpassword" className='mb-3'>
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        className="form-control"
                                                        id="userpassword"
                                                        placeholder="Enter password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                    <Form.Control.Feedback type="invalid">Please  enter a  Password.</Form.Control.Feedback>
                                                </Form.Group>

                                                <div className="mb-3 row mt-4">
                                                    <div className="col-sm-6">
                                                        {/* <div className="form-checkbox">
                                                            <input type="checkbox" className="form-check-input me-1"
                                                                id="customControlInline" />
                                                            <label className="form-label form-check-label"
                                                                for="customControlInline">Remember me</label>
                                                        </div> */}
                                                    </div>

                                                    <div className="col-sm-6 text-end">

                                                        <span onClick={handleShow} style={{ cursor: 'pointer' }} className="text-muted"><i
                                                            className="mdi mdi-lock"></i> Forgot your password?</span>
                                                    </div>

                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-12 text-center">
                                                        <Button id="handleSubmit" onClick={handleSubmit} className="btn btn-primary w-100 waves-effect waves-light" >
                                                            {
                                                                prop.load && <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>
                                                            }
                                                            &nbsp;  <span style={{ color: "#fff" }}>Log In</span>
                                                        </Button>




                                                    </div>

                                                </div>

                                            </Form>
                                            <Modal show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title> Forgot Password</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>User Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter your User Name" />

                                                        </Form.Group>

                                                    </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" onClick={handleClose}>
                                                        Save Changes
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgetPassword