import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import DocFile from "./docfile"
import Form from 'react-bootstrap/Form'
import api from "../../apiUrl";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import jwt_decode from "jwt-decode";
import FamilyInquiries from "./familyInquiries";
import Mipc from "./mipc";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CorporateAffiliations from "./corporateAffiliations";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Confidential() {
    const [validated, setValidated] = useState(false);
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    var apis = api()
    // const param = useParams();
    //const { name } = param;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };
    const location = useLocation();
    console.log("state", location.state);
    var doc = location.state.doc;
    console.log("Case ID", location.state.id)

    var dates = new Date(location.state.createOn)
    var split = dates.toString().split('GMT')
    var dateString = split[0].trim()
    console.log("dateString", location.state.createOn)


    // const [data, setData] = useState([]);
    //Candidate Provided Details States
    const [clientType, setClientType] = useState("");
    const [Sname, setSName] = useState("Mr.");
    const [Fname, setFName] = useState("");
    const [Lname, setLName] = useState("");
    const [Gname, setGName] = useState("");
    const [gender, setGender] = useState("Male");
    const [placeOfBirth, setPlaceOfBirth] = useState("");
    const [dobDAY, setDobDAY] = useState("");
    const [day, setDay] = useState("01")
    const [dobMonth, setDobMonth] = useState("January");
    const [dobYear, setDobYear] = useState("2020");

    const [ppId, setPpId] = useState("");
    const [idCard, setidCard] = useState("");
    const [netid, setNetid] = useState("");
    const [knowAddress, setKnowAddress] = useState("");
    const [currentAddress, setCurrentAddress] = useState("");
    const [corporateAffiliations, setcorporateAffiliations] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");

    //Type of Inquiries States
    //const [sourcesSearched, setSourcesSearched] = useState("");
    const [civilLitigation, setCivilLitigation] = useState("");
    const [criminialRecord, setcriminialRecord] = useState("");
    const [internationalEmbassiesMission, setInternationalEmbassiesMission] = useState("");
    const [globalRegulatoryData, setGlobalRegulatoryData] = useState("");
    const [bankruptcyRecords, setBankruptcyRecords] = useState("");
    const [globalSanctionsWatchlists, setGlobalSanctionsWatchlists] = useState("");
    const [globalLawEnforcementData, setGlobalLawEnforcementData] = useState("");
    const [globalPEHPpFgod, setGlobalPEHPpFgod] = useState("");
    const [adverseMediaInternetSearches, setAdverseMediaInternetSearches] = useState("");

    //Date of Birth, Nationality, and Address Details States





    //Security Risk Matrix States
    const [civilLitigationInvestigation, setCivilLitigationInvestigation] = useState("");
    const [criminalRecordInvestigation, setCriminalRecordInvestigation] = useState("");
    const [bankruptcyInvestigation, setBankruptcyInvestigation] = useState("");
    const [corporateInterestsInvestigation, setCorporateInterestsInvestigation] = useState("");
    const [worldComplianceInvestigation, setWorldComplianceInvestigation] = useState("");
    const [blacklistInvestigation, setBlacklistInvestigation] = useState("");
    const [mediaInternetSearches, setMediaInternetSearches] = useState("");
    const [conclusion, setConclusion] = useState("");
    const [backgroundInformation, setBackgroundInformation] = useState("");
    const [derogatoryInformation, setDerogatoryInformation] = useState("");
    const [employmentStatusSourcesIncome, setEmploymentStatusSourcesIncome] = useState("");
    const [companiesUndisclosed, setCompaniesUndisclosed] = useState("");
    const [mostImportantPersonsC, setMostImportantPersonsC] = useState("");
    const [documentsSubmitted, setDocumentsSubmitted] = useState("");
    const [internationalBansred, setInternationalBansred] = useState("");
    const [val, setVal] = useState("");
    const [natval, setNatVal] = useState("");
    const [textval, setTextVale] = useState("");
    const [birthval, setbirthtextval] = useState("");



    //--------------Find BY GCSL -----------------------------
    const [findday, setFindDay] = useState("01")
    const [finddobMonth, setFindDobMonth] = useState("January");
    const [finddobYear, setFindDobYear] = useState("2020");
    const [findplaceOfBirth, setFindPlaceOfBirth] = useState("");
    const [findnetid, setFindNetid] = useState("");
    const [findnatval, setFindNatVal] = useState("");
    const [findcurrentAddress, setFindCurrentAddress] = useState("");
    //--------------Find BY GCSL -----------------------------


    const editorConfiguration = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'link', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
            shouldNotGroupWhenFull: false,
            wordcount: {
                showCharCount: true,
            }

        }


    };

    const numberValidated = (e) => {
        console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        // if (e.target.value === "" || regex.test(e.target.value)) {
        //     console.log("e.target.value", e.target.value)
        // setVal(e.target.value);
        // }
        setVal(e.target.value);
    };
    const natnumberValidated = (e) => {
        console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        // if (e.target.value === "" || regex.test(e.target.value)) {
        //     console.log("e.target.value", e.target.value)
        //     setNatVal(e.target.value);
        // }
        setNatVal(e.target.value);
    };
    const findnatnumberValidated = (e) => {
        console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        // if (e.target.value === "" || regex.test(e.target.value)) {
        //     console.log("e.target.value", e.target.value)
        //     setNatVal(e.target.value);
        // }
        setFindNatVal(e.target.value);
    };

    const onlyTextValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        // if (e.target.value === "" || regex.test(e.target.value)) {
        //     console.log("e.target.value", e.target.value)
        //     setTextVale(e.target.value);
        // }
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setTextVale(e.target.value);
        }

    };
    const birthTextValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        // if (e.target.value === "" || regex.test(e.target.value)) {
        //     console.log("e.target.value", e.target.value)
        //     setbirthtextval(e.target.value);
        // }
        setbirthtextval(e.target.value);
    };


    // Date of Birth perameter
    var date = new Date();
    var currentYear = date.getFullYear()
    var yearArr = []
    for (let i = 0; i < 100; i++) {
        yearArr.push(currentYear - i)
    }

    var monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    var dayArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]

    function getFebDay() {

        function daysInThisMonth() {
            var now = new Date();
            return new Date(dobYear, 2, 0).getDate();
        }

        var getDay = daysInThisMonth();
        dayArr = []
        for (let i = 0; i < getDay; i++) {
            dayArr.push(getDay - i)
        }
        dayArr.reverse()
        console.log("getDay", getDay, dobYear, dayArr)
    }

    if (dobMonth === 'February') {
        getFebDay()
    }

    // function leapyear(year) { return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0); }

    useEffect(() => {

    }, [dobYear])

    useEffect(() => {
        setDobYear(yearArr[0].toString())
        getFebDay()
    }, [])

    console.log(yearArr);
    function isDateValid(inputDate) {
        const monthMap = {
            "January": 1,
            "February": 2,
            "March": 3,
            "April": 4,
            "May": 5,
            "June": 6,
            "July": 7,
            "August": 8,
            "September": 9,
            "October": 10,
            "November": 11,
            "December": 12,
        };

        const dateComponents = inputDate.split('-');
        const year = parseInt(dateComponents[0]);
        const month = monthMap[dateComponents[1]];
        const day = parseInt(dateComponents[2]);

        if (
            isNaN(year) ||
            isNaN(month) ||
            isNaN(day) ||
            month < 1 || month > 12 || day < 1 || day > 31
        ) {
            // The date is invalid
            return false;
        }

        // Check for valid days based on the month
        const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        const daysInMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (day > daysInMonth[month - 1]) {
            // The date is invalid
            return false;
        }

        // Create a new Date object and check if it is valid
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) {
            // The date is invalid
            return false;
        }

        // The date is valid
        return true;
    }

    // Example usage:


    const inputDate2 = `${dobYear}-${dobMonth}-${day}`;
    const isValid2 = isDateValid(inputDate2);
    ; // Output: true
    useEffect(() => {
        console.log("isValid2", dobYear, dobMonth, day, isDateValid(inputDate2))
    }, [dobYear, dobMonth, day])
    // Date of Birth perameter


    var createBy = location.state.createBy;
    var createOn = location.state.createOn;
    console.log(location.state)

    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    var postDataJson = {
        "createBy": createBy,
        "createOn": createOn,
        "caseId": location.state.id,
        "userId": location.state.id,
        "salutation": Sname,
        "FirstName": Fname,
        "LastName": Lname,
        "GivenName": Gname,
        "Gender": gender,
        "BirthOfDay": day,
        "BirthOfMonth": dobMonth,
        "BirthOfYear": dobYear,
        "placeOfBirth": placeOfBirth,
        "ppId": ppId,
        "idCard": idCard,
        "knowAddress": knowAddress,
        "currentAddress": currentAddress,
        //"corpora  teAffiliations": corporateAffiliations,
        "civilLitigationInvestigation": civilLitigationInvestigation,
        "criminalLitigationInvestigation": criminalRecordInvestigation,
        "bankruptcyInvestigation": bankruptcyInvestigation,
        "corporateInterestsInvestigation": corporateInterestsInvestigation,
        "worldComplianceInvestigation": worldComplianceInvestigation,
        "blacklistInvestigation": blacklistInvestigation,
        "mediaInternetSearches": mediaInternetSearches,
        "natID": netid,
        "CurrentUserId": currentUserId,
        //--------------Find BY GCSL -----------------------------
        "FbyGcsldate": findday,
        "FbyGcslMonth": finddobMonth,
        "FbyGcslYear": finddobYear,
        "placeofBirthFindingsbyGCSL": findplaceOfBirth,
        "natIDFindingsbyGCSL": findnetid,
        "currentAddressFindingsbyGCSL": findcurrentAddress,

    }
    if (location.state.dependent == true) {
        postDataJson.dependentId = location.state.dependentId
    }
    var dependentSatus = location.state.dependent
    //var obj = {name:"Ayush",lastName:"Sengar"}
    function postData() {
        console.log("validate Date", inputDate2)
        if (isDateValid(inputDate2) == true) {
            console.log("Json", JSON.stringify(postDataJson))
            setLoad(true)
            console.log(JSON.stringify(postDataJson))
            console.log("postDataJson", postDataJson)
            if (location.state.dependent == true) {
                fetch(`${apis}Admin/CreateProfileForDependent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(postDataJson),
                }).then((respon) => {
                    respon.json().then((result) => {
                        console.log(result);
                        setLoad(false)
                        navigate('/')
                    })
                })

            } else {
                fetch(`${apis}Admin/CreateProfile`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(postDataJson),
                }).then((respon) => {
                    respon.json().then((result) => {
                        console.log(result);
                        setLoad(false)
                        navigate('/')
                    })
                })
            }
        } else {
            setLoad(false);
            setMessage("Please select valid date.")//set in value in tMessage useState for Toster Message
            setOpens(true);//set in value in useState
            setColor("error")//set in value in useState
        }



    }

    // const handleSubmit = (event) => {
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     } else {
    //         postData()
    //         // console.log("postDataJson", postDataJson)
    //         // if (
    //         //     !name === false &&
    //         //     !placeOfBirth === false &&
    //         //     !ppId === false &&
    //         //     !idCard === false &&
    //         //     !netid === false &&
    //         //     !knowAddress === false &&
    //         //     !currentAddress === false &&
    //         //     !corporateAffiliations === false &&
    //         //     !civilLitigationInvestigation === false &&
    //         //     !criminialRecord === false &&
    //         //     !internationalEmbassiesMission === false &&
    //         //     !globalRegulatoryData === false &&
    //         //     !criminalRecordInvestigation === false &&
    //         //     !bankruptcyInvestigation === false &&
    //         //     !corporateInterestsInvestigation === false &&
    //         //     !worldComplianceInvestigation === false &&
    //         //     !blacklistInvestigation === false &&
    //         //     !mediaInternetSearches === false &&
    //         //     //!conclusion === false &&
    //         //     //!backgroundInformation === false &&
    //         //     //!derogatoryInformation === false &&
    //         //     //!employmentStatusSourcesIncome === false &&
    //         //     //!companiesUndisclosed === false &&
    //         //     //!mostImportantPersonsC === false &&
    //         //     //!documentsSubmitted === false &&
    //         //     //!internationalBansred === false &&
    //         //     !civilLitigation === false &&
    //         //     !bankruptcyRecords === false &&
    //         //     !globalSanctionsWatchlists === false &&
    //         //     !globalLawEnforcementData === false &&
    //         //     !globalPEHPpFgod === false &&
    //         //     !netid === false &&
    //         //     !criminialRecord === false &&
    //         //     !internationalEmbassiesMission === false &&
    //         //     !globalRegulatoryData === false &&
    //         //     !adverseMediaInternetSearches === false) {
    //         //     console.log("good")
    //         //     postData()
    //         // } else {
    //         //     console.log("not")
    //         // }

    //     }

    //     setValidated(true);
    // };

    useEffect(() => {
        // var modalBackdrop = document.getElementsByClassName("modal-backdrop");
        // if (modalBackdrop.length > 0) {
        //     modalBackdrop[0].classList.remove('show')
        // }

    }, [])
    return (<>

        <div className="border-bottom px-3 pt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#!">Documents</a>
                                    </li>
                                    <li className="breadcrumb-item active">Create Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row align-it">
                <div className="col-md-12 py-2">
                    <div className="row align-it">
                        <div className="col-md-12 text-center d-flex">

                            <p className="card-text mb-0 px-2">Case Name : {location.state.caseName} &nbsp; &nbsp; |</p>
                            <p className="card-text mb-0 px-2">Created By : {location.state.createBy} &nbsp; &nbsp; |</p>
                            <p className="card-text mb-0 px-2">Created On : {location.state.createOn}</p>
                            <p className="card-text mb-0 px-2">Client Name : {location.state.clientName}</p>

                        </div>
                    </div>
                    <div className="row align-it mt-4">
                        {
                            doc.map((item) =>
                                <DocFile fileType={item.fileType} fileName={item.fileName} filePath={item.filePath} />
                            )
                        }
                    </div>
                </div>
            </div>
            <Form noValidate validated={validated} >
                <div id="accordion" className="custom-accordion mt-4">
                    <div className="card mb-1 shadow-none">
                        <a href="#collapseOne" className="text-dark" data-bs-toggle="collapse" aria-expanded="false"
                            aria-controls="collapseOne">
                            <div className="card-header" id="headingOne">
                                <h6 className="m-0">
                                    Candidate Provided Details
                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordion">
                            <div className="card-body p-0">
                                <div className="">
                                    <div className="card-body ">
                                        {/*Given Name*/}
                                        <div className="row">
                                            <div className="col-md-1">
                                                <Form.Group className="mb-3" controlId="validationCustom11">
                                                    <Form.Label><span>&nbsp;</span></Form.Label>
                                                    <Form.Control required as="select" type="select"
                                                        id="validationCustom11"
                                                        onChange={(e) => setSName(e.target.value)}
                                                    >
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Ms.">Ms.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            {/* <div className="col-md-3">
                                                <Form.Group className="mb-3" controlId="validationCustom01">
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={Fname}
                                                        //  maxLength="50"
                                                        id="validationCustom01"
                                                        onChange={(e) => {
                                                            onlyTextValidated(e)
                                                            setFName(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div> */}
                                            <div className="col-md-5">
                                                <Form.Group className="mb-3" controlId="validationCustom02">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom02"
                                                        value={Lname}
                                                        //  maxLength="50"
                                                        onChange={(e) => {
                                                            onlyTextValidated(e)
                                                            setLName(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom02">
                                                    <Form.Label>Given Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom02"
                                                        value={Gname}
                                                        //  maxLength="50"
                                                        onChange={(e) => {
                                                            onlyTextValidated(e)
                                                            setGName(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {/*Given Name*/}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label><span>Date of Birth</span></Form.Label>
                                                            <Form.Control required as="select" type="select"
                                                                id="validationCustom11"
                                                                onChange={(e) => setDay(e.target.value)}
                                                            ><option value="" disabled>-- Day --</option>
                                                                {
                                                                    dayArr.map((item) =>
                                                                        <option value={item}

                                                                        //selected={item == "3" ? true : false}
                                                                        >{item}</option>
                                                                    )
                                                                }
                                                            </Form.Control>

                                                            <Form.Control.Feedback type="invalid">
                                                                Please select this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label><span>&nbsp;</span></Form.Label>
                                                            <Form.Control required as="select" type="select"
                                                                id="validationCustom11"
                                                                onChange={(e) => setDobMonth(e.target.value)}
                                                            ><option value="" disabled>-- Month --</option>
                                                                {
                                                                    monthArr.map((item) =>
                                                                        <option value={item}
                                                                        //selected={item == "November" ? true : false}
                                                                        >{item}</option>
                                                                    )
                                                                }
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label><span>&nbsp;</span></Form.Label>
                                                            <Form.Control required as="select" type="select"
                                                                id="validationCustom11"
                                                                onChange={(e) => setDobYear(e.target.value)}
                                                            ><option value="" disabled>-- Year --</option>
                                                                {
                                                                    yearArr.map((item) =>
                                                                        <option value={item}
                                                                        //selected={item == "2020" ? true : false}
                                                                        >{item}</option>
                                                                    )
                                                                }

                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label><span>Findings by GCSL</span></Form.Label>
                                                            <Form.Control required as="select" type="select"
                                                                id="validationCustom11"
                                                                onChange={(e) => setFindDay(e.target.value)}
                                                            ><option value="" disabled>-- Day --</option>
                                                                {
                                                                    dayArr.map((item) =>
                                                                        <option value={item}

                                                                        //selected={item == "3" ? true : false}
                                                                        >{item}</option>
                                                                    )
                                                                }
                                                            </Form.Control>

                                                            <Form.Control.Feedback type="invalid">
                                                                Please select this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label><span>&nbsp;</span></Form.Label>
                                                            <Form.Control required as="select" type="select"
                                                                id="validationCustom11"
                                                                onChange={(e) => setFindDobMonth(e.target.value)}
                                                            ><option value="" disabled>-- Month --</option>
                                                                {
                                                                    monthArr.map((item) =>
                                                                        <option value={item}
                                                                        //selected={item == "November" ? true : false}
                                                                        >{item}</option>
                                                                    )
                                                                }
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                                            <Form.Label><span>&nbsp;</span></Form.Label>
                                                            <Form.Control required as="select" type="select"
                                                                id="validationCustom11"
                                                                onChange={(e) => setFindDobYear(e.target.value)}
                                                            ><option value="" disabled>-- Year --</option>
                                                                {
                                                                    yearArr.map((item) =>
                                                                        <option value={item}
                                                                        //selected={item == "2020" ? true : false}
                                                                        >{item}</option>
                                                                    )
                                                                }

                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form.Group className="mb-3" controlId="validationCustom02">
                                                    <Form.Label>Gender</Form.Label>
                                                    <Form.Control required as="select" type="select"
                                                        id="validationCustom111"
                                                        onChange={(e) => setGender(e.target.value)}
                                                    ><option value="" disabled>-- Select Gender --</option>
                                                        <option value="Male" >Male</option>
                                                        <option value="Female">Female</option>


                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom02">
                                                    <Form.Label>Place of Birth</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom02"
                                                        value={birthval}
                                                        //    maxLength="50"
                                                        onChange={(e) => {
                                                            birthTextValidated(e)
                                                            setPlaceOfBirth(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom02">
                                                    <Form.Label>Findings by GCSL</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom02"
                                                        value={findplaceOfBirth}
                                                        //    maxLength="50"
                                                        onChange={(e) => {

                                                            setFindPlaceOfBirth(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom03">
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="date"
                                                        id="validationCustom03"
                                                        data-provide="datepicker"
                                                        data-date-container="#datepicker3"
                                                        data-date-format="dd M, yyyy" data-date-multidate="true"
                                                        onChange={(e) => setDob(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Choose a date
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div> */}
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom04">
                                                    <Form.Label>P.P. ID</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom04"
                                                        //   maxLength="10"
                                                        onChange={(e) => setPpId(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom05">
                                                    <Form.Label>ID Card</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom05"
                                                        value={val}
                                                        //   maxLength="10"
                                                        onChange={(e) => {
                                                            numberValidated(e)
                                                            setidCard(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom05-1">
                                                    <Form.Label>Nat. ID</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom05"
                                                        value={natval}
                                                        //  maxLength="10"
                                                        onChange={(e) => {
                                                            natnumberValidated(e)
                                                            setNetid(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom05-1">
                                                    <Form.Label>Findings by GCSL</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom05"
                                                        value={findnatval}
                                                        //  maxLength="10"
                                                        onChange={(e) => {
                                                            findnatnumberValidated(e)
                                                            setFindNetid(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form.Group className="mb-3" controlId="validationCustom06">
                                                    <Form.Label>Known Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        // maxLength="130"
                                                        id="validationCustom06"
                                                        style={{ display: "block" }}
                                                        onChange={(e) => setKnowAddress(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom08">
                                                    <Form.Label>Current Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        id="validationCustom08"
                                                        // maxLength="130"
                                                        style={{ display: "block" }}
                                                        onChange={(e) => setCurrentAddress(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom08">
                                                    <Form.Label>Findings by GCSL</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        id="validationCustom08"
                                                        // maxLength="130"
                                                        style={{ display: "block" }}
                                                        onChange={(e) => setFindCurrentAddress(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form.Group className="mb-3" controlId="validationCustom07">

                                                    {/* <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        id="validationCustom07"
                                                        maxLength="130"
                                                        onChange={(e) => setcorporateAffiliations(e.target.value)}
                                                    /> */}
                                                    <CorporateAffiliations caseId={location.state.id} dependentId={location.state.dependentId} dependentSatus={dependentSatus} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------- */}
                    {/* <div className="card mb-1 shadow-none">
                        <a href="#collapseTwo" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                            aria-controls="collapseTwo">
                            <div className="card-header" id="headingTwo">
                                <h6 className="m-0">
                                    Type of Inquiries
                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                            <div className="card-body  p-0">
                                <div className="">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom09">
                                                    <Form.Label>Civil Litigation</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom09"
                                                        maxLength="50"
                                                        onChange={(e) => setCivilLitigation(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom10-1">
                                                    <Form.Label>Criminial Record</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom10"
                                                        maxLength="50"
                                                        onChange={(e) => setcriminialRecord(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>

                                        </div>
                                        <div className="row"> <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="validationCustom14">
                                                <Form.Label>Bankruptcy records</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    id="validationCustom14"
                                                    maxLength="50"
                                                    onChange={(e) => setBankruptcyRecords(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                            <div className="col-md-6 col-12">
                                                <Form.Group className="mb-3" controlId="validationCustom11">
                                                    <Form.Label>Global Sanctions and  Watchlists:</Form.Label>
                                                    <Form.Control required as="select" type="select"
                                                        id="validationCustom11"
                                                        onChange={(e) => setGlobalSanctionsWatchlists(e.target.value)}
                                                    ><option value="">Select valid Option</option>
                                                        <option value="Global Sanction Database (GSD)">Global Sanction Database (GSD)</option>
                                                        <option value="Office of Foreign Access Control (OFAC)">Office of Foreign Access Control (OFAC)</option>
                                                        <option value="Excluded Parties List System (EPLS)">Excluded Parties List System (EPLS)</option>
                                                        <option value="U.S. Department of State">U.S. Department of State</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                        </div>



                                        <div className="row">

                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom13">
                                                    <Form.Label>Global Law Enforcement Data</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom13"
                                                        maxLength="50"
                                                        onChange={(e) => setGlobalLawEnforcementData(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom09-1">
                                                    <Form.Label>International Embassies/Mission</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom09"
                                                        maxLength="50"
                                                        onChange={(e) => setInternationalEmbassiesMission(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom10-2">
                                                    <Form.Label>Global Regulatory Data</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom10"
                                                        maxLength="50"
                                                        onChange={(e) => setGlobalRegulatoryData(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom14">
                                                    <Form.Label>Global P.E.P/High-Profile persons/ Foreign Government Official Data</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom14"
                                                        maxLength="50"
                                                        onChange={(e) => setGlobalPEHPpFgod(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>

                                        </div>
                                        <div className="row">


                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom15">
                                                    <Form.Label>Adverse Media and Internet Searches</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom16"
                                                        maxLength="50"
                                                        onChange={(e) => setAdverseMediaInternetSearches(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* ------------------------------------------------------------------------------------------- */}
                    {/* --------------------------Family Inquiries ----------------------------------------------------------------- */}
                    <FamilyInquiries caseId={location.state.id} dependentSatus={dependentSatus} varification={false} dependentId={location.state.dependentId} />
                    <Mipc caseId={location.state.id} dependentSatus={dependentSatus} varification={false} dependentId={location.state.dependentId} />
                    {/* --------------------------Family Inquiries ----------------------------------------------------------------- */}

                    {/* <div className="card mb-0 shadow-none">
                        <a href="#collapseThree" className="text-dark collapsed" data-bs-toggle="collapse"
                            aria-expanded="false" aria-controls="collapseThree">
                            <div className="card-header" id="headingThree">
                                <h6 className="m-0">
                                    Date of Birth, Nationality, and Address Details

                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Date of Birth</label>
                                            <div className="input-group" id="datepicker3">
                                                <input type="date" className="form-control" placeholder="dd/mm/yyyy"
                                                    data-provide="datepicker" data-date-container="#datepicker3"
                                                    data-date-format="dd M, yyyy" data-date-multidate="true"
                                                    onChange={(e) => setFormThreeDob(e.target.value)}
                                                />

                                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Place of Birth</label>

                                            <input type="text" className="form-control"
                                                onChange={(e) => setFormThreePlaceOfBirth(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                </div>

                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Nat. ID </label>

                                            <input type="text" className="form-control"
                                                onChange={(e) => setNatId(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Current
                                                Address </label>

                                            <input type="text" className="form-control"
                                                onChange={(e) => setCurrentAddress(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>

                    </div> */}
                    {/* ------------------------------------------------------------------------------------------- */}
                    {/* <div className="card mb-0 shadow-none mb-3">
                        <a href="#collapseFour" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                            aria-controls="collapseFour">
                            <div className="card-header" id="headingFour">
                                <h6 className="m-0">
                                    Security Risk Matrix

                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                            <div className="card-body ">

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom16">
                                            <Form.Label>Civil Litigation Investigation</Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    console.log({ event, editor, data });
                                                    setCivilLitigationInvestigation(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom17">
                                            <Form.Label>Criminal Record Investigation</Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setCriminalRecordInvestigation(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom18">
                                            <Form.Label>Bankruptcy Investigation </Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setBankruptcyInvestigation(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom19">
                                            <Form.Label>Corporate Interests Investigation</Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setCorporateInterestsInvestigation(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom20">
                                            <Form.Label>World Compliance Investigation</Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setWorldComplianceInvestigation(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom20">
                                            <Form.Label>Blacklist Investigation </Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setBlacklistInvestigation(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom21">
                                            <Form.Label>Media and Internet Searches</Form.Label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data=""
                                                onReady={editor => {
                                                    editor.ui.view.editable.element.style.height = '300px'

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setMediaInternetSearches(data)

                                                }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                </div>









                                <div className="row">
                                    <div className="col-md-6">  </div>

                                    <div className="col-md-6 text-right">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div> */}
                    <button onClick={postData} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                        data-qa-tag="uploader-drop-zone-button">
                        {
                            load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                        }
                        Submit</button>
                </div>
            </Form>
            <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div >

    </>)
}

export default Confidential