import React from 'react'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
//import DocFile from "./docfile"
//import ModulePopup from "./model-popup"
import api from "../../apiUrl";
import jwt_decode from "jwt-decode";
import Casecompo from '../casecompo';
function StackholderClosedCase(prop) {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [load, setLoad] = useState(false)
    // const navigate = useNavigate();
    var ms3 = "ms-3";
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    var apis = api()
    function getAPi() {
        setLoad(true)
        fetch(`${apis}User/FindClosedCasesUser/${currentUserId}`, {
            mode: "cors",
        }).then((respon) => {
            respon.json().then((result) => {
                console.log(result);
                setData(result.data)
                setLoad(false)
            })
        })
    }

    useEffect(() => {
        getAPi()
        // setData(json)
    }, [])
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12 p-0">
                            <div className="page-title-box align-items-center justify-content-between pb-0">
                                <h4 className="mb-0">Welcome back! {prop.name} {api}</h4>
                                <p>&nbsp;</p>
                                {/* <p>Your last login: Today at 02:46 am</p> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-12 text-right p-0">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-xl-8 col-md-8">
                                <h5 className="mt-3 mb-0">Closed  Cases</h5>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <form className="app-search d-none d-lg-block px-3 pb-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-transparent" placeholder="Search.."
                                            aria-label="Text input with dropdown button"
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <span className="mdi mdi-magnify"></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                {
                    data.length == 0 ? <div className='records'><p>Records not found</p></div> : null
                }
                {
                    load ? <div class="spin-a"><div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> :
                        <div className="row mt-4">
                            <div className="col-lg-12 col-12">
                                <ul>
                                    {
                                        data &&
                                        data.length > 0 &&
                                        data.filter((item) => {
                                            if (search === "") return item; // searchTerm is in scope?
                                            if (item.candidateName.toLowerCase().includes(search.toLowerCase())) {
                                                return item;
                                            } else {
                                                return false;
                                            }

                                        }).map((item, i) =>
                                            <Casecompo data={item}
                                            />
                                        )
                                    }
                                </ul>

                            </div>
                        </div>
                }


            </div>

        </>
    )
}

export default StackholderClosedCase