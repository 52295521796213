import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useFetcher } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Col, Row, Table } from 'react-bootstrap';
import api from '../../apiUrl';
import ButtonM from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function CaseAllocated(prop) {
    var apis = api()
    const [show, setShow] = useState(false);
    const [data, setData] = useState([])
    const [reviewer, setReviewer] = useState([])
    const [verificationResearcher, setVerificationResearcher] = useState([])
    const [finalVerificationResearcher, setFinalVerificationResearcher] = useState([])
    //--------------------------------------------------------------------
    const [reviewervalue, setReviewervalue] = useState([])
    const [verificationResearchervalue, setVerificationResearchervalue] = useState([])
    const [finalVerificationResearchervalue, setFinalVerificationResearchervalue] = useState([])

    const [load, setLoad] = useState(false)
    const [getId, setGetId] = useState("")
    const [search, setSearch] = useState("");
    console.log("propRole", prop)

    const [open, setOpen] = useState(false);
    const handleClickOpen = (e) => {
        setOpen(true);

    };
    const handleCloseD = () => {
        setOpen(false);
    };



    const [validated, setValidated] = useState(false);





    const [selectedValues, setSelectedValues] = useState([]);
    const handleCheckboxChange = (value) => {
        // Toggle the selection of the checkbox value
        setSelectedValues(prevValues => {
            const parsedValue = parseInt(value); // or parseFloat(value) for decimal values

            if (!isNaN(parsedValue)) {
                if (prevValues.includes(parsedValue)) {
                    return prevValues.filter(val => val !== parsedValue);
                } else {
                    return [...prevValues, parsedValue];
                }
            } else {
                // Handle the case where the value is not a valid number
                console.error('Invalid value:', value);
                return prevValues;
            }
        });
        console.log("selectedValues", selectedValues);
        console.log("selectedValues", selectedValues, reviewervalue)
    };
    const [userIdArray, setUserIdArray] = useState([]);
    const [length, setLength] = useState(0)
    const [oldValue, setOldValue] = useState()
    function getUserss() {
        fetch(`${apis}Admin/GetAdminUserList/${prop.role}/${prop.caseId}`).then((respons) => {
            respons.json().then((result) => {
                // console.log("getUser", result)
                var propss;


                // console.log("listdata", listdata)
                setData(result.data)
                setUserIdArray(result.data.map(item => item.userId))
                // if (prop.role == "GCSLResearcher") {
                //     setSelectedValues(result.selected)
                // }
                setSelectedValues(result.selected)
                setLength(result.selected.length)
                if (role !== "GCSLResearcher") {
                    const matchingUserIds = result.data
                        .filter(item => result.selected.includes(item.userId))
                        .map(item => item.userId);

                    matchingUserIds.forEach(userId => {
                        setOldValue(userId);
                    });
                }
            })
        })
    }
    console.log("length", length)

    var selectedValuesLength = selectedValues.length
    console.log("length", selectedValuesLength)
    //--------------------------------------------------------
    function getreviewer() {
        fetch(`${apis}Admin/GetAdminUserList/${"GCSLReviewer"}/${prop.caseId}`).then((respons) => {
            respons.json().then((result) => {
                console.log("listdata getreviewer", result.data)
                setReviewer(result.data)

                if (selectedValues.length > 0) {
                    result.data.map((item) => {
                        selectedValues.includes(item.userId) == item.userId ? setReviewervalue([]) : setReviewervalue(parseInt(item.userId))
                    })
                }



                // setUserIdArray(result.data.map(item => item.userId))
                // if (prop.role == "GCSLResearcher") {
                //     setSelectedValues(result.selected)
                // }


            })
        })
    }
    //--------------------------------------------------------
    function getVerificationResearcher() {
        fetch(`${apis}Admin/GetAdminUserList/${"GCSLVerificationResearcher"}/${prop.caseId}`).then((respons) => {
            respons.json().then((result) => {
                console.log("listdata getVerificationResearcher", result.data)
                setVerificationResearcher(result.data)
                if (selectedValues.length > 0) {
                    result.data.map((item) => {
                        selectedValues.includes(item.userId) == item.userId ? setVerificationResearchervalue([]) : setVerificationResearchervalue(parseInt(item.userId))
                    })
                }




                // setUserIdArray(result.data.map(item => item.userId))
                // if (prop.role == "GCSLResearcher") {
                //     setSelectedValues(result.selected)
                // }


            })
        })
    }
    //--------------------------------------------------------
    function getFinalVerificationResearcher() {
        fetch(`${apis}Admin/GetAdminUserList/${"FinalVerificationResearcher"}/${prop.caseId}`).then((respons) => {
            respons.json().then((result) => {
                console.log("listdata getFinalVerificationResearcher", result.data)
                setFinalVerificationResearcher(result.data)
                if (selectedValues.length > 0) {
                    result.data.map((item) => {
                        selectedValues.includes(item.userId) == item.userId ? setFinalVerificationResearchervalue([]) : setFinalVerificationResearchervalue(parseInt(item.userId))
                    })
                }




                // setUserIdArray(result.data.map(item => item.userId))
                // if (prop.role == "GCSLResearcher") {
                //     setSelectedValues(result.selected)
                // }


            })
        })
    }
    // useState(() => {
    //     const userIds = data.map(item => item.userId);
    //     setUserIdArray(userIds);
    //   }, [data])

    useEffect(() => {
        //  getUserss()
        if (role == "GCSLResearcher") {
            getreviewer()
            getVerificationResearcher()
            getFinalVerificationResearcher()
        }

    }, [length])

    useEffect(() => {
        setSelectedValues(selectedValues)
    }, [selectedValues])
    const handleShow = () => {
        setShow(true);
        getUserss()
        // setReviewervalue([])
        // setVerificationResearchervalue([])
        // setFinalVerificationResearchervalue([])
        if (role == "GCSLResearcher") {
            getreviewer()
            getVerificationResearcher()
            getFinalVerificationResearcher()

        }
    }


    function allocated() {

        if (role == "GCSLResearcher") {
            var allocatedJson = {
                "caseId": prop.caseId,
                "userId": selectedValues.concat(reviewervalue, verificationResearchervalue, finalVerificationResearchervalue),
                "role": prop.role,
                "stage": prop.stage,
                "caseCurrentLevel": prop.currentLevel == "PENDING" ? "LEVEL1" : prop.currentLevel == "LEVEL1" ? "LEVEL2" : prop.currentLevel == "LEVEL2" ? "LEVEL3" : prop.currentLevel == "LEVEL3" ? "LEVEL4" : null,
                "caseCurrentLevel1": prop.currentLevel1 == 0 ? 1 : prop.currentLevel1 == 1 ? 2 : prop.currentLevel1 == 2 ? 3 : prop.currentLevel1 == 3 ? 4 : null,
                "CaseLevel": prop.currentLevel == "PENDING" ? "LEVEL1" : prop.currentLevel
            }
        } else {
            var allocatedJson = {
                "caseId": prop.caseId,
                "userId": selectedValues,
                "role": prop.role,
                "stage": prop.stage,
                "caseCurrentLevel": "0",
                "caseCurrentLevel1": 0,
                "CaseLevel": prop.currentLevel == "PENDING" ? "LEVEL1" : prop.currentLevel,
                "oldValue": oldValue
            }
        }
        console.log("allocatedJson", allocatedJson)
        setLoad(true)
        fetch(`${apis}Admin/CaseAllotDetails`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(allocatedJson),
        }).then((respons) => {
            respons.json().then((result) => {
                // console.log("getUser Post", result)
                setLoad(false)
                setShow(false)
                prop.reload()
                setReviewervalue([])
                setVerificationResearchervalue([])
                setFinalVerificationResearchervalue([])
                setSelectedValues([])
                setOldValue()
            })
        })
    }
    function allocatedAll() {
        console.log("prop.currentLevel", prop.currentLevel)
        setSelectedValues(userIdArray)
        // var allocatedJson = {
        //     "caseId": prop.caseId,
        //     "userId": role == "GCSLResearcher" ? userIdArray : [getId],
        //     "role": prop.role,
        //     "stage": prop.stage,
        //     "caseCurrentLevel": prop.currentLevel == "PENDING" ? "LEVEL1" : prop.currentLevel == "LEVEL1" ? "LEVEL2" : prop.currentLevel == "LEVEL2" ? "LEVEL3" : prop.currentLevel == "LEVEL3" ? "LEVEL4" : null,
        //     "caseCurrentLevel1": prop.currentLevel1 == 0 ? 1 : prop.currentLevel1 == 1 ? 2 : prop.currentLevel == 2 ? 3 : prop.currentLevel1 == 3 ? 4 : null
        // }
        // console.log("userIdArray", prop.currentLevel, allocatedJson)
        // setLoad(true)
        // fetch(`${apis}Admin/CaseAllotDetails`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     mode: "cors",
        //     body: JSON.stringify(allocatedJson),
        // }).then((respons) => {
        //     respons.json().then((result) => {
        //         // console.log("getUser Post", result)
        //         setLoad(false)
        //         setShow(false)
        //         prop.reload()
        //     })
        // })
    }

    const handleClose = () => allocated();
    const crossClose = () => {
        setReviewervalue([])
        setVerificationResearchervalue([])
        setFinalVerificationResearchervalue([])
        setSelectedValues([])
        setLength(0)
        setShow(false)
        setOldValue()

    };

    const role = prop.role
    // useEffect(() => {
    //     getUserss()

    // if(role == "GCSLResearcher"){
    //     getreviewer()
    //     getVerificationResearcher()
    //     getFinalVerificationResearcher()
    // }

    // }, [])

    useEffect(() => {
        // console.log("getId", getId)
    }, [getId])




    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (role == "GCSLResearcher") {
                if (selectedValues.length !== 0 && reviewervalue.length !== 0 && verificationResearchervalue.length !== 0 && finalVerificationResearchervalue.length !== 0) {
                    allocated()
                    //selectedValues.length == 0 && role == "GCSLResearcher" ? handleClickOpen() : getId !== "" || selectedValues.length !== 0 ? handleClose() : null
                }
            } else {
                allocated()
            }

        }

        setValidated(true);
    };
    const [allocateAll, setAllocateAll] = useState(false)
    function selectAll(e) {
        setAllocateAll(e.target.checked)
        allocateAll == true ? setSelectedValues([]) : setSelectedValues(userIdArray)
    }



    return (
        <>

            <Link onClick={handleShow} >
                <span className="B1piuH">
                    <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path fill="currentColor" d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12q0-1.25.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2q1.25 0 2.125.875T21 5q0 1.25-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12q0 .175-.025.363T8.9 12.7l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19q0 1.25-.875 2.125T18 22Zm0-16q.425 0 .713-.288T19 5q0-.425-.288-.713T18 4q-.425 0-.713.288T17 5q0 .425.288.713T18 6ZM6 13q.425 0 .713-.288T7 12q0-.425-.288-.713T6 11q-.425 0-.713.288T5 12q0 .425.288.713T6 13Zm12 7q.425 0 .713-.288T19 19q0-.425-.288-.713T18 18q-.425 0-.713.288T17 19q0 .425.288.713T18 20Zm0-15ZM6 12Zm12 7Z" /></svg>
                    </i>
                </span>
                <span className="rglmnm">Case Allocation</span>
            </Link>
            <Modal show={show} onHide={crossClose} size='xl' backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Case Allocation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Search User for {prop.role == "GCSLResearcher" ? "Researcher" : prop.role == "GCSLResearcher2" ? "Researcher 2" : prop.role == "GCSLResearcher3" ? "Researcher 3" : prop.role == "GCSLReviewer" ? "Reviewer" : prop.role == "GCSLVerificationResearcher" ? "Verification Researcher" : prop.role == "FinalVerificationResearcher" ? "Final Verification Researcher" : null}</Form.Label>
                        <Form.Control type="text" placeholder="Search by Email & Name" onChange={(e) => setSearch(e.target.value)} />
                    </Form.Group>
                    <Form validated={validated}>
                        <Form.Group controlId="formBasicEmail">

                            <Table responsive className="mt-3 mb-0">

                            </Table>
                            <div className='scrollTbale'>
                                {/* <p>{prop.currentLevel1}</p> */}
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>User Name</th>
                                            <th><span style={{ display: "flex", }}>{role == "GCSLResearcher" ? <Form.Check label="Select  All" onClick={(e) => selectAll(e)} value={allocateAll} /> : "Change"}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            data &&
                                            data.length > 0 &&
                                            data.filter((item) => {
                                                if (search === "") return item; // searchTerm is in scope?
                                                if (item.name.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase())) {
                                                    return item;
                                                } else {
                                                    return false;
                                                }

                                            }).map((item, i) =>
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>

                                                        <Form.Group style={{ display: "flex", }}>

                                                            {
                                                                role == "GCSLResearcher" ?
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        checked={selectedValues.includes(item.userId)}
                                                                        label=""
                                                                        value={item.userId}
                                                                        name={`checkbox${i}`}
                                                                        feedback=""
                                                                        required
                                                                        feedbackType="invalid"
                                                                        onChange={(e) => handleCheckboxChange(e.target.value)}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        type='radio'
                                                                        label=""
                                                                        checked={selectedValues.includes(item.userId)}
                                                                        value={item.userId}
                                                                        name="allot"
                                                                        feedback=""
                                                                        required
                                                                        feedbackType="invalid"
                                                                        onChange={(e) => {
                                                                            setSelectedValues([])
                                                                            handleCheckboxChange(e.target.value)
                                                                        }}
                                                                    />
                                                            }
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <hr />
                            {
                                role == "GCSLResearcher" ?
                                    <Row>
                                        <Col>
                                            <label>Reviewer</label>
                                            <Form.Select aria-label="Default select example" onChange={(e) => e.target.value == NaN || e.target.value == "" ? setReviewervalue([]) : setReviewervalue(parseInt(e.target.value))} required>
                                                <option value="">Select Reviewer</option>
                                                {
                                                    reviewer.map((item) =>
                                                        <option value={item.userId} selected={(selectedValues.includes(item.userId))}>{item.email}</option>
                                                    )
                                                }

                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <label>Verification Researcher</label>
                                            <Form.Select aria-label="Default select example" onChange={(e) => e.target.value == NaN || e.target.value == "" ? setVerificationResearchervalue([]) : setVerificationResearchervalue(parseInt(e.target.value))} required>
                                                <option value="">Select Verification Researcher</option>
                                                {
                                                    verificationResearcher.map((item) =>
                                                        <option key={item.userId} value={item.userId} selected={selectedValues.includes(item.userId)}>{item.email}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <label>Final Verification Researcher</label>
                                            <Form.Select aria-label="Default select example" onChange={(e) => e.target.value == NaN || e.target.value == "" ? setFinalVerificationResearchervalue([]) : setFinalVerificationResearchervalue(parseInt(e.target.value))} required>
                                                <option value="">Select Final Verification Researcher</option>
                                                {
                                                    finalVerificationResearcher.map((item) =>
                                                        <option value={item.userId} selected={selectedValues.includes(item.userId)}>{item.email}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row> : null}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={crossClose}>
                        Close
                    </Button>
                    {/* {
                        selectedValues.length == 0 && role == "GCSLResearcher" ? <Button onClick={handleSubmit}> Allocate to All </Button> : null
                    } */}

                    {
                        role !== "GCSLResearcher" && selectedValues.length !== 0 || (role == "GCSLResearcher" && selectedValues.length !== 0 && reviewervalue.length !== 0 && verificationResearchervalue.length !== 0 && finalVerificationResearchervalue.length !== 0) ?
                            <Button variant="primary" onClick={handleSubmit}>
                                {
                                    load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Allocation...</div> : "Allocate"
                                }

                            </Button>
                            :
                            null
                    }

                </Modal.Footer>
            </Modal>
            <Dialog
                open={open}
                onClose={handleCloseD}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You want to Allocate to All Researchers!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonM onClick={handleCloseD}>Cancel</ButtonM>
                    <ButtonM onClick={allocatedAll} autoFocus>
                        Yes, Allocate to All
                    </ButtonM>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CaseAllocated;