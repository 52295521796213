import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "./stack.css";
import iconzip from './iconzip.png'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import api from '../../apiUrl';
import jwt_decode from "jwt-decode"
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//import './uploadImageJs'
function CreateCase(prop) {
    const [upload, setUpload] = useState();
    const [fileSize, setfileSize] = useState();
    const [caseName, setCaseName] = useState("");
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    var apis = api()

    // for Toaster Close on click button
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };
    const caseNameValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        // if (e.target.value === "" || regex.test(e.target.value)) {
        //     console.log("e.target.value", e.target.value)
        //     setCaseName(e.target.value);
        // }
        setCaseName(e.target.value);
    };
    let disableRef = useRef(null)
    //Drag and Drop
    setTimeout(() => {
        // File Upload
        // 
        function ekUpload() {
            function Init() {

                console.log("Upload Initialised");

                var fileSelect = document.getElementById('file-upload'),
                    fileDrag = document.getElementById('file-drag'),
                    submitButton = document.getElementById('submit-button');

                fileSelect.addEventListener('change', fileSelectHandler, false);

                // Is XHR2 available?
                var xhr = new XMLHttpRequest();
                if (xhr.upload) {
                    // File Drop
                    fileDrag.addEventListener('dragover', fileDragHover, false);
                    fileDrag.addEventListener('dragleave', fileDragHover, false);
                    fileDrag.addEventListener('drop', fileSelectHandler, false);
                }
            }

            function fileDragHover(e) {
                var fileDrag = document.getElementById('file-drag');

                e.stopPropagation();
                e.preventDefault();

                fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
            }

            function fileSelectHandler(e) {
                // Fetch FileList object
                var files = e.target.files || e.dataTransfer.files;

                // Cancel event and hover styling
                fileDragHover(e);

                // Process all File objects
                for (var i = 0, f; f = files[i]; i++) {
                    parseFile(f);
                    uploadFile(f);
                }
            }

            // Output
            function output(msg) {
                // Response
                var m = document.getElementById('messages');
                m.innerHTML = msg;
            }

            function parseFile(file) {

                // console.log(file.name);
                // console.log("file", file);
                setUpload(file)
                output(
                    '<strong>' + encodeURI(file.name) + '</strong>'
                );

                // var fileType = file.type;
                // console.log(fileType);
                var imageName = file.name;

                var isGood = (/\.(?=zip|rar)/gi).test(imageName);
                console.log(isGood)
                if (isGood) {
                    document.getElementById('start').classList.add("hidden");
                    document.getElementById('response').classList.remove("hidden");
                    document.getElementById('notimage').classList.add("hidden");
                    // Thumbnail Preview
                    document.getElementById('file-image').classList.remove("hidden");
                    //document.getElementById('file-image').src = URL.createObjectURL(file);
                }
                else {
                    document.getElementById('file-image').classList.add("hidden");
                    document.getElementById('notimage').classList.remove("hidden");
                    document.getElementById('start').classList.remove("hidden");
                    document.getElementById('response').classList.add("hidden");
                    document.getElementById("file-upload-form").reset();
                }
            }

            function setProgressMaxValue(e) {
                var pBar = document.getElementById('file-progress');

                if (e.lengthComputable) {
                    pBar.max = e.total;
                }
            }

            function updateFileProgress(e) {
                var pBar = document.getElementById('file-progress');

                if (e.lengthComputable) {
                    pBar.value = e.loaded;
                }
            }

            function uploadFile(file) {
                setfileSize(file.size)
                var xhr = new XMLHttpRequest(),
                    fileInput = document.getElementById('class-roster-file'),
                    pBar = document.getElementById('file-progress'),
                    fileSizeLimit = 200; // In MB
                if (xhr.upload) {
                    // Check if file is less than x MB
                    if (file.size <= fileSizeLimit * 1024 * 1024) {
                        // Progress bar
                        pBar.style.display = 'inline';
                        xhr.upload.addEventListener('loadstart', setProgressMaxValue, false);
                        xhr.upload.addEventListener('progress', updateFileProgress, false);

                        // File received / failed
                        xhr.onreadystatechange = function (e) {
                            if (xhr.readyState == 4) {
                                // Everything is good!

                                // progress.className = (xhr.status == 200 ? "success" : "failure");
                                // document.location.reload(true);
                            }
                        };

                        // Start upload
                        xhr.open('POST', document.getElementById('file-upload-form').action, true);
                        xhr.setRequestHeader('X-File-Name', file.name);
                        xhr.setRequestHeader('X-File-Size', file.size);
                        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                        xhr.send(file);
                    } else {
                        output('Please upload a smaller file (< ' + fileSizeLimit + ' MB).');
                        document.getElementById('file-image').classList.add("hidden");
                        document.getElementById("file-upload-form").reset();
                    }
                }
            }

            // Check for the various File API support.
            if (window.File && window.FileList && window.FileReader) {
                Init();
            } else {
                document.getElementById('file-drag').style.display = 'none';
            }
        }
        ekUpload();

        // Zip Reader

    }, [1000])

    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    function upLoad() {
        setLoad(true)
        console.log(upload)
        console.log("formData", caseName)
        const formData = new FormData();
        formData.append('Zipfile', upload);
        formData.append('CandidateName', caseName);
        formData.append('CurrentUserId', currentUserId);

        console.log("apis", apis)
        fetch(`${apis}User/CreateCase/`, {
            method: 'POST',
            body: formData,
            mode: "cors",
        }).then((response) => response.json()).then((result) => {
            console.log('Success:', result);
            setLoad(false)
            setMessage("Case Has been Created")//set in value in tMessage useState for Toster Message
            setOpens(true);//set in value in useState
            setColor("success")//set in value in useState
            setCaseName(null)
            setUpload(null)
            navigate('/')
        }).catch((error) => {
            console.error('Error:', error);
            setLoad(false)
        });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!caseName === false && 0 < fileSize) {
                upLoad()
            } else if (fileSize == undefined) {
                setMessage("Please upload ZIP file")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("error")//set in value in useState
            }
        }
        setValidated(true);
        if (caseName == null || caseName == '' || fileSize == undefined) {
            console.log("setAttribute", caseName)
            //disableRef.current.setAttribute("disabled", "false")
        } else {
            console.log("removeAttribute", caseName)
            disableRef.current.setAttribute("disabled", "true")

        }

    };


    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12 p-0">
                            <div className="page-title-box align-items-center justify-content-between pb-0">
                                <h4 className="mb-0">Welcome back! {prop.name}</h4>
                                <p>&nbsp;</p>
                                {/* <p>Your last login: Today at 02:46 am</p> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-12 text-right p-0">
                        </div>
                    </div>
                </div>
            </div>
            <Form noValidate validated={validated} >
                <div className="container-fluid  mt-3">
                    <div className="row  dash_body-2-white ">

                        <div className="col-md-4 ">
                            <div className="text-center">
                                <h5><b>Upload Documents </b></h5>

                            </div>
                            <div className="row">
                                <Form.Group controlId="formFile" id="file-upload-form" className="uploader">


                                    <Form.Control required type="file" value={upLoad} id="file-upload" name="fileUpload" accept="zip/*" onChange={(e) => {
                                        setUpload(e.target.files[0])
                                        console.log("upload", upload)
                                    }} />
                                    <Form.Label for="file-upload" id="file-drag">
                                        <img id="file-image" src={iconzip} alt="Preview" className="hidden" />
                                        <div id="start">
                                            <i className="fa fa-download" aria-hidden="true"></i>
                                            <div>Select a file or drag here</div>
                                            <div id="notimage" className="hidden">Please select Zip File</div>
                                            <span id="file-upload-btn" className="btn btn-primary">Select a file</span>
                                        </div>
                                        <div id="response" className="hidden">
                                            <div id="messages"></div>
                                            {/* <progress className="progress" id="file-progress" value="0">
                                            <span>0</span>%
                                        </progress> */}
                                        </div>
                                    </Form.Label>

                                    {/* <input value={upLoad} id="file-upload" type="file" name="fileUpload" accept="zip/*" onChange={(e) => {
                                    setUpload(e.target.files[0])
                                    console.log("upload", upload)
                                }} /> */}


                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-8 pb-5">
                            <div>
                                <h5><b>Candidate Name</b></h5>
                            </div>
                            <div className="row">
                                <div>
                                    <Form.Group controlId="username" className='mb-3'>

                                        <Form.Control
                                            required
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            placeholder="Enter username"
                                            onChange={(e) => caseNameValidated(e)}
                                            value={caseName}
                                            maxLength={50}

                                        />
                                        <Form.Control.Feedback type="invalid">Please enter a Candidate name</Form.Control.Feedback>
                                    </Form.Group>
                                    {/* <input type="text " value={caseName} className="add-member-page  p-3" onChange={(e) => setCaseName(e.target.value)} /> */}
                                    <Button className="my-submit-btn mt-3" onClick={handleSubmit} ref={disableRef}>
                                        {
                                            load ? <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div> : null
                                        }
                                        &nbsp; Create Case</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                        <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                    {/* <Form noValidate validated={validated} >
                        <Form.Group controlId="username" className='mb-3'>

                            <Form.Control
                                required
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"
                                onChange={(e) => setCaseName(e.target.value)}
                                value={caseName}

                            />
                            <Form.Control.Feedback type="invalid">Please enter a user name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="username" className='mb-3'>

                            <Form.Control
                                required
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"



                            />
                            <Form.Control.Feedback type="invalid">Please enter a user name</Form.Control.Feedback>
                        </Form.Group>

                    </Form> */}
                    {/* <Button onClick={handleSubmit}>jndfbn</Button> */}
                </div>
            </Form>
        </>
    )
}

export default CreateCase