import { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavNavLink } from "react-router-dom";
import './sidenav.css'
function Sidenav(prop) {
   var menuli = document.getElementsByClassName("has-arrow");
   setTimeout(() => {
      for (let i = 0; i < menuli.length; i++) {
         menuli[i].addEventListener("click", function () {

            var cls = menuli[i].parentElement.classList.contains("mm-active");
            if (cls == true) {
               // alert(cls)
               // console.log("cls true")
               menuli[i].parentElement.classList.remove("mm-active");
               menuli[i].nextSibling.classList.remove("mm-show");
            } else if (cls == false) {
               //console.log("cls false")
               menuli[i].parentElement.classList.add("mm-active");
               menuli[i].nextSibling.classList.add("mm-show")

            }
         });

      }
   }, 500);


   return (
      <>
         <div data-simplebar class="">
            <div id="sidebar-menu">
               {
                  prop.role == "3" || prop.role == "4" ? <div>
                     <form className="app-search d-none d-lg-block px-3"><div className="input-group"></div></form>
                     <hr className="mt-0" />
                     <div className="GbBBzF px-3 mb-3">
                        <NavLink to="/createCase" type="button" className="button_jEWP9J btn" id="dropdownMenuButton"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           style={{ width: 100 + '%', minWidth: 160 + 'px' }}>
                           <svg className="svg_exo2UA" aria-hidden="true" data-qa-tag="svg" xmlns="http://www.w3.org/2000/svg"
                              width="16" height="16" style={{ fill: "#fff" }}>
                              <path fill-rule="evenodd"
                                 d="M9 7h2c.5523 0 1 .4477 1 1s-.4477 1-1 1H9v2c0 .5523-.4477 1-1 1s-1-.4477-1-1V9H5c-.5523 0-1-.4477-1-1s.4477-1 1-1h2V5c0-.5523.4477-1 1-1s1 .4477 1 1v2zm-1 9c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8-3.5817 8-8 8zm0-2c3.3137 0 6-2.6863 6-6s-2.6863-6-6-6-6 2.6863-6 6 2.6863 6 6 6z">
                              </path>
                           </svg> <span>&nbsp;</span>
                           <span>Create Case</span>
                        </NavLink>
                     </div>
                  </div> : ""
               }
               <ul className="metismenu list-unstyled" id="side-menu">
                  <li>
                     <NavLink to='/' className="waves-effect">
                        <i className="ri-dashboard-line"></i>
                        <span>Dashboard</span>
                     </NavLink>

                  </li>
                  {
                     prop.role == "1" ?
                        <li id="dd" className="subMenu">
                           <a className="has-arrow waves-effect">
                              <i className="ri-layout-3-line"></i>
                              <span> 	Manage Client</span>
                           </a>
                           <ul className="sub-menu mm-collapse" aria-expanded="true">
                              <li>
                                 <NavLink className=" waves-effect" to="/AddStakeholder"><i className="ri-layout-3-line"></i>
                                    <span> Add Client</span></NavLink>

                              </li>
                              <li>
                                 <NavLink to="/viewstakeholder" className=" waves-effect">
                                    <i className="fa fa-building" aria-hidden="true"></i>
                                    <span>View Client</span>
                                 </NavLink>
                              </li>
                           </ul>
                        </li>
                        : ""
                  }

                  <li className="subMenu">
                     <a className="has-arrow waves-effect">
                        <i className="ri-layout-3-line"></i>
                        <span> 	Manage Cases</span>
                     </a>
                     {
                        prop.role == "1" || prop.role == "2" ?
                           <ul className="sub-menu mm-collapse" aria-expanded="true">
                              <li>
                                 <NavLink to="/recentCase" className=" waves-effect">
                                    <i className="fa fa-building" aria-hidden="true"></i>
                                    <span>Recent Cases</span>
                                 </NavLink>
                              </li>
                              <li>
                                 <NavLink to="/pendingCase" className=" waves-effect">
                                    <i className="fa fa-building" aria-hidden="true"></i>
                                    <span>Pending Cases</span>
                                 </NavLink>
                              </li>
                              <li>
                                 <NavLink to="/closedCase" className=" waves-effect">
                                    <i className="fa fa-building" aria-hidden="true"></i>
                                    <span>Closed Cases</span>
                                 </NavLink>
                              </li>
                           </ul>
                           :
                           prop.role == "3" || prop.role == "4" ?
                              <ul className="sub-menu mm-collapse" aria-expanded="true">
                                 <li>
                                    <NavLink to="/stackholderrecentCase" className=" waves-effect">
                                       <i className="fa fa-building" aria-hidden="true"></i>
                                       <span>Recent Cases</span>
                                    </NavLink>
                                 </li>
                                 <li>
                                    <NavLink to="/stackholderPendingCase" className=" waves-effect">
                                       <i className="fa fa-building" aria-hidden="true"></i>
                                       <span>Pending Cases</span>
                                    </NavLink>
                                 </li>
                                 <li>
                                    <NavLink to="/stackholderClosedCase" className=" waves-effect">
                                       <i className="fa fa-building" aria-hidden="true"></i>
                                       <span>Closed Cases</span>
                                    </NavLink>
                                 </li>
                              </ul>
                              : ""
                     }
                  </li>
                  {
                     prop.role == "1" || prop.role == "3" ?
                        <li className="subMenu">
                           <a href="javascript:void(0)" className="has-arrow waves-effect">
                              <i className="ri-layout-3-line"></i>
                              <span> 	Security</span>
                           </a>
                           <ul className="sub-menu mm-collapse" aria-expanded="true">
                              {
                                 prop.role == "1" || prop.role == "2" ?
                                    <li>
                                       <NavLink to="/user" className=" waves-effect">
                                          <i className="fa fa-building" aria-hidden="true"></i>
                                          <span>User</span>
                                       </NavLink>
                                    </li>
                                    :
                                    prop.role == "3" ?
                                       <li>
                                          <NavLink to="/stakeUser" className=" waves-effect">
                                             <i className="fa fa-building" aria-hidden="true"></i>
                                             <span>User</span>
                                          </NavLink>
                                       </li> : ""
                              }
                              {
                                 prop.role == "1" || prop.role == "2" ?
                                    <li>
                                       <NavLink to="/modulepermission" className=" waves-effect">
                                          <i className="fa fa-building" aria-hidden="true"></i>
                                          <span>  Module Permission</span>
                                       </NavLink>
                                    </li>
                                    : ""
                              }


                              {/* {
                           prop.role == "Admin" ?
                              <li>
                                 <a href="javascript:void(0)" className=" waves-effect">
                                    <i className="fa fa-building" aria-hidden="true"></i>
                                    <span>Manage Role</span>
                                 </a>
                              </li>
                              : ""
                        } */}


                           </ul>
                        </li>
                        : ""
                  }

                  {/* {
                     prop.role == "1" || prop.role == "2" ?
                        <li>
                           <a href="javascript:void(0)" className="waves-effect">
                              <i className="fa fa-bell" aria-hidden="true"></i>
                              <span> 	 	Notification Management </span>
                           </a>
                        </li>
                        : ""
                  } */}
                  {
                     prop.role == "1" ?
                        <li className="subMenu">
                           <a className="has-arrow waves-effect">
                              <i className="ri-layout-3-line"></i>
                              <span>Create Risk Matrix</span>
                           </a>
                           <ul className="sub-menu mm-collapse" aria-expanded="true">
                              <li>
                                 <NavLink to="/riskMatrix" className="waves-effect">
                                    <i className="ri-layout-3-line" aria-hidden="true"></i>
                                    <span>Applicant Risk Matrix</span>
                                 </NavLink>
                              </li>
                              <li>
                                 <NavLink to="/keyriskMatrix" className="waves-effect">
                                    <i className="ri-layout-3-line" aria-hidden="true"></i>
                                    <span>Risk Matrix Key</span>
                                 </NavLink>
                              </li>
                           </ul>
                        </li>
                        : ""
                  }


                  {
                     prop.role == "1" ?
                        <li>
                           <NavLink to="/report" className=" waves-effect">
                              <i className="ri-layout-3-line" aria-hidden="true"></i>
                              <span>  Report</span>
                           </NavLink>
                        </li>
                        : ""
                  }
                  <li>
                     <NavLink to="/eventLog" className="waves-effect">
                        <i className="fa fa-history" aria-hidden="true"></i>
                        <span>Event Log Module</span>
                     </NavLink>
                  </li>
               </ul>
            </div>
         </div>




      </>
   )
}

export default Sidenav