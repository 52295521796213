import React, { useEffect, useState } from "react";
import api from "../../apiUrl";
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function FamilyInquiries(prop) {
    var apis = api()
    const caseId = prop.caseId
    const dependentSatus = prop.dependentSatus
    const dependentId = prop.dependentId
    const [validated, setValidated] = useState(false);
    const [load, setLoad] = useState(false)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };
    // Family Inquiries 
    const [Family, setFamily] = useState([]);
    const [familyMemberName, setFamilyMemberName] = useState('');
    const [familyrelation, setFamilyRelation] = useState('');
    const [familyDob, setFamilyDob] = useState('');
    const [familypassportNumber, setFamilyPassportNumber] = useState('');
    const [familyplaceofbirth, setFamilyPlaceofbirth] = useState('');
    const [familyComments, setfamilyComments] = useState('');
    const [familyModelshow, setFamilyModelShow] = useState(false);
    const [familyUpdateModelshow, setFamilyUpdateModelShow] = useState(false);
    const [familymodelValidated, setFamilyModelValidated] = useState(false);
    const [modelValidated, setModelValidated] = useState(false);
    const [apiData, setApiData] = useState([])

    //--------------update----only
    const [updatefamilyId, setUpdatefamilyId] = useState('');
    const [updatefamilyMemberName, setUpdateFamilyMemberName] = useState('');
    const [updatefamilyrelation, setUpdateFamilyRelation] = useState('');
    const [updatefamilyDob, setUpdateFamilyDob] = useState('');
    const [updatefamilypassportNumber, setUpdateFamilyPassportNumber] = useState('');
    const [updatefamilyplaceofbirth, setUpdateFamilyPlaceofbirth] = useState('');
    const [updatefamilyComments, setUpdatefamilyComments] = useState('');
    //--------------update----only

    const [day, setDay] = useState("01")
    const [dobMonth, setDobMonth] = useState("January");
    const [dobYear, setDobYear] = useState("2020");
    const [updateday, setUpdateDay] = useState("01")
    const [updatedobMonth, setUpdateDobMonth] = useState("January");
    const [updatedobYear, setUpdateDobYear] = useState("2020");


    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");

    const editorConfiguration = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'link', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
            shouldNotGroupWhenFull: false,
            wordcount: {
                showCharCount: true,
            }

        }


    };


    // Date of Birth perameter
    var date = new Date();
    var currentYear = date.getFullYear()
    var yearArr = []
    for (let i = 0; i < 100; i++) {
        yearArr.push(currentYear - i)
    }
    var monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var dayArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    //console.log(yearArr);
    function getFebDay() {

        function daysInThisMonth() {
            var now = new Date();
            return new Date(dobYear, 2, 0).getDate();
        }

        var getDay = daysInThisMonth();
        dayArr = []
        for (let i = 0; i < getDay; i++) {
            dayArr.push(getDay - i)
        }
        dayArr.reverse()
        console.log("getDay", getDay, dobYear, dayArr)
    }

    if (dobMonth === 'February') {
        getFebDay()
    }

    // function leapyear(year) { return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0); }

    useEffect(() => {

    }, [dobYear])

    useEffect(() => {
        setDobYear(yearArr[0])
        getFebDay()
    }, [])
    // Date of Birth perameter

    function isDateValid(inputDate) {
        const monthMap = {
            "January": 1,
            "February": 2,
            "March": 3,
            "April": 4,
            "May": 5,
            "June": 6,
            "July": 7,
            "August": 8,
            "September": 9,
            "October": 10,
            "November": 11,
            "December": 12,
        };

        const dateComponents = inputDate.split('-');
        const year = parseInt(dateComponents[0]);
        const month = monthMap[dateComponents[1]];
        const day = parseInt(dateComponents[2]);

        if (
            isNaN(year) ||
            isNaN(month) ||
            isNaN(day) ||
            month < 1 || month > 12 || day < 1 || day > 31
        ) {
            // The date is invalid
            return false;
        }

        // Check for valid days based on the month
        const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        const daysInMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (day > daysInMonth[month - 1]) {
            // The date is invalid
            return false;
        }

        // Create a new Date object and check if it is valid
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) {
            // The date is invalid
            return false;
        }

        // The date is valid
        return true;
    }

    // Example usage:
    const inputDate2 = `${dobYear}-${dobMonth}-${day}`;
    const isValid2 = isDateValid(inputDate2);
    ; // Output: true
    useEffect(() => {
        console.log("isValid2", dobYear, dobMonth, day, isDateValid(inputDate2))
    }, [dobYear, dobMonth, day])
    // Date of Birth perameter





    //get APIdata
    function familyApidata() {
        //console.log("familyApidata")
        if (dependentSatus == true) {
            fetch(`${apis}Admin/ViewFamilyInquiriesForDependent/${caseId}/${dependentId}`).then((respons) => {
                respons.json().then((result) => {
                    // console.log(result)
                    setApiData(result.data)
                    // navigate('/')
                })
            })
        } else {
            fetch(`${apis}Admin/ViewFamilyInquiriesDetails/${caseId}`).then((respons) => {
                respons.json().then((result) => {
                    // console.log(result)
                    setApiData(result.data)
                    // navigate('/')
                })
            })
        }

    }
    if (dependentSatus == true) {
        var familyData = {
            "CaseId": caseId,
            "CaseType": "D",
            "familyMember": familyMemberName,
            "relation": familyrelation,
            "BirthOfDay": day,
            "BirthOfMonth": dobMonth,
            "BirthOfYear": dobYear,
            "passportNumber": familypassportNumber,
            "placeOfBirth": familyplaceofbirth,
            "comments": familyComments,
            "dependentId": dependentId
        }
    } else {
        var familyData = {
            "CaseId": caseId,
            "CaseType": "C",
            "familyMember": familyMemberName,
            "relation": familyrelation,
            "BirthOfDay": day,
            "BirthOfMonth": dobMonth,
            "BirthOfYear": dobYear,
            "passportNumber": familypassportNumber,
            "placeOfBirth": familyplaceofbirth,
            "comments": familyComments
        }
    }


    function addfamilydata() {
        if (dependentSatus == true) {
            //alert("FamilyInquiriesForDependent")
            fetch(`${apis}Admin/FamilyInquiriesForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(familyData),
            }).then((respons) => {
                respons.json().then((result) => {
                    //   console.log(result)
                    familyApidata()

                })
            })
        } else {
            fetch(`${apis}Admin/FamilyInquiriesDetail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(familyData),
            }).then((respons) => {
                respons.json().then((result) => {
                    // console.log(result)
                    familyApidata()

                })
            })
        }

    }


    var updateFamilyData = {
        "CaseId": caseId,
        "id": updatefamilyId,
        "familyMember": updatefamilyMemberName,
        "relation": updatefamilyrelation,
        "BirthOfDay": updateday,
        "BirthOfMonth": updatedobMonth,
        "BirthOfYear": updatedobYear,
        "passportNumber": updatefamilypassportNumber,
        "placeOfBirth": updatefamilyplaceofbirth,
        "comments": updatefamilyComments,
        "dependentId": dependentId
    }
    function UpdateFamilydata() {
        // console.log("updateFamilyData", updateFamilyData)
        if (dependentSatus == true) {
            fetch(`${apis}Admin/UpdateFamilyInquiriesForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(updateFamilyData),
            }).then((respons) => {
                respons.json().then((result) => {
                    // console.log(result)
                    familyApidata()

                })
            })
        } else {
            fetch(`${apis}Admin/UpdateFamilyInquiriesDetail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(updateFamilyData),
            }).then((respons) => {
                respons.json().then((result) => {
                    // console.log(result)
                    familyApidata()

                })
            })
        }

    }
    useEffect(() => {
        familyApidata()
    }, [])
    function deletefamilyData(e) {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/DeleteFamilyInquiriesForDependent/${e}/${dependentId}`, {
                method: "POST",
            }).then((respons) => {
                respons.json().then((result) => {
                    // console.log("deletefamilyData ", result)
                    familyApidata()
                })
            })
        } else {
            fetch(`${apis}Admin/DeleteFamilyInquiriesDetails/${e}`, {
                method: "POST",
            }).then((respons) => {
                respons.json().then((result) => {
                    //  console.log("deletefamilyData ", result)
                    familyApidata()
                })
            })
        }

        // console.log("deletefamilyData ", e)
    }
    //get API data
    const familyModelhandleClose = (event) => {
        setModelValidated(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //  console.log("setModelShow(true);")
        } else if (!familyMemberName == false && !familyrelation == false &&
            !familypassportNumber == false && !familyplaceofbirth == false
            //&& !familyComments == false
        ) {
            if (isDateValid(inputDate2) == true) {
                addfamilydata()
                setFamilyModelShow(false);
                setFamilyMemberName(null)
                setFamilyRelation(null)
                setDay(null)
                setDobMonth(null)
                setDobYear(null)
                setFamilyPassportNumber(null)
                setFamilyPlaceofbirth(null)
                setfamilyComments(null)
                event.target.reset();
            } else {
                setLoad(false);
                setMessage("Please select valid date.")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("error")//set in value in useState
            }


        }

        setModelValidated(true);
    }

    const familymodelhandleShow = () => {
        setFamilyModelShow(true)
        // setFamilyMemberName("")
        // setFamilyRelation("")
        // setFamilyDob("")
        // setFamilyPassportNumber("")
        // setFamilyPlaceofbirth("")
        // setfamilyComments("")
    };
    const familyUpdatModelhandleClose = (event) => {
        setModelValidated(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //  console.log("setModelShow(true);")
        } else if (!updatefamilyMemberName == false && !updatefamilyrelation == false &&
            !updatefamilypassportNumber == false && !updatefamilyplaceofbirth == false
            //&& !updatefamilyComments == false
        ) {
            UpdateFamilydata()
            setFamilyUpdateModelShow(false);
        }

        setModelValidated(true);
    }
    const familyUpdatemodelhandleShow = () => {
        setFamilyUpdateModelShow(true)
    };
    const familymodelhandleChross = () => setFamilyModelShow(false);
    const familyUpdatemodelhandleChross = () => setFamilyUpdateModelShow(false);


    return (
        <>
            <div className="card mb-0 shadow-none mb-3">
                <a href="#collapseFive" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                    aria-controls="collapseFour">
                    <div className="card-header" id="headingFive">
                        <h6 className="m-0">
                            Family Inquiries
                            <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                        </h6>
                    </div>
                </a>
                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className='mipc'>
                                            <th>Family Member</th>
                                            <th>Relation</th>
                                            <th>Date of Birth</th>
                                            <th>Passport Number</th>
                                            <th>Place of Birth</th>
                                            {
                                                prop.varification === true ? <th>Comments</th> : ""
                                            }
                                            {
                                                prop.viewOnly === true ? "" :
                                                    <th className='action'>Action
                                                        {
                                                            prop.varification === true ? "" : <Button disabled={apiData.length === 5 ? true : false} variant="primary" onClick={familymodelhandleShow}>
                                                                <i class="mdi mdi-plus float-end accor-plus-icon"></i>
                                                            </Button>
                                                        }


                                                        <Modal size="xl" show={familyModelshow} onHide={familymodelhandleChross}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add New Family Inquiries</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form id="mipcForm" noValidate validated={modelValidated}>
                                                                    <Row className="mb-3">
                                                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                                            <Form.Label>Family Member</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                onChange={(e) => setFamilyMemberName(e.target.value)}
                                                                            // maxLength={50}
                                                                            />
                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                                            <Form.Label>Relation</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                onChange={(e) => setFamilyRelation(e.target.value)}
                                                                            //maxLength={50}
                                                                            />
                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        {/* <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                                    <Form.Label>Date of Birth</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        type="date"
                                                                        onChange={(e) => setFamilyDob(e.target.value)}
                                                                        maxLength={50}
                                                                    />
                                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                </Form.Group> */}
                                                                        <Col xs={5} >
                                                                            <Form.Label><span>Date of Birth</span></Form.Label>
                                                                            <div className="row">
                                                                                <div className="col-md-4">

                                                                                    <Form.Group className="mb-3" controlId="validationCustom11">

                                                                                        <Form.Control required as="select" type="select"
                                                                                            id="validationCustom11"
                                                                                            onChange={(e) => setDay(e.target.value)}
                                                                                        ><option value="" disabled>-- Day --</option>
                                                                                            {
                                                                                                dayArr.map((item) =>
                                                                                                    <option value={item}

                                                                                                    //selected={item == "3" ? true : false}
                                                                                                    >{item}</option>
                                                                                                )
                                                                                            }
                                                                                        </Form.Control>

                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            Please select this field
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <Form.Group className="mb-3" controlId="validationCustom11">

                                                                                        <Form.Control required as="select" type="select"
                                                                                            id="validationCustom11"
                                                                                            onChange={(e) => setDobMonth(e.target.value)}
                                                                                        ><option value="" disabled>-- Month --</option>
                                                                                            {
                                                                                                monthArr.map((item) =>
                                                                                                    <option value={item}
                                                                                                    //selected={item == "November" ? true : false}
                                                                                                    >{item}</option>
                                                                                                )
                                                                                            }
                                                                                        </Form.Control>
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            Please select this field
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <Form.Group className="mb-3" controlId="validationCustom11">

                                                                                        <Form.Control required as="select" type="select"
                                                                                            id="validationCustom11"
                                                                                            onChange={(e) => setDobYear(e.target.value)}
                                                                                        ><option value="" disabled>-- Year --</option>
                                                                                            {
                                                                                                yearArr.map((item) =>
                                                                                                    <option value={item}
                                                                                                    //selected={item == "2020" ? true : false}
                                                                                                    >{item}</option>
                                                                                                )
                                                                                            }

                                                                                        </Form.Control>
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            Please select this field
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                                            <Form.Label>Passport Number</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                onChange={(e) => setFamilyPassportNumber(e.target.value)}
                                                                            //  maxLength={50}
                                                                            />
                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                                            <Form.Label>Place of Birth</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                onChange={(e) => setFamilyPlaceofbirth(e.target.value)}
                                                                            // maxLength={50}
                                                                            />
                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        {
                                                                            prop.varification === true ?
                                                                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                                                    <Form.Label>Comments</Form.Label>
                                                                                    <Form.Control
                                                                                        required
                                                                                        type="text"
                                                                                        onChange={(e) => setfamilyComments(e.target.value)}
                                                                                    //    maxLength={150}
                                                                                    />
                                                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                                : ""
                                                                        }

                                                                    </Row>
                                                                </Form>

                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={familymodelhandleChross}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={familyModelhandleClose}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </th>

                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            apiData.map((item) =>
                                                <tr>
                                                    <td>{item.familyMember}</td>
                                                    <td>{item.relation}</td>
                                                    <td>{item.birthOfDay} - {item.birthOfMonth} - {item.birthOfYear}</td>
                                                    <td>{item.passportNumber}</td>
                                                    <td>{item.placeOfBirth}</td>
                                                    {
                                                        prop.varification === true ? <td>
                                                            {/* {item.comments} */}
                                                            <div dangerouslySetInnerHTML={{ __html: item.comments }}></div></td> : ""
                                                    }
                                                    {
                                                        prop.viewOnly === true ? "" :
                                                            <td
                                                                width={prop.varification === true ? "15%" : "20%"}
                                                            ><div className="btn btn-xs btn-green-outline2"
                                                                onClick={() => {
                                                                    familyUpdatemodelhandleShow()
                                                                    setUpdatefamilyId(item.id)
                                                                    setUpdateFamilyMemberName(item.familyMember)
                                                                    setUpdateFamilyRelation(item.relation)
                                                                    setUpdateDay(item.birthOfDay)
                                                                    setUpdateDobMonth(item.birthOfMonth)
                                                                    setUpdateDobYear(item.birthOfYear)

                                                                    setUpdateFamilyPassportNumber(item.passportNumber)
                                                                    setUpdateFamilyPlaceofbirth(item.placeOfBirth)
                                                                    setUpdatefamilyComments(item.comments)
                                                                }
                                                                }
                                                            >
                                                                    {
                                                                        prop.varification === true ? <span>Add & Update Comment</span> : <i class="fas fa-pen"></i>
                                                                    }</div> <span>&nbsp;</span>

                                                                {prop.varification === true ?

                                                                    <Modal size="lg" show={familyUpdateModelshow} onHide={familyUpdatemodelhandleChross}>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>Add Comment</Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <Form id="mipcForm" noValidate validated={modelValidated}>



                                                                                {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                                                        <Form.Label>Comments</Form.Label>
                                                                                        <Form.Control
                                                                                            value={updatefamilyComments}
                                                                                            type="text"
                                                                                            onChange={(e) => setUpdatefamilyComments(e.target.value)}
                                                                                        //  maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group> */}
                                                                                <CKEditor

                                                                                    editor={ClassicEditor}
                                                                                    config={editorConfiguration}
                                                                                    data={""}
                                                                                    onReady={editor => {
                                                                                        // editor.ui.view.editable.element.style.height = '300px'

                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        // setCkData(data)
                                                                                        setUpdatefamilyComments(data)

                                                                                    }}

                                                                                />

                                                                            </Form>

                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button variant="secondary" onClick={familyUpdatemodelhandleChross}>
                                                                                Close
                                                                            </Button>
                                                                            <Button variant="primary" onClick={familyUpdatModelhandleClose}>
                                                                                Save Changes
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                    :


                                                                    <Modal size="xl" show={familyUpdateModelshow} onHide={familyUpdatemodelhandleChross}>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>Update Family Inquiries</Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <Form id="mipcForm" noValidate validated={modelValidated}>
                                                                                <Row className="mb-3">
                                                                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                                                        <Form.Label>Family Member</Form.Label>
                                                                                        <Form.Control
                                                                                            value={updatefamilyMemberName}
                                                                                            type="text"
                                                                                            onChange={(e) => setUpdateFamilyMemberName(e.target.value)}
                                                                                        // maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                                                        <Form.Label>Relation</Form.Label>
                                                                                        <Form.Control
                                                                                            value={updatefamilyrelation}
                                                                                            type="text"
                                                                                            onChange={(e) => setUpdateFamilyRelation(e.target.value)}
                                                                                        //  maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    {/* <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                                            <Form.Label>Date of Birth</Form.Label>
                                                                            <Form.Control
                                                                                value={updatefamilyDob}
                                                                                type="date"
                                                                                onChange={(e) => setUpdateFamilyDob(e.target.value)}
                                                                                maxLength={50}
                                                                            />
                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                        </Form.Group> */}
                                                                                    <Col xs={5} >
                                                                                        <Form.Label><span>Date of Birth</span></Form.Label>
                                                                                        <div className="row">
                                                                                            <div className="col-md-4">

                                                                                                <Form.Group className="mb-3" controlId="validationCustom11">

                                                                                                    <Form.Control required as="select" type="select"
                                                                                                        id="validationCustom11"
                                                                                                        onChange={(e) => setUpdateDay(e.target.value)}
                                                                                                    ><option value="" disabled>-- Day --</option>
                                                                                                        {
                                                                                                            dayArr.map((item) =>
                                                                                                                <option value={item}

                                                                                                                    selected={item == updateday ? true : false}
                                                                                                                >{item}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    </Form.Control>

                                                                                                    <Form.Control.Feedback type="invalid">
                                                                                                        Please select this field
                                                                                                    </Form.Control.Feedback>
                                                                                                </Form.Group>
                                                                                            </div>
                                                                                            <div className="col-md-4">
                                                                                                <Form.Group className="mb-3" controlId="validationCustom11">

                                                                                                    <Form.Control required as="select" type="select"
                                                                                                        id="validationCustom11"
                                                                                                        onChange={(e) => setUpdateDobMonth(e.target.value)}
                                                                                                    ><option value="" disabled>-- Month --</option>
                                                                                                        {
                                                                                                            monthArr.map((item) =>
                                                                                                                <option value={item}
                                                                                                                    selected={item == updatedobMonth ? true : false}
                                                                                                                >{item}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    </Form.Control>
                                                                                                    <Form.Control.Feedback type="invalid">
                                                                                                        Please select this field
                                                                                                    </Form.Control.Feedback>
                                                                                                </Form.Group>
                                                                                            </div>
                                                                                            <div className="col-md-4">
                                                                                                <Form.Group className="mb-3" controlId="validationCustom11">

                                                                                                    <Form.Control required as="select" type="select"
                                                                                                        id="validationCustom11"
                                                                                                        onChange={(e) => setUpdateDobYear(e.target.value)}
                                                                                                    ><option value="" disabled>-- Year --</option>
                                                                                                        {
                                                                                                            yearArr.map((item) =>
                                                                                                                <option value={item}
                                                                                                                    selected={item == updatedobYear ? true : false}
                                                                                                                >{item}</option>
                                                                                                            )
                                                                                                        }

                                                                                                    </Form.Control>
                                                                                                    <Form.Control.Feedback type="invalid">
                                                                                                        Please select this field
                                                                                                    </Form.Control.Feedback>
                                                                                                </Form.Group>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3">
                                                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                                                        <Form.Label>Passport Number</Form.Label>
                                                                                        <Form.Control
                                                                                            value={updatefamilypassportNumber}
                                                                                            type="text"
                                                                                            onChange={(e) => setUpdateFamilyPassportNumber(e.target.value)}
                                                                                        // maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                                                        <Form.Label>Place of Birth</Form.Label>
                                                                                        <Form.Control
                                                                                            value={updatefamilyplaceofbirth}
                                                                                            type="text"
                                                                                            onChange={(e) => setUpdateFamilyPlaceofbirth(e.target.value)}
                                                                                        // maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    {
                                                                                        prop.varification === true ? <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                                                            <Form.Label>Comments</Form.Label>
                                                                                            <Form.Control
                                                                                                value={updatefamilyComments}
                                                                                                type="text"
                                                                                                onChange={(e) => setUpdatefamilyComments(e.target.value)}
                                                                                            //    maxLength={150}
                                                                                            />
                                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                        </Form.Group> : ""
                                                                                    }

                                                                                </Row>
                                                                            </Form>

                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button variant="secondary" onClick={familyUpdatemodelhandleChross}>
                                                                                Close
                                                                            </Button>
                                                                            <Button variant="primary" onClick={familyUpdatModelhandleClose}>
                                                                                Save Changes
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                }
                                                                {
                                                                    prop.varification === true ? "" : <div className="btn btn-xs btn-danger" onClick={() => deletefamilyData(item.id)}><i className="fa fa-trash fa-lg"></i></div>
                                                                }
                                                            </td>

                                                    }


                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default FamilyInquiries;