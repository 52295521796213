import { useEffect, useState } from "react"
import api from '../../apiUrl';
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
function FileHistory(prop) {
    //dayjs.locale("id");
    var localized = localizedFormat
    dayjs.extend(localized);
    var apis = api()
    const location = useLocation()
    //console.log("prop.dependentStatus", location.state.dependentStatus)
    const caseId = location.state.caseId
    const dependentId = location.state.dependentId
    const dependentStatus = location.state.dependentStatus

    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false)
    function getAPi() {
        if (dependentStatus == true) {
            fetch(`${apis}Admin/GetReportHistoryDetailsForDependent/${dependentId}`, {
                mode: "cors",
            }).then((respon) => {
                respon.json().then((result) => {
                    console.log(result);
                    console.log("FileHistory", result)
                    setData(result.data)
                    //setLoad(false)
                })
            })
        } else {
            fetch(`${apis}Admin/GetReportHistoryDetails/${caseId}`, {
                mode: "cors",
            }).then((respon) => {
                respon.json().then((result) => {
                    console.log(result);
                    console.log("FileHistory", result)
                    setData(result.data)
                    //setLoad(false)
                })
            })
        }
        //setLoad(true)

    }

    useEffect(() => {
        getAPi()
        // setData(json)
    }, [])
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item">View Profile</li>
                                        <li className="breadcrumb-item active">View File History</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                load ? <div class="spin-a"><div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> :
                    <div className="container-fluid">
                        <div className="table-responsive mt-4">

                            <table id="example" className="table table-striped table-bordered" style={{ width: "100%", }}>

                                <thead>
                                    <tr>
                                        <th width="5%"> ID</th>
                                        <th width="15%"> File Name</th>
                                        <th width="15%"> Upload Date </th>
                                        <th width="15%"> Upload Time </th>
                                        <th width="10%"> version </th>
                                        <th width="10%"> Action </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        data.length == 0 ?
                                            <tr>
                                                <td colSpan={7}>

                                                    <div className='records'><p>Records not found</p></div>
                                                </td>
                                            </tr>
                                            : null
                                    }

                                    {
                                        data.map((item) =>
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.fileName}</td>
                                                <td>{item.uploadDate}</td>
                                                <td>{item.uploadTime}</td>
                                                <td>{item.version}</td>
                                                <td><a href={item.download} target="_blank">Download</a></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
            }
        </>
    );
}

export default FileHistory;