import React, { useEffect, useState } from "react"
import api from "../../apiUrl"
import dayjs from "dayjs"
import EditIcon from '@mui/icons-material/Edit';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import gcslImg from '../../topnav/logo-light.png'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function RoughWork(prop) {
    const [photo, setPhoto] = useState(localStorage.getItem('photo'))
    console.log("Roughprop", prop)
    var apis = api()
    const caseId = prop.caseId
    const dependent = prop.dependent
    const dependentId = prop.dependentId
    const currentUserId = prop.currentUserId
    const [roughWork, setTroughWork] = useState([])
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [editfor, setEditfor] = useState("");
    const AlerthandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };
    function getRoughWork() {
        if (dependent == true) {
            fetch(`${apis}Admin/GetRoughWorkDataForDependent/${caseId}/${dependentId}`).then((respon) => {
                respon.json().then((result) => {
                    console.log("getRoughWork", result)
                    setTroughWork(result.data)
                })
            })
        } else {
            fetch(`${apis}Admin/GetRoughWorkData/${caseId}`).then((respon) => {
                respon.json().then((result) => {
                    console.log("getRoughWork", result)
                    setTroughWork(result.data)
                })
            })
        }

    }
    useEffect(() => {
        getRoughWork()
    }, [])

    const editorConfiguration = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
            shouldNotGroupWhenFull: false,
            wordcount: {
                showCharCount: true,
            }

        }


    };

    //------------------------------------ Add Mothod
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [editshow, seteditShow] = useState(false);
    const edithandleClose = () => seteditShow(false);
    const edithandleShow = () => {
        seteditShow(true)
    };

    const [ckData, setCkData] = useState("")
    const [updatckData, setUpdatCkData] = useState()
    const [load, setLoad] = useState(false)

    function add() {
        if (dependent == true) {
            if (ckData == "" || null) {
                setMessage("Please Add Some Data")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("error")//set in value in useState
            } else {
                setLoad(true)
                var addJson = {
                    "userId": currentUserId,
                    "caseId": caseId,
                    "roughWorkData": ckData,
                    "dependentId": dependentId,
                    "EditFor": "15"
                }
                fetch(`${apis}Admin/AddRoughWorkForDependent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(addJson),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("getRoughWork", result)
                        setLoad(false)
                        setShow(false)
                        getRoughWork()
                        setCkData("")
                    })
                })
            }
        } else {
            if (ckData == "" || null) {
                setMessage("Please Add Some Data")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("error")//set in value in useState
            } else {
                setLoad(true)
                var addJson = {
                    "userId": currentUserId,
                    "caseId": caseId,
                    "roughWorkData": ckData,
                    "EditFor": "15"

                }
                fetch(`${apis}Admin/AddRoughWork`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(addJson),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("getRoughWork", result)
                        setLoad(false)
                        setShow(false)
                        getRoughWork()
                        setCkData("")
                    })
                })
            }
        }



    }
    function edit() {
        console.log("edit")
        if (dependent == true) {
            setLoad(true)
            var addJson = {
                "userId": currentUserId,
                "caseId": caseId,
                "roughWorkData": updatckData,
                "dependentId": dependentId,
                "EditFor": editfor
            }
            fetch(`${apis}Admin/AddRoughWorkForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addJson),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("getRoughWork", result)
                    setLoad(false)
                    seteditShow(false)
                    getRoughWork()
                })
            })
        } else {
            setLoad(true)
            var addJson = {
                "userId": currentUserId,
                "caseId": caseId,
                "roughWorkData": updatckData,
                "EditFor": editfor
            }
            fetch(`${apis}Admin/AddRoughWork`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addJson),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("getRoughWork", result)
                    setLoad(false)
                    seteditShow(false)
                    setEditfor("")
                    getRoughWork()
                })
            })
        }

    }
    const [id, setId] = useState()
    const [userId, setUserId] = useState()
    function update() {
        console.log("update")
        if (dependent == true) {
            setLoad(true)
            var addJson = {
                "id": id,
                "userId": currentUserId,
                "caseId": caseId,
                "roughWorkData": updatckData,
                "dependentId": dependentId
            }
            fetch(`${apis}Admin/UpdateRoughWorkForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addJson),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("getRoughWork", result)
                    setLoad(false)
                    seteditShow(false)
                    getRoughWork()
                })
            })
        } else {
            setLoad(true)
            var addJson = {
                "id": id,
                "userId": currentUserId,
                "caseId": caseId,
                "roughWorkData": updatckData
            }
            fetch(`${apis}Admin/UpdateRoughWork`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addJson),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("getRoughWork", result)
                    setLoad(false)
                    seteditShow(false)
                    getRoughWork()
                })
            })
        }

    }

    console.log("prop.true", prop.true)
    return (<>
        <div className="card mb-1 shadow-none">
            <a href="#collapseTwoRoughwork" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="collapseTwoRoughwork">
                <div className="card-header" id="headingRoughwork">
                    <h6 className="m-0">
                        Rough Work
                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                    </h6>
                </div>
            </a>
            <div id="collapseTwoRoughwork" className="collapse" aria-labelledby="headingRoughwork" data-bs-parent="#accordion">
                <div class="card-body ">
                    <section>
                        <div style={{ textAlign: "right", marginBottom: '15px' }}>{prop.view == true ? <button className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4" type="button" data-qa-tag="uploader-drop-zone-button" onClick={handleShow}> Add</button> : null}</div>
                        <div className='roughwork'>
                            {roughWork.map((item) =>
                                <div className='workList'>
                                    <div className='userPicture'><img src={item.userImg === 'https://gcsl.api.digitalnoticeboard.biz/GcslProfile//' ? gcslImg : item.userImg} /></div>
                                    <div className='commentBox'>
                                        <div className='userName'><p>{item.userName} | {dayjs(item.time).format('ddd, MMM D, YYYY h:mm A')}</p> <p><EditIcon onClick={() => {
                                            setUpdatCkData(item.roughData)
                                            setId(item.id)
                                            setUserId(item.userId)
                                            edithandleShow()
                                            setEditfor(item.userId.toString())
                                        }} style={{ cursor: 'pointer' }} /> </p></div>
                                        {item.editFor == null || item.editFor == "BlankBlank" && item.userType == null ?
                                            null
                                            : <div style={{ display: "flex" }}>

                                                <ul className="edite-Client">
                                                    <li><b>Edit For:</b> {item.editFor} </li>
                                                    <li><b>User Type:</b> {item.userType}</li>
                                                    {/* <li><b>Time:</b> {dayjs(item.time).format('ddd, MMM D, YYYY h:mm A')}</li> */}
                                                </ul> </div>}

                                        <hr />
                                        <div dangerouslySetInnerHTML={{ __html: item.roughData }}></div>
                                    </div>

                                </div>
                            )}


                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add Rough Work</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={""}
                    onReady={editor => {
                        // editor.ui.view.editable.element.style.height = '300px'

                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setCkData(data)

                    }}

                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={add}>
                    {
                        load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Saving...</div> : "Save"
                    }

                </Button>
            </Modal.Footer>
        </Modal>
        {/* //-----------------------------------
        edit User
        //----------------------------------- */}
        <Modal show={editshow} onHide={edithandleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Rough Work</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={updatckData}
                    onReady={editor => {
                        //  editor.ui.view.editable.element.style.height = '300px'

                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setUpdatCkData(data)

                    }}

                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={edithandleClose}>
                    Close
                </Button>
                {
                    userId == currentUserId ? <Button variant="primary" onClick={update}>
                        {
                            load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Saving...</div> : "Save"
                        }
                    </Button> :

                        <Button variant="primary" onClick={edit}>
                            {
                                load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Saving...</div> : "Save"
                            }
                        </Button>
                }

            </Modal.Footer>
        </Modal>
        <Snackbar open={opens} autoHideDuration={6000} onClose={AlerthandleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert onClose={AlerthandleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    </>);
}

export default RoughWork;