import { useState, useEffect } from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import api from '../../apiUrl';
import { Col, Row } from 'react-bootstrap';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CaseLevelRollback(prop) {
    // console.log("prop.dependentId", prop.dependentId)
    var apis = api()
    // console.log(prop)
    const [upload, setUpload] = useState();
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [dependentName, setdependentName] = useState();
    const [selectUser, setSelectUser] = useState("GCSLResearcher");
    const [comments, setComments] = useState("")

    //Drag and Drop

    const [userList, setUserList] = useState([])
    function getUserLIst() {
        fetch(`${apis}Admin/RoleList`).then((respon) => {
            respon.json().then((result) => {
                console.log("getRoughWork", result)
                setUserList(result.data)
            })
        })
    }
    useEffect(() => {
        getUserLIst()
    }, [])
    const currentRole = prop.role

    const json = {
        "caseId": prop.caseId,
        "RoleName": selectUser,
        "stage": prop.stage,
        "Comments": comments,
        "FromLevel": prop.currentLevel,
        "ToLevel": selectUser == "GCSLResearcher" ? "LEVEL1" : selectUser == "GCSLReviewer" ? "LEVEL2" : selectUser == "GCSLVerificationResearcher" ? "LEVEL3" : selectUser == "FinalVerificationResearcher" ? "LEVEL4" : "LEVEL1",
        "caseCurrentLevel1": selectUser == "GCSLResearcher" ? 1 : selectUser == "GCSLReviewer" ? 2 : selectUser == "GCSLVerificationResearcher" ? 3 : selectUser == "FinalVerificationResearcher" ? 4 : 1
    }
    function upLoad(e) {
        console.log("Comments json", json)
        setLoad(true)

        fetch(`${apis}Admin/RollBack`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(json),
            mode: "cors",
        }).then((response) => response.json()).then((result) => {
            // console.log('Success:', result);
            setLoad(false)
            setMessage("Report Has been Created")//set in value in tMessage useState for Toster Message
            setOpens(true);//set in value in useState
            setColor("success")//set in value in useState
            // setCaseName(null)
            setUpload(null)
            setShow(false)
            prop.reload()



        }).catch((error) => {
            console.error('Error:', error);
            setLoad(false)
        });
    }
    const crossClose = () => {
        setShow(false)
    };

    const handleClose = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //  console.log("setModelShow(true);")
        } else if (!dependentName == false) {
            upLoad()
            setShow(false)
        };
        setValidated(true);

    };
    return (
        <>
            <Link onClick={handleShow} >
                <span className="B1piuH">
                    <i class="mdi mdi-arrow-collapse-up"></i>
                </span>
                <span className="rglmnm">Case Level Rollback</span>
            </Link>

            <Modal show={show} onHide={crossClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Case Level Rollback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>

                        <Row className="mb-3">


                            <Form.Group as={Col} md="12" controlId="validationCustom02" className="mb-3">
                                <Form.Label>Current User Role : {prop.role}</Form.Label>
                                <Form.Control
                                    required
                                    as="select"
                                    id="validationCustom13"
                                    onChange={(e) => {
                                        setSelectUser(e.target.value)
                                    }}
                                >
                                    <option value="" disabled>Select User Role</option>
                                    {
                                        userList
                                            .filter(item => item.value !== currentRole) // Exclude currentRole
                                            .filter(item => {
                                                const currentRoleIndex = userList.findIndex(role => role.value === currentRole);
                                                const itemIndex = userList.findIndex(role => role.value === item.value);
                                                return itemIndex < currentRoleIndex; // Include roles before currentRole
                                            })
                                            .map((item) => (
                                                <option key={item.id} value={item.value} disabled={prop.role === item.value}>
                                                    {item.roleName}
                                                </option>
                                            ))
                                    }
                                </Form.Control>


                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control
                                    // required
                                    as="textarea"
                                    placeholder=""
                                    onChange={(e) => setComments(e.target.value)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>

                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={crossClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={upLoad}>
                        {
                            load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Saving...</div> : "Update"
                        }

                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CaseLevelRollback;