import React, { useEffect } from "react";
import { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useLocation, useNavigate } from "react-router-dom";
import { upload } from "@testing-library/user-event/dist/upload";
import '../manageStackeHolder/stackholder.css'
import api from "../../apiUrl";
import dummyAvatar from '../manageStackeHolder/dummy-avatar.jpg'
import jwt_decode from "jwt-decode"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});





function UpdateUser() {
    const location = useLocation();
    const data = location.state
    console.log("test", data)
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [validated, setValidated] = useState(false);
    const [firstName, setFirstName] = useState(data.firstName);
    const [lastName, setLastName] = useState(data.lastName);
    const [mobileNumber, setMobileNumber] = useState(data.mobileNo);
    const [uploadPhoto, setUploadPhoto] = useState(null);
    const [basesf, setBasesf] = useState(null);
    const [addressLine, setAddressLine] = useState(data.addressLine);
    const [city, setCity] = useState(data.city);
    const [doorNoRoadName, setDoorNoRoadName] = useState(data.roadName);
    const [county, setCounty] = useState(data.counrty);
    const [postalCode, setPostalCode] = useState(data.postalCode);
    const [email, setEmail] = useState(data.email);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [timeZone, setTimeZone] = useState("(UTC-10:00) Hawaii");
    const [
        role, setRole] = useState("")
    const [load, setLoad] = useState(false)
    const [val, setVal] = useState(mobileNumber);
    const navigate = useNavigate();
    var password = "";
    var apis = api()
    console.log(timeZone)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };


    //phoneNumberValidated
    const phoneNumberValidated = (e) => {
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            setVal(e.target.value);
        }
    };
    const postalValidated = (e) => {
        const regex = /^[0-9\b]+$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            setPostalCode(e.target.value)

        }
    };
    const firstNameValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setFirstName(e.target.value);
        }
    };

    //LastNameValidated
    const lastNameValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setLastName(e.target.value);
        }
    };
    //PasswordValidation
    function passwordValidation(e) {
        console.log("passwordValidation", e.target.value)
        var passwordInput = document.getElementById('validationCustom11');
        var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if (e.target.value.match(paswd)) {
            // console.log("Reg Expression is good");
            passwordInput.classList.remove('passwordValidate');
            setNewPassword(e.target.value)
        } else {
            // console.log("Reg Expression is not good")
            passwordInput.classList.add('passwordValidate')
        }
    }
    //   image to base64

    // setTimeout(() => {
    //     var basesfBtn = document.getElementById('validationCustom04');
    //     basesfBtn.addEventListener('change', function () {
    //         const selectedfile = uploadPhoto;
    //         if (selectedfile.length > 0) {
    //             const [imageFile] = selectedfile;
    //             const fileReader = new FileReader();
    //             fileReader.onload = () => {
    //                 const srcData = fileReader.result;
    //                 setBasesf(srcData)
    //                 console.log('base64:', srcData)
    //             };
    //             fileReader.readAsDataURL(imageFile);
    //         }
    //     })
    // }, 1000)

    function onchnagePhoto(e) {
        console.log("onchnagePhoto", e.target.files)
        var basesfBtn = document.getElementById('validationCustom04');

        const selectedfile = e.target.files;
        if (selectedfile.length > 0) {
            const [imageFile] = selectedfile;
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const srcData = fileReader.result;
                setBasesf(srcData)
                console.log('base64:', srcData)
            };
            fileReader.readAsDataURL(imageFile);
        }

    }


    var postDataJson = {
        "firstName": firstName,
        "lastName": lastName,
        "mobileNo": mobileNumber,
        "photo": basesf,
        "addressLine": addressLine,
        "roadName": doorNoRoadName,
        "counrty": county,
        "postalCode": postalCode,
        "timeZone": timeZone,
        "email": email,
        "password": confirmNewPassword,
        "CurrentUserId": localStorage.getItem("id"),
    }
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    function postData() {
        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('mobileNo', mobileNumber);
        if (uploadPhoto == null || uploadPhoto.type == 'image/png' || uploadPhoto.type == 'image/jpeg' || uploadPhoto.type == 'image/gif' || uploadPhoto.type == 'image/webp') {
            formData.append('photo', uploadPhoto);
        } else {
            formData.append('photo', null);
        }
        formData.append('addressLine', addressLine);
        formData.append('roadName', doorNoRoadName);
        formData.append('city', city);
        formData.append('counrty', county);
        formData.append('postalCode', postalCode);
        formData.append('timeZone', timeZone);
        formData.append('Role', role);
        // formData.append('assignBranch', branch);
        formData.append('StakeHolderId', data.stakeHolderId);
        formData.append('CurrentUserId', currentUserId)
        console.log("formData", formData)
        setLoad(true)
        fetch(`${apis}Admin/UpdateAdminUsers`, {
            method: "POST",
            // headers: {
            //     "Content-Type": "application/json",
            // },
            mode: "cors",
            body: formData,
        }).then((respon) => {
            respon.json().then((result) => {
                console.log(result);
                navigate('/user')
                //console("basesf", basesf)
                setLoad(false)
            })
        })


    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            // console.log(postDataJson)
            if (!firstName === false
                //&& !lastName === false && !mobileNumber === false && !addressLine === false && !doorNoRoadName === false && !county === false && !postalCode === false
            ) {

                postData()
            }


        }

        setValidated(true);
    };
    useEffect(() => {
        function readURL(input) {
            if (input.files && input.files[0]) {

                //console.log("FileReader", input.files[0].type)
                if (input.files[0].type == 'image/png' || input.files[0].type == 'image/jpeg' || input.files[0].type == 'image/gif' || input.files[0].type == 'image/webp') {
                    var reader = new FileReader();
                    console.log(input.files[0].type, " file are supported")
                    reader.onload = function (e) {
                        document.querySelector('#imagePreview').style.backgroundImage = 'url(' + e.target.result + ')';
                    }
                } else {
                    setUploadPhoto(null)
                    console.log("uploadPhoto", uploadPhoto)
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        document.querySelector('#imagePreview').style.backgroundImage = `url(${data.photo})`;
                    }
                    setMessage(" Only PNG, JPG, GIF, WEBP is supported")//set in value in tMessage useState for Toster Message
                    setOpens(true);//set in value in useState
                    setColor("error")//set in value in useState
                }

                reader.readAsDataURL(input.files[0]);
            }
        }
        document.querySelector('#imageUpload').addEventListener('change', function () {
            readURL(this);
        });

    }, [])
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="index.html">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Stakeholder</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card radius-10" data-select2-id="12">
                    <div className="card-header bg-dark">
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h5 className="mb-0 color-blue"> Update User</h5>
                            </div>
                            <div className="col">
                                <div className="d-flex align-items-center justify-content-end gap-3 cursor-pointer">
                                    <div className="dropdown">
                                        <a className="dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown" aria-expanded="false"><i className="bx bx-dots-horizontal-rounded font-22 text-option"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="javascript:;">Ascending Name Wise</a>
                                            </li>
                                            <li><a className="dropdown-item" href="javascript:;">Descending Name Wise</a>
                                            </li>
                                            <li><a className="dropdown-item" href="javascript:;">Ascending Newest Date</a>
                                            </li>
                                            <li><a className="dropdown-item" href="javascript:;">Ascending Oldest Date</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Form noValidate validated={validated} >
                    <div className="card-body px-0 pt-0 pb-0" data-select2-id="11">
                        <div className="form-body" data-select2-id="10">
                            <div className="card-header bg-light py-1">
                                <div className="row g-3 align-items-center">
                                    <div className="col">
                                        <h5 className="form-section mt-2 mb-2">
                                            <i className="fa fa-user" aria-hidden="true"></i> Personal
                                            <span> Details</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body row">
                                <div className="col-md-3">
                                    <div className="avatar-upload">
                                        <div className="avatar-edit">
                                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(e) => setUploadPhoto(e.target.files[0])} />
                                            <label for="imageUpload">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

                                                    <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" />
                                                </svg>
                                            </label>
                                        </div>
                                        <div className="avatar-preview">
                                            <div id="imagePreview" style={{ backgroundImage: `url("${data.photo}")`, }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Form.Label>Contact First Name <span class="redstar">*</span></Form.Label>
                                                <Form.Control

                                                    type="text"
                                                    id="validationCustom01"
                                                    value={firstName}
                                                    maxLength={50}
                                                    onChange={(e) => firstNameValidated(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="validationCustom02">
                                                <Form.Label>Contact Last Name </Form.Label>
                                                <Form.Control

                                                    type="text"
                                                    id="validationCustom02"
                                                    value={lastName}
                                                    maxLength={50}
                                                    onChange={(e) => lastNameValidated(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">

                                            <Form.Group className="mb-3" controlId="validationCustom03">
                                                <Form.Label>Mobile Number </Form.Label>
                                                <Form.Control

                                                    type="text"
                                                    id="validationCustom03"
                                                    oninput="this.value = this.;"
                                                    maxLength="10"
                                                    value={val}
                                                    onChange={(e) => {
                                                        phoneNumberValidated(e)
                                                        setMobileNumber(e.target.value)
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* <div className="col-md-6 col-lg-6 col-12 mb-3">
                                            <Form.Group className="mb-3" controlId="validationCustom04">
                                                <Form.Label>Upload Photo</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    className="local"
                                                    id="validationCustom04"
                                                    // onChange={(e) => setUploadPhoto(e.target.files)}
                                                    onChange={(e) => onchnagePhoto(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </div> */}
                                    </div>
                                </div>

                            </div>
                            <div className="card-header bg-light py-1">
                                <div className="row g-3 align-items-center">
                                    <div className="col">
                                        <h5 className="form-section mt-2 mb-2">
                                            <i className="fas fa-map-marker-alt fa-lg"></i> Address
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" controlId="validationCustom05">
                                            <Form.Label>Address Line </Form.Label>
                                            <Form.Control

                                                type="text"
                                                id="validationCustom05"
                                                value={addressLine}
                                                maxLength={50}
                                                onChange={(e) => setAddressLine(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationCustom06">
                                            <Form.Label>City </Form.Label>
                                            <Form.Control

                                                type="text"
                                                id="validationCustom06"
                                                value={city}
                                                maxLength={50}
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" controlId="validationCustom07">
                                            <Form.Label>Door No. & Road Name </Form.Label>
                                            <Form.Control

                                                type="text"
                                                id="validationCustom07"
                                                value={doorNoRoadName}
                                                maxLength={50}
                                                onChange={(e) => setDoorNoRoadName(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationCustom08">
                                            <Form.Label>Country </Form.Label>
                                            <Form.Control

                                                type="text"
                                                id="validationCustom08"
                                                value={county}
                                                maxLength={50}
                                                onChange={(e) => setCounty(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" controlId="validationCustom09">
                                            <Form.Label>Postal Code </Form.Label>
                                            <Form.Control

                                                type="text"
                                                id="validationCustom09"
                                                value={postalCode}
                                                maxLength={10}
                                                onChange={(e) => postalValidated(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                </div>
                            </div>
                            {/* <div className="card-header bg-light py-1 mb-2 mt-4">
                                <div className="row g-3 align-items-center">
                                    <div className="col">
                                        <h5 className="form-section mt-2 mb-2">
                                            <i className="fa fa-credit-card" aria-hidden="true"></i> Account
                                            <span>Detail</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                             <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <Form.Group className="mb-3" controlId="validationCustom10">
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control
                                                
                                                type="email"
                                                id="validationCustom10"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 ">
                                        <Form.Group className="mb-3" controlId="validationCustom11">
                                            <Form.Label>Password *</Form.Label>
                                            <Form.Control
                                                
                                                type="password"
                                                id="validationCustom11"
                                                placeholder="Ex. (Password@123)"
                                                onChange={(e) => {
                                                    passwordValidation(e)
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <Form.Group className="mb-3" controlId="validationCustom12">
                                            <Form.Label>Confirm Password *</Form.Label>
                                            <Form.Control
                                                
                                                type="password"
                                                id="validationCustom12"
                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" controlId="validationCustom13">
                                            <Form.Label>Time Zone </Form.Label>
                                            <Form.Control
                                                
                                                as="select" type="select"
                                                id="validationCustom13"
                                                onChange={(e) => setTimeZone(e.target.value)}
                                            >

                                                <option value=" (UTC-10:00) Hawaii ">(UTC-10:00) Hawaii
                                                </option>
                                                <option value=" (UTC-09:30) Marquesas Islands ">(UTC-09:30) Marquesas Islands
                                                </option>
                                                <option value=" (UTC-09:00) Alaska ">(UTC-09:00) Alaska
                                                </option>
                                                <option value=" (UTC-09:00) Coordinated Universal Time-09 ">(UTC-09:00) Coordinated Universal Time-09
                                                </option>
                                                <option value=" (UTC-08:00) Baja California ">(UTC-08:00) Baja California
                                                </option>
                                                <option value=" (UTC-08:00) Coordinated Universal Time-08 ">(UTC-08:00) Coordinated Universal Time-08
                                                </option>
                                                <option value=" (UTC-08:00) Pacific Time (US &amp; Canada) ">(UTC-08:00) Pacific Time (US &amp; Canada)
                                                </option>
                                                <option value=" (UTC-07:00) Arizona ">(UTC-07:00) Arizona
                                                </option>
                                                <option value=" (UTC-07:00) Chihuahua, La Paz, Mazatlan ">(UTC-07:00) Chihuahua, La Paz, Mazatlan
                                                </option>
                                                <option value=" (UTC-07:00) Mountain Time (US &amp; Canada) ">(UTC-07:00) Mountain Time (US &amp; Canada)
                                                </option>
                                                <option value=" (UTC-07:00) Yukon ">(UTC-07:00) Yukon
                                                </option>
                                                <option value=" (UTC-06:00) Central America ">(UTC-06:00) Central America
                                                </option>
                                                <option value=" (UTC-06:00) Central Time (US &amp; Canada) ">(UTC-06:00) Central Time (US &amp; Canada)
                                                </option>
                                                <option value=" (UTC-06:00) Easter Island ">(UTC-06:00) Easter Island
                                                </option>
                                                <option value=" (UTC-06:00) Guadalajara, Mexico City, Monterrey ">(UTC-06:00) Guadalajara, Mexico City, Monterrey
                                                </option>
                                                <option value=" (UTC-06:00) Saskatchewan ">(UTC-06:00) Saskatchewan
                                                </option>
                                                <option value=" (UTC-05:00) Bogota, Lima, Quito, Rio Branco ">(UTC-05:00) Bogota, Lima, Quito, Rio Branco
                                                </option>
                                                <option value=" (UTC-05:00) Chetumal ">(UTC-05:00) Chetumal
                                                </option>
                                                <option value=" (UTC-05:00) Eastern Time (US &amp; Canada) ">(UTC-05:00) Eastern Time (US &amp; Canada)
                                                </option>
                                                <option value=" (UTC-05:00) Haiti ">(UTC-05:00) Haiti
                                                </option>
                                                <option value=" (UTC-05:00) Havana ">(UTC-05:00) Havana
                                                </option>
                                                <option value=" (UTC-05:00) Indiana (East) ">(UTC-05:00) Indiana (East)
                                                </option>
                                                <option value=" (UTC-05:00) Turks and Caicos ">(UTC-05:00) Turks and Caicos
                                                </option>
                                                <option value=" (UTC-04:00) Asuncion ">(UTC-04:00) Asuncion
                                                </option>
                                                <option value=" (UTC-04:00) Atlantic Time (Canada) ">(UTC-04:00) Atlantic Time (Canada)
                                                </option>
                                                <option value=" (UTC-04:00) Caracas ">(UTC-04:00) Caracas
                                                </option>
                                                <option value=" (UTC-04:00) Cuiaba ">(UTC-04:00) Cuiaba
                                                </option>
                                                <option value=" (UTC-04:00) Georgetown, La Paz, Manaus, San Juan ">(UTC-04:00) Georgetown, La Paz, Manaus, San Juan
                                                </option>
                                                <option value=" (UTC-04:00) Santiago ">(UTC-04:00) Santiago
                                                </option>
                                                <option value=" (UTC-03:30) Newfoundland ">(UTC-03:30) Newfoundland
                                                </option>
                                                <option value=" (UTC-03:00) Araguaina ">(UTC-03:00) Araguaina
                                                </option>
                                                <option value=" (UTC-03:00) Brasilia ">(UTC-03:00) Brasilia
                                                </option>
                                                <option value=" (UTC-03:00) Cayenne, Fortaleza ">(UTC-03:00) Cayenne, Fortaleza
                                                </option>
                                                <option value=" (UTC-03:00) City of Buenos Aires ">(UTC-03:00) City of Buenos Aires
                                                </option>
                                                <option value=" (UTC-03:00) Greenland ">(UTC-03:00) Greenland
                                                </option>
                                                <option value=" (UTC-03:00) Montevideo ">(UTC-03:00) Montevideo
                                                </option>
                                                <option value=" (UTC-03:00) Punta Arenas ">(UTC-03:00) Punta Arenas
                                                </option>
                                                <option value=" (UTC-03:00) Saint Pierre and Miquelon ">(UTC-03:00) Saint Pierre and Miquelon
                                                </option>
                                                <option value=" (UTC-03:00) Salvador ">(UTC-03:00) Salvador
                                                </option>
                                                <option value=" (UTC-02:00) Coordinated Universal Time-02 ">(UTC-02:00) Coordinated Universal Time-02
                                                </option>
                                                <option value=" (UTC-02:00) Mid-Atlantic - Old ">(UTC-02:00) Mid-Atlantic - Old
                                                </option>
                                                <option value=" (UTC-01:00) Azores ">(UTC-01:00) Azores
                                                </option>
                                                <option value=" (UTC-01:00) Cabo Verde Is ">(UTC-01:00) Cabo Verde Is.
                                                </option>
                                                <option value=" (UTC) Coordinated Universal Time ">(UTC) Coordinated Universal Time
                                                </option>
                                                <option value=" (UTC+00:00) Dublin, Edinburgh, Lisbon, London ">(UTC+00:00) Dublin, Edinburgh, Lisbon, London
                                                </option>
                                                <option value=" (UTC+00:00) Monrovia, Reykjavik ">(UTC+00:00) Monrovia, Reykjavik
                                                </option>
                                                <option value=" (UTC+00:00) Sao Tome ">(UTC+00:00) Sao Tome
                                                </option>
                                                <option value=" (UTC+01:00) Casablanca ">(UTC+01:00) Casablanca
                                                </option>
                                                <option value=" (UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna ">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                <option value=" (UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague ">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                <option value=" (UTC+01:00) Brussels, Copenhagen, Madrid, Paris ">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris
                                                </option>
                                                <option value=" (UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb ">(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                                                </option>
                                                <option value=" (UTC+01:00) West Central Africa ">(UTC+01:00) West Central Africa
                                                </option>
                                                <option value=" (UTC+02:00) Amman ">(UTC+02:00) Amman
                                                </option>
                                                <option value=" (UTC+02:00) Athens, Bucharest ">(UTC+02:00) Athens, Bucharest
                                                </option>
                                                <option value=" (UTC+02:00) Beirut ">(UTC+02:00) Beirut
                                                </option>
                                                <option value=" (UTC+02:00) Cairo ">(UTC+02:00) Cairo
                                                </option>
                                                <option value=" (UTC+02:00) Chisinau ">(UTC+02:00) Chisinau
                                                </option>
                                                <option value=" (UTC+02:00) Damascus ">(UTC+02:00) Damascus
                                                </option>
                                                <option value=" (UTC+02:00) Gaza, Hebron ">(UTC+02:00) Gaza, Hebron
                                                </option>
                                                <option value=" (UTC+02:00) Harare, Pretoria ">(UTC+02:00) Harare, Pretoria
                                                </option>
                                                <option value=" (UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius ">(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius

                                                </option>
                                                <option value=" (UTC+02:00) Jerusalem ">(UTC+02:00) Jerusalem
                                                </option>
                                                <option value=" (UTC+02:00) Juba ">(UTC+02:00) Juba
                                                </option>
                                                <option value=" (UTC+02:00) Kaliningrad ">(UTC+02:00) Kaliningrad
                                                </option>
                                                <option value=" (UTC+02:00) Khartoum ">(UTC+02:00) Khartoum
                                                </option>
                                                <option value=" (UTC+02:00) Tripoli ">(UTC+02:00) Tripoli
                                                </option>
                                                <option value=" (UTC+02:00) Windhoek ">(UTC+02:00) Windhoek
                                                </option>
                                                <option value=" (UTC+03:00) Baghdad ">(UTC+03:00) Baghdad
                                                </option>
                                                <option value=" (UTC+03:00) Istanbul ">(UTC+03:00) Istanbul
                                                </option>
                                                <option value=" (UTC+03:00) Kuwait, Riyadh ">(UTC+03:00) Kuwait, Riyadh
                                                </option>
                                                <option value=" (UTC+03:00) Minsk ">(UTC+03:00) Minsk
                                                </option>
                                                <option value=" (UTC+03:00) Moscow, St. Petersburg ">(UTC+03:00) Moscow, St. Petersburg
                                                </option>
                                                <option value=" (UTC+03:00) Nairobi ">(UTC+03:00) Nairobi
                                                </option>
                                                <option value=" (UTC+03:00) Volgograd ">(UTC+03:00) Volgograd
                                                </option>
                                                <option value=" (UTC+03:30) Tehran ">(UTC+03:30) Tehran
                                                </option>
                                                <option value=" (UTC+04:00) Abu Dhabi, Muscat ">(UTC+04:00) Abu Dhabi, Muscat
                                                </option>
                                                <option value=" (UTC+04:00) Astrakhan, Ulyanovsk ">(UTC+04:00) Astrakhan, Ulyanovsk
                                                </option>
                                                <option value=" (UTC+04:00) Baku ">(UTC+04:00) Baku
                                                </option>
                                                <option value=" (UTC+04:00) Izhevsk, Samara ">(UTC+04:00) Izhevsk, Samara
                                                </option>
                                                <option value=" (UTC+04:00) Port Louis ">(UTC+04:00) Port Louis
                                                </option>
                                                <option value=" (UTC+04:00) Saratov ">(UTC+04:00) Saratov
                                                </option>
                                                <option value=" (UTC+04:00) Tbilisi ">(UTC+04:00) Tbilisi
                                                </option>
                                                <option value=" (UTC+04:00) Yerevan ">(UTC+04:00) Yerevan
                                                </option>
                                                <option value=" (UTC+04:30) Kabul ">(UTC+04:30) Kabul
                                                </option>
                                                <option value=" (UTC+05:00) Ashgabat, Tashkent ">(UTC+05:00) Ashgabat, Tashkent
                                                </option>
                                                <option value=" (UTC+05:00) Ekaterinburg ">(UTC+05:00) Ekaterinburg
                                                </option>
                                                <option value=" (UTC+05:00) Islamabad, Karachi ">(UTC+05:00) Islamabad, Karachi
                                                </option>
                                                <option value=" (UTC+05:00) Qyzylorda ">(UTC+05:00) Qyzylorda
                                                </option>
                                                <option value=" (UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi ">(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi
                                                </option>
                                                <option value=" (UTC+05:30) Sri Jayawardenepura ">(UTC+05:30) Sri Jayawardenepura
                                                </option>
                                                <option value=" (UTC+05:45) Kathmandu ">(UTC+05:45) Kathmandu
                                                </option>
                                                <option value=" (UTC+06:00) Astana ">(UTC+06:00) Astana
                                                </option>
                                                <option value=" (UTC+06:00) Dhaka ">(UTC+06:00) Dhaka
                                                </option>
                                                <option value=" (UTC+06:00) Omsk ">(UTC+06:00) Omsk
                                                </option>
                                                <option value=" (UTC+06:30) Yangon (Rangoon) ">(UTC+06:30) Yangon (Rangoon)
                                                </option>
                                                <option value=" (UTC+07:00) Bangkok, Hanoi, Jakarta ">(UTC+07:00) Bangkok, Hanoi, Jakarta
                                                </option>
                                                <option value=" (UTC+07:00) Barnaul, Gorno-Altaysk ">(UTC+07:00) Barnaul, Gorno-Altaysk
                                                </option>
                                                <option value=" (UTC+07:00) Hovd ">(UTC+07:00) Hovd
                                                </option>
                                                <option value=" (UTC+07:00) Krasnoyarsk ">(UTC+07:00) Krasnoyarsk
                                                </option>
                                                <option value=" (UTC+07:00) Novosibirsk ">(UTC+07:00) Novosibirsk
                                                </option>
                                                <option value=" (UTC+07:00) Tomsk ">(UTC+07:00) Tomsk
                                                </option>
                                                <option value=" (UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi ">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                                                </option>
                                                <option value=" (UTC+08:00) Irkutsk ">(UTC+08:00) Irkutsk
                                                </option>
                                                <option value=" (UTC+08:00) Kuala Lumpur, Singapore ">(UTC+08:00) Kuala Lumpur, Singapore
                                                </option>
                                                <option value=" (UTC+08:00) Perth ">(UTC+08:00) Perth
                                                </option>
                                                <option value=" (UTC+08:00) Taipei ">(UTC+08:00) Taipei
                                                </option>
                                                <option value=" (UTC+08:00) Ulaanbaatar ">(UTC+08:00) Ulaanbaatar
                                                </option>
                                                <option value=" (UTC+08:45) Eucla ">(UTC+08:45) Eucla
                                                </option>
                                                <option value=" (UTC+09:00) Chita ">(UTC+09:00) Chita
                                                </option>
                                                <option value=" (UTC+09:00) Osaka, Sapporo, Tokyo ">(UTC+09:00) Osaka, Sapporo, Tokyo
                                                </option>
                                                <option value=" (UTC+09:00) Pyongyang ">(UTC+09:00) Pyongyang
                                                </option>
                                                <option value=" (UTC+09:00) Seoul ">(UTC+09:00) Seoul
                                                </option>
                                                <option value=" (UTC+09:00) Yakutsk ">(UTC+09:00) Yakutsk
                                                </option>
                                                <option value=" (UTC+09:30) Adelaide ">(UTC+09:30) Adelaide
                                                </option>
                                                <option value=" (UTC+09:30) Darwin ">(UTC+09:30) Darwin
                                                </option>
                                                <option value=" (UTC+10:00) Brisbane ">(UTC+10:00) Brisbane
                                                </option>
                                                <option value=" (UTC+10:00) Canberra, Melbourne, Sydney ">(UTC+10:00) Canberra, Melbourne, Sydney
                                                </option>
                                                <option value=" (UTC+10:00) Guam, Port Moresby ">(UTC+10:00) Guam, Port Moresby
                                                </option>
                                                <option value=" (UTC+10:00) Hobart ">(UTC+10:00) Hobart
                                                </option>
                                                <option value=" (UTC+10:00) Vladivostok ">(UTC+10:00) Vladivostok
                                                </option>
                                                <option value=" (UTC+10:30) Lord Howe Island ">(UTC+10:30) Lord Howe Island
                                                </option>
                                                <option value=" (UTC+11:00) Bougainville Island ">(UTC+11:00) Bougainville Island
                                                </option>
                                                <option value=" (UTC+11:00) Chokurdakh ">(UTC+11:00) Chokurdakh
                                                </option>
                                                <option value=" (UTC+11:00) Magadan ">(UTC+11:00) Magadan
                                                </option>
                                                <option value=" (UTC+11:00) Norfolk Island ">(UTC+11:00) Norfolk Island
                                                </option>
                                                <option value=" (UTC+11:00) Sakhalin ">(UTC+11:00) Sakhalin
                                                </option>
                                                <option value=" (UTC+11:00) Solomon Is., New Caledonia ">(UTC+11:00) Solomon Is., New Caledonia
                                                </option>
                                                <option value=" (UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky ">(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky
                                                </option>
                                                <option value=" (UTC+12:00) Auckland, Wellington ">(UTC+12:00) Auckland, Wellington
                                                </option>
                                                <option value=" (UTC+12:00) Coordinated Universal Time+12 ">(UTC+12:00) Coordinated Universal Time+12
                                                </option>
                                                <option value=" (UTC+12:00) Fiji ">(UTC+12:00) Fiji
                                                </option>
                                                <option value=" (UTC+12:00) Petropavlovsk-Kamchatsky - Old ">(UTC+12:00) Petropavlovsk-Kamchatsky - Old
                                                </option>
                                                <option value=" (UTC+12:45) Chatham Islands ">(UTC+12:45) Chatham Islands
                                                </option>
                                                <option value=" (UTC+13:00) Coordinated Universal Time+13 ">(UTC+13:00) Coordinated Universal Time+13
                                                </option>
                                                <option value=" (UTC+13:00) Nuku'alofa ">(UTC+13:00) Nuku'alofa
                                                </option>
                                                <option value=" (UTC+13:00) Samoa ">(UTC+13:00) Samoa
                                                </option>
                                                <option value=" (UTC+14:00) Kiritimati Island ">(UTC+14:00) Kiritimati Island</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-right">

                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Form>
                <div className="card-body pt-0">
                    <div className="card-body px-0 pt-0" data-select2-id="11">
                        <button onClick={handleSubmit} className="btn btn-primary" >
                            {
                                load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                            }   Update
                        </button>
                    </div>
                </div>

            </div>

            <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdateUser