import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "./style.css";
import { Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import DoneIcon from '@mui/icons-material/Done';
import { makeStyles } from "@material-ui/styles";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import jwt_decode from "jwt-decode";
import api from "../../apiUrl";
import { useIsFocusVisible } from "@mui/material";
import Buttons from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const useStyles = makeStyles({
    chipRoot: {
        "& .MuiChip-icon": {
            order: 1, // the label has a default order of 0, so this icon goes after the label
            marginRight: "0px",
            cursor: "pointer"
        },
        "& .MuiChip-deleteIcon": {
            marginRight: "0px",
            order: 2 // since this is greater than an order of 1, it goes after the icon
        }
    }
});

function RiskMatrix() {
    var apis = api()
    const classes = useStyles();
    const handleClick = (e) => {
        // do something
    }
    const [open, setOpen] = useState(false);
    const [openSub, setOpenSub] = useState(false);
    const [deleteItem, setdeleteItem] = useState()
    const [deleteItemSub, setdeleteItemSub] = useState()

    //---------------------------Add-----------------
    const [data, setData] = useState([]);
    const [category, setCategory] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const [field, setField] = useState("");
    const [editeCategory, setEditeCategory] = useState("");
    const [editeSubCategory, setEditeSubCategory] = useState("");
    const [editeCategoryId, setEditeCategoryId] = useState()
    const [editeSubCategoryId, setEditeSubCategoryId] = useState()
    const [fieldId, setFieldId] = useState(0)
    const [fieldList, setFieldList] = useState([]);
    const [fieldJson, setFieldJson] = useState([]);
    const [show, setShow] = useState(false);
    const [addFeildModel, setaddFeildModel] = useState(false);
    const [editFeildModel, setEditFeildModel] = useState(false);

    const [editSubFeildModel, setEditSubFeildModel] = useState(false);


    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id

    function getCategory() {
        fetch(`${apis}Admin/GetCategory`).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                setCategoryData(result.data)
            })
        })
    }
    const handleCloseDelete = () => {
        setOpen(false);
    };
    const handleCloseDeleteSub = () => {
        setOpenSub(false);
    };
    const handleClickOpen = (e) => {
        setOpen(true);
        setdeleteItem(e)

    };
    const handleClickOpenSub = (e) => {
        setOpenSub(true);
        setdeleteItemSub(e)
        console.log(e)

    };
    function deleteCategory(id) {
        fetch(`${apis}Admin/DeleteCategory?CategoryId=${deleteItem}`, { method: "POST", }).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                getCategory()
                setOpen(false);
            })
        })
    }
    function deleteSubCategory(id) {
        fetch(`${apis}Admin/DeleteSubCategory?Id=${deleteItemSub}`, { method: "POST", }).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                getCategory()
                setOpenSub(false);
            })
        })
    }

    // function addfield() {
    //     setFieldId(fieldId + 1)
    //     var fieldObj = {
    //         "id": fieldId,
    //         "name": field,
    //     }
    //     setFieldList([...fieldList, fieldObj])
    //     console.log("fieldList", fieldList)
    //     setField("")
    // }
    function add() {
        let setJson = {
            "CategoryName": category,
            "currentUserId": currentUserId

        }
        console.log("AddCategory", setJson)
        fetch(`${apis}Admin/AddCategory`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(setJson),
        }).then((respons) => {
            respons.json().then((result) => {
                console.log("AddCategory", result)
                getCategory()
                setCategory('')
                setShow(false)

            })
        })

        console.log("fieldJson", JSON.stringify(fieldJson))
    }
    const addCategoryhandleSubmit = (event) => {
        setValidated(false);
        console.log("addCategoryhandleSubmit", event)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!category == false) {
                add()
            }
        }
        setValidated(true);
    };

    const editFeildModelShow = (e) => {
        setEditFeildModel(true)
        setEditeCategoryId(e)
        setValidated(false);
    };
    const editFeildModelCloseCross = () => {
        setEditFeildModel(false)
    }
    const editFeildModelClose = (event) => {
        setValidated(false);
        let setJson = {
            "Id": editeCategoryId,
            "CategoryName": editeCategory,
            "currentUserId": currentUserId

        }

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!editeCategory == false) {
                fetch(`${apis}Admin/EditCategory`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(setJson),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("Edit Category", result)
                        getCategory()
                        //setCategory('')
                        setEditFeildModel(false)
                        setEditeCategory('')

                    })
                })
            }
        }
        setValidated(true);

    };

    //------------------------Edit-Sub Category--------------------------------
    const editSubFeildModelShow = (e) => {
        setEditSubFeildModel(true)
        setEditeSubCategoryId(e)
        setValidated(false);
    };
    const editSubFeildModelCloseCross = () => {
        setEditSubFeildModel(false)
    }
    const editSubFeildModelClose = (event) => {
        setValidated(false);
        let setJson = {
            "id": editeSubCategoryId,
            "subCategoryName": editeSubCategory,

        }

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!editeSubCategory == false) {
                fetch(`${apis}Admin/EditSubCategory`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify({ "id": editeSubCategoryId, "subCategoryName": editeSubCategory, }),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("Edit Category", result)
                        getCategory()
                        //setCategory('')
                        setEditSubFeildModel(false)
                        setEditeSubCategory('')

                    })
                })
            }
        }
        setValidated(true);

    };

    useEffect(() => {
        getCategory()
    }, [])





    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setValidated(false);
    };

    const addFeildModelClose = () => setaddFeildModel(false);
    const addFeildModelShow = () => {
        setValidated(false);
        setaddFeildModel(true)
    };
    //------------------------Add-Sub Category--------------------------------
    const addFeildModelSave = (event) => {
        console.log("addFeildModelSave")
        setValidated(false);
        let setJson = {
            "categoryId": categoryId,
            "subCategoryName": field,
            "currentUserId": currentUserId

        }

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!field == false) {
                fetch(`${apis}Admin/AddSubCategory`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(setJson),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("Edit Category", result)
                        getCategory()
                        //setCategory('')
                        setaddFeildModel(false)
                        setField('')

                    })
                })
            }
        }
        setValidated(true);
    }




    const [validated, setValidated] = useState(false);





    const handleDelete = (chipToDelete) => () => {

    };



    return (
        <>

            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard</li>
                                        <li className="breadcrumb-item active">
                                            Applicant Risk Matrix
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="masterBox">
                            <div className="action text-right">
                                <Button variant="primary" onClick={handleShow}>
                                    Add Category
                                </Button>
                                <Modal size="lg" show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add New Category</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label>Category name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setCategory(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>


                                            </Row>


                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={addCategoryhandleSubmit}>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* -------------------Add-Feild---------------------------------- */}
                                <Modal size="lg" show={addFeildModel} onHide={addFeildModelClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Subcategory</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated} >
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label>Subcategory Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setField(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>


                                            </Row>


                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={addFeildModelSave}>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* -------------------Add-Feild---------------------------------- */}
                                <Modal size="lg" show={editFeildModel} onHide={editFeildModelCloseCross}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Category Name </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label>Edit Field name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder=""
                                                        value={editeCategory}
                                                        onChange={(e) => setEditeCategory(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>


                                            </Row>


                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={editFeildModelCloseCross}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={editFeildModelClose}>
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* ------------------------Edit-Category-Name---------------------------------- */}
                                <Modal size="lg" show={editSubFeildModel} onHide={editSubFeildModelCloseCross}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit subcategory Name </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label>Edit Field name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder=""
                                                        value={editeSubCategory}
                                                        onChange={(e) => setEditeSubCategory(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>


                                            </Row>


                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={editSubFeildModelCloseCross}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={editSubFeildModelClose}>
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                            {
                                categoryData.map((item) =>
                                    <div className="masterItem">
                                        <div className="items">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h4 style={{ display: 'flex' }}>{item.categoryName} <span className="editemaincat">
                                                        <BorderColorIcon onClick={() => {
                                                            setEditeCategory(item.categoryName)
                                                            editFeildModelShow(item.categoryId)
                                                        }} />
                                                        <DeleteIcon onClick={() => handleClickOpen(item.categoryId)} />
                                                    </span></h4>
                                                </div>
                                                <div className="col-md-2 text-right">
                                                    <div className="iconAction">
                                                        <Chip
                                                            label="Add Subcategory"
                                                            onClick={() => {
                                                                addFeildModelShow()
                                                                setCategoryId(item.categoryId)
                                                            }}
                                                            icon={<AddIcon color="#fff" fontSize="small" />}
                                                            sx={{ backgroundColor: '#192e4d', color: "#fff" }}
                                                            className="addFeild"
                                                        />


                                                    </div>
                                                </div>
                                            </div>
                                            <Stack className="chiplist" direction="row" spacing={1}>
                                                {
                                                    item.field.map((items) =>
                                                        <Chip
                                                            classes={{
                                                                root: classes.chipRoot
                                                            }}
                                                            label={items.name}
                                                            variant="outlined"
                                                            onDelete={() => handleClickOpenSub(items.id)}
                                                            deleteIcon={<DeleteIcon />}
                                                            icon={<EditIcon onClick={() => {
                                                                setEditeSubCategory(items.name)
                                                                editSubFeildModelShow(items.id)
                                                            }} />}
                                                        />
                                                    )
                                                }

                                            </Stack>
                                        </div>


                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div >
            <Dialog
                open={open}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You won't be able to revert this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleCloseDelete}>Cancel</Buttons>
                    <Buttons onClick={deleteCategory} autoFocus>
                        Yes, delete it!
                    </Buttons>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSub}
                onClose={handleCloseDeleteSub}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You won't be able to revert this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleCloseDeleteSub}>Cancel</Buttons>
                    <Buttons onClick={deleteSubCategory} autoFocus>
                        Yes, delete it!
                    </Buttons>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default RiskMatrix;
