import { useState } from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import api from '../../apiUrl';
import './dashboard.css'
import { Col, Row } from 'react-bootstrap';
import { CircularProgress, Tooltip } from '@mui/material';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Filerename(prop) {
    // console.log("prop.dependentId", prop.dependentId)
    var apis = api()
    // console.log(prop)
    const [upload, setUpload] = useState();
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [newName, setNewName] = useState(prop.oldName);
    const [relationship, setRelationship] = useState(null);
    const [nameEdit, setNameEdit] = useState(true)
    //Drag and Drop

    // console.log("rename", prop.oldName.split("."))
    const json = {
        "caseId": prop.caseId,
        "documentId": prop.fileID,
        "dependentId": prop.documentId,
        "oldFileName": prop.oldName,
        "newFileName": newName + "." + prop.oldName.split(".")[1],
    }
    function upLoad(e) {

        setLoad(true)

        fetch(`${apis}Admin/ReNameDocuments/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(json),
            mode: "cors",
        }).then((response) => response.json()).then((result) => {
            // console.log('Success:', result);
            setLoad(false)
            setMessage("Report Has been Created")//set in value in tMessage useState for Toster Message
            setOpens(true);//set in value in useState
            setColor("success")//set in value in useState
            // setCaseName(null)
            setUpload(null)
            setShow(false)
            setNameEdit(true)
            prop.reload()



        }).catch((error) => {
            console.error('Error:', error);
            setLoad(false)
        });
        console.log("rename", json)
    }
    const crossClose = () => {
        setShow(false)
    };

    const handleClose = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //  console.log("setModelShow(true);")
        } else if (!newName == false) {
            upLoad()
            setShow(false)
        };
        setValidated(true);

    };
    return (
        <>

            <p className="mb-0 mt-2 ptextOver">
                {
                    nameEdit == true ? <>
                        <label className='textOver'>{prop.oldName}</label>
                        <span>&nbsp;</span>

                        <Tooltip title="Rename" arrow><span className="badge badge-soft-danger" onClick={() => setNameEdit(false)} style={prop.role == "3" || prop.role == "4" ? { display: "block" } : { display: "none" }}>
                            <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                        </span></Tooltip>

                    </> : <>
                        <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} style={{ width: "100%" }} />
                        <span>&nbsp;</span>
                        <span className="badge badge-soft-success" onClick={upLoad} >
                            {
                                load ? <CircularProgress size={15} /> : <i className="fas fa-check" aria-hidden="true"></i>
                            }

                        </span>
                    </>
                }
            </p>
        </>
    );
}

export default Filerename;