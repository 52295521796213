import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Topnav from "./topnav/topnav";
import Sidenav from "./sidenav/sidenav";
import Dashboard from "./main/dashboard/dashboard";
import AddStakeholder from "./main/manageStackeHolder/add-stakeholder";
import Confidential from "./main/dashboard/confidential";
import ViewProfile from "./main/dashboard/viewProfile";
import Verification from "./main/dashboard/verification";
import EditProfile from "./main/dashboard/editProfile";
import { useState } from "react";
import Logins from "./main/login";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ViewStakeholder from "./main/manageStackeHolder/view-stakeholder";
import FormExample from "./fvalidate";
import Modulepermission from "./main/security/modulepermission";
import Stackholderdashboard from "./main/stackholder/stackholderdashboard";
import CreateCase from "./main/stackholder/createCase";
import api from "./apiUrl";
import PendingCase from "./main/managecase/pending";
import RecentCase from "./main/managecase/recent";
import ClosedCase from "./main/managecase/closed";
import User from "./main/security/user";
import AddUser from "./main/security/adduser";
import UpdateStakeholder from "./main/manageStackeHolder/update-stakeholder";
import UpdateUser from "./main/security/update-user";
import AddDependent from "./main/stackholder/add-dependent";
import FinalRport from "./main/finalReport/finalRport";
import StackholderRecentCase from "./main/stackholderManagecase/recent";
import StackholderPendingCase from "./main/stackholderManagecase/pending";
import StackholderClosedCase from "./main/stackholderManagecase/closed";
import StakeAddUser from "./main/stakholder-security/stakeadduser";
import StakeUpdateUser from "./main/stakholder-security/stakeupdate-user";
import StakeUser from "./main/stakholder-security/stakeuser";
import DependedCreateProfile from "./main/dashboard/dependent/dependedCreateProfile";
import DependentViewProfile from "./main/dashboard/dependent/dependentviewProfile";
import DependentEditProfile from "./main/dashboard/dependent/dependentEditProfile";
import DependentVerification from "./main/dashboard/dependent/dependentVerification";
import Casecompo from "./main/casecompo";
import EventLog from "./main/event-log/eventLog";
import jwt_decode from "jwt-decode";
import ViewVerification from "./main/dashboard/viewVerification";
import FileHistory from "./main/dashboard/filehistory";
import Report from "./main/report/report";
import RiskMatrix from "./main/riskMatrix/riskMatrix";
import KeyRiskMatrix from "./main/riskMatrix/keyRiskMatrix";
import ForgetPassword from "./main/forgetPassword";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function App() {
  const [login, setLogin] = useState(localStorage.getItem("token"));
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [opens, setOpens] = useState(false);
  const [color, setColor] = useState("");
  var apis = api()


  // for Toaster Close on click button
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpens(false);
  };

  //Login API integrate function
  function loginsApp(e) {
    //set in value in load useState
    setLoad(true);
    //defind variable for get the value of email & password
    const sendUserData = {
      email: e.email,
      password: e.password,
    };

    // console.log(JSON.stringify(sendUserData));
    //Login API Fetch
    fetch(`${apis}Auth/Login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(sendUserData),
    }).then((respons) => {
      respons.json().then((result) => {
        console.log(JSON.stringify(result.data));
        if (result.data == null) {

          setMessage("Your Login Credentials is wrong.")//set in value in tMessage useState for Toster Message
          setOpens(true);//set in value in useState
          setColor("error")//set in value in useState
          setLoad(false);//set in value in useState
        } else if (result.data.accessToken) {
          //get the value from respon of API and set in local Storage
          localStorage.setItem("token", result.data.accessToken);
          localStorage.setItem("photo", result.data.photo);
          localStorage.setItem("userRoles", result.data.userRole);
          // localStorage.setItem("role", result.data.roleId[0]);
          //localStorage.setItem("name", result.data.contactname);
          //localStorage.setItem("id", result.data.id);
          var cretae = localStorage.getItem("token");
          setLogin(cretae);//set in value in login useState
          setLoad(true)//set in value in load useState
          autoLogout()//call this function for start the timer when user is on idel & no activity



        }

      });
      // if (respons.ok) {
      //   return respons.text();

      // }
      // throw new Error('Something went wrong.');
    }

    ).catch(e => {
      console.log(e);
      setLoad(false);
      setMessage("something went wrong.")//set in value in tMessage useState for Toster Message
      setOpens(true);//set in value in useState
      setColor("error")//set in value in useState

    });
  }

  //-jwtToen Decode-----------------
  var jwtToken = localStorage.getItem("token")

  if (jwtToken == null) {
    console.log(("jwtToken null"))
  } else {
    //get the from localStorage and save in variable
    const jwtDecode = jwt_decode(jwtToken)
    var rol = jwtDecode.RoleId;
    var name = jwtDecode.Name;
    var currentUserId = jwtDecode.Id;
    //console.log("jwtToken", jwtDecode)
  }



  //call the function when user click on Logout Button
  function logout() {
    setLogin(null);
    setLoad(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem("id");
    window.location.href = "/"
  }
  //call the function when user will be login.
  function autoLogout() {
    const idleDurationSecs = 900;
    const redirectUrl = 'https://www.google.co.in/';
    let idleTimeout;

    const resetIdleTimeout = function () {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      idleTimeout = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("name");
        localStorage.removeItem("id");
        setLogin(null);
        setLoad(false);
        window.location.href = "/"
      }, 10800000);
    };

    // Key events for reset time
    resetIdleTimeout();
    window.onmousemove = resetIdleTimeout;
    window.onkeypress = resetIdleTimeout;
    window.click = resetIdleTimeout;
    window.onclick = resetIdleTimeout;
    window.touchstart = resetIdleTimeout;
    window.onfocus = resetIdleTimeout;
    window.onchange = resetIdleTimeout;
    window.onmouseover = resetIdleTimeout;
    window.onmouseout = resetIdleTimeout;
    window.onmousemove = resetIdleTimeout;
    window.onmousedown = resetIdleTimeout;
    window.onmouseup = resetIdleTimeout;
    window.onkeypress = resetIdleTimeout;
    window.onkeydown = resetIdleTimeout;
    window.onkeyup = resetIdleTimeout;
    window.onsubmit = resetIdleTimeout;
    window.onreset = resetIdleTimeout;
    window.onselect = resetIdleTimeout;
    window.onscroll = resetIdleTimeout;

  }
  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("role");
  //     localStorage.removeItem("name");
  //     localStorage.removeItem("id");
  //   });
  // }, [])

  return (
    <>
      {/* Toaster Tag */}
      <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Router>

        {login == null ?
          <>
            <Logins login={loginsApp}
              load={load}
              handleClose={handleClose}
              opens={opens}
              message={message}
              color={color}
            />
          </>
          :
          <>
            <header id="page-topbar">
              <Topnav logout={logout} currentUserId={currentUserId} role={rol} name={name} />
            </header>
            <div class="vertical-menu">
              <Sidenav role={rol} />
            </div>
            <div className="main-content">
              <div className="page-content">
                <Routes>
                  {
                    rol == "1" || rol == "2" ? <Route path="/" element={<Dashboard name={name} roleId={rol} />} /> :
                      rol == "3" || rol == "4" ? <Route path="/" element={<Stackholderdashboard name={name} />} /> : ""
                  }
                  {
                    rol == "1" || rol == "2" ? <Route path="/*" element={<Dashboard name={name} />} /> :
                      rol == "3" || rol == "4" ? <Route path="/*" element={<Stackholderdashboard name={name} />} /> : ""
                  }
                  <Route path="/AddStakeholder" element={<AddStakeholder />} />
                  <Route path="/confidential" element={<Confidential />} />
                  <Route path="/viewProfile" element={<ViewProfile />} />
                  <Route path="/verification" element={<Verification />} />
                  <Route path="/editProfile" element={<EditProfile />} />
                  <Route path="/viewstakeholder" element={<ViewStakeholder />} />
                  <Route path="/formexample" element={<FormExample />} />
                  <Route path="/login" element={<Logins />} />
                  <Route path="/modulepermission" element={<Modulepermission />} />
                  <Route path="/createCase" element={<CreateCase />} />
                  <Route path="/pendingCase" element={<PendingCase name={name} roleId={rol} />} />
                  <Route path="/recentCase" element={<RecentCase name={name} roleId={rol} />} />
                  <Route path="/closedCase" element={<ClosedCase name={name} roleId={rol} />} />
                  <Route path="/user" element={<User />} />
                  <Route path="/adduser" element={<AddUser />} />
                  <Route path="/updateStakeholder" element={<UpdateStakeholder />} />
                  <Route path="/updateUser" element={<UpdateUser />} />
                  <Route path="/addDependent" element={<AddDependent />} />
                  <Route path="/finalRport" element={<FinalRport />} />
                  <Route path="/stackholderrecentCase" element={<StackholderRecentCase />} />
                  <Route path="/stackholderPendingCase" element={<StackholderPendingCase />} />
                  <Route path="/stackholderClosedCase" element={<StackholderClosedCase />} />
                  <Route path="/stakeUser" element={<StakeUser />} />
                  <Route path="/stakeAddUser" element={<StakeAddUser />} />
                  <Route path="/stakeUpdateUser" element={<StakeUpdateUser />} />
                  <Route path="/dependedCreateProfile" element={<DependedCreateProfile />} />
                  <Route path="/dependentViewProfile" element={<DependentViewProfile />} />
                  <Route path="/dependentEditProfile" element={<DependentEditProfile />} />
                  <Route path="/dependentVerification" element={<DependentVerification />} />
                  <Route path="/eventLog" element={<EventLog />} />
                  <Route path="/viewverification" element={<ViewVerification />} />
                  <Route path="/fileHistory" element={<FileHistory />} />
                  <Route path="/report" element={<Report />} />
                  <Route path="/riskMatrix" element={<RiskMatrix />} />
                  <Route path="/keyriskMatrix" element={<KeyRiskMatrix />} />
                  <Route path="/forgetPassword/" element={<ForgetPassword />} />
                </Routes>
              </div>
            </div>
          </>
        }
      </Router>
    </>
  );
}

export default App;
