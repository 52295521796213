import { useState, useEffect } from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import api from '../../apiUrl';
import { Col, Row } from 'react-bootstrap';
import dayjs from "dayjs"
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CaseLevelRollbackComments(prop) {
    // console.log("prop.dependentId", prop.dependentId)
    var apis = api()
    // console.log(prop)
    const [upload, setUpload] = useState();
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [dependentName, setdependentName] = useState();
    const [selectUser, setSelectUser] = useState("");
    const [comments, setComments] = useState("")

    //Drag and Drop

    const [data, setdata] = useState(prop.data)
    // function getRollBackComments() {
    //     prop.rol == 1 ?
    //         fetch(`${apis}Admin/GetRollBackCommentsforAdmin/${prop.caseId}`).then((respon) => {
    //             respon.json().then((result) => {
    //                 console.log("GetRollBackComments", result)
    //                 setdata(result.data)
    //             })
    //         }) :
    //         fetch(`${apis}Admin/GetRollBackComments/${prop.caseId}/${prop.currentUserId}/${prop.rollBackDetails}`).then((respon) => {
    //             respon.json().then((result) => {
    //                 console.log("GetRollBackComments", result)
    //                 setdata(result.data)
    //             })
    //         })
    // }
    // useEffect(() => {
    //     getRollBackComments()
    // }, [])
    const currentRole = prop.role
    console.log("currentRole", currentRole)

    const json = {
        "caseId": prop.caseId,
        "RoleName": selectUser,
        "stage": prop.stage,
        "Comments": comments,
        "FromLevel": prop.stage,
        "ToLevel": selectUser == "GCSLResearcher" ? "LEVEL1" : selectUser == "GCSLReviewer" ? "LEVEL2" : selectUser == "GCSLVerificationResearcher" ? "LEVEL3" : selectUser == "FinalVerificationResearcher" ? "LEVEL4" : null
    }
    function upLoad(e) {
        console.log("Comments json", json)
        // setLoad(true)

        // fetch(`${apis}Admin/RollBack`, {
        //     method: 'POST',
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(json),
        //     mode: "cors",
        // }).then((response) => response.json()).then((result) => {
        //     // console.log('Success:', result);
        //     setLoad(false)
        //     setMessage("Report Has been Created")//set in value in tMessage useState for Toster Message
        //     setOpens(true);//set in value in useState
        //     setColor("success")//set in value in useState
        //     // setCaseName(null)
        //     setUpload(null)
        //     setShow(false)
        //     prop.reload()



        // }).catch((error) => {
        //     console.error('Error:', error);
        //     setLoad(false)
        // });
    }
    const crossClose = () => {
        setShow(false)
    };

    const handleClose = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //  console.log("setModelShow(true);")
        } else if (!dependentName == false) {
            upLoad()
            setShow(false)
        };
        setValidated(true);

    };
    console.log("caseLevelRollbackComments", prop.data)
    return (
        <>

            <Link onClick={handleShow} >
                <span style={{ color: "#fff" }}>Case Rollback Comments</span>
            </Link>

            <Modal show={show} onHide={crossClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Case Rollback Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data == null ? <p>There is no any data</p> :
                        <div className='roughwork' style={{ height: "550px", overflow: "scroll", scrollbarWidth: "thin" }}>
                            {prop.data.map((item) =>
                                <div className='workLists'>

                                    <div className='commentBoxs'>
                                        <div className='userName'> <p style={{ margin: 5, fontSize: "12px" }}>Case Rollbacked from {item.fromLevel} to {item.toLevel} | {dayjs(item.datetime).format('ddd, MMM D, YYYY h:mm A')}</p></div>


                                        <div><b style={{ wordBreak: 'break-word' }}>{item.comment}</b></div>
                                    </div>

                                </div>
                            )}


                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={crossClose}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={upLoad}>
                        {
                            load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Saving...</div> : "Update"
                        }

                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CaseLevelRollbackComments;