import Form from 'react-bootstrap/Form';
import api from "../../apiUrl";
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

function Report() {
    var apis = api()
    const [clientData, setClientData] = useState([]);
    const [data, setData] = useState([]);
    const [client, setClient] = useState(0);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [status, setStatus] = useState("");
    const [userData, setUserData] = useState([])
    const [userId, setUserId] = useState(0)

    function getClientAPi() {
        fetch(`${apis}Admin/GetClientList`, {
            mode: "cors",
        }).then((respon) => {
            respon.json().then((result) => {
                // console.log(result);
                setClientData(result.data)
                console.log("GetClientList", result.data)
            })
        })
    }

    var reportJson = {
        "clientId": client,
        "userId": userId,
        "status": status,
        "fromDate": fromDate,
        "toDate": toDate
    }

    function getReportAPi() {
        fetch(`${apis}Admin/GetMonthlyReport`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(reportJson),
        }).then((respons) => {
            respons.json().then((result) => {
                console.log("reportJson", result.data)
                setData(result.data)
            })
        })
    }

    var testjson = [
        {
            "id": 2,
            "caseId": 1,
            "noOfDependent": 0,
            "candidateName": "Nozat Fraidoon Mohammed Mohammed",
            "status": "CLOSED",
            "createdDate": "2023-06-05T18:51:09",
            "verificationDate": "2023-06-05T19:20:00"
        }
    ]
    const columns = [
        {
            field: 'id',
            headerName: 'CaseID',
            flex: 1,
            editable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'candidateName',
            headerName: 'Case Name',
            headerClassName: 'super-app-theme--header',
            flex: 2,
            editable: false,
        },
        {
            field: 'noOfDependent',
            headerName: 'No Of Dependent',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'viewProfile',
            headerName: 'View Profile',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
            renderCell: (param) => <Link to="/viewProfile" state={{ id: param.id, report: true, }}>View Profile</Link>
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
            // valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
            valueFormatter: (params) => {
                if (params.value == null) { return ("Pending ") } else {
                    return dayjs(params.value).format('DD/MM/YYYY')
                }
            }

        },
        {
            field: 'verificationDate',
            headerName: 'Verification Date',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
            valueFormatter: (params) => {
                if (params.value == null) { return ("Pending ") } else {
                    return dayjs(params.value).format('DD/MM/YYYY')
                }
            }
        },
    ];
    function getUserss() {
        fetch(`${apis}Admin/GetAdminUserList`).then((respons) => {
            respons.json().then((result) => {
                console.log("getUser", result)
                setUserData(result.data)
            })
        })
    }
    useEffect(() => {
        setTimeout(() => {
            getClientAPi()
            getUserss()
            // getReportAPi()
        }, 500)
        // setData(json)
    }, [])
    return (<>
        <div className="border-bottom px-3 pt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Dashboard
                                    </li>
                                    <li className="breadcrumb-item active">Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">

                        <div className="col-xl-2 col-md-2">
                            <Form.Group className="mb-0 mt-2" >
                                <Form.Label><span>Client Staff Memebr</span></Form.Label>
                                <Form.Control required as="select" type="select"
                                    id="validationCustom11"
                                    onChange={(e) => {
                                        setClient(e.target.value)
                                        console.log(client)
                                    }}
                                ><option value="" disabled selected>-- Select --</option>

                                    {
                                        clientData.map((item) =>
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select this field
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-xl-2 col-md-2">
                            <Form.Group className="mb-0 mt-2" >
                                <Form.Label><span>GCSL Staff Memeber</span></Form.Label>
                                <Form.Control required as="select" type="select"
                                    id="validationCustom11"
                                    onChange={(e) => {
                                        setUserId(e.target.value)
                                        console.log(client)
                                    }}
                                ><option value="" disabled selected>-- Select --</option>

                                    {
                                        userData.map((item) =>
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select this field
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-xl-2 col-md-3">
                            <Form.Group className='mb-0 mt-2' >
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                    required
                                    type="date"
                                    onChange={(e) => setFromDate(e.target.value)}
                                    placeholder="First name"
                                    defaultValue="Mark"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-xl-2 col-md-3">
                            <Form.Group className='mb-0 mt-2' >
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                    required
                                    type="date"
                                    onChange={(e) => setToDate(e.target.value)}
                                    placeholder="First name"
                                    defaultValue="Mark"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-xl-2 col-md-2">
                            <Form.Group className="mb-0 mt-2">
                                <Form.Label><span>Status</span></Form.Label>
                                <Form.Control required as="select" type="select"
                                    id="validationCustom11"
                                    onChange={(e) => setStatus(e.target.value)}
                                ><option value="" disabled selected>-- Select --</option>
                                    <option value={"PENDING"}>PENDING</option>
                                    <option value={"CLOSED"}>CLOSED</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select this field
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-xl-2 col-md-2">
                            <label class="form-label" style={{ display: 'block' }}><span>&nbsp;</span></label>
                            <button onClick={getReportAPi} style={{ padding: '.47rem .75rem' }} class="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-2 mb-0" createby="st. Kitts-Nevis-Anguilla" href="/confidential">Search</button>
                        </div>
                    </div>
                </div>
                <div>
                    <br />
                    <br />
                </div>
                <Box sx={{
                    height: 650, width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: 'rgba(210, 210, 210, 1)',
                    },
                }}>
                    <DataGrid
                        rows={data}
                        columns={columns}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },

                            },
                        }}
                        pageSizeOptions={[50]}
                        disableColumnMenu
                        disableSelectionOnClick
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Box>
            </div>
            <hr />

        </div>
    </>);
}

export default Report;