import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Form } from "react-bootstrap";
import avtars from '../manageStackeHolder/dummy-avatar.jpg'
//Alert dialog import
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import api from "../../apiUrl"
import jwt_decode from "jwt-decode"

function User() {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    var apis = api()

    //Alert dialog setup
    const [open, setOpen] = useState(false);
    const [deleteItem, setdeleteItem] = useState()
    const [user, setUser] = useState(null)
    const [client, setClient] = useState(0);
    const [clientData, setClientData] = useState([]);

    const handleClickOpen = (e) => {
        setOpen(true);
        setdeleteItem(e)

    };

    const handleClose = () => {
        setOpen(false);
    };
    const DeleteHandleClose = () => {
        setOpen(false);
        fetch(`${apis}Admin/DeleteAdminUser/${deleteItem}/${currentUserId}`, {
            method: "POST",
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                console.log(result.data)
                viewAdminUser()
            })
        })
    };

    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id

    function getClientAPi() {
        fetch(`${apis}Admin/GetClientList`, {
            mode: "cors",
        }).then((respon) => {
            respon.json().then((result) => {
                // console.log(result);
                setClientData(result.data)
                console.log("GetClientList", result.data)
            })
        })
    }
    var jsonAll = {
        "currentUserId": 0,
        "clientId": 0,
        "role": "",
        "showAll": ""
    }
    var jsonAdmin = {
        "currentUserId": 0,
        "clientId": 0,
        "role": "AdminUser",
        "showAll": ""
    }
    var jsonClient = {
        "currentUserId": 0,
        "clientId": client,
        "role": "",
        "showAll": ""
    }

    function viewAdminUser() {
        setLoad(true)
        fetch(`${apis}Admin/GetAdminUser/${user}/${client}`).then((respons) => {
            respons.json().then((result) => {
                console.log(result.data)
                setData(result.data)
                setLoad(false)

            })
        })

    }



    function deletViewstakeholderUser(e) {
        fetch(`${apis}Admin/DeleteAdminUser/${e}/${localStorage.getItem('id')}`, {
            method: "POST",
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                console.log(result.data)
                viewAdminUser()
            })
        })
    }
    useEffect(() => {
        viewAdminUser()
        getClientAPi()
    }, [])
    useEffect(() => {
        viewAdminUser()
    }, [user])
    useEffect(() => {
        viewAdminUser()
    }, [client])
    return (
        <>

            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item active">View All Users</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col col-lg-4 col-md-4">
                            <label className="control-label">Role Name</label>
                            <div className="input-group">
                                <select className="single-select form-select" id="inputGroupSelect05"
                                    onChange={(e) => {
                                        setUser(e.target.value)
                                        setClient(0)

                                    }}
                                >
                                    <option selected="selected" value="showall">Show All</option>
                                    <option value="Admin">GCSL Staff Memeber</option>
                                    <option value="Client">Client Staff Memebr</option>
                                </select>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-4">
                            {
                                user == "Client" ?
                                    <div>
                                        <label className="control-label">Select Client</label>
                                        <div className="input-group">
                                            <select className="single-select form-select" id="inputGroupSelect05"
                                                onChange={(e) => {
                                                    setClient(e.target.value)
                                                    console.log(client)
                                                }}
                                            >
                                                <option value="" disabled selected>-- Client Name --</option>

                                                {
                                                    clientData.map((item) =>
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    : ""
                            }

                        </div>




                        <div className="col col-lg-4 col-md-4 text-right mt-3">
                            <Link to="/adduser" type="button" className="btn _2V07sR _1pRmkk _2BrVpl _1ILhXq px-3">+ Add</Link>
                        </div>
                    </div>

                </div>
            </div >

            {
                load ? <div class="spin-a" > <div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> :
                    <div className="container-fluid">
                        <div className="table-responsive mt-4">
                            <table id="example" className="table table-striped table-bordered" style={{ width: "100%", }}>
                                <thead>
                                    <tr>
                                        <th> Logo </th>
                                        <th> Name </th>

                                        <th> Email/UserName  </th>
                                        <th> Role </th>
                                        <th> Address </th>
                                        <th>  Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.length == 0 ?
                                            <tr>
                                                <td colSpan={7}>

                                                    <div className='records'><p>Records not found</p></div>
                                                </td>
                                            </tr>
                                            : null
                                    }
                                    {
                                        data.map((item) =>
                                            <tr key={item.id}>
                                                <td className=" sorting_1"><img className="rounded-circle header-profile-user"
                                                    src={
                                                        item.photo == '' ? avtars : item.photo
                                                    } alt="User Photo" style={{ width: "36px", height: "36px", objectFit: "cover", objectPosition: "center" }} /></td>
                                                <td className=" sorting_1">{item.firstName} {item.lastName}</td>

                                                <td className="">{item.email}</td>
                                                <td className="">{item.role}</td>
                                                <td className="">{item.addressLine},{item.counrty}</td>

                                                <td className="">&nbsp;
                                                    <Link to="/updateUser" className="btn btn-green-outline2 btn-xs" state={item}> <i class="fas fa-pen"></i></Link>&nbsp;
                                                    <button className="btn btn-xs btn-danger" onClick={() => handleClickOpen(item.userId)}><i className="fa fa-trash fa-lg"></i></button>


                                                </td>
                                            </tr>

                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You won't be able to revert this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={DeleteHandleClose} autoFocus>
                        Yes, delete it!
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default User