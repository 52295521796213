import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "./style.css";
import { Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import DoneIcon from '@mui/icons-material/Done';
import { makeStyles } from "@material-ui/styles";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import api from "../../apiUrl";
import jwt_decode from "jwt-decode";
import Buttons from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const useStyles = makeStyles({
    chipRoot: {
        "& .MuiChip-icon": {
            order: 1, // the label has a default order of 0, so this icon goes after the label
            marginRight: "0px",
            cursor: "pointer"
        },
        "& .MuiChip-deleteIcon": {
            marginRight: "0px",
            order: 2 // since this is greater than an order of 1, it goes after the icon
        }
    }
});


function KeyRiskMatrix() {
    const classes = useStyles();
    const handleClick = (e) => {
        // do something
    }
    var apis = api()
    //---------------------------Add-----------------
    const [data, setData] = useState([]);
    const [category, setCategory] = useState("");
    const [field, setField] = useState("");
    const [editefield, setEditeField] = useState("");
    const [fieldId, setFieldId] = useState(0)
    const [fieldList, setFieldList] = useState([]);
    const [fieldJson, setFieldJson] = useState([]);
    const [show, setShow] = useState(false);
    const [addFeildModel, setaddFeildModel] = useState(false);
    const [editFeildModel, setEditFeildModel] = useState(false);
    const [editValue, setEditValue] = useState("");


    const [open, setOpen] = useState(false);
    const [deleteItem, setdeleteItem] = useState()


    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id


    function getApi() {
        fetch(`${apis}Admin/GetInformationRiskMatrix/0`).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                setData(result.data)
            })
        })
    }
    useEffect(() => {
        getApi()
    }, [])
    function addfield() {
        setFieldId(fieldId + 1)
        var fieldObj = {
            "id": fieldId,
            "name": field,
        }
        setFieldList([...fieldList, fieldObj])
        console.log("fieldList", fieldList)
        setField("")
    }
    function add() {
        let setJson = {
            "category": category,
            "field": fieldList
        }
        setFieldJson(setJson)
        setFieldList([])
        setFieldId(0)
        setShow(false)
        console.log("fieldJson", JSON.stringify(fieldJson))
    }




    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const editFeildModelClose = () => setEditFeildModel(false);
    const editFeildModelShow = (e, id) => {
        setEditeField(e)
        setFieldId(id)
        setEditFeildModel(true)
        setValidated(false);
    };
    const addFeildModelClose = () => setaddFeildModel(false);
    const addFeildModelSave = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!field == false) {
                let setJson = {
                    "subCategoryName": field,
                    "currentUserId": currentUserId
                }
                fetch(`${apis}Admin/AddInformationRisk`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(setJson),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("Edit Category", result)
                        getApi()
                        //setCategory('')
                        setaddFeildModel(false)
                        setField('')

                    })
                })
            }

        }
        setValidated(true);

    };

    const editeFeildModelSave = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!editefield == false) {
                let setJson = {
                    "Id": fieldId,
                    "subCategoryName": editefield,
                    "currentUserId": currentUserId
                }
                fetch(`${apis}Admin/EditInformationRiskMatrix`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(setJson),
                }).then((respons) => {
                    respons.json().then((result) => {
                        console.log("Edit Category", result)
                        getApi()
                        //setCategory('')
                        setEditFeildModel(false)
                        setField('')

                    })
                })
            }

        }
        setValidated(true);

    };
    const addFeildModelShow = () => {
        setValidated(false);
        setaddFeildModel(true)
    };



    const [chipData, setChipData] = React.useState([
        { key: 0, label: "Angular" },
        { key: 1, label: "jQuery" },
        { key: 2, label: "Polymer" },
        { key: 3, label: "React" },
        { key: 4, label: "Vue.js" },
    ]);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    const handleClickOpen = (e) => {
        setOpen(true);
        setdeleteItem(e)

    };

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) =>
            chips.filter((chip) => chip.key !== chipToDelete.key)
        );
    };



    //setField([...field,])
    var json = [
        {
            "id": 1,
            "name": "Undisclosed Corporate Affiliations "
        },
        {
            "id": 2,
            "name": "Income And Net Worth"
        },
        {
            "id": 1,
            "name": "Financial Standings "
        },
        {
            "id": 1,
            "name": "Bank Risk"
        },
        {
            "id": 1,
            "name": "Bankruptcy"
        }
    ]
    const handleCloseDelete = () => {
        setOpen(false);
    };
    function deleteCategory(id) {
        fetch(`${apis}Admin/DeleteInformationRisk?Id=${deleteItem}`, { method: "POST", }).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                getApi()
                setOpen(false);
            })
        })
    }
    return (
        <>

            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard</li>
                                        <li className="breadcrumb-item active">
                                            Risk Matrix Key
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="masterBox">
                            <div className="action text-right">

                                {/* -------------------Add-Feild---------------------------------- */}
                                <Modal size="lg" show={addFeildModel} onHide={addFeildModelClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Subcategory</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated} >
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label>Subcategory Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setField(e.target.value)}
                                                    />
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </Form.Group>


                                            </Row>


                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={addFeildModelSave}>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* -------------------Add-Feild---------------------------------- */}
                                <Modal size="lg" show={editFeildModel} onHide={editFeildModelClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Subcategory</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label></Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={editefield}
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setEditeField(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={editeFeildModelSave}>
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                            <div className="masterItem">
                                <div className="items">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <h4>Information Risk Matrix Key</h4>
                                        </div>
                                        <div className="col-md-0 text-right">
                                            <Chip
                                                label="Add"
                                                onClick={addFeildModelShow}
                                                icon={<AddIcon color="#fff" fontSize="small" />}
                                                sx={{ backgroundColor: '#192e4d', color: "#fff" }}
                                                className="addFeild"
                                            />
                                        </div>
                                    </div>
                                    <Stack className="chiplist" direction="row" spacing={1}>
                                        {
                                            data.map((items) =>
                                                <Chip
                                                    classes={{
                                                        root: classes.chipRoot
                                                    }}
                                                    label={items.name}
                                                    variant="outlined"
                                                    onDelete={() => handleClickOpen(items.id)}
                                                    deleteIcon={<DeleteIcon onClick={() => handleClickOpen(items.id)} />}
                                                    icon={<EditIcon onClick={() => { editFeildModelShow(items.name, items.id) }} />}
                                                />
                                            )
                                        }

                                    </Stack>
                                </div>

                                <Dialog
                                    open={open}
                                    onClose={handleCloseDelete}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Are you sure?"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            You won't be able to revert this!
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Buttons onClick={handleCloseDelete}>Cancel</Buttons>
                                        <Buttons onClick={deleteCategory} autoFocus>
                                            Yes, delete it!
                                        </Buttons>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default KeyRiskMatrix;
