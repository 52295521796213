import { useEffect, useState } from 'react';
import { json, Link, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import '../dashboard.css';
import api from '../../../apiUrl';
import jwt_decode from "jwt-decode";
import Table from 'react-bootstrap/Table';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { flushSync } from 'react-dom';
import { Button } from '@mui/material';
function DependentViewProfile() {
    const location = useLocation();
    const [datas, setDatas] = useState([])
    const [load, setLoad] = useState(false)
    const [loadPdf, setLoadPdf] = useState(false)
    const [loadWord, setLoadWord] = useState(false)
    const [caseId, setcaseId] = useState(location.state.caseId)
    const [dateV, setDatev] = useState()
    console.log("location.state", location.state)
    const state = location.state.profileDetails;
    console.log("View Profile", location.state.createOn)
    console.log("View CaseID", location.state.caseId)
    // console.log({ "createBye": location.state.createBy, "createOn": location.state.createOn })
    console.log("test json", caseId)
    var apis = api()
    var dates = new Date(location.state.createOn)
    var split = dates.toString().split('GMT')
    var dateString = split[0].trim()
    const [open, setOpen] = useState(false);
    const [deleteItem, setdeleteItem] = useState()
    const handleClickOpen = (e) => {
        setOpen(true);
        setdeleteItem(e)

    };
    const handleClose = () => {
        setOpen(false);
    };
    function getUserApi() {
        setLoad(true)
        console.log("state.id:-", caseId)
        fetch(`${apis}Admin/GetDependentProfile/${location.state.dependentId}/${currentUserId}/?CaseId=${location.state.caseId}`, {
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                // console.log(result.data[0].name)
                console.log(JSON.stringify(result.data))
                setDatas([result.data])
                console.log("setDatas", datas)
                setLoad(false)
            })
        })
    }


    const date = new Date(location.state.createOn)
    var pdfR = document.getElementById("pdfWrapper")
    function genratepdf() {
        var doc = new jsPDF("p", "pt", "a4");
        doc.html(pdfR, {
            callback: function (pdf) {
                pdf.save("report.pdf")
            }
        })
    }
    //get - API - for View Verification
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    const role = jwtDecode.Role
    const caserole = location.state.roles
    console.log("role", role, caserole)
    const [viewDatas, setViewDatas] = useState([])
    const [draftDatas, setDraftDatas] = useState([])
    const [draftSet, setDraftSet] = useState([])
    async function getViewVerification() {
        const data = await fetch(`${apis}Admin/ViewCandidateVerificationForDependent/${location.state.dependentId}`)
        const viewData = await data.json()
        setViewDatas(viewData.data)
        console.log("View Verification", JSON.stringify(viewData.data))

    }

    //get - API - for Draft Verification
    async function getDraftVerification() {
        const ddata = await fetch(`${apis}Admin/ViewCandidateVerificationForDependent/${location.state.dependentId}`)
        const DraftData = await ddata.json()
        setDraftDatas(DraftData.data)
        console.log("Draft Verification", JSON.stringify(DraftData.data))


    }

    //get - API - for Draft Verification
    const getReportFilePdf = () => {
        setLoadPdf(true)
        // using Java Script method to get PDF file
        fetch(`${apis}Admin/DownLoadReportForDependent/${location.state.id}/${location.state.dependentId}/PDF`).then((respons) => {
            respons.text().then((result) => {
                setLoadPdf(false)
                console.log("pdf Result", result)
                var fileDownload = document.createElement("a");
                document.body.appendChild(fileDownload);
                fileDownload.href = result;
                fileDownload.target = "_blank";
                fileDownload.download = `Report - ${location.state.candidateName}.pdf`;
                fileDownload.click();
                document.body.removeChild(fileDownload);
            })
        })

    }
    const getReportFileWord = () => {
        setLoadWord(true)
        // using Java Script method to get PDF file
        fetch(`${apis}Admin/DownLoadReportForDependent/${location.state.id}/${location.state.dependentId}/WORD`).then(response => {
            response.text().then((result) => {
                setLoadWord(false)
                console.log("pdf Result", result)
                var fileDownload = document.createElement("a");
                document.body.appendChild(fileDownload);
                fileDownload.href = result;
                fileDownload.target = "_blank";
                fileDownload.download = `Report - ${location.state.candidateName}.docx`;;
                fileDownload.click();
                document.body.removeChild(fileDownload);
            })
        })
    }
    function caseClosed() {
        fetch(`${apis}Admin/CloseCaseForDependent/${caseId}/${location.state.dependentId}`, {
            method: "POST",
        }).then((respons) => {
            respons.json().then((result) => {
                console.log(result)
                getUserApi()
                setOpen(false);
            })
        })
    }
    //get - API - for View Verification
    useEffect(() => {
        getUserApi()
        //console.log("state Name", datas)
        console.log("useEffect called")
        //console.log("state Name", name)
        getViewVerification()
        getDraftVerification()
    }, [])
    useEffect(() => {

        draftDatas.map((item) => {
            console.log("Item Draft Verification", item)
            setDraftSet(item)
        })
        //--------------

    }, [draftDatas])
    return (
        <>
            {
                load ? <div class="spin-a"><div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> : null
            }
            {datas.map((item) =>
                <div id="pdflayout">
                    <div className="border-bottom px-3 pt-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">

                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                                                <li className="breadcrumb-item active">View Profile</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid mt-4">
                        <div className='container-fluid'>

                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-10 ">
                                            <p className=''><b>Last Edit By</b>: {item.lastEditedUser}</p>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <button onClick={genratepdf}>PDF</button> */}
                        <div className="card">
                            <div className="card-header bg-light py-1 mb-2">
                                <div className="row g-3 align-items-center">
                                    <div className="col">

                                        <h5 className="form-section mt-2 mb-2"> Candidate Provided Details
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Last Name
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.lastName}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Given Name
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.givenName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Date of Birth
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.birthOfDay} {item.birthOfMonth} {item.birthOfYear}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Findings by GCSL
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.fbyGcsldate} {item.fbyGcslMonth} {item.fbyGcslYear}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Place of Birth
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.placeOfBirth}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Findings by GCSL
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.placeofBirthFindingsbyGCSL}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    P.P. ID
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.ppid}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    ID Card
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.idCard}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Nat. ID
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.natID}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Findings by GCSL
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.natIDFindingsbyGCSL}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Known Address
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.knowAddress}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Gender
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.gender}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Current Address
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.currentAddress}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="border-bottom2">
                                                    Findings by GCSL
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-dotted">
                                                {item.currentAddressFindingsbyGCSL}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header bg-light py-1 mb-2">
                                    <div className="row g-3 align-items-center">
                                        <div className="col">

                                            <h5 className="form-section mt-2 mb-2"> Corporate Affiliations
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Table striped bordered hover>

                                            <tbody>
                                                {
                                                    item.corporateAfflicationss.map((items) =>
                                                        <tr>
                                                            <td>{items.corporateAffiliation}</td>

                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>


                                <div className="card-header bg-light py-1 mb-2">
                                    <div className="row g-3 align-items-center">
                                        <div className="col">

                                            <h5 className="form-section mt-2 mb-2"> Family Inquiries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Family Member</th>
                                                    <th>Relation</th>
                                                    <th>Date of Birth</th>
                                                    <th>Passport Number</th>
                                                    <th>Place of Birth</th>
                                                    {/* <th>Comments</th> */}
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {
                                                    item.familyEnquiry.map((items) =>
                                                        <tr>
                                                            <td>{items.familyMember}</td>
                                                            <td>{items.relation}</td>
                                                            <td>{items.birthOfDay} - {items.birthOfMonth} - {items.birthOfYear}</td>
                                                            <td>{items.passportNumber}</td>
                                                            <td>{items.placeOfBirth}</td>
                                                            {/* <td>{items.comments}</td> */}

                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <div className="card-header bg-light py-1 mb-2">
                                    <div className="row g-3 align-items-center">
                                        <div className="col">

                                            <h5 className="form-section mt-2 mb-2"> Most Important Persons/ Companies
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Location</th>
                                                    {/* <th>Comments</th> */}
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {
                                                    item.mostImportantPerson.map((items) =>
                                                        <tr>
                                                            <td>{items.name}</td>
                                                            <td>{items.location}</td>
                                                            {/* <td>{items.comments}</td> */}

                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>




                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-2 text-right full-w-button">
                                                {item.isVerified == false ?
                                                    <Link to="/editProfile" state={{
                                                        "createBy": location.state.createBy,
                                                        "createOn": location.state.createOn,
                                                        "id": location.state.caseId,
                                                        "doc": location.state.doc,
                                                        "profileDetails": item,
                                                        'caseId': location.state.caseId,
                                                        'dependentId': location.state.dependentId,
                                                        dependent: true,
                                                        "createBy": location.state.createBy,
                                                        "createOn": location.state.createOn,
                                                        "candidateName": location.state.candidateName,
                                                        "clientName": location.state.clientName,
                                                        "candidateName": location.state.caseName
                                                    }
                                                    }

                                                        className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button"
                                                        data-qa-tag="uploader-drop-zone-button"> Edit Profile </Link>
                                                    : ""}
                                            </div>
                                            <div className="col-md-2 text-right full-w-button">
                                                {
                                                    (item.isCaseClosed == false && caserole !== role) || (item.isCaseClosed == true && caserole !== role) || (item.isCaseClosed == true && caserole == role) ?
                                                        viewDatas.map((verify) =>
                                                            <Link to="/viewverification" state={{
                                                                "createBy": location.state.createBy,
                                                                "createOn": location.state.createOn,
                                                                "id": location.state.id,
                                                                "doc": location.state.files,
                                                                "profileDetails": item,
                                                                'caseId': location.state.id,
                                                                'verifyData': verify,

                                                            }
                                                            }

                                                                className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button"
                                                                data-qa-tag="uploader-drop-zone-button">View Verification </Link>
                                                        )
                                                        : item.isVerified == true && item.isCaseClosed == false && caserole == role ?
                                                            <Link to="/dependentVerification" state={{
                                                                "profileDetails": item,
                                                                'caseId': caseId,
                                                                'dependentId': location.state.dependentId,
                                                                "draft": draftSet,
                                                                "dependentSatus": true
                                                            }}
                                                                className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button"
                                                                data-qa-tag="uploader-drop-zone-button"> Edit Verification </Link>
                                                            : ""
                                                }
                                                {

                                                }

                                            </div>
                                            <div className="col-md-4 text-center full-w-button">



                                                {
                                                    item.isVerified == false ?
                                                        <Link to="/dependentVerification" state={{
                                                            "profileDetails": item,
                                                            'caseId': caseId,
                                                            'dependentId': location.state.dependentId,
                                                            "draft": draftSet,
                                                            "dependentSatus": true,
                                                            clientName: location.state.clientName
                                                        }}
                                                            className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button"
                                                            data-qa-tag="uploader-drop-zone-button"> Start Verification </Link>
                                                        :
                                                        <div className='twoBtn'>
                                                            <button onClick={getReportFilePdf} style={{ width: "100%", marginRight: "23px" }} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3">
                                                                {
                                                                    loadPdf ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Downloading...</div> : "Download PDF"
                                                                }
                                                            </button>
                                                            {/* <button onClick={getReportFileWord} style={{ width: "100%" }} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3">
                                                                {
                                                                    loadWord ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Downloading...</div> : "Download Word"
                                                                }
                                                            </button> */}
                                                        </div>
                                                    // <Link className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" to="/finalRport" state={{ dependent: true, dependentId: location.state.dependentId, caseId: location.state.caseId }}>View Report</Link>
                                                }

                                            </div>
                                            <div className="col-md-2 full-w-button">
                                                {/* {
                                                    item.isVerified == true ?
                                                        <Link to="/fileHistory" className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3"
                                                            state={{
                                                                'caseId': caseId,
                                                                'dependentId': location.state.dependentId,
                                                                'dependentStatus': true,
                                                            }}
                                                            dependentStatus={true}
                                                            type="button" data-qa-tag="uploader-drop-zone-button">File History </Link>
                                                        : ""} */}


                                            </div>
                                            <div className="col-md-2 full-w-button">
                                                {/* <Link to="/" className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3"
                                                    type="button" data-qa-tag="uploader-drop-zone-button">Back </Link> */}
                                                {
                                                    item.isVerified === true && role == "FinalVerificationResearcher" ? <button onClick={handleClickOpen} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3"
                                                        style={{ backgroundColor: item.isCaseClosed == false ? '#a00000' : '#8f8f8f', width: "100%" }}
                                                        disabled={item.isCaseClosed}
                                                        type="button" data-qa-tag="uploader-drop-zone-button">Case Close  </button> : ""
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
            }


            {/* <div id="pdfWrapper">
                <div className='page1'>

                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nisi ac tellus tristique suscipit. Nunc mattis, justo ut tincidunt fringilla, urna diam pretium nisi, ut sagittis urna sapien non nunc. In risus ante, tempus vitae hendrerit pulvinar, pellentesque id ex. Morbi sed ultricies elit. Suspendisse ultrices, libero pharetra imperdiet condimentum, lectus odio viverra purus, ut vulputate urna eros a ex. Proin ultrices feugiat fringilla. Vivamus id semper lacus. Praesent fringilla diam in ante semper, sed dapibus neque tristique.

                    Ut commodo dignissim elit, sit amet hendrerit massa tincidunt at. Donec vel tincidunt orci. Mauris a nulla non ligula pulvinar molestie. Donec ut diam turpis. Donec nulla nunc, pellentesque id odio at, fermentum laoreet ante. Etiam quis maximus risus. Nam finibus tellus non gravida ullamcorper. Maecenas non erat nisl. Pellentesque posuere, dui nec dapibus lobortis, libero est tempor ipsum, tempor ornare augue elit vitae erat. Nullam at eros nisl. Nulla ornare, tellus ut tempor porttitor, diam nibh vulputate ligula, ut fringilla lacus ante eu justo. Cras ipsum diam, finibus sit amet fringilla non, rutrum eget diam. Quisque at volutpat quam, a laoreet augue. Integer risus nibh, varius in facilisis in, finibus eget lacus. Proin id diam imperdiet, venenatis velit et, rhoncus elit. Etiam a rutrum metus.

                    Curabitur at cursus erat. Nam purus massa, varius ac accumsan vitae, luctus in ligula. Integer sed dolor massa. Vivamus fermentum quam id mauris tempor consectetur. Aenean facilisis vestibulum ligula ut placerat. Maecenas at gravida arcu. Vivamus ligula risus, fermentum at imperdiet ut, egestas ut nunc. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque nisl leo, lacinia quis hendrerit id, tristique sit amet urna. Etiam vitae lorem at mi pharetra porta. Etiam et libero id est gravida rutrum. Donec nibh diam, rutrum non molestie vel, consectetur eget tellus. Aenean efficitur velit vitae velit suscipit cursus. Suspendisse id tellus eu ante tincidunt fermentum. Duis faucibus pharetra ligula, nec tristique diam rutrum non. Donec dignissim vel erat ut consectetur.

                    Sed molestie justo faucibus feugiat iaculis. Pellentesque vel tempus purus. Nam sollicitudin ligula eu felis mollis, id ornare purus laoreet. Vestibulum sed volutpat mauris, eget sodales enim. Phasellus varius at enim nec gravida. Phasellus in ipsum vel odio dapibus maximus in ut metus. Vivamus sed mauris eget erat placerat malesuada in sed quam. Ut varius quam massa, eget efficitur sem suscipit non. Proin nec augue ut dolor sodales euismod vel vel velit. Donec lectus dui, aliquet sed bibendum a, scelerisque id magna. Donec a interdum sem. Sed sit amet ornare dui. Curabitur fermentum faucibus purus, maximus auctor enim tempus sed. Donec enim ex, tristique vel enim quis, elementum ultricies ligula.

                    Vestibulum bibendum, eros quis accumsan scelerisque, elit urna rutrum turpis, in vestibulum mi dolor sed elit. Fusce suscipit id mi quis auctor. Suspendisse potenti. Nulla mauris sem, tempus in turpis eu, pretium malesuada nulla. Maecenas sollicitudin mi a felis lobortis, hendrerit viverra leo faucibus. Mauris accumsan lectus at eros bibendum, at vestibulum libero auctor. Integer lorem erat, hendrerit vel quam ac, porttitor aliquam augue. Suspendisse hendrerit aliquet nisi ornare venenatis. Ut tempor, ipsum vel imperdiet aliquet, neque arcu hendrerit augue, pharetra ultricies quam tellus ac sapien. Pellentesque vel massa imperdiet, iaculis sapien sed, volutpat arcu. Mauris sodales porta lorem et imperdiet.

                    Sed quis enim ultricies ligula dapibus sollicitudin vel sed velit. Aliquam sed ex non risus lobortis efficitur. Vestibulum non neque tristique enim sodales pharetra</div>
                <div className='page2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nisi ac tellus tristique suscipit. Nunc mattis, justo ut tincidunt fringilla, urna diam pretium nisi, ut sagittis urna sapien non nunc. In risus ante, tempus vitae hendrerit pulvinar, pellentesque id ex. Morbi sed ultricies elit. Suspendisse ultrices, libero pharetra imperdiet condimentum, lectus odio viverra purus, ut vulputate urna eros a ex. Proin ultrices feugiat fringilla. Vivamus id semper lacus. Praesent fringilla diam in ante semper, sed dapibus neque tristique.

                    Ut commodo dignissim elit, sit amet hendrerit massa tincidunt at. Donec vel tincidunt orci. Mauris a nulla non ligula pulvinar molestie. Donec ut diam turpis. Donec nulla nunc, pellentesque id odio at, fermentum laoreet ante. Etiam quis maximus risus. Nam finibus tellus non gravida ullamcorper. Maecenas non erat nisl. Pellentesque posuere, dui nec dapibus lobortis, libero est tempor ipsum, tempor ornare augue elit vitae erat. Nullam at eros nisl. Nulla ornare, tellus ut tempor porttitor, diam nibh vulputate ligula, ut fringilla lacus ante eu justo. Cras ipsum diam, finibus sit amet fringilla non, rutrum eget diam. Quisque at volutpat quam, a laoreet augue. Integer risus nibh, varius in facilisis in, finibus eget lacus. Proin id diam imperdiet, venenatis velit et, rhoncus elit. Etiam a rutrum metus.

                    Curabitur at cursus erat. Nam purus massa, varius ac accumsan vitae, luctus in ligula. Integer sed dolor massa. Vivamus fermentum quam id mauris tempor consectetur. Aenean facilisis vestibulum ligula ut placerat. Maecenas at gravida arcu. Vivamus ligula risus, fermentum at imperdiet ut, egestas ut nunc. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque nisl leo, lacinia quis hendrerit id, tristique sit amet urna. Etiam vitae lorem at mi pharetra porta. Etiam et libero id est gravida rutrum. Donec nibh diam, rutrum non molestie vel, consectetur eget tellus. Aenean efficitur velit vitae velit suscipit cursus. Suspendisse id tellus eu ante tincidunt fermentum. Duis faucibus pharetra ligula, nec tristique diam rutrum non. Donec dignissim vel erat ut consectetur.

                    Sed molestie justo faucibus feugiat iaculis. Pellentesque vel tempus purus. Nam sollicitudin ligula eu felis mollis, id ornare purus laoreet. Vestibulum sed volutpat mauris, eget sodales enim. Phasellus varius at enim nec gravida. Phasellus in ipsum vel odio dapibus maximus in ut metus. Vivamus sed mauris eget erat placerat malesuada in sed quam. Ut varius quam massa, eget efficitur sem suscipit non. Proin nec augue ut dolor sodales euismod vel vel velit. Donec lectus dui, aliquet sed bibendum a, scelerisque id magna. Donec a interdum sem. Sed sit amet ornare dui. Curabitur fermentum faucibus purus, maximus auctor enim tempus sed. Donec enim ex, tristique vel enim quis, elementum ultricies ligula.

                    Vestibulum bibendum, eros quis accumsan scelerisque, elit urna rutrum turpis, in vestibulum mi dolor sed elit. Fusce suscipit id mi quis auctor. Suspendisse potenti. Nulla mauris sem, tempus in turpis eu, pretium malesuada nulla. Maecenas sollicitudin mi a felis lobortis, hendrerit viverra leo faucibus. Mauris accumsan lectus at eros bibendum, at vestibulum libero auctor. Integer lorem erat, hendrerit vel quam ac, porttitor aliquam augue. Suspendisse hendrerit aliquet nisi ornare venenatis. Ut tempor, ipsum vel imperdiet aliquet, neque arcu hendrerit augue, pharetra ultricies quam tellus ac sapien. Pellentesque vel massa imperdiet, iaculis sapien sed, volutpat arcu. Mauris sodales porta lorem et imperdiet.

                    Sed quis enim ultricies ligula dapibus sollicitudin vel sed velit. Aliquam sed ex non risus lobortis efficitur. Vestibulum non neque tristique enim sodales pharetra</div>
                <div className='page3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nisi ac tellus tristique suscipit. Nunc mattis, justo ut tincidunt fringilla, urna diam pretium nisi, ut sagittis urna sapien non nunc. In risus ante, tempus vitae hendrerit pulvinar, pellentesque id ex. Morbi sed ultricies elit. Suspendisse ultrices, libero pharetra imperdiet condimentum, lectus odio viverra purus, ut vulputate urna eros a ex. Proin ultrices feugiat fringilla. Vivamus id semper lacus. Praesent fringilla diam in ante semper, sed dapibus neque tristique.

                    Ut commodo dignissim elit, sit amet hendrerit massa tincidunt at. Donec vel tincidunt orci. Mauris a nulla non ligula pulvinar molestie. Donec ut diam turpis. Donec nulla nunc, pellentesque id odio at, fermentum laoreet ante. Etiam quis maximus risus. Nam finibus tellus non gravida ullamcorper. Maecenas non erat nisl. Pellentesque posuere, dui nec dapibus lobortis, libero est tempor ipsum, tempor ornare augue elit vitae erat. Nullam at eros nisl. Nulla ornare, tellus ut tempor porttitor, diam nibh vulputate ligula, ut fringilla lacus ante eu justo. Cras ipsum diam, finibus sit amet fringilla non, rutrum eget diam. Quisque at volutpat quam, a laoreet augue. Integer risus nibh, varius in facilisis in, finibus eget lacus. Proin id diam imperdiet, venenatis velit et, rhoncus elit. Etiam a rutrum metus.

                    Curabitur at cursus erat. Nam purus massa, varius ac accumsan vitae, luctus in ligula. Integer sed dolor massa. Vivamus fermentum quam id mauris tempor consectetur. Aenean facilisis vestibulum ligula ut placerat. Maecenas at gravida arcu. Vivamus ligula risus, fermentum at imperdiet ut, egestas ut nunc. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque nisl leo, lacinia quis hendrerit id, tristique sit amet urna. Etiam vitae lorem at mi pharetra porta. Etiam et libero id est gravida rutrum. Donec nibh diam, rutrum non molestie vel, consectetur eget tellus. Aenean efficitur velit vitae velit suscipit cursus. Suspendisse id tellus eu ante tincidunt fermentum. Duis faucibus pharetra ligula, nec tristique diam rutrum non. Donec dignissim vel erat ut consectetur.

                    Sed molestie justo faucibus feugiat iaculis. Pellentesque vel tempus purus. Nam sollicitudin ligula eu felis mollis, id ornare purus laoreet. Vestibulum sed volutpat mauris, eget sodales enim. Phasellus varius at enim nec gravida. Phasellus in ipsum vel odio dapibus maximus in ut metus. Vivamus sed mauris eget erat placerat malesuada in sed quam. Ut varius quam massa, eget efficitur sem suscipit non. Proin nec augue ut dolor sodales euismod vel vel velit. Donec lectus dui, aliquet sed bibendum a, scelerisque id magna. Donec a interdum sem. Sed sit amet ornare dui. Curabitur fermentum faucibus purus, maximus auctor enim tempus sed. Donec enim ex, tristique vel enim quis, elementum ultricies ligula.

                    Vestibulum bibendum, eros quis accumsan scelerisque, elit urna rutrum turpis, in vestibulum mi dolor sed elit. Fusce suscipit id mi quis auctor. Suspendisse potenti. Nulla mauris sem, tempus in turpis eu, pretium malesuada nulla. Maecenas sollicitudin mi a felis lobortis, hendrerit viverra leo faucibus. Mauris accumsan lectus at eros bibendum, at vestibulum libero auctor. Integer lorem erat, hendrerit vel quam ac, porttitor aliquam augue. Suspendisse hendrerit aliquet nisi ornare venenatis. Ut tempor, ipsum vel imperdiet aliquet, neque arcu hendrerit augue, pharetra ultricies quam tellus ac sapien. Pellentesque vel massa imperdiet, iaculis sapien sed, volutpat arcu. Mauris sodales porta lorem et imperdiet.

                    Sed quis enim ultricies ligula dapibus sollicitudin vel sed velit. Aliquam sed ex non risus lobortis efficitur. Vestibulum non neque tristique enim sodales pharetra</div>
            </div> */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You won't be able to revert this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={caseClosed} autoFocus>
                        Yes, Close this Case !
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default DependentViewProfile