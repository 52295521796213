import { useEffect, useState } from "react";
import api from "../../apiUrl";
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function Mipc(prop) {
    var apis = api()
    const caseId = prop.caseId
    // MIPC sction 
    const [mipc, setmipc] = useState([]);
    const [idmipc, setIdmipc] = useState(0)
    const [mipcName, setMipcName] = useState('');
    const [mipcLocation, setMipcLocation] = useState('');
    const [mipcComments, setMipcComments] = useState('');
    const [updateMipcName, setUpdateMipcName] = useState('');
    const [updateMipcLocation, setUpdateMipcLocation] = useState('');
    const [updateMipcComments, setUpdateMipcComments] = useState('');
    const [modelshow, setModelShow] = useState(false);
    const [updateModelshow, setUpdateModelShow] = useState(false);
    const [modelValidated, setModelValidated] = useState(false);
    const [updateModelValidated, setUpdateModelValidated] = useState(false);
    const dependentSatus = prop.dependentSatus
    const dependentId = prop.dependentId

    const editorConfiguration = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'link', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
            shouldNotGroupWhenFull: false,
            wordcount: {
                showCharCount: true,
            }

        }


    };
    //-----get MIPC Data------
    function getMipcAPIData() {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/ViewMostImpPersonForDependent/${caseId}/${dependentId}`).then((respons) => {
                respons.json().then((result) => {

                    setmipc(result.data)
                    //  console.log("getMipcAPIData", mipc)
                    // navigate('/')
                })
            })
        } else {
            fetch(`${apis}Admin/ViewMostImpPersonDetails/${caseId}`).then((respons) => {
                respons.json().then((result) => {

                    setmipc(result.data)
                    // console.log("getMipcAPIData", mipc)
                    // navigate('/')
                })
            })
        }

    }
    useEffect(() => {
        getMipcAPIData()
    }, [])
    //-----get MIPC Data------

    //----- Detele MIPC Data-----
    function deleteMipcData(e) {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/DeleteMostImpPersonDetailsForDependent/${e}/${dependentId}`, {
                method: "POST",
            }).then((respons) => {
                respons.json().then((result) => {
                    //  console.log("deletefamilyData ", result)
                    getMipcAPIData()
                })
            })
        } else {
            fetch(`${apis}Admin/DeleteMostImpPersonDetails/${e}`, {
                method: "POST",
            }).then((respons) => {
                respons.json().then((result) => {
                    //  console.log("deletefamilyData ", result)
                    getMipcAPIData()
                })
            })
        }

        // console.log("deletefamilyData ", e)
    }
    //----- Detele MIPC Data-----

    //-----Add MIPC Data------
    if (dependentSatus == true) {
        var addMipcData = {
            "CaseId": caseId,
            "CaseType": "D",
            id: idmipc,
            name: mipcName,
            location: mipcLocation,
            comments: mipcComments,
            "dependentId": dependentId
        }
    } else {
        var addMipcData = {
            "CaseId": caseId,
            "CaseType": "C",
            id: idmipc,
            name: mipcName,
            location: mipcLocation,
            comments: mipcComments
        }
    }

    function addObjInMipc() {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/MostImpPersonForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addMipcData),
            }).then((respons) => {
                respons.json().then((result) => {
                    //  console.log("getMipcAPIData", result)
                    getMipcAPIData()
                })
            })
        } else {
            fetch(`${apis}Admin/MostImpPerson`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addMipcData),
            }).then((respons) => {
                respons.json().then((result) => {
                    //  console.log("getMipcAPIData", result)
                    getMipcAPIData()
                })
            })
        }

    }

    //-----Add MIPC Data------

    //-----Update MIPC Data------
    if (dependentSatus == true) {
        var updateMipcData = {
            "CaseId": caseId,
            id: idmipc,
            name: updateMipcName,
            location: updateMipcLocation,
            comments: updateMipcComments,
            "dependentId": dependentId
        }
    } else {
        var updateMipcData = {
            "CaseId": caseId,
            id: idmipc,
            name: updateMipcName,
            location: updateMipcLocation,
            comments: updateMipcComments
        }
    }
    // var updateMipcData = {
    //     "CaseId": caseId,
    //     id: idmipc,
    //     name: updateMipcName,
    //     location: updateMipcLocation,
    //     comments: updateMipcComments
    // }
    function updateMipc() {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/UpdateMostImpPersonForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(updateMipcData),
            }).then((respons) => {
                respons.json().then((result) => {
                    // console.log("getMipcAPIData", result)
                    getMipcAPIData()
                })
            })
        } else {
            fetch(`${apis}Admin/UpdateMostImpPerson`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(updateMipcData),
            }).then((respons) => {
                respons.json().then((result) => {
                    // console.log("getMipcAPIData", result)
                    getMipcAPIData()
                })
            })
        }

    }
    //-----Update MIPC Data------
    // const mipcObj = {
    //     name: id,
    //     location: mipcLocation,
    //     comments: mipcComments
    // }
    // function addObjInMipc() {
    //     fetch(`${apis}Admin/MostImpPerson`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         mode: "cors",
    //         body: JSON.stringify(familyData),
    //     }).then((respons) => {
    //         respons.json().then((result) => {
    //             console.log(result)
    //             familyApidata()

    //         })
    //     })
    // }

    const modelhandleClose = (event) => {
        setModelValidated(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            // console.log("setModelShow(true);")
        } else if (!mipcName == false && !mipcLocation == false
            //&& !mipcComments == false
        ) {
            addObjInMipc()
            setModelShow(false);
            setMipcName(null)
            setMipcLocation(null)
            setMipcComments(null)
            event.target.reset();
        }
        setModelValidated(true);
    }
    const updateModelhandleClose = (event) => {
        setModelValidated(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //console.log("setModelShow(true);")
        } else if (!updateMipcName == false && !updateMipcLocation == false
            //&& !updateMipcComments == false
        ) {
            updateMipc()
            setUpdateModelShow(false);
        }
        setUpdateModelValidated(true);
    }
    const modelhandleShow = () => {
        setModelShow(true)
        setMipcName("")
        setMipcLocation("")
        setMipcComments("")
    };
    const updateModelhandleShow = () => {
        setUpdateModelShow(true)
        setMipcName("")
        setMipcLocation("")
        setMipcComments("")
    };
    const modelhandleChross = () => setModelShow(false);
    const updateModelhandleChross = () => setUpdateModelShow(false);

    // MIPC sction 

    return (
        <>
            <div className="card mb-0 shadow-none mb-3">
                <a href="#collapseSix" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                    aria-controls="collapseFour">
                    <div className="card-header" id="headingSix">
                        <h6 className="m-0">
                            Most Important Persons/ Companies
                            <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                        </h6>
                    </div>
                </a>
                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-bs-parent="#accordion">
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className='mipc'>
                                            <th>Name</th>
                                            <th>Location</th>
                                            {
                                                prop.varification === true ? <th>Comments</th> : ""
                                            }
                                            {
                                                prop.viewOnly === true ? "" :
                                                    <th className='action'>Action
                                                        {
                                                            prop.varification === true ? "" : <Button disabled={mipc.length === 5 ? true : false} variant="primary" onClick={modelhandleShow}>
                                                                <i class="mdi mdi-plus float-end accor-plus-icon"></i>
                                                            </Button>
                                                        }


                                                        <Modal show={modelshow} onHide={modelhandleChross}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add New  Most Important Persons/ Companies</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form id="mipcForm" noValidate validated={modelValidated}>
                                                                    <Form.Group controlId="validationCustom01">
                                                                        <Form.Label>Name</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text" setMipcName
                                                                            onChange={(e) => setMipcName(e.target.value)}
                                                                        // maxLength={50}
                                                                        />
                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group controlId="validationCustom01">
                                                                        <Form.Label>Location</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            onChange={(e) => setMipcLocation(e.target.value)}
                                                                        // maxLength={50}
                                                                        />
                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    {
                                                                        prop.varification === true ? <Form.Group controlId="validationCustom01">
                                                                            <Form.Label>Comments</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                onChange={(e) => setMipcComments(e.target.value)}
                                                                            //  maxLength={150}
                                                                            />
                                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                        </Form.Group> : ""
                                                                    }

                                                                </Form>

                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={modelhandleChross}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={modelhandleClose}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </th>
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            mipc.map((items) =>
                                                <tr>
                                                    <td>{items.name}</td>
                                                    <td>{items.location}</td>
                                                    {
                                                        prop.varification === true ? <td>
                                                            {/* {items.comments} */}
                                                            <div dangerouslySetInnerHTML={{ __html: items.comments }}></div>
                                                        </td> : ""
                                                    }
                                                    {
                                                        prop.viewOnly === true ? "" :
                                                            <td
                                                                width={prop.varification === true ? "15%" : "20%"}
                                                            ><div className="btn btn-xs btn-green-outline2"
                                                                onClick={() => {
                                                                    updateModelhandleShow()
                                                                    setIdmipc(items.id)
                                                                    setUpdateMipcName(items.name)
                                                                    setUpdateMipcLocation(items.location)
                                                                    setUpdateMipcComments(items.comments)
                                                                }}
                                                            >
                                                                    {
                                                                        prop.varification === true ? <span>Add & Update Comment</span> : <i class="fas fa-pen"></i>
                                                                    }
                                                                </div> <span>&nbsp;</span>
                                                                {
                                                                    prop.varification === true ?

                                                                        <Modal show={updateModelshow} onHide={updateModelhandleChross} size="lg">
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>Update  Most Important Persons/ Companies</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <Form id="mipcForm" noValidate validated={updateModelValidated}>


                                                                                    {
                                                                                        prop.varification === true ?
                                                                                            // <Form.Group controlId="validationCustom01">
                                                                                            //     <Form.Label>Comments</Form.Label>
                                                                                            //     <Form.Control
                                                                                            //         required
                                                                                            //         value={updateMipcComments}
                                                                                            //         type="text"
                                                                                            //         onChange={(e) => setUpdateMipcComments(e.target.value)}
                                                                                            //     //   maxLength={50}
                                                                                            //     />
                                                                                            //     <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                            // </Form.Group>
                                                                                            <CKEditor

                                                                                                editor={ClassicEditor}
                                                                                                config={editorConfiguration}
                                                                                                data={""}
                                                                                                onReady={editor => {
                                                                                                    // editor.ui.view.editable.element.style.height = '300px'

                                                                                                }}
                                                                                                onChange={(event, editor) => {
                                                                                                    const data = editor.getData();
                                                                                                    // setCkData(data)
                                                                                                    setUpdateMipcComments(data)

                                                                                                }}

                                                                                            />
                                                                                            : ""
                                                                                    }

                                                                                </Form>

                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="secondary" onClick={updateModelhandleChross}>
                                                                                    Close
                                                                                </Button>
                                                                                <Button variant="primary" onClick={updateModelhandleClose}>
                                                                                    Save Changes
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                        :


                                                                        <Modal show={updateModelshow} onHide={updateModelhandleChross}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>Update  Most Important Persons/ Companies</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <Form id="mipcForm" noValidate validated={updateModelValidated}>

                                                                                    <Form.Group controlId="validationCustom01">
                                                                                        <Form.Label>Name</Form.Label>
                                                                                        <Form.Control
                                                                                            value={updateMipcName}
                                                                                            required
                                                                                            type="text" setMipcName
                                                                                            onChange={(e) => setUpdateMipcName(e.target.value)}
                                                                                        //  maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    <Form.Group controlId="validationCustom01">
                                                                                        <Form.Label>Location</Form.Label>
                                                                                        <Form.Control
                                                                                            required
                                                                                            value={updateMipcLocation}
                                                                                            type="text"
                                                                                            onChange={(e) => setUpdateMipcLocation(e.target.value)}
                                                                                            maxLength={50}
                                                                                        />
                                                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    {
                                                                                        prop.varification === true ?
                                                                                            <Form.Group controlId="validationCustom01">
                                                                                                <Form.Label>Comments</Form.Label>
                                                                                                <Form.Control
                                                                                                    required
                                                                                                    value={updateMipcComments}
                                                                                                    type="text"
                                                                                                    onChange={(e) => setUpdateMipcComments(e.target.value)}
                                                                                                //    maxLength={150}
                                                                                                />
                                                                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                                            </Form.Group>
                                                                                            : ""
                                                                                    }

                                                                                </Form>

                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="secondary" onClick={updateModelhandleChross}>
                                                                                    Close
                                                                                </Button>
                                                                                <Button variant="primary" onClick={updateModelhandleClose}>
                                                                                    Save Changes
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                }

                                                                {
                                                                    prop.varification === true ? "" : <div className="btn btn-xs btn-danger" onClick={() => deleteMipcData(items.id)}><i className="fa fa-trash fa-lg"></i></div>
                                                                }
                                                            </td>
                                                    }

                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}
export default Mipc