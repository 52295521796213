import { responsiveFontSizes } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { json, useNavigate } from 'react-router-dom';
import api from '../../apiUrl';
function Modulepermission() {
    const [data, setData] = useState([])
    const [post, setPost] = useState()
    const [check, setCheck] = useState(true);
    const [exampleState, setExampleState] = useState([data])
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    // const exampleState = [
    //     {
    //         "id": 1,
    //         "moduleName": "Dashboard",
    //         "view": true,
    //         "add": false,
    //         "edit": true,
    //         "delete": false
    //     },
    //     {
    //         "id": 2,
    //         "moduleName": "Elmah",
    //         "view": false,
    //         "add": false,
    //         "edit": true,
    //         "delete": true
    //     },
    //     {
    //         "id": 3,
    //         "moduleName": "Security",
    //         "view": true,
    //         "add": true,
    //         "edit": true,
    //         "delete": false
    //     }
    // ]
    var apis = api()

    var arr = []
    function getFunction() {
        fetch(`${apis}Admin/GetModulePermission/1`).then((respons) => {
            respons.json().then((result) => {
                console.log(JSON.stringify(result.data))
                setData(result.data)
                arr.push(result.data)
                console.log("getFunction called", arr)
            })
        })
        console.log("getFunction called")
    }
    function getchecked() {
        setLoad(true)
        fetch(`${apis}Admin/UpdateModule`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)

        }).then((respon) => {
            respon.json().then((result) => {
                console.log("ForgotPassword result", result)

                setLoad(false)
                navigate('/')



            })
        })
        console.log("exampleState", data)
    }

    //
    const handleCheckboxChange = (moduleId, key) => {
        setData((prevState) =>
            prevState.map((module) =>
                module.id === moduleId ? { ...module, [key]: !module[key] } : module
            )
        );
    };






    useEffect(() => {
        getFunction()
    }, [check])
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item">Security</li>
                                        <li className="breadcrumb-item active">Module Permission</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col col-lg-4 col-md-4">
                        <label className="control-label">Role Name</label>
                        <div className="input-group">
                            <select className="single-select form-select" id="inputGroupSelect05">
                                <option selected="selected" value="0">Show All</option>
                                <option value="1">Admin - Admin</option>
                                <option value="2">AdminUsers - AdminUsers</option>
                                <option value="3">Company - Company</option>
                                <option value="4">CompanyUsers - CompanyUsers</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-8 col-md-8 text-right mt-3"></div>
                </div>
            </div>
            <div className="card radius-10">
                <div className="card-header bg-dark">
                    <div className="row g-3 align-items-center">
                        <div className="col">
                            <h5 className="mb-0 color-blue">Module Permissions To Role</h5>
                        </div>
                        <div className="col">
                            <div className="d-flex align-items-center justify-content-end gap-3 cursor-pointer">
                                <div className="dropdown">
                                    <a className="dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown" aria-expanded="false"><i className="bx bx-dots-horizontal-rounded font-22 text-option"></i>
                                    </a>
                                    <ul className="dropdown-menu">

                                        <li className="pl-2 font-small">Download
                                        </li>
                                        <li><a className="dropdown-item" href="javascript:;"><i className="fa fa-file-pdf-o" aria-hidden="true"></i> PDF</a>
                                        </li>
                                        <li><a className="dropdown-item" href="javascript:;"><i className="fa fa-file-o" aria-hidden="true"></i> CSV</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <Form className="">
                        <div id="example_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <div className="row">

                            </div>
                            <div className="row"><div className="col-sm-12">
                                <table id="example" className="table table-striped table-bordered dataTable no-footer" role="grid" aria-describedby="example_info">
                                    <thead className="table-light">
                                        <tr role="row"><th className="sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Module: activate to sort column descending" style={{ width: "316.219px" }}>Module</th><th className="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label=" View : activate to sort column ascending" style={{ width: "133.969px" }}> View </th><th className="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label=" Add: activate to sort column ascending" style={{ width: "120.156px" }}> Add</th><th className="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label=" Edit: activate to sort column ascending" style={{ width: "118.625px" }}> Edit</th><th className="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="  Delete: activate to sort column ascending" style={{ width: "161.656px" }}>  Delete</th></tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((module, i) => (
                                                <tr key={module.id}>
                                                    <td>{module.moduleName}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            switch="none"
                                                            checked={module.view}
                                                            id={`switchss${module.id}${i}`}
                                                            onChange={() => handleCheckboxChange(module.id, "view")}
                                                        />
                                                        <label for={`switchss${module.id}${i}`} data-on-label="Yes" data-off-label="No"></label>

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            switch="none"
                                                            checked={module.add}
                                                            id={`switchssa${module.id}${i}`}
                                                            onChange={() => handleCheckboxChange(module.id, "add")}
                                                        />
                                                        <label for={`switchssa${module.id}${i}`} data-on-label="Yes" data-off-label="No"></label>

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            switch="none"
                                                            checked={module.edit}
                                                            id={`switchss3${module.id}${i}`}
                                                            onChange={() => handleCheckboxChange(module.id, "edit")}
                                                        />
                                                        <label for={`switchss3${module.id}${i}`} data-on-label="Yes" data-off-label="No"></label>

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            switch="none"
                                                            checked={module.delete}
                                                            id={`switchss4${module.id}${i}`}
                                                            onChange={() => handleCheckboxChange(module.id, "delete")}
                                                        />
                                                        <label for={`switchss4${module.id}${i}`} data-on-label="Yes" data-off-label="No"></label>

                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>

                                </table></div></div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="dataTables_info text-right" id="example_info" role="status" aria-live="polite">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <button className='_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3' onClick={getchecked}>
                        {
                            load && <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>
                        }
                        Submit</button>
                    {/* <p>{exampleState.module[0].moduleName}</p>
                    <button onClick={exampleTest}>Text</button> */}
                </div>
            </div>
        </>
    )
}

export default Modulepermission