import { useState } from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import api from '../../apiUrl';
import { Col, Row } from 'react-bootstrap';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AdddependentforAdmin(prop) {
    // console.log("prop.dependentId", prop.dependentId)
    var apis = api()
    // console.log(prop)
    const [upload, setUpload] = useState();
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [dependentName, setdependentName] = useState();
    const [relationship, setRelationship] = useState(null);
    //Drag and Drop


    const json = {
        "dependentName": dependentName,
        "caseId": prop.caseId,
        "relation": relationship,
        "currentUserId": prop.currentUserId
    }
    function upLoad(e) {

        setLoad(true)

        fetch(`${apis}Admin/AddDependentAdminSide/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(json),
            mode: "cors",
        }).then((response) => response.json()).then((result) => {
            // console.log('Success:', result);
            setLoad(false)
            setMessage("Report Has been Created")//set in value in tMessage useState for Toster Message
            setOpens(true);//set in value in useState
            setColor("success")//set in value in useState
            // setCaseName(null)
            setUpload(null)
            setShow(false)
            prop.reload()



        }).catch((error) => {
            console.error('Error:', error);
            setLoad(false)
        });
    }
    const crossClose = () => {
        setShow(false)
    };

    const handleClose = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //  console.log("setModelShow(true);")
        } else if (!dependentName == false) {
            upLoad()
            setShow(false)
        };
        setValidated(true);

    };
    return (
        <>
            <Link onClick={handleShow} >
                <span className="B1piuH">
                    <i class="mdi mdi-arrow-collapse-up"></i>
                </span>
                <span className="rglmnm">Add Dependent</span>
            </Link>

            <Modal show={show} onHide={crossClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Dependent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Dependent Name <span class="redstar">*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setdependentName(e.target.value)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Row className="mb-3">


                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Label>Relationship with Applicant</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setRelationship(e.target.value)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>

                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={crossClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        {
                            load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Saving...</div> : "Add"
                        }

                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AdddependentforAdmin;