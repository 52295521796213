import React, { useEffect, useRef } from 'react'
import { fontSize } from "@mui/system";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import api from "../../apiUrl";
import jwt_decode from "jwt-decode";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CorporateAffiliations from "./corporateAffiliations";
import Logins from '../login';
import FamilyInquiries from './familyInquiries';
import Mipc from './mipc';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import './veristyle.css'
import chartImg from '../finalReport/assets/img/new-screen-shot.png'
import RoughWork from './roughWork';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Verification() {
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");

    // for Toaster Close on click button
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };


    var apis = api()

    const location = useLocation();
    //const [load, setLoad] = useState(false);
    const navigate = useNavigate()
    const state = location.state.profileDetails;
    const datas = [location.state.profileDetails];
    const draft = location.state.draft
    const riskMatrix = location.state.profileDetails.clientInfo.riskMatrixKey
    const clientChartName = location.state.profileDetails.clientInfo.clientChartName
    const clientName = location.state.clientName
    console.log("draft", location.state)
    console.log("clientName", location.state.clientName)
    var dependentSatus = location.state.dependentSatus
    // console.log("dependentSatus", location.state);
    // setTimeout(() => {
    //     datas.map((item) => {
    //         console.log("civilLitigationInvestigation", item.criminalRecordInvestigation)
    //         document.getElementById("civilLitigationInvestigation").innerHTML = item.civilLitigationInvestigation
    //         document.getElementById("criminalRecordInvestigation").innerHTML = item.criminalRecordInvestigation
    //         // document.getElementById("bankruptcyInvestigation").innerHTML = item.bankruptcyInvestigation
    //         // document.getElementById("corporateInterestsInvestigation").innerHTML = item.corporateInterestsInvestigation
    //         // document.getElementById("worldComplianceInvestigation").innerHTML = item.worldComplianceInvestigation
    //         // document.getElementById("blacklistInvestigation").innerHTML = item.blacklistInvestigation
    //         // document.getElementById("mediaandInternetSearches").innerHTML = item.mediaInternetSearches
    //     })
    // }, 50)

    const editorConfiguration = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'link', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'todoList',
                '|', 'outdent', 'indent',
                '|', 'indentParagraph', // Add the custom button
            ],
            shouldNotGroupWhenFull: false,
            wordcount: {
                showCharCount: true,
            }

        }
    };

    const editorConfigurationAppedex = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'link', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
            shouldNotGroupWhenFull: false,
            wordcount: {
                showCharCount: true,
            }

        }


    };
    const [show, setShow] = useState(false);

    const handleCloseModel = () => setShow(false);
    const handleShow = () => setShow(true);




    var dates = new Date(state.dateOfBirth)
    var split = dates.toString().split('T')
    var dateString = split[0].trim()
    // console.log(document.getElementsByTagName("textarea").length)
    const [disabled, setDisabled] = useState(false)
    const [caseId, setCaseId] = useState(location.state.caseId)
    const [name, setName] = useState(state.name);
    const [lname, setlName] = useState(state.lastName);
    const [gname, setgName] = useState(state.givenName);
    const [placeOfBirth, setPlaceOfBirth] = useState(state.placeOfBirth);
    const [day, setDay] = useState(state.birthOfDay);
    const [month, setMonth] = useState(state.birthOfMonth);
    const [year, setYear] = useState(state.birthOfYear);
    const [ppId, setPpId] = useState(state.ppid);
    const [idCard, setidCard] = useState(state.idCard);
    const [netid, setNetid] = useState(state.natID);
    const [knowAddress, setKnowAddress] = useState(state.knowAddress);
    const [currentAddress, setCurrentAddress] = useState(state.currentAddress);
    const [corporateAffiliations, setcorporateAffiliations] = useState(state.corporateAffiliations);


    //Type of Inquiries States
    // const [sourcesSearched, setSourcesSearched] = useState(state.sourcesSearched);
    const [civilLitigation, setCivilLitigation] = useState(state.civilLitigation);
    const [bankruptcyRecords, setBankruptcyRecords] = useState(state.bankruptcyRecords);
    const [criminialRecord, setcriminialRecord] = useState("");
    const [internationalEmbassiesMission, setInternationalEmbassiesMission] = useState("");
    const [globalRegulatoryData, setGlobalRegulatoryData] = useState("");
    const [globalSanctionsWatchlists, setGlobalSanctionsWatchlists] = useState(state.globalSanctionsWatchlists);
    const [globalLawEnforcementData, setGlobalLawEnforcementData] = useState(state.globalLawEnforcementData);
    const [globalPEHPpFgod, setGlobalPEHPpFgod] = useState(state.globalPEHPpFgod);
    const [adverseMediaInternetSearches, setAdverseMediaInternetSearches] = useState(state.adverseMediaInternetSearches);

    //Date of Birth, Nationality, and Address Details States
    const [formThreeDob, setFormThreeDob] = useState(state.formThreeDob);
    const [findingsGCSLDob, setfindingsGCSLDob] = useState(draft == "" ? false : draft.dateofBirthFindingsbyGCSL);
    const [formThreePlaceOfBirth, setFormThreePlaceOfBirth] = useState(state.formThreeDob);
    const [findingsGCSLplaceOfBirth, setFindingsGCSLplaceOfBirth] = useState(draft == "" ? false : draft.placeofBirthFindingsbyGCSL);
    const [natId, setNatId] = useState(state.formThreeDob);
    const [findingByGCSLNatId, setFindingByGCSLNatId] = useState(draft == "" ? false : draft.natIDFindingsbyGCSL);

    const [findingsGCSLCurrentAddress, setFindingsGCSLCurrentAddress] = useState(draft == "" ? false : draft.currentAddressFindingsbyGCSL);

    //Security Risk Matrix States
    const [civilLitigationInvestigation, setCivilLitigationInvestigation] = useState(state.civilLitigationInvestigation);
    const [criminalRecordInvestigation, setCriminalRecordInvestigation] = useState(state.criminalRecordInvestigation);
    const [bankruptcyInvestigation, setBankruptcyInvestigation] = useState(state.bankruptcyInvestigation);
    const [corporateInterestsInvestigation, setCorporateInterestsInvestigation] = useState(state.corporateInterestsInvestigation);
    const [worldComplianceInvestigation, setWorldComplianceInvestigation] = useState(state.worldComplianceInvestigation);
    const [blacklistInvestigation, setBlacklistInvestigation] = useState(state.blacklistInvestigation);
    const [mediaInternetSearches, setMediaInternetSearches] = useState(state.mediaInternetSearches);
    const [conclusion, setConclusion] = useState(state.conclusion);
    const [backgroundInformation, setBackgroundInformation] = useState(state.backgroundInformation);
    const [derogatoryInformation, setDerogatoryInformation] = useState(state.derogatoryInformation);
    const [employmentStatusSourcesIncome, setEmploymentStatusSourcesIncome] = useState(state.employmentStatusSourcesIncome);
    const [companiesUndisclosed, setCompaniesUndisclosed] = useState(state.companiesUndisclosed);
    const [mostImportantPersonsC, setMostImportantPersonsC] = useState(state.mostImportantPersonsC);
    const [documentsSubmitted, setDocumentsSubmitted] = useState(state.documentsSubmitted);
    const [internationalBansred, setInternationalBansred] = useState(state.internationalBansred);
    const [load, setLoad] = useState(false)
    const [loads, setLoads] = useState(false)

    //Verified State (Candidate Provided Details States)
    const [nameVerify, setNameVerify] = useState(draft == [] ? false : draft.isNameVerified);
    const [placeOfBirthVerify, setPlaceOfBirthVerify] = useState(draft == [] ? false : draft.isPlaceOfBirthVerified);
    // console.log("nameVerify", nameVerify);
    const [dobVerify, setDobVerify] = useState(draft == [] ? false : draft.isDateOfBirthVerified);
    const [ppIdVerify, setPpIdVerify] = useState(draft == [] ? false : draft.isPpidVerified);
    const [idCardVerify, setidCardVerify] = useState(draft == [] ? false : draft.isIdVerified);
    const [netidVerify, setNetidVerify] = useState(draft == [] ? false : draft.isNatIDVerified);
    const [knowAddressVerify, setKnowAddressVerify] = useState(draft == [] ? false : draft.isKnownAddressVerified);
    const [currentAddressVerify, setCurrentAddressVerify] = useState(draft == [] ? false : draft.isCurrentAddressVerified);
    const [corporateAffiliationsVerify, setcorporateAffiliationsVerify] = useState(draft == [] ? false : draft.isCorporateAfflicationVerified);


    //Verified State (Type of Inquiries States)
    const [civilLitigationVerify, setCivilLitigationVerify] = useState(draft == [] ? false : draft.isCivilLitigationVerified);
    const [bankruptcyRecordsVerify, setBankruptcyRecordsVerify] = useState(draft == [] ? false : draft.isBankruptcyRecordsVerified);
    const [criminialRecordVerify, setcriminialRecordVerify] = useState(draft == [] ? false : draft.isCriminalRecordVerified);
    const [internationalEmbassiesMissionVerify, setInternationalEmbassiesMissionVerify] = useState(draft == [] ? false : draft.isInternationalEmbassiesMissionVerified)
    const [globalRegulatoryDataVerify, setGlobalRegulatoryDataVerify] = useState(draft == [] ? false : draft.isGlobalRegulatoryDataVerified);
    const [globalSanctionsWatchlistsVerify, setGlobalSanctionsWatchlistsVerify] = useState(draft == [] ? false : draft.isGlobalSanctionsVerified);
    const [globalLawEnforcementDataVerify, setGlobalLawEnforcementDataVerify] = useState(draft == [] ? false : draft.isGlobalLawVerified);
    const [globalPEHPpFgodVerify, setGlobalPEHPpFgodVerify] = useState(draft == [] ? false : draft.isGlobalPepVerified);
    const [adverseMediaInternetSearchesVerify, setAdverseMediaInternetSearchesVerify] = useState(draft == [] ? false : draft.isAdverseMediaVerified);

    //Verified State (Security Risk Matrix States)
    const [civilLitigationInvestigationVerify, setCivilLitigationInvestigationVerify] = useState(false);
    const [criminalRecordInvestigationVerify, setCriminalRecordInvestigationVerify] = useState(false);
    const [bankruptcyInvestigationVerify, setBankruptcyInvestigationVerify] = useState(false);
    const [corporateInterestsInvestigationVerify, setCorporateInterestsInvestigationVerify] = useState(false);
    const [worldComplianceInvestigationVerify, setWorldComplianceInvestigationVerify] = useState(false);
    const [blacklistInvestigationVerify, setBlacklistInvestigationVerify] = useState(false);
    const [mediaInternetSearchesVerify, setMediaInternetSearchesVerify] = useState(false);
    const [conclusionVerify, setConclusionVerify] = useState(false);
    const [backgroundInformationVerify, setBackgroundInformationVerify] = useState(false);
    const [derogatoryInformationVerify, setDerogatoryInformationVerify] = useState(false);
    const [employmentStatusSourcesIncomeVerify, setEmploymentStatusSourcesIncomeVerify] = useState(false);
    const [companiesUndisclosedVerify, setCompaniesUndisclosedVerify] = useState(false);
    const [mostImportantPersonsCVerify, setMostImportantPersonsCVerify] = useState(false);
    const [documentsSubmittedVerify, setDocumentsSubmittedVerify] = useState(false);
    const [internationalBansredVerify, setInternationalBansredVerify] = useState(false);

    //Issues State (Candidate Provided Details States)
    const [nameIssues, setNameIssues] = useState(draft == "" ? "" : draft.nameComment);
    const [placeOfBirthIssues, setPlaceOfBirthIssues] = useState(draft == "" ? "" : draft.placeOfBirthComment);
    const [dobIssues, setDobIssues] = useState(draft == "" ? "" : draft.dateOfBirthComment);
    const [ppIdIssues, setPpIdIssues] = useState(draft == "" ? "" : draft.ppidComment);
    const [idCardIssues, setidCardIssues] = useState(draft == "" ? "" : draft.idComment);
    const [netidIssues, setNetidIssues] = useState(draft == "" ? "" : draft.natIDComment);
    const [knowAddressIssues, setKnowAddressIssues] = useState(draft == "" ? "" : draft.knownAddressComment);
    const [currentAddressIssues, setcurrentAddressIssues] = useState(draft == "" ? "" : draft.currentAddressComment);
    const [corporateAffiliationsIssues, setcorporateAffiliationsIssues] = useState(draft == "" ? "" : draft.corporateAfflicationComment);

    //Issues State (Type of Inquiries States)
    const [civilLitigationIssues, setCivilLitigationIssues] = useState(draft == "" ? "" : draft.civilLitigationComment);
    const [bankruptcyRecordsIssues, setBankruptcyRecordsIssues] = useState(draft == "" ? "" : draft.bankruptcyRecordsComment);
    const [criminialRecordIssues, setcriminialRecordIssues] = useState(draft == "" ? "" : draft.criminalRecordComment);
    const [internationalEmbassiesMissionIssues, setinternationalEmbassiesMissionIssues] = useState(draft == "" ? "" : draft.internationalEmbassiesMissionComment);
    const [globalRegulatoryDataIssues, setGlobalRegulatoryDataIssues] = useState(draft == "" ? "" : draft.globalRegulatoryDataComment);
    const [globalSanctionsWatchlistsIssues, setGlobalSanctionsWatchlistsIssues] = useState(draft == "" ? "" : draft.globalSanctionsComment);
    const [globalLawEnforcementDataIssues, setGlobalLawEnforcementDataIssues] = useState(draft == "" ? "" : draft.globalLawComment);
    const [globalPEHPpFgodIssues, setGlobalPEHPpFgodIssues] = useState(draft == "" ? "" : draft.globalPepComment);
    const [adverseMediaInternetSearchesIssues, setAdverseMediaInternetSearchesIssues] = useState(draft == "" ? "" : draft.adverseMediaComment);

    //Issues State (Security Risk Matrix States)
    const [civilLitigationInvestigationIssues, setCivilLitigationInvestigationIssues] = useState(draft == "" ? "" : draft.civilLitigationInvestigation);
    const [criminalRecordInvestigationIssues, setCriminalRecordInvestigationIssues] = useState(draft == "" ? "" : draft.criminalLitigationInvestigation);
    const [bankruptcyInvestigationIssues, setBankruptcyInvestigationIssues] = useState(draft == "" ? "" : draft.bankruptcyInvestigation);
    const [corporateInterestsInvestigationIssues, setCorporateInterestsInvestigationIssues] = useState(draft == "" ? "" : draft.corporateInterestsInvestigation);
    const [worldComplianceInvestigationIssues, setWorldComplianceInvestigationIssues] = useState(draft == "" ? "" : draft.worldComplianceInvestigation);
    // const [blacklistInvestigationIssues, setBlacklistInvestigationIssues] = useState(draft == "" ? "" : draft.blacklistInvestigation);
    const [blacklistInvestigationIssues, setBlacklistInvestigationIssues] = useState(draft == "" ? "" : draft.blacklistInvestigationForPdfs.map(item => item.value));

    const [mediaInternetSearchesIssues, setMediaInternetSearchesIssues] = useState(draft == "" ? "" : draft.mediaInternetSearches);
    const [conclusionIssues, setConclusionIssues] = useState(draft == "" ? "" : draft.conclusion);
    const [backgroundInformationIssues, setBackgroundInformationIssues] = useState("");
    const [derogatoryInformationIssues, setDerogatoryInformationIssues] = useState("");
    const [employmentStatusSourcesIncomeIssues, setEmploymentStatusSourcesIncomeIssues] = useState("");
    const [companiesUndisclosedIssues, setCompaniesUndisclosedIssues] = useState("");
    const [mostImportantPersonsCIssues, setMostImportantPersonsCIssues] = useState("");
    const [documentsSubmittedIssues, setDocumentsSubmittedIssues] = useState("");
    const [internationalBansredIssues, setInternationalBansredIssues] = useState("");
    //Add New Cooment According to new Report
    const [investigatorReportComment, setInvestigatorReportComment] = useState(draft == "" ? "" : draft.investigatorReportComment);
    const [riskManagementReviewComment, setRiskManagementReviewComment] = useState(draft == "" ? "" : draft.riskManagementReviewComment);
    const [src1, setSrc1] = useState(draft == "" ? "" : draft.securityRiskMatrixComment.split('-')[0]);
    const [src2, setSrc2] = useState(draft == "" ? "" : draft.securityRiskMatrixComment.split('-')[1]);
    const [securityRiskMatrixComment, setSecurityRiskMatrixComment] = useState();
    // src1 number validation
    const src1Validation = (e) => {
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            setSrc1(e.target.value)

        }
    };
    // src2 number validation
    const src2Validation = (e) => {
        const regex = /^[0-9\b]+$/;
        // console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            setSrc2(e.target.value)

        }
    };
    //Add New Cooment According to new Report

    //Findings by GCSL
    const [netidFind, setnetidFind] = useState(draft == "" ? "" : draft.natIDFindingsbyGCSL);
    const [dobFind, setDobFind] = useState(draft == "" ? "" : draft.dateofBirthFindingsbyGCSL);
    const [placeOfBirthFind, setPlaceOfBirthFind] = useState(draft == "" ? "" : draft.placeofBirthFindingsbyGCSL);
    const [currentAddressFind, setCurrentAddressFind] = useState(draft == "" ? "" : draft.currentAddressFindingsbyGCSL);


    const [saveId, setSaveId] = useState(draft.verificationId == undefined ? 0 : draft.verificationId)
    const [securityRiskIdIdV, setsecurityRiskIdIdV] = useState(draft.securityRiskId == undefined ? 0 : draft.securityRiskId)
    //Json Data
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    const [roles, setRoles] = useState(
        jwtDecode.Role == "Admin" ? location.state.roles :
            (location.state.roles == "GCSLResearcher" ? "GCSLReviewer" : location.state.roles == "GCSLReviewer" ? "GCSLVerificationResearcher" : location.state.roles == "GCSLVerificationResearcher" ? "FinalVerificationResearcher" : location.state.roles == "FinalVerificationResearcher" ? "FinalVerificationResearcher" : null)
    )
    const [stage, setStage] = useState(
        jwtDecode.Role == "Admin" ? location.state.Stage :
            (location.state.Stage == "PENDING" ? "LEVEL1" : location.state.Stage == "LEVEL1" ? "LEVEL2" : location.state.Stage == "LEVEL2" ? "LEVEL3" : location.state.Stage == "LEVEL3" ? "LEVEL4" : location.state.Stage == "LEVEL4" ? "LEVEL4" : null)
    )

    const [caseCurrentLevel, setCaseCurrentLevel] = useState(
        jwtDecode.Role == "Admin" ? location.state.caseCurrentLevel :
            (location.state.caseCurrentLevel == "PENDING" ? "LEVEL1" : location.state.caseCurrentLevel == "LEVEL1" ? "LEVEL2" : location.state.caseCurrentLevel == "LEVEL2" ? "LEVEL3" : location.state.caseCurrentLevel == "LEVEL3" ? "LEVEL4" : "LEVEL4")
    )
    const [caseCurrentLevelOne, setCaseCurrentLevelOne] = useState(
        jwtDecode.Role == "Admin" ? location.state.caseCurrentLevel1 :
            (location.state.caseCurrentLevel1 == 0 ? 1 : location.state.caseCurrentLevel1 == 1 ? 2 : location.state.caseCurrentLevel1 == 2 ? 3 : location.state.caseCurrentLevel1 == 3 ? 4 : 4)
    )

    const [getCheckBoxBlack, setGetCheckBoxBlack] = useState(blacklistInvestigationIssues)
    console.log("blacklistInvestigationIssues", getCheckBoxBlack)
    console.log("stageRole", location.state.caseCurrentLevel1, roles, stage)
    console.log("caseCurrentLevelOne", caseCurrentLevelOne, location.state.caseCurrentLevel)
    const [totalRisk, setTotalRisk] = useState(draft == "" ? "" : draft.totalRisk)
    const [tableData, setTableData] = useState([])
    const [appendix, setAppendix] = useState(draft == "" ? "" : draft.appendix)
    function getCategoryForVerification() {
        fetch(`${apis}Admin/GetCategoryForVerification/${caseId}`).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                setTableData(result.data)
            })
        })
    }
    const [tableDatakey, setTableDatakey] = useState([])
    function getInformationRiskMatrix() {
        fetch(`${apis}Admin/GetInformationRiskMatrix/${caseId}`).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                setTableDatakey(result.data)
            })
        })
    }
    useEffect(() => {
        getCategoryForVerification()
        getInformationRiskMatrix()
    }, [])
    const handleFindingChange = (itemId, fieldId, event) => {
        console.log("handleFindingChange", itemId, fieldId, event.target.value)
        const newtableData = tableData.map((item) => {
            if (item.categoryId === itemId) {
                return {
                    ...item,
                    field: item.field.map((field) => {
                        if (field.subCategoryId === fieldId) {
                            return {
                                ...field,
                                finding: event.target.value // Update the finding property with the new value
                            };
                        }
                        return field;
                    })
                };
            }
            return item;
        });

        setTableData(newtableData);
    };
    const handleFindingChangekey = (itemId, fieldId, event) => {
        const newtableDatakey = tableDatakey.map((item) => {
            if (item.id === itemId) {
                return {
                    ...item,
                    value: event.target.value
                };
            }
            return item;
        });
        setTableDatakey(newtableDatakey);
    };

    // Define the onchange event handler for field.verified
    const handleVerifiedChange = (itemId, fieldId, event) => {
        const newtableData = tableData.map((item) => {
            if (item.categoryId === itemId) {
                return {
                    ...item,
                    field: item.field.map((field) => {
                        if (field.subCategoryId === fieldId) {
                            return {
                                ...field,
                                verified: event.target.checked // Update the verified property with the new value
                            };
                        }
                        return field;
                    })
                };
            }
            return item;
        });

        setTableData(newtableData);
    };

    // Define the onchange event handler for field.rating
    const handleRatingChange = (itemId, fieldId, event) => {
        const newtableData = tableData.map((item) => {
            if (item.categoryId === itemId) {
                return {
                    ...item,
                    field: item.field.map((field) => {
                        if (field.subCategoryId === fieldId) {
                            return {
                                ...field,
                                rating: event.target.value // Update the rating property with the new value
                            };
                        }
                        return field;
                    })
                };
            }
            return item;
        });

        setTableData(newtableData);
    };

    //const filetrblacklistInvestigationIssues = getCheckBoxBlack.filter(item => item.trim() !== "")
    useEffect(() => {
        let filetrblacklistInvestigationIssues = getCheckBoxBlack.indexOf("" || null);

        // Remove the empty string using splice
        if (filetrblacklistInvestigationIssues !== -1) {
            getCheckBoxBlack.splice(filetrblacklistInvestigationIssues, 1);
        }
    }, [])
    var json = {
        "caseId": caseId,
        "VerificationId": saveId,
        "securityRiskId": securityRiskIdIdV,
        "isNameVerified": nameVerify,
        "nameComment": nameIssues,
        "isPlaceOfBirthVerified": placeOfBirthVerify,
        "placeOfBirthComment": placeOfBirthIssues,
        "placeofBirthFindingsbyGCSL": placeOfBirthFind,
        "isDateOfBirthVerified": dobVerify,
        "dateOfBirthComment": dobIssues,
        // "dateofBirthFindingsbyGCSL": dobFind,
        "isPpidVerified": ppIdVerify,
        "ppidComment": ppIdIssues,
        "isIdVerified": idCardVerify,
        "idComment": idCardIssues,
        //"natIDFindingsbyGCSL": netidFind,
        "isNatIDVerified": netidVerify,
        "natIDComment": netidIssues,
        //"natIDFindingsbyGCSL": netidFind,
        "isKnownAddressVerified": knowAddressVerify,
        "knownAddressComment": knowAddressIssues,
        "IsCurrentAddressVerified": currentAddressVerify,
        "CurrentAddressComment": currentAddressIssues,
        //"currentAddressFindingsbyGCSL": currentAddressFind,
        "IsCorporateAfflicationVerified": corporateAffiliationsVerify,
        "CorporateAfflicationComment": corporateAffiliationsIssues,
        "isCivilLitigationVerified": civilLitigationVerify,
        "civilLitigationComment": civilLitigationIssues,
        "isCriminalRecordVerified": criminialRecordVerify,
        "criminalRecordComment": criminialRecordIssues,
        "isBankruptcyRecordsVerified": bankruptcyRecordsVerify,
        "bankruptcyRecordsComment": bankruptcyRecordsIssues,
        "isGlobalSanctionsVerified": globalSanctionsWatchlistsVerify,
        "globalSanctionsComment": globalSanctionsWatchlistsIssues,
        "isGlobalLawVerified": globalLawEnforcementDataVerify,
        "globalLawComment": globalLawEnforcementDataIssues,
        "isInternationalEmbassiesMissionVerified": internationalEmbassiesMissionVerify,
        "internationalEmbassiesMissionComment": internationalEmbassiesMissionIssues,
        "isGlobalRegulatoryDataVerified": globalRegulatoryDataVerify,
        "globalRegulatoryDataComment": globalRegulatoryDataIssues,
        "isGlobalPepVerified": globalPEHPpFgodVerify,
        "globalPepComment": globalPEHPpFgodIssues,
        "isAdverseMediaVerified": adverseMediaInternetSearchesVerify,
        "adverseMediaComment": adverseMediaInternetSearchesIssues,
        "CivilLitigationInvestigation": civilLitigationInvestigationIssues,
        "CriminalLitigationInvestigation": criminalRecordInvestigationIssues,
        "BankruptcyInvestigation": bankruptcyInvestigationIssues,
        "CorporateInterestsInvestigation": corporateInterestsInvestigationIssues,
        "WorldComplianceInvestigation": worldComplianceInvestigationIssues,
        //"BlacklistInvestigation": blacklistInvestigationIssues,
        "BlacklistInvestigations": getCheckBoxBlack,
        "MediaInternetSearches": mediaInternetSearchesIssues,
        "InvestigatorReportComment": investigatorReportComment,
        "RiskManagementReviewComment": riskManagementReviewComment,
        "SecurityRiskMatrixComment": `${src1}-${src2}`,
        "CategoryResponse": tableData,
        "CategoryResponseKey": tableDatakey,
        "TotalRisk": totalRisk,
        "Appendix": appendix,
        "roles": roles,
        "stage": stage,
        "CurrentStage": location.state.Stage,
        "CurrentRole": location.state.roles,
        "caseCurrentLevel": caseCurrentLevel,
        "caseCurrentLevel1": caseCurrentLevelOne,
        "Conclusion": conclusionIssues
    }
    // disabled function
    function disabledT(e) {

        var switchId = e.target.id
        var disClass = document.getElementById(switchId)
        var disClassName = disClass.parentElement.classList.contains("swirtchcLass");
        if (disClassName == true) {
            var addTargetClass = disClass.parentElement.classList.add(switchId);
            var getClass = document.getElementsByClassName(switchId)
            var nextElementSibling = getClass[0].nextElementSibling.classList.contains("col-md-6")
            var nextElementSiblingTwo = getClass[0].nextElementSibling.classList.contains("col-md-3")
            var classValue = `addCLassDis${switchId}`;
            var ascacs = getClass[0].nextElementSibling.classList.add(classValue)
            var addCLassDis = document.getElementsByClassName(classValue)
            var divalue = addCLassDis[0].lastChild.childNodes[1]
            if (nextElementSibling == true || nextElementSiblingTwo == true && e.target.checked == true) {

                if (e.target.checked == true) {
                    divalue.value += ` ${'<p style="color:#fff,opacity:0">.</p>'}`;
                } else {
                    divalue.value = '';
                }
                divalue.click()
                console.log("nextElementSibling", divalue.value)
                console.log("nextElementSibling", nameVerify)
                divalue.classList.add("validate")

            } else {
                console.log("nextElementSibling False")
                divalue.value = '';
                divalue.click()

            }

        } else {
            disClass.parentElement.classList.remove(addTargetClass);
        }

    }

    function verification() {
        console.log("json,", JSON.stringify(json))
        setLoad(true)
        fetch(`${apis}Admin/CandidateVerification/Submit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(json),
        }).then((respons) => {
            respons.json().then((result) => {
                console.log("result.status", result.status.status)
                if (result.status.status == "AlreadySubmited") {
                    setShow(true)
                } else {
                    navigate('/')
                }
                setLoad(false)
                //navigate('/')
            })
        })
    }

    var saveJson = {
        "caseId": caseId,
        "VerificationId": saveId,
        "securityRiskId": securityRiskIdIdV,
        "CurrentUserId": currentUserId,
        "isNameVerified": nameVerify,
        "nameComment": nameIssues,
        "isPlaceOfBirthVerified": placeOfBirthVerify,
        "placeOfBirthComment": placeOfBirthIssues,
        "placeofBirthFindingsbyGCSL": placeOfBirthFind,
        "isDateOfBirthVerified": dobVerify,
        "dateOfBirthComment": dobIssues,
        "dateofBirthFindingsbyGCSL": dobFind,
        "isPpidVerified": ppIdVerify,
        "ppidComment": ppIdIssues,
        "isIdVerified": idCardVerify,
        "idComment": idCardIssues,
        "natIDFindingsbyGCSL": netidFind,
        "isNatIDVerified": netidVerify,
        "natIDComment": netidIssues,
        "natIDFindingsbyGCSL": netidFind,
        "isKnownAddressVerified": knowAddressVerify,
        "knownAddressComment": knowAddressIssues,
        "IsCurrentAddressVerified": currentAddressVerify,
        "CurrentAddressComment": currentAddressIssues,
        "currentAddressFindingsbyGCSL": currentAddressFind,
        "IsCorporateAfflicationVerified": corporateAffiliationsVerify,
        "CorporateAfflicationComment": corporateAffiliationsIssues,
        "isCivilLitigationVerified": civilLitigationVerify,
        "civilLitigationComment": civilLitigationIssues,
        "isCriminalRecordVerified": criminialRecordVerify,
        "criminalRecordComment": criminialRecordIssues,
        "isBankruptcyRecordsVerified": bankruptcyRecordsVerify,
        "bankruptcyRecordsComment": bankruptcyRecordsIssues,
        "isGlobalSanctionsVerified": globalSanctionsWatchlistsVerify,
        "globalSanctionsComment": globalSanctionsWatchlistsIssues,
        "isGlobalLawVerified": globalLawEnforcementDataVerify,
        "globalLawComment": globalLawEnforcementDataIssues,
        "isInternationalEmbassiesMissionVerified": internationalEmbassiesMissionVerify,
        "internationalEmbassiesMissionComment": internationalEmbassiesMissionIssues,
        "isGlobalRegulatoryDataVerified": globalRegulatoryDataVerify,
        "globalRegulatoryDataComment": globalRegulatoryDataIssues,
        "isGlobalPepVerified": globalPEHPpFgodVerify,
        "globalPepComment": globalPEHPpFgodIssues,
        "isAdverseMediaVerified": adverseMediaInternetSearchesVerify,
        "adverseMediaComment": adverseMediaInternetSearchesIssues,
        "CivilLitigationInvestigation": civilLitigationInvestigationIssues,
        "CriminalLitigationInvestigation": criminalRecordInvestigationIssues,
        "BankruptcyInvestigation": bankruptcyInvestigationIssues,
        "CorporateInterestsInvestigation": corporateInterestsInvestigationIssues,
        "WorldComplianceInvestigation": worldComplianceInvestigationIssues,
        //"BlacklistInvestigation": blacklistInvestigationIssues,
        "BlacklistInvestigations": getCheckBoxBlack,
        "MediaInternetSearches": mediaInternetSearchesIssues,
        "InvestigatorReportComment": investigatorReportComment,
        "RiskManagementReviewComment": riskManagementReviewComment,
        "SecurityRiskMatrixComment": `${src1}-${src2}`,
        "CategoryResponse": tableData,
        "CategoryResponseKey": tableDatakey,
        "TotalRisk": totalRisk,
        "Appendix": appendix,
        "roles": location.state.roles,
        "stage": location.state.Stage,
        "CurrentRole": location.state.roles,
        "CurrentStage": location.state.Stage,
        "caseCurrentLevel": caseCurrentLevel,
        "Conclusion": conclusionIssues

    }
    function verificationSave() {
        // console.log("JSON.stringify ID", saveId);
        //  console.log("JSON.stringify(saveJson)", JSON.stringify(saveJson));
        // console.log("json,", JSON.stringify(saveJson))
        setLoads(true)
        fetch(`${apis}Admin/CandidateVerification/Save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(saveJson),
        }).then((respons) => {
            respons.json().then((result) => {
                // console.log(result)
                setLoads(false)
                if (result.status.status == "AlreadySubmited") {
                    setShow(true)
                } else {
                    navigate('/')
                }
            })
        })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (
                !nameIssues === false &&
                !placeOfBirthIssues === false &&
                !dobIssues === false &&
                !ppIdIssues === false &&
                !idCardIssues === false &&
                !netidIssues === false &&
                !knowAddressIssues === false &&
                !currentAddressIssues === false &&
                !corporateAffiliationsIssues === false &&
                !getCheckBoxBlack == false
                // !civilLitigationIssues === false &&
                // !bankruptcyRecordsIssues === false &&
                // !criminialRecordIssues === false &&
                // !internationalEmbassiesMissionIssues === false &&
                // !globalRegulatoryDataIssues === false &&
                // !globalSanctionsWatchlistsIssues === false &&
                // !globalLawEnforcementDataIssues === false &&
                // !globalPEHPpFgodIssues === false &&
                // !adverseMediaInternetSearchesIssues === false
                //!civilLitigationInvestigationIssues === false &&
                //!criminalRecordInvestigationIssues === false &&
                //!bankruptcyInvestigationIssues === false &&
                //!corporateInterestsInvestigationIssues === false &&
                //!worldComplianceInvestigationIssues === false &&
                //!blacklistInvestigationIssues === false &&
                //!mediaInternetSearchesIssues === false &&
                //!conclusionIssues === false &&
                //!backgroundInformationIssues === false &&
                //!derogatoryInformationIssues === false &&
                //!employmentStatusSourcesIncomeIssues === false &&
                //!companiesUndisclosedIssues === false &&
                //!mostImportantPersonsCIssues === false &&
                //!documentsSubmittedIssues === false &&
                //!internationalBansredIssues === false &&
                //!netidFind === false &&
                //!dobFind === false &&
                //!placeOfBirthFind === false &&
                //!currentAddressFind === false
                //----Add new Paerameter Accdoding to new report
                //!investigatorReportComment === false &&
                // !riskManagementReviewComment === false &&
                // !src1 === false &&
                // !src1 === false
            ) {
                // setSecurityRiskMatrixComment(`${src1}-${src2}`)
                verification()
            } else {
                setMessage("Please fill out all required fields")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("error")//set in value in useState
            }


        }

        setValidated(true);
    };
    const [formData, setFormData] = useState([]);
    const [finding, setfinding] = useState("");
    const [verified, setVerified] = useState();
    const [remark, setRemark] = useState();
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        var obj = {
            "name": name,
            "action": {
                "finding": value,
                "verified": verified,
                "remark": remark
            }
        }

        setFormData([...formData, obj]);
        // setFormData({
        //     ...formData,
        //     [name]: value,
        // });
    };

    function addValueInFd(name) {
        var obj = {
            "name": name,
            "feild": {
                "finding": finding,
                "verified": verified,
                "remark": remark
            }

        }
        setFormData([...formData, obj]);
        console.log("formData", formData);

    }
    function getformData() {
        console.log("formData", formData);
    }
    // var tableJson = [{
    //     "id": "1",
    //     "name": "SOURCE OF WEALTH",
    //     "field": [
    //         { "id": "1", "name": "Undisclosed Corporate Affiliations " },
    //         { "id": "2", "name": "Income And Net Worth" },
    //         { "id": "3", "name": "Financial Standings" },
    //         { "id": "4", "name": "Bank Risk" },
    //         { "id": "5", "name": "Bankruptcy" }
    //     ]
    // },
    // {
    //     "id": "2",
    //     "name": "CRIMINAL AND CIVIL RECORDS",
    //     "field": [
    //         { "id": "1", "name": "Money Laundering Activities" },
    //         { "id": "2", "name": "Criminal Records" },
    //         { "id": "3", "name": "Civil Records" }
    //     ]
    // }
    // ]

    var tableJson = [
        {
            "id": "1",
            "name": "SOURCE OF WEALTH",
            "field": [
                {
                    "id": "1",
                    "name": "Undisclosed Corporate Affiliations ",
                    "finding": "",
                    "verified": false,
                    "rating": ""
                },
                {
                    "id": "2",
                    "name": "Income And Net Worth",
                    "verified": false,
                    "rating": ""
                },
                {
                    "id": "3",
                    "name": "Financial Standings",
                    "verified": false,
                    "rating": ""
                },
                {
                    "id": "4",
                    "name": "Bank Risk",
                    "verified": false,
                    "rating": ""
                },
                {
                    "id": "5",
                    "name": "Bankruptcy",
                    "verified": false,
                    "rating": ""
                }
            ]
        }
    ]

    const [roughWork, setTroughWork] = useState([])
    function getRoughWork() {
        fetch(`${apis}Admin/GetRoughWorkData/${caseId}`).then((respon) => {
            respon.json().then((result) => {
                console.log("getRoughWork", result)
                setTroughWork(result.data)
            })
        })
    }
    useEffect(() => {
        getRoughWork()
    }, [])
    const saveToJSON = () => {
        const jsonData = JSON.stringify(saveJson);
        console.log("fjsonData", jsonData); // You can do whatever you want with the JSON data here.
    };

    var disable = jwtDecode.Role == "GCSLResearcher" || jwtDecode.Role == "Admin"
    console.log("disable", !disable, disable)

    // const editorToolbar = useRef(false)
    // useEffect(() => {
    //     if (!editorToolbar.current) {
    //         var script = document.createElement("script");
    //         script.innerHTML = `
    //         tinymce.init({
    //             selector: '#validationCustom01'
    //           });
    //           `
    //         document.body.appendChild(script)
    //         editorToolbar.current = true
    //     }

    // }, [])

    const [chartshow, setChartShow] = useState(false);

    const charthandleClose = () => setChartShow(false);
    const charthandleShow = () => setChartShow(true);


    const [blackListCheckBox, setBlackListCheckBox] = useState([])
    function getBlackListCheckBox() {
        fetch(`${apis}Admin/BlackListInfo`).then((respon) => {
            respon.json().then((result) => {
                console.log("BlackListInfo", result.data)
                setBlackListCheckBox(result.data)
            })
        })
    }
    useEffect(() => {
        getBlackListCheckBox()
    }, [])
    function selecBlackList(e) {


        const itemId = e.target.value;
        if (e.target.checked) {
            // Add the selected item to the list
            setGetCheckBoxBlack([...getCheckBoxBlack, itemId]);
        } else {
            // Remove the item from the list
            setGetCheckBoxBlack(getCheckBoxBlack.filter((item) => item !== itemId));
        }
        setGetCheckBoxBlack((prevList) => {
            let index = prevList.indexOf("");
            if (index !== -1) {
                let updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            }
            return prevList;
        });

        console.log("selecBlackList", getCheckBoxBlack)
    }
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">

                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item">View Profile</li>
                                        <li className="breadcrumb-item active"> Verification</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>

                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 ">
                                <p className='px-3'><b>Client Name</b>: {location.state.clientName}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <Form noValidate validated={validated} >
                    <div id="accordion" className="custom-accordion mt-4">
                        <div className="card mb-1 shadow-none">
                            <a href="#collapseOne" className="text-dark" data-bs-toggle="collapse" aria-expanded="false"
                                aria-controls="collapseOne">
                                <div className="card-header" id="headingOne">
                                    <h6 className="m-0">
                                        Candidate Provided Details
                                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordion">
                                <div className="card-body p-0">
                                    <div className="">
                                        <div className="card-body ">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Name</label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={gname} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input className="disabledInput" type="checkbox" id="switch1" switch="none"
                                                        checked={nameVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setNameVerify(e.target.checked)
                                                        }}

                                                    />
                                                    <label for="switch1" data-on-label="Yes" data-off-label="No"></label>

                                                </div>
                                                <div className="col-md-6 ">

                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom01">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            value={nameIssues}
                                                            required
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setNameIssues(e.target.value)}
                                                            onChange={(e) => { setNameIssues(e.target.value) }}
                                                            onBlur={
                                                                (e) => {
                                                                    if (nameVerify == true) {
                                                                        //  console.log("nameIssues", nameIssues)
                                                                        if (nameVerify == true && nameIssues !== '') {
                                                                            e.target.value = nameIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = nameIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={nameIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setNameIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Place of Birth</label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={placeOfBirth} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch2" switch="none"
                                                        checked={placeOfBirthVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setPlaceOfBirthVerify(e.target.checked)
                                                        }} />
                                                    <label for="switch2" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">

                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom02">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={placeOfBirthIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setPlaceOfBirthIssues(e.target.value)}
                                                            onChange={(e) => setPlaceOfBirthIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (placeOfBirthVerify == true) {
                                                                        if (nameVerify == true && placeOfBirthIssues !== '') {
                                                                            e.target.value = placeOfBirthIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = placeOfBirthIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={placeOfBirthIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setPlaceOfBirthIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={placeOfBirthFind}
                                                            as="textarea"
                                                            //maxLength="25"

                                                            disabled={!disable}
                                                            onChange={(e) => setPlaceOfBirthFind(e.target.value)}

                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Date of Birth</label>
                                                        <div className="input-group" id="datepicker3">
                                                            <input disabled type="text" className="form-control" value={`${day} - ${month} - ${year}`} placeholder="dd/mm/yyyy"
                                                                data-provide="datepicker" data-date-container="#datepicker3"
                                                                data-date-format="dd M, yyyy" data-date-multidate="true" />
                                                            <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch3" switch="none"
                                                        checked={dobVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setDobVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch3" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom03">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={dobIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setDobIssues(e.target.value)}
                                                            onChange={(e) => setDobIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (dobVerify == true) {
                                                                        if (dobVerify == true && dobIssues !== '') {
                                                                            e.target.value = dobIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = dobIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={dobIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setDobIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={dobFind}
                                                            as="textarea"
                                                            //maxLength="15"
                                                            disabled={!disable}
                                                            onChange={(e) => setDobFind(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">P.P. ID </label>
                                                        <input disabled type="text" className="form-control" value={ppId} id="validationCustom01" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch4" switch="none"
                                                        checked={ppIdVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setPpIdVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch4" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom04">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={ppIdIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setPpIdIssues(e.target.value)}
                                                            onChange={(e) => setPpIdIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (ppIdVerify == true) {
                                                                        if (ppIdVerify == true && ppIdIssues !== '') {
                                                                            e.target.value = ppIdIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = ppIdIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={ppIdIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setPpIdIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">ID Card </label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={idCard} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch5" switch="none"
                                                        checked={idCardVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setidCardVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch5" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={idCardIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setidCardIssues(e.target.value)}
                                                            onChange={(e) => setidCardIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (idCardVerify == true) {
                                                                        if (idCardVerify == true && idCardIssues !== '') {
                                                                            e.target.value = idCardIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = idCardIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={idCardIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setidCardIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Nat. ID </label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={netid} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch5-1" switch="none"
                                                        checked={netidVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setNetidVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch5-1" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={netidIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setNetidIssues(e.target.value)}
                                                            onChange={(e) => setNetidIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (netidVerify == true) {
                                                                        if (netidVerify == true && netidIssues !== '') {
                                                                            e.target.value = netidIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = netidIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={netidIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setNetidIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={netidFind}
                                                            as="textarea"
                                                            //maxLength="10"
                                                            disabled={!disable}
                                                            onChange={(e) => setnetidFind(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Known Address </label>
                                                        <textarea disabled id="textarea" className="form-control" value={knowAddress} style={{ display: "block" }} maxlength="225" rows="1"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch6" switch="none"
                                                        checked={knowAddressVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setKnowAddressVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch6" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom06">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={knowAddressIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setKnowAddressIssues(e.target.value)}
                                                            onChange={(e) => setKnowAddressIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (knowAddressVerify == true) {
                                                                        if (knowAddressVerify == true && knowAddressIssues !== '') {
                                                                            e.target.value = knowAddressIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = knowAddressIssues;
                                                                    }
                                                                }
                                                            }

                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={knowAddressIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setKnowAddressIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Current
                                                            Address </label>
                                                        <input disabled type="text" className="form-control" value={currentAddress} />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch23" switch="none"
                                                        checked={currentAddressVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setCurrentAddressVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch23" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom07">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={currentAddressIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setcurrentAddressIssues(e.target.value)}
                                                            onChange={(e) => setcurrentAddressIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (currentAddressVerify == true) {
                                                                        if (currentAddressVerify == true && currentAddressIssues !== '') {
                                                                            e.target.value = currentAddressIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = currentAddressIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={currentAddressIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setcurrentAddressIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={currentAddressFind}
                                                            as="textarea"
                                                            //maxLength="120"
                                                            disabled={!disable}
                                                            onChange={(e) => setCurrentAddressFind(e.target.value)}

                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Corporate Affiliations
                                                        </label>
                                                        {/* 
                                                        <textarea disabled id="textarea" className="form-control" value={corporateAffiliations} maxlength="225" rows="1"
                                                            placeholder=""></textarea> */}
                                                        <div>
                                                            <ul>
                                                                {
                                                                    state.corporateAfflicationss.map((item) =>
                                                                        <li>{item.corporateAffiliation}</li>
                                                                    )
                                                                }
                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch7" switch="none"
                                                        checked={corporateAffiliationsVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setcorporateAffiliationsVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch7" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom08">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={corporateAffiliationsIssues}
                                                            as="textarea"
                                                            // maxLength="50"
                                                            onClick={(e) => setcorporateAffiliationsIssues(e.target.value)}
                                                            onChange={(e) => setcorporateAffiliationsIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (corporateAffiliationsVerify == true) {
                                                                        if (corporateAffiliationsVerify == true && corporateAffiliationsIssues !== '') {
                                                                            e.target.value = corporateAffiliationsIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = corporateAffiliationsIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={corporateAffiliationsIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setcorporateAffiliationsIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-1 shadow-none">
                            <a href="#collapseTwo" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                aria-controls="collapseTwo">
                                <div className="card-header" id="headingTwo">
                                    <h6 className="m-0">
                                        Type of Inquiries
                                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                <div className="card-body  p-0">
                                    <div className="">
                                        <div className="card-body">
                                            {/* <div className="row">
                                            <div className="col-md-5">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Sources searched</label>
                                                    <input type="text" className="form-control" id="validationCustom01" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-1 mt-1 swirtchcLass">
                                                <label>Verified</label>
                                                <input type="checkbox" id="switch9" switch="none" />
                                                <label for="switch9" data-on-label="Yes" data-off-label="No"></label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Comments</label>
                                                    <textarea id="textarea" className="form-control" maxlength="225" rows="1"
                                                        placeholder=""></textarea>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label ">Civil Litigation</label>
                                                        {/* <input disabled type="text" className="form-control" value={civilLitigation} id="validationCustom01" required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch10" switch="none"
                                                        checked={civilLitigationVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setCivilLitigationVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch10" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom09">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            value={civilLitigationIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setCivilLitigationIssues(e.target.value)}
                                                            onChange={(e) => setCivilLitigationIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (civilLitigationVerify == true) {
                                                                        if (civilLitigationVerify == true && civilLitigationIssues !== '') {
                                                                            e.target.value = civilLitigationIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = civilLitigationIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={civilLitigationIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setCivilLitigationIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                            <div className="col-md-5">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Civil Litigation </label>
                                                    <input type="text" className="form-control" id="validationCustom01" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-1 mt-1 swirtchcLass">
                                                <label>Verified</label>
                                                <input type="checkbox" id="switch12" switch="none" />
                                                <label for="switch12" data-on-label="Yes" data-off-label="No"></label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Comments</label>
                                                    <textarea id="textarea" className="form-control" maxlength="225" rows="1"
                                                        placeholder=""></textarea>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Bankruptcy records </label>
                                                        {/* <input disabled type="text" className="form-control" id="validationCustom01" value={bankruptcyRecords} required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch13" switch="none"
                                                        checked={bankruptcyRecordsVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setBankruptcyRecordsVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch13" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            value={bankruptcyRecordsIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setBankruptcyRecordsIssues(e.target.value)}
                                                            onChange={(e) => setBankruptcyRecordsIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (bankruptcyRecordsVerify == true) {
                                                                        if (bankruptcyRecordsVerify == true && bankruptcyRecordsIssues !== '') {
                                                                            e.target.value = bankruptcyRecordsIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {

                                                                        e.target.value = bankruptcyRecordsIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={bankruptcyRecordsIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setBankruptcyRecordsIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Criminal Record</label>
                                                        {/* <input disabled type="text" className="form-control" id="validationCustom01" value={bankruptcyRecords} required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch13-1" switch="none"
                                                        checked={criminialRecordVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setcriminialRecordVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch13-1" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10-0">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            value={criminialRecordIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setcriminialRecordIssues(e.target.value)}
                                                            onChange={(e) => setcriminialRecordIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (criminialRecordVerify == true) {
                                                                        if (criminialRecordVerify == true && criminialRecordIssues !== '') {
                                                                            e.target.value = criminialRecordIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = criminialRecordIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={criminialRecordIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setcriminialRecordIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 col-12">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Global Sanctions and
                                                            Watchlists: </label>
                                                        {/* <input disabled type="text" className="form-control" id="validationCustom01" value={globalSanctionsWatchlists} required="" /> */}

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch14" switch="none"
                                                        checked={globalSanctionsWatchlistsVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setGlobalSanctionsWatchlistsVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch14" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom11">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            value={globalSanctionsWatchlistsIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setGlobalSanctionsWatchlistsIssues(e.target.value)}
                                                            onChange={(e) => setGlobalSanctionsWatchlistsIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (globalSanctionsWatchlistsVerify == true) {
                                                                        if (globalSanctionsWatchlistsVerify == true && globalSanctionsWatchlistsIssues !== '') {
                                                                            e.target.value = globalSanctionsWatchlistsIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = globalSanctionsWatchlistsIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={globalSanctionsWatchlistsIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setGlobalSanctionsWatchlistsIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Global Law Enforcement Data
                                                        </label>
                                                        {/* <input disabled type="text" className="form-control" value={globalLawEnforcementData} id="validationCustom01" required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch15" switch="none"
                                                        checked={globalLawEnforcementDataVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setGlobalLawEnforcementDataVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch15" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom12">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            value={globalLawEnforcementDataIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setGlobalLawEnforcementDataIssues(e.target.value)}
                                                            onChange={(e) => setGlobalLawEnforcementDataIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (globalLawEnforcementDataVerify == true) {
                                                                        if (globalLawEnforcementDataVerify == true && globalLawEnforcementDataIssues !== '') {
                                                                            e.target.value = globalLawEnforcementDataIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = globalLawEnforcementDataIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={globalLawEnforcementDataIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setGlobalLawEnforcementDataIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">International Embassies/Mission</label>
                                                        {/* <input disabled type="text" className="form-control" id="validationCustom01" value={bankruptcyRecords} required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch13-2" switch="none"
                                                        checked={internationalEmbassiesMissionVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setInternationalEmbassiesMissionVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch13-2" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10-1">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            //  required
                                                            value={internationalEmbassiesMissionIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setinternationalEmbassiesMissionIssues(e.target.value)}
                                                            onChange={(e) => setinternationalEmbassiesMissionIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (internationalEmbassiesMissionVerify == true) {
                                                                        if (internationalEmbassiesMissionVerify == true && internationalEmbassiesMissionIssues !== '') {
                                                                            e.target.value = internationalEmbassiesMissionIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = internationalEmbassiesMissionIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={internationalEmbassiesMissionIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setinternationalEmbassiesMissionIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label"> Global Regulatory Data</label>
                                                        {/* <input disabled type="text" className="form-control" id="validationCustom01" value={bankruptcyRecords} required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch13-3" switch="none"
                                                        checked={globalRegulatoryDataVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setGlobalRegulatoryDataVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch13-3" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10-2">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            value={globalRegulatoryDataIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setGlobalRegulatoryDataIssues(e.target.value)}
                                                            onChange={(e) => setGlobalRegulatoryDataIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (globalRegulatoryDataVerify == true) {
                                                                        if (globalRegulatoryDataVerify == true && globalRegulatoryDataIssues !== '') {
                                                                            e.target.value = globalRegulatoryDataIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {
                                                                        e.target.value = globalRegulatoryDataIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={globalRegulatoryDataIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setGlobalRegulatoryDataIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Global P.E.P/High-Profile
                                                            persons/
                                                            Foreign Government Official Data</label>
                                                        {/* <input disabled type="text" className="form-control" value={globalPEHPpFgod} id="validationCustom01" required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch16" switch="none"
                                                        checked={globalPEHPpFgodVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setGlobalPEHPpFgodVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch16" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom13">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            //  required
                                                            value={globalPEHPpFgodIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setGlobalPEHPpFgodIssues(e.target.value)}
                                                            onChange={(e) => setGlobalPEHPpFgodIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (globalPEHPpFgodVerify == true) {
                                                                        if (globalPEHPpFgodVerify == true && globalPEHPpFgodIssues !== '') {
                                                                            e.target.value = globalPEHPpFgodIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {

                                                                        e.target.value = globalPEHPpFgodIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={globalPEHPpFgodIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setGlobalPEHPpFgodIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Adverse Media and Internet
                                                            Searches</label>
                                                        {/* <input disabled type="text" className="form-control" value={adverseMediaInternetSearches} id="validationCustom01" required="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 text-center">
                                                    <label className='d-block'>Verified</label>
                                                    <input type="checkbox" id="switch17" switch="none"
                                                        checked={adverseMediaInternetSearchesVerify}
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setAdverseMediaInternetSearchesVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch17" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom14">
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            //  required
                                                            value={adverseMediaInternetSearchesIssues}
                                                            as="textarea"
                                                            //maxLength="50"
                                                            onClick={(e) => setAdverseMediaInternetSearchesIssues(e.target.value)}
                                                            onChange={(e) => setAdverseMediaInternetSearchesIssues(e.target.value)}
                                                            onBlur={
                                                                (e) => {
                                                                    if (adverseMediaInternetSearchesVerify == true) {
                                                                        if (adverseMediaInternetSearchesVerify == true && adverseMediaInternetSearchesIssues !== '') {
                                                                            e.target.value = adverseMediaInternetSearchesIssues;
                                                                        } else {
                                                                            e.target.value = " ";
                                                                        }
                                                                    } else {

                                                                        e.target.value = adverseMediaInternetSearchesIssues;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <div class="cke-no-border">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={adverseMediaInternetSearchesIssues}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setAdverseMediaInternetSearchesIssues(data)
                                                                }}
                                                            />
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FamilyInquiries caseId={location.state.caseId} dependentSatus={dependentSatus} varification={true} dependentId={location.state.dependentId} />

                        <Mipc caseId={location.state.caseId} dependentSatus={dependentSatus} varification={true} dependentId={location.state.dependentId} />
                        <div className="card mb-0 shadow-none mb-3">
                            <a href="#collapseFour" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                aria-controls="collapseFour">
                                <div className="card-header" id="headingFour">
                                    <h6 className="m-0">
                                        Inquiry Details

                                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                <div className="card-body ">

                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom16">
                                                <Form.Label>Civil Litigation Investigation</Form.Label>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={civilLitigationInvestigationIssues}
                                                    onReady={editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        editor.ui.view.editable.element.style.height = '300px'

                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ event, editor, data });
                                                        setCivilLitigationInvestigationIssues(data)

                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom17">
                                                <Form.Label>Criminal Record Investigation</Form.Label>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={criminalRecordInvestigationIssues}
                                                    onReady={editor => {
                                                        editor.ui.view.editable.element.style.height = '300px'

                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setCriminalRecordInvestigationIssues(data)

                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom18">
                                                <Form.Label>Bankruptcy Investigation </Form.Label>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={bankruptcyInvestigationIssues}
                                                    onReady={editor => {
                                                        editor.ui.view.editable.element.style.height = '300px'

                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setBankruptcyInvestigationIssues(data)

                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom19">
                                                <Form.Label>Corporate Interests Investigation</Form.Label>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={corporateInterestsInvestigationIssues}
                                                    onReady={editor => {
                                                        editor.ui.view.editable.element.style.height = '300px'

                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setCorporateInterestsInvestigationIssues(data)

                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom20">
                                                <Form.Label>World Compliance Investigation</Form.Label>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={worldComplianceInvestigationIssues}
                                                    onReady={editor => {
                                                        editor.ui.view.editable.element.style.height = '300px'

                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setWorldComplianceInvestigationIssues(data)

                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom20">
                                                <Form.Label>Blacklist Investigation </Form.Label>

                                                {/* <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={blacklistInvestigationIssues}
                                                    onReady={editor => {
                                                        editor.ui.view.editable.element.style.height = '300px'

                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setBlacklistInvestigationIssues(data)

                                                    }}

                                                /> */}
                                                <div style={{ border: "1px solid #ccced1", padding: "15px", height: "339px" }}>
                                                    {
                                                        blackListCheckBox.map((item) => (
                                                            <Form.Check
                                                                required
                                                                type={"checkbox"}
                                                                //inline
                                                                value={item.formate}
                                                                id={`id${item.formate}`}
                                                                label={item.formate}
                                                                name={"blackCheckBox"}
                                                                checked={getCheckBoxBlack.includes(item.formate)}
                                                                onChange={(e) => {
                                                                    selecBlackList(e);
                                                                }}
                                                            />
                                                        ))}
                                                </div>


                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <Form.Group className="mb-3" controlId="validationCustom21">
                                                <Form.Label>Media and Internet Searches</Form.Label>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfiguration}
                                                    data={mediaInternetSearchesIssues}
                                                    onReady={editor => {
                                                        editor.ui.view.editable.element.style.height = '300px'
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setMediaInternetSearchesIssues(data)

                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                    </div>
                                    {
                                        clientName !== "St. Kitts Nevis Anguilla National Bank Ltd" ?
                                            <>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <Form.Group className="mb-3" controlId="validationCustom20">
                                                            <Form.Label>Investigator Report</Form.Label>

                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={investigatorReportComment}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'

                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setInvestigatorReportComment(data)

                                                                }}

                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please fill this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <Form.Group className="mb-3" controlId="validationCustom20">
                                                            <Form.Label>Risk Management Review</Form.Label>

                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={riskManagementReviewComment}
                                                                onReady={editor => {
                                                                    editor.ui.view.editable.element.style.height = '300px'

                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setRiskManagementReviewComment(data)

                                                                }}

                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please fill this field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                            </> : null
                                    }
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            {/* {
                                                clientName == "St. Kitts Nevis Anguilla National Bank Ltd" ?
                                                    <img src={chartImg} style={{ width: "100%" }} /> :
                                                    clientName == "Saint Lucia Citizenship by Investment Program (CIP)" ?
                                                        <img src={chartImg} style={{ width: "100%" }} /> :
                                                        clientName == "St. Kitts and Nevis Citizenship by Investment Unit (CIU)" ?
                                                            <img src={chartImg} style={{ width: "100%" }} /> : null
                                            } */}
                                            <div className='text-center'><img src={`../assets/images/clientsm/${clientChartName}`} style={{ width: "40%", margin: "0 auto" }} /></div>

                                            <br />
                                            <div className="">
                                                <label for="validationCustom01" className="form-label">Security Risk Matrix
                                                    {/* <span onClick={charthandleShow}>{`(View Chart)`}</span> */}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <Form.Group className="mb-4" md="4" controlId="validationCustomsrc1">

                                                <Form.Control
                                                    required
                                                    type="text"
                                                    // id="validationCustom09"
                                                    maxLength="10"
                                                    value={src1}
                                                    onChange={(e) => {
                                                        src1Validation(e)

                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-4" md="4" controlId="validationCustomsrc2">

                                                <Form.Control
                                                    required
                                                    type="text"
                                                    // id="validationCustom09"
                                                    maxLength="10"
                                                    value={src2}
                                                    onChange={(e) => {
                                                        src2Validation(e)

                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {
                                        clientName == "St. Kitts Nevis Anguilla National Bank Ltd" ?
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <Form.Group className="mb-3" controlId="validationCustom21">
                                                        <Form.Label>Conclusion</Form.Label>

                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={editorConfiguration}
                                                            data={conclusionIssues}
                                                            onReady={editor => {
                                                                editor.ui.view.editable.element.style.height = '300px'
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setConclusionIssues(data)

                                                            }}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please fill this field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                            </div> : null}










                                </div>
                            </div>

                        </div>

                        {
                            riskMatrix == true && clientName !== "St. Kitts Nevis Anguilla National Bank Ltd" ? <>

                                <div className="card mb-3 shadow-none">
                                    <a href="#collapseTwoRiskMatrix" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="collapseTwoRiskMatrix">
                                        <div className="card-header" id="headingRiskMatrix">
                                            <h6 className="m-0">
                                                Applicant Information Risk Matrix
                                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div id="collapseTwoRiskMatrix" className="collapse" aria-labelledby="headingRiskMatrix" data-bs-parent="#accordion">
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-md-12 col-12">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr class="mipc">
                                                                <th>CATEGORY</th>
                                                                <th>FINDINGS</th>
                                                                <th width="5%">VERIFIED</th>
                                                                <th>RISK RATING</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableData.map((item) =>
                                                                    <>
                                                                        <tr>
                                                                            <td colspan="4"><b>{item.categoryName}</b></td>
                                                                        </tr>
                                                                        {
                                                                            item.field.map((items, i, index) =>
                                                                                <tr>
                                                                                    <td>{items.name}</td>
                                                                                    <td>
                                                                                        <Form.Group className="" md="4" controlId={`riskm${i}`}>
                                                                                            <Form.Control
                                                                                                //required
                                                                                                type="text"
                                                                                                // id="validationCustom09"
                                                                                                //  maxLength="10"
                                                                                                value={items.finding}
                                                                                                name={items.name}

                                                                                                // onChange={(e) => setfinding(e.target.value)}
                                                                                                //onChange={(e) => handleTextChange(index, e.target.value)}
                                                                                                onChange={(e) => handleFindingChange(item.categoryId, items.subCategoryId, e)}
                                                                                            />
                                                                                            <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                                                        </Form.Group>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="checkbox" id={`switchss${item.categoryId}${i}`} switch="none"
                                                                                            checked={items.verified}
                                                                                            name='findings'
                                                                                            value={items.verified}
                                                                                            onChange={(e) => handleVerifiedChange(item.categoryId, items.subCategoryId, e)} />
                                                                                        <label for={`switchss${item.categoryId}${i}`} data-on-label="Yes" data-off-label="No"></label>
                                                                                        {/* <input
                                                                                    type="checkbox"
                                                                                    checked={items.verified}
                                                                                    onChange={(e) =>
                                                                                        handleVerifiedChange(item.id, items.id, e)
                                                                                    }
                                                                                /> */}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Group className="" md="4" controlId="validationCustomsrc2">
                                                                                            <Form.Control
                                                                                                //   required
                                                                                                type="text"
                                                                                                // id="validationCustom09"
                                                                                                //  maxLength="10"
                                                                                                value={items.rating}
                                                                                                onChange={(e) => handleRatingChange(item.categoryId, items.subCategoryId, e)}
                                                                                            />
                                                                                            <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                                                        </Form.Group>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div></div>
                                    </div>
                                </div>


                                <div className="card mb-3 shadow-none">
                                    <a href="#collapseTwoRiskMatrixkey" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="collapseTwoRiskMatrixkey">
                                        <div className="card-header" id="headingRiskMatrixkey">
                                            <h6 className="m-0">
                                                Information Risk Matrix Key
                                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div id="collapseTwoRiskMatrixkey" className="collapse" aria-labelledby="headingRiskMatrixkey" data-bs-parent="#accordion">
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-md-12 col-12">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr class="mipc">
                                                                <th>CATEGORY</th>
                                                                <th>FINDINGS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableDatakey.map((item, i) =>
                                                                    <>
                                                                        <tr>
                                                                            <td ><b>{item.name}</b></td>
                                                                            <td>
                                                                                <Form.Group className="" md="4" controlId={`riskmkey${i}`}>
                                                                                    <Form.Control
                                                                                        // required
                                                                                        type="text"
                                                                                        // id="validationCustom09"
                                                                                        //  maxLength="10"
                                                                                        value={item.value}
                                                                                        name={item.name}

                                                                                        // onChange={(e) => setfinding(e.target.value)}
                                                                                        //onChange={(e) => handleTextChange(index, e.target.value)}
                                                                                        onChange={(e) => handleFindingChangekey(item.id, item.name, e)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                            </td>
                                                                        </tr>

                                                                    </>
                                                                )
                                                            }
                                                            <tr>
                                                                <td ><b>Total Risk</b></td>
                                                                <td>
                                                                    <Form.Group className="" md="4" controlId={`riskmkeyTotal`}>
                                                                        <Form.Control
                                                                            //  required
                                                                            type="text"
                                                                            // id="validationCustom09"
                                                                            //  maxLength="10"
                                                                            value={totalRisk}
                                                                            //name={}

                                                                            // onChange={(e) => setfinding(e.target.value)}
                                                                            //onChange={(e) => handleTextChange(index, e.target.value)}
                                                                            onChange={(e) => setTotalRisk(e.target.value)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div></div>
                                    </div>
                                </div>







                                <div className="card mb-1 shadow-none">
                                    <a href="#collapseTwoAppendix" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="collapseTwoAppendix">
                                        <div className="card-header" id="headingAppendix">
                                            <h6 className="m-0">
                                                Appendix
                                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div id="collapseTwoAppendix" className="collapse" aria-labelledby="headingAppendix" data-bs-parent="#accordion">
                                        <div class="card-body ">
                                            <section>
                                                <Form.Group className="mb-3" controlId="validationCustom20">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={editorConfigurationAppedex}
                                                        data={appendix}
                                                        onReady={editor => {
                                                            editor.ui.view.editable.element.style.height = '300px'
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setAppendix(data)
                                                        }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                            </> : ""
                        }





                        <RoughWork caseId={caseId} currentUserId={currentUserId} view={true} />


                    </div>
                </Form>
                {/* <button onClick={saveToJSON}>Save to JSON</button>
                <button onClick={getformData}>save</button> */}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <button onClick={verificationSave} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                        data-qa-tag="uploader-drop-zone-button">
                        {
                            loads ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                        }
                        Save</button><span>&nbsp;</span>
                    {/* <button onClick={verificationSave} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                        data-qa-tag="uploader-drop-zone-button">
                        {
                            loads ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                        }
                        Submit case for All Researcher</button><span>&nbsp;</span> */}
                    <button onClick={handleSubmit} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                        data-qa-tag="uploader-drop-zone-button">
                        {
                            load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                        }
                        {
                            location.state.roles == "GCSLResearcher" ? "Submit case for All Researcher" : "Submit"
                        }
                    </button>
                </div>

            </div>
            <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

            <Modal show={show} onHide={handleCloseModel}>

                <Modal.Body><p style={{ textAlign: "center", fontWeight: "bold", fontSize: " 20px" }}>Verification data already submitted,<br />case moved to next level.<br />Please contact Administrator

                </p> </Modal.Body>
                <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                    {/* <Button variant="primary" onClick={() => { navigate(-1) }}>
                        Please Go Back
                    </Button> */}
                    <Button variant="primary" onClick={() => setShow(false)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={chartshow}
                onHide={charthandleClose}
                backdrop="static"
                size='lg'
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Security Risk Matrix Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={chartImg} style={{ width: "100%" }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Verification