import { useState } from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import api from '../../apiUrl';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddReport(prop) {
    // console.log("prop.dependentId", prop.dependentId)
    var apis = api()
    // console.log(prop)
    const [upload, setUpload] = useState();
    const [load, setLoad] = useState(false)
    const [fileSize, setfileSize] = useState();
    const [caseName, setCaseName] = useState();
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const [fileNames, setFileNames] = useState([])

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const dependentStatus = prop.dependentStatus
    const dependentId = prop.dependentId
    //Drag and Drop
    setTimeout(() => {
        // File Upload
        // 
        function ekUpload() {
            function Init() {

                //  console.log("Upload Initialised");

                var fileSelect = document.getElementById('file-upload'),
                    fileDrag = document.getElementById('file-drag'),
                    submitButton = document.getElementById('submit-button');

                fileSelect.addEventListener('change', fileSelectHandler, false);

                // Is XHR2 available?
                var xhr = new XMLHttpRequest();
                if (xhr.upload) {
                    // File Drop
                    fileDrag.addEventListener('dragover', fileDragHover, false);
                    fileDrag.addEventListener('dragleave', fileDragHover, false);
                    fileDrag.addEventListener('drop', fileSelectHandler, false);
                }
            }

            function fileDragHover(e) {
                var fileDrag = document.getElementById('file-drag');

                e.stopPropagation();
                e.preventDefault();

                fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
            }

            function fileSelectHandler(e) {
                // Fetch FileList object
                var files = e.target.files || e.dataTransfer.files;

                // Cancel event and hover styling
                fileDragHover(e);

                // Process all File objects
                for (var i = 0, f; f = files[i]; i++) {
                    parseFile(f);
                    uploadFile(f);
                }
            }

            // Output
            function output(msg) {
                // Response
                var m = document.getElementById('messages');
                m.innerHTML = msg;
            }

            function parseFile(file) {

                // console.log(file.name);
                // console.log("file", file);
                setUpload(file)
                output(
                    '<strong>' + encodeURI(file.name) + '</strong>'
                );

                // var fileType = file.type;
                // console.log(fileType);
                var imageName = file.name;

                var isGood = (/\.(?=doc|docx|pdf)/gi).test(imageName);
                //  console.log(isGood)
                if (isGood) {
                    document.getElementById('start').classList.add("hidden");
                    document.getElementById('response').classList.remove("hidden");
                    document.getElementById('notimage').classList.add("hidden");
                    // Thumbnail Preview
                    document.getElementById('file-image').classList.remove("hidden");
                    //document.getElementById('file-image').src = URL.createObjectURL(file);
                }
                else {
                    document.getElementById('file-image').classList.add("hidden");
                    document.getElementById('notimage').classList.remove("hidden");
                    document.getElementById('start').classList.remove("hidden");
                    document.getElementById('response').classList.add("hidden");
                    document.getElementById("file-upload-form").reset();
                }
            }

            function setProgressMaxValue(e) {
                var pBar = document.getElementById('file-progress');

                if (e.lengthComputable) {
                    pBar.max = e.total;
                }
            }

            function updateFileProgress(e) {
                var pBar = document.getElementById('file-progress');

                if (e.lengthComputable) {
                    pBar.value = e.loaded;
                }
            }

            function uploadFile(file) {
                setfileSize(file.size)
                var xhr = new XMLHttpRequest(),
                    fileInput = document.getElementById('class-roster-file'),
                    pBar = document.getElementById('file-progress'),
                    fileSizeLimit = 200; // In MB
                if (xhr.upload) {
                    // Check if file is less than x MB
                    if (file.size <= fileSizeLimit * 1024 * 1024) {
                        // Progress bar
                        pBar.style.display = 'inline';
                        xhr.upload.addEventListener('loadstart', setProgressMaxValue, false);
                        xhr.upload.addEventListener('progress', updateFileProgress, false);

                        // File received / failed
                        xhr.onreadystatechange = function (e) {
                            if (xhr.readyState == 4) {
                                // Everything is good!

                                // progress.className = (xhr.status == 200 ? "success" : "failure");
                                // document.location.reload(true);
                            }
                        };

                        // Start upload
                        xhr.open('POST', document.getElementById('file-upload-form').action, true);
                        xhr.setRequestHeader('X-File-Name', file.name);
                        xhr.setRequestHeader('X-File-Size', file.size);
                        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                        xhr.send(file);
                    } else {
                        output('Please upload a smaller file (< ' + fileSizeLimit + ' MB).');
                        document.getElementById('file-image').classList.add("hidden");
                        document.getElementById("file-upload-form").reset();
                    }
                }
            }

            // Check for the various File API support.
            if (window.File && window.FileList && window.FileReader) {
                Init();
            } else {
                document.getElementById('file-drag').style.display = 'none';
            }
        }
        ekUpload();

        // Zip Reader

    }, [1000])


    function upLoad(e) {

        setLoad(true)
        const formData = new FormData();
        formData.append('file', upload)
        formData.append('CaseId', prop.caseId);
        if (dependentStatus == true) {
            formData.append('DependentId', dependentId);
        }
        // console.log("upload Report", formData)
        // console.log("upload Add", formData)
        //  console.log("apis", formData)
        if (dependentStatus == true) {
            fetch(`${apis}Admin/UpLoadReportHistoryForDependent/`, {
                method: 'POST',
                body: formData,
                mode: "cors",
            }).then((response) => response.json()).then((result) => {
                // console.log('Success:', result);
                setLoad(false)
                setMessage("Report Has been Created")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("success")//set in value in useState
                setCaseName(null)
                setUpload(null)
                setShow(false)
                prop.reload()



            }).catch((error) => {
                console.error('Error:', error);
                setLoad(false)
            });
        } else {
            fetch(`${apis}Admin/UpLoadReportHistory/`, {
                method: 'POST',
                body: formData,
                mode: "cors",
            }).then((response) => response.json()).then((result) => {
                // console.log('Success:', result);
                setLoad(false)
                setMessage("Report Has been Created")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("success")//set in value in useState
                setCaseName(null)
                setUpload(null)
                setShow(false)
                prop.reload()



            }).catch((error) => {
                console.error('Error:', error);
                setLoad(false)
            });
        }

    }
    const crossClose = () => {
        setShow(false)
    };

    const handleClose = () => {
        upLoad()
    };
    return (
        <>
            <Link onClick={handleShow} >
                <span className="B1piuH">
                    <i class="mdi mdi-arrow-collapse-up"></i>
                </span>
                <span className="rglmnm">Upload Report</span>
            </Link>

            <Modal show={show} onHide={crossClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formFile" id="file-upload-form" className="uploader">


                        <Form.Control required type="file"
                            //value={upLoad}
                            id="file-upload" name="fileUpload" accept="docx/*,pdf/*" onChange={(e) => {
                                setUpload(e.target.files[0])
                            }} />
                        <Form.Label for="file-upload" id="file-drag">
                            <img id="file-image"

                                //src={iconzip}

                                alt="Preview" className="hidden" />
                            <div id="start">
                                <i className="fa fa-download" aria-hidden="true"></i>
                                <div>Select File or Drag Here</div>
                                <div id="notimage" className="hidden">Please select Word or PDF File</div>
                                <span id="file-upload-btn" className="btn btn-primary">Select file</span>
                            </div>
                            <div id="response" className="hidden">
                                <div id="messages"></div>

                            </div>
                        </Form.Label>




                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={crossClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        {
                            load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Uploading...</div> : "Upload"
                        }

                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddReport;