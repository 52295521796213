import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import DocFile from "../docfile"
import Form from 'react-bootstrap/Form'
import api from "../../../apiUrl";
import jwt_decode from "jwt-decode";
function DependedCreateProfile() {
    const [validated, setValidated] = useState(false);
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    var apis = api()
    // const param = useParams();
    //const { name } = param;
    const location = useLocation();
    var doc = location.state.doc;
    console.log("Case ID", location.state.caseId)

    var dates = new Date(location.state.createOn)
    var split = dates.toString().split('GMT')
    var dateString = split[0].trim()
    console.log("dateString", location.state.createOn)


    // const [data, setData] = useState([]);
    //Candidate Provided Details States
    const [name, setName] = useState("");
    const [placeOfBirth, setPlaceOfBirth] = useState("");
    const [dob, setDob] = useState("");
    const [ppId, setPpId] = useState("");
    const [idCard, setidCard] = useState("");
    const [netid, setNetid] = useState("");
    const [knowAddress, setKnowAddress] = useState("");
    const [corporateAffiliations, setcorporateAffiliations] = useState("");

    //Type of Inquiries States
    //const [sourcesSearched, setSourcesSearched] = useState("");
    const [civilLitigation, setCivilLitigation] = useState("");
    const [criminialRecord, setcriminialRecord] = useState("");
    const [internationalEmbassiesMission, setInternationalEmbassiesMission] = useState("");
    const [globalRegulatoryData, setGlobalRegulatoryData] = useState("");
    const [bankruptcyRecords, setBankruptcyRecords] = useState("");
    const [globalSanctionsWatchlists, setGlobalSanctionsWatchlists] = useState("");
    const [globalLawEnforcementData, setGlobalLawEnforcementData] = useState("");
    const [globalPEHPpFgod, setGlobalPEHPpFgod] = useState("");
    const [adverseMediaInternetSearches, setAdverseMediaInternetSearches] = useState("");

    //Date of Birth, Nationality, and Address Details States
    const [dob1, setFormThreeDob] = useState("");

    const [pob1, setFormThreePlaceOfBirth] = useState("");

    const [natId, setNatId] = useState("");

    const [currentAddress, setCurrentAddress] = useState("");


    //Security Risk Matrix States
    const [civilLitigationInvestigation, setCivilLitigationInvestigation] = useState("");
    const [criminalRecordInvestigation, setCriminalRecordInvestigation] = useState("");
    const [bankruptcyInvestigation, setBankruptcyInvestigation] = useState("");
    const [corporateInterestsInvestigation, setCorporateInterestsInvestigation] = useState("");
    const [worldComplianceInvestigation, setWorldComplianceInvestigation] = useState("");
    const [blacklistInvestigation, setBlacklistInvestigation] = useState("");
    const [mediaInternetSearches, setMediaInternetSearches] = useState("");
    const [conclusion, setConclusion] = useState("");
    const [backgroundInformation, setBackgroundInformation] = useState("");
    const [derogatoryInformation, setDerogatoryInformation] = useState("");
    const [employmentStatusSourcesIncome, setEmploymentStatusSourcesIncome] = useState("");
    const [companiesUndisclosed, setCompaniesUndisclosed] = useState("");
    const [mostImportantPersonsC, setMostImportantPersonsC] = useState("");
    const [documentsSubmitted, setDocumentsSubmitted] = useState("");
    const [internationalBansred, setInternationalBansred] = useState("");
    const [val, setVal] = useState("");
    const [natval, setNatVal] = useState("");
    const [textval, setTextVale] = useState("");
    const [birthval, setbirthtextval] = useState("");

    const numberValidated = (e) => {
        console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setVal(e.target.value);
        }
    };
    const natnumberValidated = (e) => {
        console.log("e.target.value", e.target.value)
        const regex = /^[0-9\b]+$/;
        //console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setNatVal(e.target.value);
        }
    };

    const onlyTextValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setTextVale(e.target.value);
        }
    };
    const birthTextValidated = (e) => {
        //console.log("e.target.value", e.target.value)
        const regex = /^[a-zA-Z ]*$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            console.log("e.target.value", e.target.value)
            setbirthtextval(e.target.value);
        }
    };

    //Post Data Form JSON formate

    // var postDataJson = {
    //     id: "1",
    //     createBy: location.state.createBy,
    //     createOn: location.state.createOn,
    //     candidateProvidedDetails: {
    //         name: name,
    //         placeOfBirth: placeOfBirth,
    //         dob: dob,
    //         ppId: ppId,
    //         idCard: idCard,
    //         knowAddress: knowAddress,
    //         corporateAffiliations: corporateAffiliations,
    //     },
    //     typeOfInquiries: {
    //         civilLitigation: civilLitigation,
    //         bankruptcyRecords: bankruptcyRecords,
    //         globalSanctionsWatchlists: globalSanctionsWatchlists,
    //         globalLawEnforcementData: globalLawEnforcementData,
    //         globalPEHPpFgod: globalPEHPpFgod,
    //         adverseMediaInternetSearches: adverseMediaInternetSearches,
    //     },
    //     dobNationalityAddress: {
    //         dob1: dob1,
    //         pob1: pob1,
    //         natId: natId,
    //         currentAddress: currentAddress,

    //     },
    //     securityRiskMatrix: {
    //         civilLitigationInvestigation: civilLitigationInvestigation,
    //         criminalRecordInvestigation: criminalRecordInvestigation,
    //         bankruptcyInvestigation: bankruptcyInvestigation,
    //         corporateInterestsInvestigation: corporateInterestsInvestigation,
    //         worldComplianceInvestigation: worldComplianceInvestigation,
    //         blacklistInvestigation: blacklistInvestigation,
    //         mediaInternetSearches: mediaInternetSearches,
    //         conclusion: conclusion,
    //         backgroundInformation: backgroundInformation,
    //         derogatoryInformation: derogatoryInformation,
    //         employmentStatusSourcesIncome: employmentStatusSourcesIncome,
    //         companiesUndisclosed: companiesUndisclosed,
    //         mostImportantPersonsC: mostImportantPersonsC,
    //         documentsSubmitted: documentsSubmitted,
    //         internationalBansred: internationalBansred,
    //     }
    // };


    var createBy = location.state.createBy;
    var createOn = location.state.createOn;
    console.log(location.state)

    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id

    var postDataJson = {
        "createBy": createBy,
        "createOn": "2023-03-03T10:31:13.738Z",
        "caseId": location.state.caseId,
        "dependentId": location.state.dependentId,
        "name": name,
        "placeOfBirth": placeOfBirth,
        "dateOfBirth": "2022-03-14T09:42:08.790Z",
        "ppId": ppId,
        "idCard": idCard,
        "knowAddress": knowAddress,
        "currentAddress": currentAddress,
        "corporateAffiliations": corporateAffiliations,
        "civilLitigationInvestigation": civilLitigationInvestigation,
        "criminalLitigation": "bdbdfb",
        "criminalRecord": criminialRecord,
        "criminalLitigationInvestigation": criminalRecordInvestigation,
        "bankruptcyInvestigation": bankruptcyInvestigation,
        "corporateInterestsInvestigation": corporateInterestsInvestigation,
        "worldComplianceInvestigation": worldComplianceInvestigation,
        "blacklistInvestigation": blacklistInvestigation,
        "mediaInternetSearches": mediaInternetSearches,
        "conclusion": conclusion,
        "backgroundInformation": backgroundInformation,
        "derogatoryInformation": derogatoryInformation,
        "employmentStatusSourcesIncome": employmentStatusSourcesIncome,
        "companiesUndisclosed": companiesUndisclosed,
        "mostImportantPersonsC": mostImportantPersonsC,
        "documentsSubmitted": documentsSubmitted,
        "internationalBansred": internationalBansred,
        "civilLitigation": civilLitigation,
        "bankruptcyRecords": bankruptcyRecords,
        "globalSanctionsWatchlists": globalSanctionsWatchlists,
        "globalLawEnforcementData": globalLawEnforcementData,
        "globalPEHPpFgod": globalPEHPpFgod,
        "adverseMediaInternet": adverseMediaInternetSearches,
        "adverseMediaInternetSearches": "adverseMediaInternetSearches",
        "internationalEmbassisesMission": internationalEmbassiesMission,
        "globalRegulatory": globalRegulatoryData,
        "natID": netid,
        "CurrentUserId": currentUserId,

    }
    //var obj = {name:"Ayush",lastName:"Sengar"}
    function postData() {
        setLoad(true)
        console.log(JSON.stringify(postDataJson))
        console.log("postDataJson", postDataJson)

        fetch(`${apis}Admin/CreateProfileForDependent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postDataJson),
        }).then((respon) => {
            respon.json().then((result) => {
                console.log(result);
                setLoad(false)
                navigate('/')
            })
        })

    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            console.log("postDataJson", postDataJson)
            if (
                !name === false &&
                !placeOfBirth === false &&
                !ppId === false &&
                !idCard === false &&
                !netid === false &&
                !knowAddress === false &&
                !currentAddress === false &&
                !corporateAffiliations === false &&
                !civilLitigationInvestigation === false &&
                !criminialRecord === false &&
                !internationalEmbassiesMission === false &&
                !globalRegulatoryData === false &&
                !criminalRecordInvestigation === false &&
                !bankruptcyInvestigation === false &&
                !corporateInterestsInvestigation === false &&
                !worldComplianceInvestigation === false &&
                !blacklistInvestigation === false &&
                !mediaInternetSearches === false &&
                !conclusion === false &&
                !backgroundInformation === false &&
                !derogatoryInformation === false &&
                !employmentStatusSourcesIncome === false &&
                !companiesUndisclosed === false &&
                !mostImportantPersonsC === false &&
                !documentsSubmitted === false &&
                !internationalBansred === false &&
                !civilLitigation === false &&
                !bankruptcyRecords === false &&
                !globalSanctionsWatchlists === false &&
                !globalLawEnforcementData === false &&
                !globalPEHPpFgod === false &&
                !netid === false &&
                !criminialRecord === false &&
                !internationalEmbassiesMission === false &&
                !globalRegulatoryData === false &&
                !adverseMediaInternetSearches === false) {
                console.log("good")
                postData()
            } else {
                console.log("not")
            }

        }

        setValidated(true);
    };

    useEffect(() => {
        // var modalBackdrop = document.getElementsByClassName("modal-backdrop");
        // if (modalBackdrop.length > 0) {
        //     modalBackdrop[0].classList.remove('show')
        // }
    }, [])
    return (<>

        <div className="border-bottom px-3 pt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#!">Documents</a>
                                    </li>
                                    <li className="breadcrumb-item active">Create Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row align-it">
                <div className="col-md-12 py-2">
                    <div className="row align-it">
                        <div className="col-md-12 text-center d-flex">
                            <p className="card-text mb-0 px-2">Dependent Name : {location.state.name} &nbsp; &nbsp; |</p>
                            <p className="card-text mb-0 px-2">Created By: {location.state.createBy} &nbsp; &nbsp; |</p>
                            <p className="card-text mb-0 px-2">Created On: {location.state.createOn}</p>
                        </div>
                    </div>
                    <div className="row align-it mt-4">
                        {
                            doc.map((item) =>
                                <DocFile fileType={item.fileType} fileName={item.fileName} filePath={item.filePath} />
                            )
                        }
                    </div>
                </div>
            </div>
            <Form noValidate validated={validated} >
                <div id="accordion" className="custom-accordion mt-4">
                    <div className="card mb-1 shadow-none">
                        <a href="#collapseOne" className="text-dark" data-bs-toggle="collapse" aria-expanded="false"
                            aria-controls="collapseOne">
                            <div className="card-header" id="headingOne">
                                <h6 className="m-0">
                                    Candidate Provided Details
                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordion">
                            <div className="card-body p-0">
                                <div className="">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom01">
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={textval}
                                                        maxLength="50"
                                                        id="validationCustom01"
                                                        onChange={(e) => {
                                                            onlyTextValidated(e)
                                                            setName(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom02">
                                                    <Form.Label>Place of Birth</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom02"
                                                        value={birthval}
                                                        maxLength="50"
                                                        onChange={(e) => {
                                                            birthTextValidated(e)
                                                            setPlaceOfBirth(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom03">
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="date"
                                                        id="validationCustom03"
                                                        data-provide="datepicker"
                                                        data-date-container="#datepicker3"
                                                        data-date-format="dd M, yyyy" data-date-multidate="true"
                                                        onChange={(e) => setDob(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Choose a date
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom04">
                                                    <Form.Label>P.P. ID</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom04"
                                                        maxLength="50"
                                                        onChange={(e) => setPpId(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom05">
                                                    <Form.Label>ID Card</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom05"
                                                        value={val}
                                                        maxLength="10"
                                                        onChange={(e) => {
                                                            numberValidated(e)
                                                            setidCard(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">

                                                <Form.Group className="mb-3" controlId="validationCustom05-1">
                                                    <Form.Label>Nat. ID</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom05"
                                                        value={natval}
                                                        maxLength="10"
                                                        onChange={(e) => {
                                                            natnumberValidated(e)
                                                            setNetid(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom06">
                                                    <Form.Label>Known Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        maxLength="200"
                                                        id="validationCustom06"
                                                        onChange={(e) => setKnowAddress(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom08">
                                                    <Form.Label>Current Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        id="validationCustom08"
                                                        maxLength="200"
                                                        onChange={(e) => setCurrentAddress(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form.Group className="mb-3" controlId="validationCustom07">
                                                    <Form.Label>Corporate Affiliations</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        type="textarea"
                                                        id="validationCustom07"
                                                        maxLength="680"
                                                        onChange={(e) => setcorporateAffiliations(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------- */}
                    <div className="card mb-1 shadow-none">
                        <a href="#collapseTwo" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                            aria-controls="collapseTwo">
                            <div className="card-header" id="headingTwo">
                                <h6 className="m-0">
                                    Type of Inquiries
                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                            <div className="card-body  p-0">
                                <div className="">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom09">
                                                    <Form.Label>Civil Litigation</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom09"
                                                        maxLength="50"
                                                        onChange={(e) => setCivilLitigation(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom10-1">
                                                    <Form.Label>Criminial Record</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom10"
                                                        maxLength="50"
                                                        onChange={(e) => setcriminialRecord(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>

                                        </div>
                                        <div className="row"> <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="validationCustom14">
                                                <Form.Label>Bankruptcy records</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    id="validationCustom14"
                                                    maxLength="50"
                                                    onChange={(e) => setBankruptcyRecords(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill this field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                            <div className="col-md-6 col-12">
                                                <Form.Group className="mb-3" controlId="validationCustom11">
                                                    <Form.Label>Global Sanctions and  Watchlists:</Form.Label>
                                                    <Form.Control required as="select" type="select"
                                                        id="validationCustom11"
                                                        onChange={(e) => setGlobalSanctionsWatchlists(e.target.value)}
                                                    ><option value="">Select valid Option</option>
                                                        <option value="Global Sanction Database (GSD)">Global Sanction Database (GSD)</option>
                                                        <option value="Office of Foreign Access Control (OFAC)">Office of Foreign Access Control (OFAC)</option>
                                                        <option value="Excluded Parties List System (EPLS)">Excluded Parties List System (EPLS)</option>
                                                        <option value="U.S. Department of State">U.S. Department of State</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                        </div>



                                        <div className="row">

                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom13">
                                                    <Form.Label>Global Law Enforcement Data</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom13"
                                                        maxLength="50"
                                                        onChange={(e) => setGlobalLawEnforcementData(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom09-1">
                                                    <Form.Label>International Embassies/Mission</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom09"
                                                        maxLength="50"
                                                        onChange={(e) => setInternationalEmbassiesMission(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom10-2">
                                                    <Form.Label>Global Regulatory Data</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom10"
                                                        maxLength="50"
                                                        onChange={(e) => setGlobalRegulatoryData(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom14">
                                                    <Form.Label>Global P.E.P/High-Profile persons/ Foreign Government Official Data</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom14"
                                                        maxLength="50"
                                                        onChange={(e) => setGlobalPEHPpFgod(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>

                                        </div>
                                        <div className="row">


                                            <div className="col-md-6">
                                                <Form.Group className="mb-3" controlId="validationCustom15">
                                                    <Form.Label>Adverse Media and Internet Searches</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="validationCustom16"
                                                        maxLength="50"
                                                        onChange={(e) => setAdverseMediaInternetSearches(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill this field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------- */}
                    {/* <div className="card mb-0 shadow-none">
                        <a href="#collapseThree" className="text-dark collapsed" data-bs-toggle="collapse"
                            aria-expanded="false" aria-controls="collapseThree">
                            <div className="card-header" id="headingThree">
                                <h6 className="m-0">
                                    Date of Birth, Nationality, and Address Details

                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Date of Birth</label>
                                            <div className="input-group" id="datepicker3">
                                                <input type="date" className="form-control" placeholder="dd/mm/yyyy"
                                                    data-provide="datepicker" data-date-container="#datepicker3"
                                                    data-date-format="dd M, yyyy" data-date-multidate="true"
                                                    onChange={(e) => setFormThreeDob(e.target.value)}
                                                />

                                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Place of Birth</label>

                                            <input type="text" className="form-control"
                                                onChange={(e) => setFormThreePlaceOfBirth(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                </div>

                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Nat. ID </label>

                                            <input type="text" className="form-control"
                                                onChange={(e) => setNatId(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label for="validationCustom01" className="form-label">Current
                                                Address </label>

                                            <input type="text" className="form-control"
                                                onChange={(e) => setCurrentAddress(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>

                    </div> */}
                    {/* ------------------------------------------------------------------------------------------- */}
                    <div className="card mb-0 shadow-none mb-3">
                        <a href="#collapseFour" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                            aria-controls="collapseFour">
                            <div className="card-header" id="headingFour">
                                <h6 className="m-0">
                                    Security Risk Matrix

                                    <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                </h6>
                            </div>
                        </a>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                            <div className="card-body ">

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom16">
                                            <Form.Label>Civil Litigation Investigation</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom16"
                                                maxLength="680"
                                                onChange={(e) => setCivilLitigationInvestigation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom17">
                                            <Form.Label>Criminal Record Investigation</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom17"
                                                maxLength="680"
                                                onChange={(e) => setCriminalRecordInvestigation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom18">
                                            <Form.Label>Bankruptcy Investigation </Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom18"
                                                maxLength="680"
                                                onChange={(e) => setBankruptcyInvestigation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom19">
                                            <Form.Label>Corporate Interests Investigation</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom19"
                                                maxLength="680"
                                                onChange={(e) => setCorporateInterestsInvestigation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom20">
                                            <Form.Label>World Compliance Investigation</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom20"
                                                maxLength="680"
                                                onChange={(e) => setWorldComplianceInvestigation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom20">
                                            <Form.Label>Blacklist Investigation </Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom20"
                                                maxLength="680"
                                                onChange={(e) => setBlacklistInvestigation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom21">
                                            <Form.Label>Media and Internet Searches</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom21"
                                                maxLength="680"
                                                onChange={(e) => setMediaInternetSearches(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom22">
                                            <Form.Label>Conclusion</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom22"
                                                maxLength="680"
                                                onChange={(e) => setConclusion(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom23">
                                            <Form.Label>Background Information </Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom23"
                                                maxLength="680"
                                                onChange={(e) => setBackgroundInformation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom24">
                                            <Form.Label>Derogatory Information</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom24"
                                                maxLength="680"
                                                onChange={(e) => setDerogatoryInformation(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom25">
                                            <Form.Label>Employment Status and Sources of Income</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom25"
                                                maxLength="680"
                                                onChange={(e) => setEmploymentStatusSourcesIncome(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom26">
                                            <Form.Label>Companies Undisclosed</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom26"
                                                maxLength="680"
                                                onChange={(e) => setCompaniesUndisclosed(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom27">
                                            <Form.Label>Most important persons/companies with whom the applicant does business</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom27"
                                                maxLength="680"
                                                onChange={(e) => setMostImportantPersonsC(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom28">
                                            <Form.Label>Documents Submitted </Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="textarea"
                                                id="validationCustom28"
                                                maxLength="680"
                                                onChange={(e) => setDocumentsSubmitted(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Group className="mb-3" controlId="validationCustom29">
                                            <Form.Label>International bans and “red” or “restrictive lists of international law enforcement bodies</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                id="validationCustom29"
                                                maxLength="680"
                                                onChange={(e) => setInternationalBansred(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill this field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">  </div>

                                    <div className="col-md-6 text-right">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <button onClick={handleSubmit} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                        data-qa-tag="uploader-drop-zone-button">
                        {
                            load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                        }
                        Submit</button>
                </div>
            </Form>
        </div >

    </>)
}

export default DependedCreateProfile