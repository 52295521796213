import { Link } from "react-router-dom";
import DocFile from "./docfile"
import jwt_decode from "jwt-decode";
import AddReport from "./addReport";
import AddNewFile from "./addNewFile";
function ModulePopup(prop) {
    // var statusName = {
    //     verified: "text-success",
    //     NotStarted: "text-danger",
    //     UnderProcess: "text-warning",
    //     ProcessCompleted: "text-primary",
    // }
    var statusName = ["VERIFIED",
        "PENDING",
        "UNDER PROCESS",
        "PROCESS COMPLETED", "null"]
    var statusNameIcon = {
        verified: "fas fa-check-circle",
        NotStarted: "fa fa-times-circle",
        UnderProcess: "fas fa-check-circle",
        ProcessCompleted: "fas fa-check-circle",
    }
    const jwtDecode = jwt_decode(localStorage.getItem("token"))
    var rol = jwtDecode.RoleId;
    const currentUserId = jwtDecode.Id

    var arr = [1, 2, 3]
    var dependentfiles = prop.dfiles;
    // console.log("dependentfiles", dependentfiles)

    const downloadFile = (c, d) => {

        // using Java Script method to get PDF file
        fetch(`${prop.apis}Admin/GetZipFileForDependent/${c}/${d}`).then((respons) => {
            respons.text().then((result) => {


                var fileDownload = document.createElement("a");
                document.body.appendChild(fileDownload);
                fileDownload.href = prop.fileUrl + result;
                fileDownload.target = "_blank";
                fileDownload.download = `Report - ${prop.buttonName}.pdf`;
                fileDownload.click();
                document.body.removeChild(fileDownload);
            })
        })
    }


    function createNavi() {

    }
    return (
        <>
            <div className="modal fade" id={`dependentName${prop.dependentId}`} aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel">Dependent</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row align-it">
                                <div className="col-md-3 text-center">
                                    <div className=" bg-light p-3">

                                        <h5 className="pt-3"> {prop.buttonName}</h5>
                                        <p>{prop.buttonStatus}</p>
                                        <div className="_8pVqqXr text-center" data-qa-tag="tag_name">
                                            <span className="PHDg5+ "><i className={`fa fa-circle ${prop.status == statusName[0] ? "text-success" : prop.status == statusName[1] ? "text-danger" : prop.status == statusName[2] ? "text-warning" : prop.status == statusName[3] ? "text-primary" : ""}`}
                                                aria-hidden="true"></i>
                                                {prop.status}</span>
                                        </div>

                                        {prop.buttonStatus && (rol == "1" || rol == "2") ? <button className="create-btn-model" data-bs-dismiss="modal" aria-label="Close"><Link to="/dependentViewProfile" state={{ dependentId: prop.dependentId, createBy: prop.createBy, createOn: prop.dependentcreateOn, caseId: prop.caseId, id: prop.caseId, doc: prop.dfiles, dependent: true, clientName: prop.clientName, caseName: prop.caseName, roles: prop.roles }} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button" data-qa-tag="uploader-drop-zone-button">View Profile</Link></button> :
                                            rol == "1" || rol == "2" ?
                                                <button className="create-btn-model" data-bs-dismiss="modal" aria-label="Close">
                                                    <Link to="/confidential" state={
                                                        {
                                                            createBy: prop.createBy,
                                                            createOn: prop.dependentcreateOn,
                                                            doc: prop.dfiles,
                                                            dependentId: prop.dependentId,
                                                            caseId: prop.caseId,
                                                            id: prop.caseId,
                                                            name: prop.buttonName,
                                                            dependent: true,
                                                            caseName: prop.caseName,


                                                        }

                                                    } className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" createBy={prop.createBy} >Create Profile</Link></button> : ""}
                                    </div>
                                </div>
                                <div className="col-md-9 py-2">
                                    <div className="row align-it">
                                        <div className="col-md-8 text-center d-flex">
                                            <p class="card-text mb-0 px-2"> Relation: {prop.relationName} &nbsp;&nbsp; |</p>
                                            <p className="card-text mb-0 px-2">Created On: {prop.dependentcreateOn}</p>
                                        </div>
                                        <div className="col-md-4 text-center d-flex">
                                            <ul style={{ listStyle: "none", display: "flex", paddingLeft: "0", marginLeft: 'auto' }}>
                                                {
                                                    prop.dfiles.length === 0 ? "" :
                                                        <button onClick={() => downloadFile(prop.caseId, prop.dependentId,)} style={{ background: 'transparent', border: 'none', color: '#374498', marginRight: "15px" }}>
                                                            <span className="B1piuH">
                                                                <i class="mdi mdi-arrow-collapse-down"></i>
                                                            </span>
                                                            <span className="rglmnm">Download Files</span>
                                                        </button>
                                                }

                                                <li>{
                                                    prop.role == 1 && prop.status == "CLOSED" ? <AddReport caseId={prop.caseId} dependentId={prop.dependentId} currentUserId={currentUserId} dependentStatus={true} /> :
                                                        //    prop.role == 3 ? <AddNewFile caseId={prop.caseId} currentUserId={currentUserId} reload={prop.reload} dependentId={prop.dependentId} dependentStatus={true} /> :
                                                        ""
                                                }</li>
                                                <li>{
                                                    // prop.role == 1 ? <AddReport caseId={prop.caseId} dependentId={prop.dependentId} currentUserId={currentUserId} dependentStatus={true} /> :
                                                    prop.role == 3 ? <AddNewFile caseId={prop.caseId} currentUserId={currentUserId} reload={prop.reload} dependentId={prop.dependentId} dependentStatus={true} /> :
                                                        ""
                                                }</li>
                                            </ul>


                                        </div>
                                    </div>
                                    <div className="row align-it mt-4">
                                        {prop.dfiles.map((item) =>
                                            <DocFile fileID={item.id} fileType={item.fileType} fileName={item.fileName} filePath={item.filePath} dependent={prop.dependent} dependentId={prop.dependentId} delete={prop.delete} role={prop.role} caseId={prop.caseId} reload={prop.reload} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button className="btn btn-primary" data-bs-target="#exampleModalToggle2"
                                data-bs-toggle="modal" data-bs-dismiss="modal">Open second
                                modal</button>
                        </div> */}
                    </div>
                </div>
            </div>

            <a className={prop.classList} data-bs-toggle="modal" href={`#dependentName${prop.dependentId}`} role="button">
                <span className="badge bg-success">{prop.buttonName}<br /><span className={`${prop.status == statusName[0] ? "text-success" : prop.status == statusName[1] ? "text-danger" : prop.status == statusName[2] ? "text-warning" : prop.status == statusName[3] ? "text-primary" : ""} my-verified-class`}><i className={`${prop.status == statusName[0] ? "fas fa-check-circle" : prop.status == statusName[1] ? "fa fa-times-circle" : prop.status == statusName[2] ? "fas fa-check-circle" : prop.status == statusName[3] ? "fas fa-check-circle" : prop.status == statusName[4] ? "text-danger" : ""}`} ></i> {prop.status}</span></span>
                {/* {
    prop.statusName == statusName.verified ? statusName.verified : prop.statusName == statusName.NotStarted ? statusName.NotStarted : prop.statusName == statusName.UnderProcess ? statusName.UnderProcess : prop.statusName == statusName.ProcessCompleted ? statusName.ProcessCompleted: ""
} */}
            </a>
        </>
    )
}

export default ModulePopup