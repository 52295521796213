import React, { useEffect } from 'react'
import { fontSize } from "@mui/system";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import api from "../../apiUrl";
import jwt_decode from "jwt-decode";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FamilyInquiries from './familyInquiries';
import Mipc from './mipc';
import RoughWork from './roughWork';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ViewVerification() {
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");


    // for Toaster Close on click button
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };


    var apis = api()


    const location = useLocation();
    //const [load, setLoad] = useState(false);
    const navigate = useNavigate()
    const state = location.state.profileDetails;
    const verifyData = location.state.verifyData
    const clientName = location.state.clientName
    const riskMatrix = location.state.profileDetails.clientInfo.riskMatrixKey
    console.log("View Verification verifyData", state)
    var dependentSatus = location.state.dependentSatus

    var dates = new Date(state.dateOfBirth)
    var split = dates.toString().split('T')
    var dateString = split[0].trim()
    // console.log(document.getElementsByTagName("textarea").length)
    const [disabled, setDisabled] = useState(false)
    const [caseId, setCaseId] = useState(location.state.caseId)
    const [name, setName] = useState(state.givenName);
    const [placeOfBirth, setPlaceOfBirth] = useState(state.placeOfBirth);
    const [day, setDay] = useState(state.birthOfDay);
    const [month, setMonth] = useState(state.birthOfMonth);
    const [year, setYear] = useState(state.birthOfYear);
    const [dob, setDob] = useState(state.dateOfBirth);
    const [ppId, setPpId] = useState(state.ppid);
    const [idCard, setidCard] = useState(state.idCard);
    const [netid, setNetid] = useState(state.natID);
    const [knowAddress, setKnowAddress] = useState(state.knowAddress);
    const [currentAddress, setCurrentAddress] = useState(state.currentAddress);
    const [corporateAffiliations, setcorporateAffiliations] = useState(state.corporateAffiliations);

    //Type of Inquiries States
    // const [sourcesSearched, setSourcesSearched] = useState(state.sourcesSearched);
    const [civilLitigation, setCivilLitigation] = useState(state.civilLitigation);
    const [bankruptcyRecords, setBankruptcyRecords] = useState(state.bankruptcyRecords);
    const [criminialRecord, setcriminialRecord] = useState("");
    const [internationalEmbassiesMission, setInternationalEmbassiesMission] = useState("");
    const [globalRegulatoryData, setGlobalRegulatoryData] = useState(state.globalRegulatoryData);
    const [globalSanctionsWatchlists, setGlobalSanctionsWatchlists] = useState(state.globalSanctionsWatchlists);
    const [globalLawEnforcementData, setGlobalLawEnforcementData] = useState(state.globalLawEnforcementData);
    const [globalPEHPpFgod, setGlobalPEHPpFgod] = useState(state.globalPEHPpFgod);
    const [adverseMediaInternetSearches, setAdverseMediaInternetSearches] = useState(state.adverseMediaInternetSearches);

    //Date of Birth, Nationality, and Address Details States
    const [formThreeDob, setFormThreeDob] = useState(state.formThreeDob);
    const [findingsGCSLDob, setfindingsGCSLDob] = useState(state.formThreeDob);
    const [formThreePlaceOfBirth, setFormThreePlaceOfBirth] = useState(state.formThreeDob);
    const [findingsGCSLplaceOfBirth, setFindingsGCSLplaceOfBirth] = useState(state.formThreeDob);
    const [natId, setNatId] = useState(state.formThreeDob);
    const [findingByGCSLNatId, setFindingByGCSLNatId] = useState(state.formThreeDob);

    const [findingsGCSLCurrentAddress, setFindingsGCSLCurrentAddress] = useState(state.formThreeDob);

    //Security Risk Matrix States
    const [civilLitigationInvestigation, setCivilLitigationInvestigation] = useState(state.civilLitigationInvestigation);
    const [criminalRecordInvestigation, setCriminalRecordInvestigation] = useState(state.criminalRecordInvestigation);
    const [bankruptcyInvestigation, setBankruptcyInvestigation] = useState(state.bankruptcyInvestigation);
    const [corporateInterestsInvestigation, setCorporateInterestsInvestigation] = useState(state.corporateInterestsInvestigation);
    const [worldComplianceInvestigation, setWorldComplianceInvestigation] = useState(state.worldComplianceInvestigation);
    const [blacklistInvestigation, setBlacklistInvestigation] = useState(state.blacklistInvestigation);
    const [mediaInternetSearches, setMediaInternetSearches] = useState(state.mediaInternetSearches);
    const [conclusion, setConclusion] = useState(state.conclusion);
    const [backgroundInformation, setBackgroundInformation] = useState(state.backgroundInformation);
    const [derogatoryInformation, setDerogatoryInformation] = useState(state.derogatoryInformation);
    const [employmentStatusSourcesIncome, setEmploymentStatusSourcesIncome] = useState(state.employmentStatusSourcesIncome);
    const [companiesUndisclosed, setCompaniesUndisclosed] = useState(state.companiesUndisclosed);
    const [mostImportantPersonsC, setMostImportantPersonsC] = useState(state.mostImportantPersonsC);
    const [documentsSubmitted, setDocumentsSubmitted] = useState(state.documentsSubmitted);
    const [internationalBansred, setInternationalBansred] = useState(state.internationalBansred);
    const [load, setLoad] = useState(false)

    //Verified State (Candidate Provided Details States)
    const [nameVerify, setNameVerify] = useState(verifyData.isNameVerified);
    const [placeOfBirthVerify, setPlaceOfBirthVerify] = useState(verifyData.isPlaceOfBirthVerified);
    const [dobVerify, setDobVerify] = useState(verifyData.isDateOfBirthVerified);
    const [ppIdVerify, setPpIdVerify] = useState(verifyData.isPpidVerified);
    const [idCardVerify, setidCardVerify] = useState(verifyData.isIdVerified);
    const [netidVerify, setNetidVerify] = useState(verifyData.isNatIDVerified);
    const [knowAddressVerify, setKnowAddressVerify] = useState(verifyData.isKnownAddressVerified);
    const [currentAddressVerify, setCurrentAddressVerify] = useState(verifyData.isCurrentAddressVerified);
    const [corporateAffiliationsVerify, setcorporateAffiliationsVerify] = useState(verifyData.isCorporateAfflicationVerified);
    console.log("corporateAffiliationsVerify v", corporateAffiliationsVerify)
    //Verified State (Type of Inquiries States)
    const [civilLitigationVerify, setCivilLitigationVerify] = useState(verifyData.isCivilLitigationVerified);
    const [bankruptcyRecordsVerify, setBankruptcyRecordsVerify] = useState(verifyData.isBankruptcyRecordsVerified);
    const [criminialRecordVerify, setcriminialRecordVerify] = useState(verifyData.isCriminalRecordVerified);
    const [internationalEmbassiesMissionVerify, setInternationalEmbassiesMissionVerify] = useState(verifyData.isInternationalEmbassiesMissionVerified)
    const [globalRegulatoryDataVerify, setGlobalRegulatoryDataVerify] = useState(verifyData.isGlobalRegulatoryDataVerified);
    const [globalSanctionsWatchlistsVerify, setGlobalSanctionsWatchlistsVerify] = useState(verifyData.isGlobalSanctionsVerified);
    const [globalLawEnforcementDataVerify, setGlobalLawEnforcementDataVerify] = useState(verifyData.isGlobalLawVerified);
    const [globalPEHPpFgodVerify, setGlobalPEHPpFgodVerify] = useState(verifyData.isGlobalPepVerified);
    const [adverseMediaInternetSearchesVerify, setAdverseMediaInternetSearchesVerify] = useState(verifyData.isAdverseMediaVerified);

    //Verified State (Security Risk Matrix States)
    const [civilLitigationInvestigationVerify, setCivilLitigationInvestigationVerify] = useState(verifyData.isCivilLitigationInvestigationVerified);
    const [criminalRecordInvestigationVerify, setCriminalRecordInvestigationVerify] = useState(verifyData.isCriminalRecordVerified);
    const [bankruptcyInvestigationVerify, setBankruptcyInvestigationVerify] = useState(verifyData.isBankruptcyInvestigationVerified);
    const [corporateInterestsInvestigationVerify, setCorporateInterestsInvestigationVerify] = useState(verifyData.isCorporateInterestsVerified);
    const [worldComplianceInvestigationVerify, setWorldComplianceInvestigationVerify] = useState(verifyData.isWorldComplianceVerified);
    const [blacklistInvestigationVerify, setBlacklistInvestigationVerify] = useState(verifyData.isBlacklistInvestigationVerified);
    const [mediaInternetSearchesVerify, setMediaInternetSearchesVerify] = useState(verifyData.isMediaAndInternetVerified);
    const [conclusionVerify, setConclusionVerify] = useState(false);
    const [backgroundInformationVerify, setBackgroundInformationVerify] = useState();
    const [derogatoryInformationVerify, setDerogatoryInformationVerify] = useState();
    const [employmentStatusSourcesIncomeVerify, setEmploymentStatusSourcesIncomeVerify] = useState();
    const [companiesUndisclosedVerify, setCompaniesUndisclosedVerify] = useState();
    const [mostImportantPersonsCVerify, setMostImportantPersonsCVerify] = useState();
    const [documentsSubmittedVerify, setDocumentsSubmittedVerify] = useState();
    const [internationalBansredVerify, setInternationalBansredVerify] = useState(verifyData.isInternationalBansVerified);

    //Issues State (Candidate Provided Details States)
    const [nameIssues, setNameIssues] = useState(verifyData.nameComment);
    const [placeOfBirthIssues, setPlaceOfBirthIssues] = useState(verifyData.placeOfBirthComment);
    const [dobIssues, setDobIssues] = useState(verifyData.placeOfBirthComment);
    const [ppIdIssues, setPpIdIssues] = useState(verifyData.dateOfBirthComment);
    const [idCardIssues, setidCardIssues] = useState(verifyData.idComment);
    const [netidIssues, setNetidIssues] = useState(verifyData.natIDComment);
    const [knowAddressIssues, setKnowAddressIssues] = useState(verifyData.knownAddressComment);
    const [currentAddressIssues, setcurrentAddressIssues] = useState(verifyData.currentAddressComment);
    const [corporateAffiliationsIssues, setcorporateAffiliationsIssues] = useState(verifyData.corporateAfflicationComment);
    console.log("corporateAffiliationsIssues", corporateAffiliationsIssues)
    //Issues State (Type of Inquiries States)
    const [civilLitigationIssues, setCivilLitigationIssues] = useState(verifyData.civilLitigationComment);
    const [bankruptcyRecordsIssues, setBankruptcyRecordsIssues] = useState(verifyData.bankruptcyRecordsComment);
    const [criminialRecordIssues, setcriminialRecordIssues] = useState(verifyData.criminalRecordComment);
    const [internationalEmbassiesMissionIssues, setinternationalEmbassiesMissionIssues] = useState(verifyData.internationalEmbassiesMissionComment);
    const [globalRegulatoryDataIssues, setGlobalRegulatoryDataIssues] = useState(verifyData.globalRegulatoryDataComment);
    const [globalSanctionsWatchlistsIssues, setGlobalSanctionsWatchlistsIssues] = useState(verifyData.globalSanctionsComment);
    const [globalLawEnforcementDataIssues, setGlobalLawEnforcementDataIssues] = useState(verifyData.globalLawComment);
    const [globalPEHPpFgodIssues, setGlobalPEHPpFgodIssues] = useState(verifyData.globalPepComment);
    const [adverseMediaInternetSearchesIssues, setAdverseMediaInternetSearchesIssues] = useState(verifyData.adverseMediaComment);

    //Issues State (Security Risk Matrix States)
    const [civilLitigationInvestigationIssues, setCivilLitigationInvestigationIssues] = useState(verifyData.civilLitigationInvestigation);
    const [criminalRecordInvestigationIssues, setCriminalRecordInvestigationIssues] = useState(verifyData.criminalLitigationInvestigation);
    const [bankruptcyInvestigationIssues, setBankruptcyInvestigationIssues] = useState(verifyData.bankruptcyInvestigation);
    const [corporateInterestsInvestigationIssues, setCorporateInterestsInvestigationIssues] = useState(verifyData.corporateInterestsInvestigation);
    const [worldComplianceInvestigationIssues, setWorldComplianceInvestigationIssues] = useState(verifyData.worldComplianceInvestigation);
    // const [blacklistInvestigationIssues, setBlacklistInvestigationIssues] = useState(verifyData.blacklistInvestigation);
    const [blacklistInvestigationIssues, setBlacklistInvestigationIssues] = useState(verifyData.blacklistInvestigationForPdfs);
    const [mediaInternetSearchesIssues, setMediaInternetSearchesIssues] = useState(verifyData.mediaInternetSearches);
    const [conclusionIssues, setConclusionIssues] = useState(verifyData.conclusion);
    const [backgroundInformationIssues, setBackgroundInformationIssues] = useState("");
    const [derogatoryInformationIssues, setDerogatoryInformationIssues] = useState("");
    const [employmentStatusSourcesIncomeIssues, setEmploymentStatusSourcesIncomeIssues] = useState("");
    const [companiesUndisclosedIssues, setCompaniesUndisclosedIssues] = useState(verifyData.companiesUndisclosedComment);
    const [mostImportantPersonsCIssues, setMostImportantPersonsCIssues] = useState("");
    const [documentsSubmittedIssues, setDocumentsSubmittedIssues] = useState("");
    const [internationalBansredIssues, setInternationalBansredIssues] = useState(verifyData.internationalBansComment);
    //Add New Cooment According to new Report
    const [investigatorReportComment, setInvestigatorReportComment] = useState(verifyData.investigatorReportComment);
    const [riskManagementReviewComment, setRiskManagementReviewComment] = useState(verifyData.riskManagementReviewComment);
    const [src1, setSrc1] = useState(verifyData.securityRiskMatrixComment.split('-')[0]);
    const [src2, setSrc2] = useState(verifyData.securityRiskMatrixComment.split('-')[1]);
    const [securityRiskMatrixComment, setSecurityRiskMatrixComment] = useState();
    // src1 number validation
    const src1Validation = (e) => {
        const regex = /^[0-9\b]+$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            setSrc1(e.target.value)
        }
    };
    // src2 number validation
    const src2Validation = (e) => {
        const regex = /^[0-9\b]+$/;
        console.log(regex.test(e.target.value))
        if (e.target.value === "" || regex.test(e.target.value)) {
            setSrc2(e.target.value)

        }
    };
    //Add New Cooment According to new Report

    //Findings by GCSL
    const [netidFind, setnetidFind] = useState(verifyData.natIDFindingsbyGCSL);
    const [dobFind, setDobFind] = useState(verifyData.dateofBirthFindingsbyGCSL);
    const [placeOfBirthFind, setPlaceOfBirthFind] = useState(verifyData.placeofBirthFindingsbyGCSL);
    const [currentAddressFind, setCurrentAddressFind] = useState(verifyData.currentAddressFindingsbyGCSL);
    const [appendix, setAppendix] = useState(verifyData.appendix)
    const srm = [
        { "civilLitigationInvestigationIssues": civilLitigationInvestigationIssues },
        { "criminalRecordInvestigationIssues": criminalRecordInvestigationIssues },
        { "bankruptcyInvestigationIssues": bankruptcyInvestigationIssues },
        { "corporateInterestsInvestigationIssues": corporateInterestsInvestigationIssues },
        { "worldComplianceInvestigationIssues": worldComplianceInvestigationIssues },
        { "blacklistInvestigationIssues": blacklistInvestigationIssues },
        { "mediaInternetSearchesIssues": mediaInternetSearchesIssues },
        { "investigatorReportComment": location.state.verifyData.investigatorReportComment },
        { "riskManagementReviewComment": riskManagementReviewComment },
    ]
    setTimeout(() => {
        srm.map((item) => {
            console.log("srm", item)
            document.getElementById("civilLitigationInvestigation").innerHTML = civilLitigationInvestigationIssues
            document.getElementById("criminalRecordInvestigation").innerHTML = criminalRecordInvestigationIssues
            document.getElementById("bankruptcyInvestigation").innerHTML = bankruptcyInvestigationIssues
            document.getElementById("corporateInterestsInvestigation").innerHTML = corporateInterestsInvestigationIssues
            document.getElementById("worldComplianceInvestigation").innerHTML = worldComplianceInvestigationIssues
            document.getElementById("blacklistInvestigation").innerHTML = blacklistInvestigationIssues
            document.getElementById("mediaandInternetSearches").innerHTML = mediaInternetSearchesIssues
            document.getElementById("investigatorReport").innerHTML = investigatorReportComment
            document.getElementById("riskManagementReview").innerHTML = riskManagementReviewComment
            document.getElementById("appendix").innerHTML = appendix
        })

    }, 50)

    //Json Data
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id


    const [totalRisk, setTotalRisk] = useState(verifyData.totalRisk)
    const [tableData, setTableData] = useState([])

    function getCategoryForVerification() {
        fetch(`${apis}Admin/GetCategoryForVerification/${caseId}`).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                setTableData(result.data)
            })
        })
    }
    const [tableDatakey, setTableDatakey] = useState([])
    function getInformationRiskMatrix() {
        fetch(`${apis}Admin/GetInformationRiskMatrix/${caseId}`).then((respon) => {
            respon.json().then((result) => {
                console.log("GetCategory", result)
                setTableDatakey(result.data)
            })
        })
    }
    useEffect(() => {
        getCategoryForVerification()
        getInformationRiskMatrix()
    }, [])



    var json = {
        "caseId": caseId,
        "isNameVerified": nameVerify,
        "nameComment": nameIssues,
        "isPlaceOfBirthVerified": placeOfBirthVerify,
        "placeOfBirthComment": placeOfBirthIssues,
        "isDateOfBirthVerified": dobVerify,
        "dateOfBirthComment": dobIssues,
        "dateofBirthFindingsbyGCSL": dobFind,
        "isPpidVerified": ppIdVerify,
        "ppidComment": ppIdIssues,
        "isIdVerified": idCardVerify,
        "idComment": idCardIssues,
        "isKnownAddressVerified": knowAddressVerify,
        "knownAddressComment": knowAddressIssues,
        "IsCurrentAddressVerified": currentAddressVerify,
        "CurrentAddressComment": currentAddressIssues,
        "currentAddressFindingsbyGCSL": currentAddressFind,
        "isCorporateAffiliationsVerified": corporateAffiliationsVerify,
        "corporateAffiliationsComment": corporateAffiliationsIssues,
        "isSourceSearchedVerified": true,
        "isCivilLitigationVerified": civilLitigationVerify,
        "civilLitigationComment": civilLitigationInvestigationIssues,
        "isBankruptcyRecordsVerified": bankruptcyRecordsVerify,
        "bankruptcyRecordsComment": bankruptcyRecordsIssues,
        "isGlobalSanctionsVerified": globalSanctionsWatchlistsVerify,
        "globalSanctionsComment": globalSanctionsWatchlistsIssues,
        "isGlobalLawVerified": globalLawEnforcementDataVerify,
        "globalLawComment": globalLawEnforcementDataIssues,
        "isGlobalPepVerified": globalPEHPpFgodVerify,
        "globalPepComment": globalPEHPpFgodIssues,
        "isAdverseMediaVerified": adverseMediaInternetSearchesVerify,
        "adverseMediaComment": adverseMediaInternetSearchesIssues,
        "isCivilLitigationInvestigationVerified": civilLitigationInvestigationVerify,
        "civilLitigationInvestigationComment": civilLitigationInvestigationIssues,
        "isCriminalRecordVerified": criminalRecordInvestigationVerify,
        "criminalRecordComment": criminalRecordInvestigationIssues,
        "isBankruptcyInvestigationVerified": bankruptcyInvestigationVerify,
        "bankruptcyInvestigationComment": bankruptcyInvestigationIssues,
        "isCorporateInterestsVerified": corporateInterestsInvestigationVerify,
        "corporateInterestsComment": corporateInterestsInvestigationIssues,
        "isWorldComplianceVerified": worldComplianceInvestigationVerify,
        "worldComplianceComment": worldComplianceInvestigationIssues,
        "isBlacklistInvestigationVerified": blacklistInvestigationVerify,
        "blacklistInvestigationComment": blacklistInvestigationIssues,
        "isMediaAndInternetVerified": mediaInternetSearchesVerify,
        "mediaAndInternetComment": mediaInternetSearchesIssues,
        //"isConclusionVerified": conclusionVerify,
        //"conclusionComment": conclusionIssues,
        // "isBackgroundInformationVerified": backgroundInformationVerify,
        //"backgroundInformationComment": backgroundInformationIssues,
        //"isDerogatoryInformationVerified": derogatoryInformationVerify,
        //"derogatoryInformationComment": derogatoryInformationIssues,
        //"isEmploymentStatusVerified": employmentStatusSourcesIncomeVerify,
        //"employmentStatusComment": employmentStatusSourcesIncomeIssues,
        "isCompaniesUndisclosedVerified": companiesUndisclosedVerify,
        "companiesUndisclosedComment": companiesUndisclosedIssues,
        //"isImportantPersonsVerified": mostImportantPersonsCVerify,
        //"importantPersonsComment": mostImportantPersonsCIssues,
        //"isDocumentsSubmittedVerified": documentsSubmittedVerify,
        // "documentsSubmittedComment": documentsSubmittedIssues,
        "isInternationalBansVerified": internationalBansredVerify,
        "internationalBansComment": internationalBansredIssues,
        "placeofBirthFindingsbyGCSL": placeOfBirthFind,
        "natIDFindingsbyGCSL": netidFind,
        "isNatIDVerified": netidVerify,
        "natIDComment": netidIssues,
        "isInternationalEmbassiesMissionVerified": internationalEmbassiesMissionVerify,
        "isGlobalRegulatoryDataVerified": globalRegulatoryDataVerify,
        "globalRegulatoryDataComment": globalRegulatoryDataIssues,
        "internationalEmbassiesMissionComment": internationalEmbassiesMissionIssues,
        "isCriminalRecordVerified": criminialRecordVerify,
        "criminalRecordComment": criminialRecordIssues,
        "CriminalRecordIssue": " ",
        "CurrentUserId": currentUserId,
        //----Add new Paerameter Accdoding to new report
        "InvestigatorReportComment": investigatorReportComment,
        "RiskManagementReviewComment": riskManagementReviewComment,
        "SecurityRiskMatrixComment": `${src1}-${src2}`,

        // "globalPepComment": "skdncjk"
    }
    // disabled function
    function disabledT(e) {

        var switchId = e.target.id
        var disClass = document.getElementById(switchId)
        var disClassName = disClass.parentElement.classList.contains("swirtchcLass");
        if (disClassName == true) {
            var addTargetClass = disClass.parentElement.classList.add(switchId);
            var getClass = document.getElementsByClassName(switchId)
            var nextElementSibling = getClass[0].nextElementSibling.classList.contains("col-md-6")
            var nextElementSiblingTwo = getClass[0].nextElementSibling.classList.contains("col-md-3")
            if (nextElementSibling == true || nextElementSiblingTwo == true) {
                var classValue = `addCLassDis${switchId}`;
                var ascacs = getClass[0].nextElementSibling.classList.add(classValue)
                var addCLassDis = document.getElementsByClassName(classValue)
                var divalue = addCLassDis[0].lastChild.childNodes[1]
                //  divalue.setAttribute("value", " vdfb");
                divalue.value += ` ${''}`;
                divalue.click()
                console.log("nextElementSibling", divalue)
            } else {
                console.log("nextElementSibling False")
            }

        } else {
            disClass.parentElement.classList.remove(addTargetClass);
        }

    }

    function verification() {
        console.log("json,", JSON.stringify(json))
        setLoad(true)
        fetch(`${apis}Admin/CandidateVerification`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(json),
        }).then((respons) => {
            respons.json().then((result) => {
                console.log(result)
                setLoad(false)
                navigate('/')
            })
        })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (
                !nameIssues === false &&
                !placeOfBirthIssues === false &&
                !dobIssues === false &&
                !ppIdIssues === false &&
                !idCardIssues === false &&
                !netidIssues === false &&
                !knowAddressIssues === false &&
                !currentAddressIssues === false &&
                !corporateAffiliationsIssues === false &&
                !civilLitigationIssues === false &&
                !bankruptcyRecordsIssues === false &&
                !criminialRecordIssues === false &&
                !internationalEmbassiesMissionIssues === false &&
                !globalRegulatoryDataIssues === false &&
                !globalSanctionsWatchlistsIssues === false &&
                !globalLawEnforcementDataIssues === false &&
                !globalPEHPpFgodIssues === false &&
                !adverseMediaInternetSearchesIssues === false &&
                !civilLitigationInvestigationIssues === false &&
                !criminalRecordInvestigationIssues === false &&
                !bankruptcyInvestigationIssues === false &&
                !corporateInterestsInvestigationIssues === false &&
                !worldComplianceInvestigationIssues === false &&
                !blacklistInvestigationIssues === false &&
                !mediaInternetSearchesIssues === false &&
                //!conclusionIssues === false &&
                //!backgroundInformationIssues === false &&
                //!derogatoryInformationIssues === false &&
                //!employmentStatusSourcesIncomeIssues === false &&
                //!companiesUndisclosedIssues === false &&
                //!mostImportantPersonsCIssues === false &&
                //!documentsSubmittedIssues === false &&
                //!internationalBansredIssues === false &&
                !netidFind === false &&
                !dobFind === false &&
                !placeOfBirthFind === false &&
                !currentAddressFind === false &&
                //----Add new Paerameter Accdoding to new report
                !investigatorReportComment === false &&
                !riskManagementReviewComment === false &&
                !src1 === false &&
                !src1 === false
            ) {
                // setSecurityRiskMatrixComment(`${src1}-${src2}`)
                verification()
            } else {
                setMessage("Please fill out all required fields")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("error")//set in value in useState
            }


        }

        setValidated(true);
    };
    const renderTextWithHyperlinks = (linkValue) => {
        const textWithLinks = linkValue.replace(
            /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g,
            (match) => {
                if (match.startsWith('http')) {
                    return `<a href="${match}" target="_blank">${match}</a>`;
                } else {
                    return `<a href="http://${match}" target="_blank">${match}</a>`;
                }
            }
        );

        return { __html: linkValue };
    };
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">

                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item">View Profile</li>
                                        <li className="breadcrumb-item active">View Verification</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <Form noValidate validated={validated} >
                    <div id="accordion" className="custom-accordion mt-4">
                        <div className="card mb-1 shadow-none">
                            <a href="#collapseOne" className="text-dark" data-bs-toggle="collapse" aria-expanded="false"
                                aria-controls="collapseOne">
                                <div className="card-header" id="headingOne">
                                    <h6 className="m-0">
                                        Candidate Provided Details
                                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordion">
                                <div className="card-body p-0">
                                    <div className="">
                                        <div className="card-body ">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Name</label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={name} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input className="disabledInput" type="checkbox" id="switch1" switch="none" checked={nameVerify} />
                                                    <label for="switch1" data-on-label="Yes" data-off-label="No"></label>

                                                </div>
                                                <div className="col-md-6 ">

                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom01">
                                                        <Form.Label>Comment</Form.Label>
                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(nameIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Place of Birth</label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={placeOfBirth} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch2" switch="placeOfBirthVerify" checked={placeOfBirthVerify} />
                                                    <label for="switch2" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">

                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom02">
                                                        <Form.Label>Comment</Form.Label>
                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(placeOfBirthIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={placeOfBirthFind}
                                                            disabled
                                                            as="textarea" maxLength="25"
                                                            onChange={(e) => setPlaceOfBirthFind(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Date of Birth</label>
                                                        <div className="input-group" id="datepicker3">
                                                            <input disabled type="text" className="form-control" value={`${day} - ${month} - ${year}`} placeholder="dd/mm/yyyy"
                                                                data-provide="datepicker" data-date-container="#datepicker3"
                                                                data-date-format="dd M, yyyy" data-date-multidate="true" />
                                                            <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch3" switch="none"
                                                        checked={dobVerify}
                                                    />
                                                    <label for="switch3" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom03">
                                                        <Form.Label>Comment</Form.Label>
                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(dobIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={dobFind}
                                                            disabled
                                                            as="textarea" maxLength="15"
                                                            onChange={(e) => setDobFind(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">P.P. ID </label>
                                                        <input disabled type="text" className="form-control" value={ppId} id="validationCustom01" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch4" switch="none"
                                                        checked={ppIdVerify}
                                                    />
                                                    <label for="switch4" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom04">
                                                        <Form.Label>Comment</Form.Label>
                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(ppIdIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">ID Card </label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={idCard} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch5" switch="none" checked={idCardVerify} />
                                                    <label for="switch5" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(idCardIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Nat. ID </label>
                                                        <input disabled type="text" className="form-control" id="validationCustom01" value={netid} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch5-1" switch="none"
                                                        checked={netidVerify}
                                                    />
                                                    <label for="switch5-1" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(netidIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={netidFind}
                                                            disabled
                                                            as="textarea" maxLength="10"
                                                            onChange={(e) => setnetidFind(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Known Address </label>
                                                        <textarea disabled id="textarea" className="form-control" value={knowAddress} maxlength="225" rows="1"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch6" switch="none"
                                                        checked={knowAddressVerify} />
                                                    <label for="switch6" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom06">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(knowAddressIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Current
                                                            Address </label>
                                                        <input disabled type="text" className="form-control" value={currentAddress} />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch23" switch="none"
                                                        checked={currentAddressVerify}
                                                    />
                                                    <label for="switch23" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom07">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(currentAddressIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom05">
                                                        <Form.Label>Findings by GCSL</Form.Label>
                                                        <Form.Control
                                                            required
                                                            value={currentAddressFind}
                                                            disabled
                                                            as="textarea" maxLength="120"
                                                            onChange={(e) => setCurrentAddressFind(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Corporate Affiliations
                                                        </label>
                                                        {/* 
                                                        <textarea disabled id="textarea" className="form-control" value={corporateAffiliations} maxlength="225" rows="1"
                                                            placeholder=""></textarea> */}
                                                        <div>
                                                            <ul>
                                                                {
                                                                    state.corporateAfflicationss.map((item) =>
                                                                        <li>{item.corporateAffiliation}</li>
                                                                    )
                                                                }
                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch7" switch="none"
                                                        checked={corporateAffiliationsVerify}
                                                        disabled
                                                        onChange={(e) => {
                                                            disabledT(e)
                                                            setcorporateAffiliationsVerify(e.target.checked)
                                                        }
                                                        } />
                                                    <label for="switch7" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom08">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(corporateAffiliationsIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-1 shadow-none">
                            <a href="#collapseTwo" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                aria-controls="collapseTwo">
                                <div className="card-header" id="headingTwo">
                                    <h6 className="m-0">
                                        Type of Inquiries
                                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                <div className="card-body  p-0">
                                    <div className="">
                                        <div className="card-body">
                                            {/* <div className="row">
                                            <div className="col-md-5">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Sources searched</label>
                                                    <input type="text" className="form-control" id="validationCustom01" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-1 mt-1 swirtchcLass">
                                                <label>Verified</label>
                                                <input type="checkbox" id="switch9" switch="none" />
                                                <label for="switch9" data-on-label="Yes" data-off-label="No"></label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Comments</label>
                                                    <textarea id="textarea" className="form-control" maxlength="225" rows="1"
                                                        placeholder=""></textarea>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Civil Litigation</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch10" switch="none"
                                                        checked={civilLitigationVerify}
                                                    />
                                                    <label for="switch10" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom09">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(civilLitigationIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                            <div className="col-md-5">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Civil Litigation </label>
                                                    <input type="text" className="form-control" id="validationCustom01" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-1 mt-1 swirtchcLass">
                                                <label>Verified</label>
                                                <input type="checkbox" id="switch12" switch="none" />
                                                <label for="switch12" data-on-label="Yes" data-off-label="No"></label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label for="validationCustom01" className="form-label">Comments</label>
                                                    <textarea id="textarea" className="form-control" maxlength="225" rows="1"
                                                        placeholder=""></textarea>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Bankruptcy records </label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch13" switch="none" checked={bankruptcyRecordsVerify} />
                                                    <label for="switch13" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(bankruptcyRecordsIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Criminial Record </label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch13-1" switch="none" checked={criminialRecordVerify} />
                                                    <label for="switch13-1" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10-0">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(criminialRecordIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 col-12">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Global Sanctions and
                                                            Watchlists: </label>


                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch14" switch="none" checked={globalSanctionsWatchlistsVerify} />
                                                    <label for="switch14" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom11">
                                                        <Form.Label>Comment</Form.Label>
                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(globalSanctionsWatchlistsIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Global Law Enforcement Data
                                                        </label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch15" switch="none" checked={globalLawEnforcementDataVerify} />
                                                    <label for="switch15" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom12">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(globalLawEnforcementDataIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">International Embassies/Mission</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch13-2" switch="none" checked={internationalEmbassiesMissionVerify} />
                                                    <label for="switch13-2" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10-1">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(internationalEmbassiesMissionIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label"> Global Regulatory Data</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch13-3" switch="none" checked={globalRegulatoryDataVerify} />
                                                    <label for="switch13-3" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom10-2">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(globalRegulatoryDataIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Global P.E.P/High-Profile
                                                            persons/
                                                            Foreign Government Official Data</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch16" switch="none" checked={globalPEHPpFgodVerify} />
                                                    <label for="switch16" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom13">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(globalPEHPpFgodIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label for="validationCustom01" className="form-label">Adverse Media and Internet
                                                            Searches</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-1 mt-1 swirtchcLass">
                                                    <label>Verified</label>
                                                    <input type="checkbox" id="switch17" switch="none" checked={adverseMediaInternetSearchesVerify} />
                                                    <label for="switch17" data-on-label="Yes" data-off-label="No"></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3" md="4" controlId="validationCustom14">
                                                        <Form.Label>Comment</Form.Label>

                                                        <div className='form-control linkHTML'
                                                            dangerouslySetInnerHTML={renderTextWithHyperlinks(adverseMediaInternetSearchesIssues)}
                                                        ></div>
                                                        <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FamilyInquiries caseId={location.state.caseId} dependentSatus={dependentSatus} varification={true} dependentId={location.state.dependentId} viewOnly={true} />
                        <Mipc caseId={location.state.caseId} dependentSatus={dependentSatus} varification={true} dependentId={location.state.dependentId} viewOnly={true} />
                        <div className="card mb-0 shadow-none mb-3">
                            <a href="#collapseFour" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                aria-controls="collapseFour">
                                <div className="card-header" id="headingFour">
                                    <h6 className="m-0">
                                        Inquiry Details
                                        <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Civil Litigation
                                                    Investigation</label>

                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch25" switch="none" checked={civilLitigationInvestigationVerify} />
                                            <label for="switch25" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <div className="col-md-12">

                                            <div id="civilLitigationInvestigation"></div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Criminal Record
                                                    Investigation</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch26" switch="none" checked={criminalRecordInvestigationVerify} />
                                            <label for="switch26" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <div className="col-md-12">

                                            <div id="criminalRecordInvestigation"></div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Bankruptcy Investigation </label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch27" switch="none" checked={bankruptcyInvestigationVerify} />
                                            <label for="switch27" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <div className="col-md-12">

                                            <div id="bankruptcyInvestigation"></div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Corporate Interests
                                                    Investigation</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch28" switch="none" checked={corporateInterestsInvestigationVerify} />
                                            <label for="switch28" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div id="corporateInterestsInvestigation"></div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">World Compliance
                                                    Investigation</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch29" switch="none" checked={worldComplianceInvestigationVerify} />
                                            <label for="switch29" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div id="worldComplianceInvestigation"></div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Blacklist Investigation </label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch30" switch="none" checked={blacklistInvestigationVerify} />
                                            <label for="switch30" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <ul>
                                            {blacklistInvestigationIssues.map((item) =>
                                                <li>{item.value}</li>
                                            )}
                                        </ul>
                                        {/* <div className="col-md-12">
                                            <div id="blacklistInvestigation"></div>
                                            <hr />
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-11 col-12">
                                            <hr />
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Media and Internet
                                                    Searches</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch31" switch="none" checked={mediaInternetSearchesVerify} />
                                            <label for="switch31" data-on-label="Yes" data-off-label="No"></label>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div id="mediaandInternetSearches"></div>
                                            <hr />
                                        </div>
                                    </div>
                                    {
                                        clientName !== "St. Kitts Nevis Anguilla National Bank Ltd" ?
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12 col-12">
                                                        <hr />
                                                        <div className="">
                                                            <label for="validationCustom01" className="form-label">Investigator Report </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div id="investigatorReport"></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5 col-12">
                                                        <div className="">
                                                            <label for="validationCustom01" className="form-label">Risk Management Review</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div id="riskManagementReview"></div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5 col-12">
                                                        <div className="">
                                                            <label for="validationCustom01" className="form-label">Security Risk Matrix</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Form.Group className="mb-4" md="4" controlId="validationCustomsrc1">

                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                // id="validationCustom09"
                                                                maxLength="10"
                                                                value={src1}
                                                                disabled
                                                                onChange={(e) => {
                                                                    src1Validation(e)

                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-4" md="4" controlId="validationCustomsrc2">

                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                // id="validationCustom09"
                                                                maxLength="10"
                                                                value={src2}
                                                                disabled
                                                                onChange={(e) => {
                                                                    src2Validation(e)

                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                            </>
                                            : null}
                                    {
                                        clientName == "St. Kitts Nevis Anguilla National Bank Ltd" ?
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <Form.Group className="mb-3" controlId="validationCustom21">
                                                        <Form.Label>Conclusion</Form.Label>

                                                        <div dangerouslySetInnerHTML={{ __html: conclusionIssues }}></div>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please fill this field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                            </div> : null}
                                    {/* //--------------- */}
                                    {/* <div className="row">
                                        <div className="col-md-5 col-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Employment Status and Sources of
                                                    Income</label>
                                                <textarea disabled id="textarea" className="form-control" rows="1" value={employmentStatusSourcesIncome} placeholder=""></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch35" switch="none" onChange={(e) => {
                                                disabledT(e)
                                                setEmploymentStatusSourcesIncomeVerify(e.target.checked)
                                            }
                                            } />
                                            <label for="switch35" data-on-label="Yes" data-off-label="No"></label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" md="4" controlId="validationCustom25">
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="textarea" maxLength="170"
                                                    onClick={(e) => setEmploymentStatusSourcesIncomeIssues(e.target.value)}
                                                    onChange={(e) => setEmploymentStatusSourcesIncomeIssues(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Companies Undisclosed </label>
                                                <textarea disabled id="textarea" className="form-control" value={companiesUndisclosed} rows="1" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch36" switch="none" onChange={(e) => {
                                                disabledT(e)
                                                setCompaniesUndisclosedVerify(e.target.checked)
                                            }
                                            } />
                                            <label for="switch36" data-on-label="Yes" data-off-label="No"></label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" md="4" controlId="validationCustom26">
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="textarea" maxLength="170"
                                                    onClick={(e) => setCompaniesUndisclosedIssues(e.target.value)}
                                                    onChange={(e) => setCompaniesUndisclosedIssues(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Most important persons/companies
                                                    with whom the applicant does business</label>
                                                <textarea disabled id="textarea" className="form-control" value={mostImportantPersonsC} rows="1" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch37" switch="none" onChange={(e) => {
                                                disabledT(e)
                                                setMostImportantPersonsCVerify(e.target.checked)
                                            }
                                            } />
                                            <label for="switch37" data-on-label="Yes" data-off-label="No"></label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" md="4" controlId="validationCustom27">
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="textarea" maxLength="170"
                                                    onClick={(e) => setMostImportantPersonsCIssues(e.target.value)}
                                                    onChange={(e) => setMostImportantPersonsCIssues(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">Documents Submitted </label>
                                                <textarea disabled id="textarea" className="form-control" value={documentsSubmitted} rows="1" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch38" switch="none" onChange={(e) => {
                                                disabledT(e)
                                                setDocumentsSubmittedVerify(e.target.checked)
                                            }
                                            } />
                                            <label for="switch38" data-on-label="Yes" data-off-label="No"></label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" md="4" controlId="validationCustom28">
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="textarea" maxLength="170"
                                                    onClick={(e) => setDocumentsSubmittedIssues(e.target.value)}
                                                    onChange={(e) => setDocumentsSubmittedIssues(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12">
                                            <div className="mb-3">
                                                <label for="validationCustom01" className="form-label">International bans and “red” or
                                                    “restrictive lists of international law enforcement bodies</label>
                                                <input disabled type="text" className="form-control" value={internationalBansred} id="validationCustom01" required="" />
                                            </div>
                                        </div>
                                        <div className="col-md-1 mt-1 swirtchcLass">
                                            <label>Verified</label>
                                            <input type="checkbox" id="switch39" switch="none" onChange={(e) => {
                                                disabledT(e)
                                                setInternationalBansredVerify(e.target.checked)
                                            }
                                            } />
                                            <label for="switch39" data-on-label="Yes" data-off-label="No"></label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" md="4" controlId="validationCustom29">
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="textarea" maxLength="170"
                                                    onClick={(e) => setInternationalBansredIssues(e.target.value)}
                                                    onChange={(e) => setInternationalBansredIssues(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">Please Enter the Comment</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div> */}
                                    {/* //--------------- */}
                                </div>

                            </div>
                        </div>
                        {
                            riskMatrix == true && clientName !== "St. Kitts Nevis Anguilla National Bank Ltd" ? <>

                                <div className="card mb-3 shadow-none">
                                    <a href="#collapseTwoRiskMatrix" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="collapseTwoRiskMatrix">
                                        <div className="card-header" id="headingRiskMatrix">
                                            <h6 className="m-0">
                                                Applicant Information Risk Matrix
                                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div id="collapseTwoRiskMatrix" className="collapse" aria-labelledby="headingRiskMatrix" data-bs-parent="#accordion">
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-md-12 col-12">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr class="mipc">
                                                                <th>CATEGORY</th>
                                                                <th>FINDINGS</th>
                                                                <th width="5%">VERIFIED</th>
                                                                <th>RISK RATING</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableData.map((item) =>
                                                                    <>
                                                                        <tr>
                                                                            <td colspan="4"><b>{item.categoryName}</b></td>
                                                                        </tr>
                                                                        {
                                                                            item.field.map((items, i, index) =>
                                                                                <tr>
                                                                                    <td>{items.name}</td>
                                                                                    <td>
                                                                                        {items.finding}
                                                                                    </td>
                                                                                    <td>
                                                                                        {items.verified === true ? "Yes" : "No"}
                                                                                    </td>
                                                                                    <td>
                                                                                        {items.rating}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div></div>
                                    </div>
                                </div>


                                <div className="card mb-3 shadow-none">
                                    <a href="#collapseTwoRiskMatrixkey" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="collapseTwoRiskMatrixkey">
                                        <div className="card-header" id="headingRiskMatrixkey">
                                            <h6 className="m-0">
                                                Information Risk Matrix Key
                                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div id="collapseTwoRiskMatrixkey" className="collapse" aria-labelledby="headingRiskMatrixkey" data-bs-parent="#accordion">
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-md-12 col-12">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr class="mipc">
                                                                <th>CATEGORY</th>
                                                                <th>FINDINGS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableDatakey.map((item, i) =>
                                                                    <>
                                                                        <tr>
                                                                            <td ><b>{item.name}</b></td>
                                                                            <td>
                                                                                {item.value}
                                                                            </td>
                                                                        </tr>

                                                                    </>
                                                                )
                                                            }
                                                            <tr>
                                                                <td ><b>Total Risk</b></td>
                                                                <td>
                                                                    {totalRisk}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div></div>
                                    </div>
                                </div>







                                <div className="card mb-1 shadow-none">
                                    <a href="#collapseTwoAppendix" className="text-dark collapsed" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="collapseTwoAppendix">
                                        <div className="card-header" id="headingAppendix">
                                            <h6 className="m-0">
                                                Appendix
                                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div id="collapseTwoAppendix" className="collapse" aria-labelledby="headingAppendix" data-bs-parent="#accordion">
                                        <div class="card-body ">
                                            <section>
                                                {/* {appendix} */}
                                                <div id="appendix"></div>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                            </> : ""
                        }

                        <RoughWork caseId={caseId} currentUserId={currentUserId} view={true} />
                        <br /><br /><br /><br /><br />
                    </div>
                </Form>
                {/* <button onClick={handleSubmit} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4  my-5" type="button"
                    data-qa-tag="uploader-drop-zone-button">
                    {
                        load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                    }
                    Submit</button> */}
            </div>
            <Snackbar open={opens} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ViewVerification 