import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import DocFile from "./docfile"
import ModulePopup from "./model-popup"
import api from "../../apiUrl";
import Casecompo from "../casecompo";
import jwt_decode from "jwt-decode";

function Dashboard(prop) {
   //console.log("role id", prop.roleId)
   const roleId = prop.roleId
   const [data, setData] = useState([]);
   const [search, setSearch] = useState("");
   const [load, setLoad] = useState(false)
   const [client, setClient] = useState(0);
   const [clientData, setClientData] = useState([]);
   const [userData, setUserData] = useState([])
   const [clientId, setclientId] = useState(0)
   const [userId, setUserId] = useState(0)

   var ms3 = "ms-3"
   var apis = api()
   var jwtToken = localStorage.getItem("token")
   const jwtDecode = jwt_decode(jwtToken)
   const currentUserId = jwtDecode.Id

   function getClientAPi() {
      fetch(`${apis}Admin/GetClientList`, {
         mode: "cors",
      }).then((respon) => {
         respon.json().then((result) => {
            setClientData(result.data)
            setclientId(result.data[0].id)
         })
      })
   }
   function getUserss() {
      fetch(`${apis}Admin/GetAdminUserDashboard`).then((respons) => {
         respons.json().then((result) => {
            // console.log("getUser", result)
            setUserData(result.data)

         })
      })
   }



   function getAPi(clientIdparam) {
      // console.log("clientId getAPI", clientIdparam);
      setLoad(true)
      {
         roleId == 1 ?
            fetch(`${apis}Admin/AdminDashboard/${clientId}/${userId}`, {
               mode: "cors",
            }).then((respon) => {
               respon.json().then((result) => {
                  setData(result.data)
                  setLoad(false)
               })
            })
            :
            fetch(`${apis}Admin/AdminUserDashBoard/${currentUserId}`, {
               mode: "cors",
            }).then((respon) => {
               respon.json().then((result) => {
                  setData(result.data)
                  setLoad(false)
               })
            })
      }

   }

   useEffect(() => {
      //console.log("clientId useEffect", clientId);
      if (clientId != 0) {
         getAPi()
      }
   }, [clientId, userId])

   const [fl, setFl] = useState([])
   useEffect(() => {
      getClientAPi()
      getUserss()
   }, [])

   return (

      <>
         <div className="border-bottom px-3 pt-3">
            <div className="container-fluid">
               <div className="row">

                  <div className="col-xl-3 col-md-4">
                     <div>
                        <label className="control-label">Client  Name</label>
                        <div className="input-group" style={{ padding: '16px 0 !important' }}>
                           <select className="single-select form-select" id="inputGroupSelect05"
                              onChange={(e) => {
                                 setclientId(e.target.value)
                              }}
                              defaultValue={0}

                           >
                              <option value={clientId} selected disabled>-- Select  --</option>

                              {
                                 clientData.map((item) =>
                                    <option value={item.id} selected={item.id === clientId ? true : false}>{item.name}</option>
                                 )
                              }
                           </select>
                        </div>
                     </div>
                  </div>

                  <div className="col-xl-3 col-md-4">
                     {
                        roleId == "1" ?
                           <div>
                              <label className="control-label">GCSL Staff Name</label>
                              <div className="input-group  mb-3" style={{ padding: '16px 0 !important' }}>
                                 <select className="single-select form-select" id="inputGroupSelect05"
                                    onChange={(e) => {
                                       setUserId(e.target.value)
                                    }}
                                 >
                                    <option value="0" selected>-- Select --</option>

                                    {
                                       userData.map((item) =>
                                          <option value={item.userId}>{item.name}</option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                           : ""
                     }

                  </div>
                  <div className="col-md-6 col-12 p-0">
                     <div className="page-title-box align-items-center justify-content-between pb-0 pt-3">
                        {/* <h4 className="mb-0">Welcome back {prop.name} {api}! </h4> */}
                        {/* <p>&nbsp;</p> */}
                        {/* <p>Your last login: Today at 02:46 am</p> */}
                     </div>
                  </div>

               </div>
            </div>
         </div>
         <div className="container-fluid">
            <div className="row">
               <div className="col-xl-12">
                  <div className="row">
                     <div className="col-xl-9 col-md-9">
                        <h5 className="mt-3 mb-0">Recent Documents</h5>
                     </div>

                     {/* <div className="col-xl-3 col-md-4">
                        <div>
                           
                           <div className="input-group" style={{ paddingTop: '16px' }}>
                              <select className="single-select form-select" id="inputGroupSelect05"
                                 onChange={(e) => {
                                    setClient(e.target.value)
                                    console.log(client)
                                 }}
                              >
                                 <option value="" disabled selected>-- Select Client  --</option>

                                 {
                                    clientData.map((item) =>
                                       <option value={item.id}>{item.name}</option>
                                    )
                                 }
                              </select>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-md-4">
                        <div>
                          
                           <div className="input-group  px-3 pb-0" style={{ paddingTop: '16px' }}>
                              <select className="single-select form-select" id="inputGroupSelect05"
                                 onChange={(e) => {
                                    setClient(e.target.value)
                                    console.log(client)
                                 }}
                              >
                                 <option value="" disabled selected>-- Select GCSL User --</option>

                                 {
                                    userData.map((item) =>
                                       <option value={item.id}>{item.name}</option>
                                    )
                                 }
                              </select>
                           </div>
                        </div>
                     </div> */}
                     <div className="col-xl-3 col-md-3">
                        <form className="app-search d-none d-lg-block px-3 pb-0">
                           <div className="input-group">
                              <input type="text" className="form-control bg-transparent" placeholder="Search.."
                                 aria-label="Text input with dropdown button"
                                 onChange={(e) => setSearch(e.target.value)}
                              />
                              <span className="mdi mdi-magnify"></span>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <hr />

            {
               load ? <div class="spin-a"><div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> :
                  <div className="row mt-4">
                     <div className="col-lg-12 col-12">
                        {
                           data.length == 0 ? <div className='records'><p>Records not found</p></div> :
                              data &&
                              data.length > 0 &&
                              data.filter((item) => {
                                 if (search === "") return item; // searchTerm is in scope?
                                 if (item.candidateName.toLowerCase().includes(search.toLowerCase())) {
                                    return item;
                                 } else {
                                    return false;
                                 }

                              }).map((item, i) => item.length > 0 ?

                                 <div className='records'><p>Records not found</p></div>
                                 :
                                 <Casecompo data={item} reload={getAPi} roleId={roleId} />
                                 // <div className="card">
                                 //    <div className="card-body p-0">
                                 //       <div className="Q-gNw">
                                 //          {/* <div className="dropdown float-end px-3 pt-2">
                                 //             <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown"
                                 //                aria-expanded="false">
                                 //                <svg className="svg_exo2UA" aria-hidden="true" data-qa-tag="svg"
                                 //                   xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                 //                   <path fill-rule="evenodd"
                                 //                      d="M14 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2zM8 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2zM2 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2z">
                                 //                   </path>
                                 //                </svg>
                                 //             </a>
                                 //             <div className="dropdown-menu dropdown-menu-end">
                                 //                <div className="tippy-content-wrapper">
                                 //                   <div className="_4ofq-h px-2" data-qa-tag="context-menu">
                                 //                      <ul className="D1W-uN list-unstyled mb-0">
                                 //                         <li className="o5ar4-">
                                 //                            <a href="#!">
                                 //                               <span className="rglmnm">Comment</span>
                                 //                            </a>
                                 //                         </li>
                                 //                      </ul>
                                 //                   </div>
                                 //                </div>
                                 //             </div>
                                 //          </div> */}
                                 //       </div>
                                 //       <div className="row align-it">
                                 //          <div className="col-md-3 text-center">

                                 //             <div className="bg-light p-3"><img className="stackholderimage" src={item.photo} />
                                 //                <h5 className="pt-3"> {item.candidateName}</h5>
                                 //                <div className="_8pVqqXr text-center" data-qa-tag="tag_name">
                                 //                   <span className="PHDg5+ ">
                                 //                      {/* <i className="fa fa-circle text-danger" aria-hidden="true"></i> */}
                                 //                      <span>&nbsp;</span>{item.status}</span>
                                 //                </div>
                                 //                {/* {console.log("item item", item)} */}
                                 //                <span className="text_Tnc75r text_variant_small2_FyWe7T" data-qa-tag="text">Last activity:<b>{item.lastActivity}</b> to <b>{item.lastActivityTo}</b></span>
                                 //                {item.buttonStatus ? <Link to="viewProfile" state={item} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button" data-qa-tag="uploader-drop-zone-button">View Profile</Link> : <Link to="/confidential" state={
                                 //                   {
                                 //                      createBy: item.createBy,
                                 //                      createOn: item.createOn,
                                 //                      doc: item.files,
                                 //                      id: item.id

                                 //                   }

                                 //                } className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" createBy={item.createBy}>Create Profile</Link>}

                                 //                <div className="mt-3">
                                 //                   <span> <sub>Created By: {item.createBy} | </sub></span>
                                 //                   <span><sub> Created On: {item.createOn.toString().split('T')[0].trim()} </sub></span>
                                 //                </div>
                                 //             </div>
                                 //          </div>
                                 //          <div className="col-md-9 py-2">
                                 //             <div className="row">
                                 //                <div className="col-md-12 col-12">
                                 //                   <span className={ms3} ><span>Dependent</span></span>
                                 //                   {
                                 //                      item.dependent.map((popup) =>
                                 //                         <ModulePopup
                                 //                            classList={"ms-3"}
                                 //                            badge={popup.badge}
                                 //                            caseId={item.id}
                                 //                            dependentId={popup.id}
                                 //                            buttonName={popup.dependentName}
                                 //                            status={popup.status}
                                 //                            statusName={popup.statusName}
                                 //                            dfiles={popup.dependentfiles}
                                 //                            createBy={popup.createBy}
                                 //                            relation={popup.relation}
                                 //                            relationName={popup.relationName}
                                 //                            dependentcreateOn={popup.createOn}
                                 //                            buttonStatus={popup.buttonStatus}

                                 //                         />
                                 //                      )
                                 //                   }
                                 //                </div>
                                 //             </div>
                                 //             <div className="row align-it mt-4">
                                 //                {
                                 //                   item.files.map((fileList) =>

                                 //                      <DocFile fileType={fileList.fileType} fileName={fileList.fileName} filePath={fileList.filePath} />
                                 //                   )
                                 //                }

                                 //             </div>
                                 //          </div>
                                 //       </div>
                                 //    </div>
                                 // </div>
                              )

                        }





                     </div>
                  </div >
            }


         </div >

      </>
   )
}

export default Dashboard