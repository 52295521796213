import React, { useEffect, useState } from 'react'
import bottomBg from './assets/img/bottom-bg.png'
import logo from './assets/img/logo.png'
import waterMark from './assets/img/front-water-mark.png'
import TopBg from './assets/img/top-bg.png'
import bg from './assets/img/watermark-new.jpg'
import tableChart from './assets/img/new-screen-shot.png'
import './assets/frstyle.css'
import { Button } from 'react-bootstrap'
import jsPDF from 'jspdf';
import { useLocation } from 'react-router-dom'
import api from "../../apiUrl"
import jwt_decode from "jwt-decode";

function FinalRport() {
    const [imgBG, setimgBG] = useState(bg)
    var monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [submitedDay, setsubmitedDay] = useState("")
    const [submitedMonth, setsubmitedMonth] = useState("")
    const [submitedYear, setsubmitedYear] = useState("")
    const location = useLocation();
    const [load, setLoad] = useState(false)
    const dependent = location.state.dependent
    const dependentId = location.state.dependentId
    var pdfR = document.getElementById("pdfWrapper")
    var imgData = btoa(imgBG);

    console.log("submitedDay", submitedYear)
    function genratepdf() {
        setLoad(true)
        var doc = new jsPDF("p", "pt", "a4");
        doc.addImage(imgData, 'JPEG', 15, 40, 180, 160);
        for (let i = 1; i <= 6; i++) {
            // Set `doc.react` value for the current page
            doc.setPage(i);

            doc.setLineWidth(4);

            doc.setDrawColor(159, 198, 76);
            doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, 'S');
            doc.addPage()
        }
        doc.html(document.getElementById("pdfWrapper"), {
            autoPaging: 'text',
            pagesplit: true,
            margin: [5, 5, 5, 5],
            callback: function (pdf) {
                var pageCount = doc.internal.getNumberOfPages();
                pdf.deletePage(pageCount)
                pdf.save("report.pdf")
                console.log("Total Page Number", doc.internal.getNumberOfPages());
                console.log(pdf)
                setLoad(false)
            }
        })
    }
    //console.log("location", location.state)
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const [data, setData] = useState([])
    var apis = api()
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    function getVerification() {
        if (dependent == true) {
            fetch(`${apis}Admin/GetDependentVerificationDetails/${dependentId}/${location.state.caseId}`).then((respons) => {
                respons.json().then((result) => {
                    console.log("result", JSON.stringify(result.data))
                    setData(result.data)

                })
            })
        } else {
            fetch(`${apis}Admin/GetVerificationDetails/${location.state.caseId}/${currentUserId}`).then((respons) => {
                respons.json().then((result) => {
                    console.log("result", JSON.stringify(result.data))
                    setData(result.data)
                    setsubmitedDay(result.data[0].sumitedDate.split('/')[0].trim())
                    setsubmitedMonth(monthArr[result.data[0].sumitedDate.split('/')[1].trim().split('0')[1]])
                    setsubmitedYear(result.data[0].sumitedDate.split('/')[2].trim())
                })
            })
        }
    }

    setTimeout(() => {
        data.map((item) => {
            console.log("civilLitigationInvestigation", item.criminalRecordInvestigation)
            document.getElementById("civilLitigationInvestigation").innerHTML = item.civilLitigationInvestigationComment
            document.getElementById("criminalRecordInvestigation").innerHTML = item.criminalLitigationInvestigation
            document.getElementById("bankruptcyInvestigation").innerHTML = item.bankruptcyInvestigationComment
            document.getElementById("corporateInterestsInvestigation").innerHTML = item.corporateInterestsComment
            document.getElementById("worldComplianceInvestigation").innerHTML = item.worldComplianceComment
            document.getElementById("blacklistInvestigation").innerHTML = item.blacklistInvestigationComment
            document.getElementById("mediaandInternetSearches").innerHTML = item.mediaAndInternetComment
            document.getElementById("investigatorReport").innerHTML = item.investigatorReportComment
            document.getElementById("riskManagementReview").innerHTML = item.riskManagementReviewComment
        })
    }, 50)

    // function exportWord() {
    //     var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    //         "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    //         "xmlns='http://www.w3.org/TR/REC-html40'>" +
    //         "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    //     var footer = "</body></html>";
    //     var sourceHTML = header + document.getElementById("pdfWrapper").innerHTML + footer;

    //     var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    //     var fileDownload = document.createElement("a");
    //     document.body.appendChild(fileDownload);
    //     fileDownload.href = source;
    //     fileDownload.download = 'document.doc';
    //     fileDownload.click();
    //     document.body.removeChild(fileDownload);
    // }


    useEffect(() => {
        //   scrollToTop()
        getVerification()
    }, [])
    return (
        <>
            <div className="main-box-wrapper">
                <Button className='genrate-report' onClick={genratepdf}>
                    {
                        load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                    }
                    Download Report</Button>
                {/* <Button className='genrate-report1' onClick={exportWord}>
                    {
                        load ? <div class="spinner-border spinner-border-sm" role="status"></div> : null
                    }
                    Generate report in Doc</Button> */}
                {
                    data.map((item) =>
                        <div id='pdfWrapper' className="pdf-genrate">
                            <div className="warpper-box">
                                <div className="cover-border">

                                    <div className="wraper-logo">
                                        <img src={bottomBg} alt="" />
                                    </div>
                                    <div className="logo-pdf">
                                        <img src={logo} alt="" />
                                    </div>

                                    {
                                        item.clientName.applicantName == true ? <p class="text-h">APPLICANT:  <br />{item.givenName}</p> :

                                            <p class="text-h">&nbsp;<br /><br /></p>
                                    }
                                    <div className="main-watermk">
                                        <img src={waterMark} alt="" />
                                        <div className="top-right">
                                            <h1 className="pdf-main-heading">DUE DILIGENCE<br />INVESTIGATION<br />REPORT</h1>
                                        </div>
                                        <div className="text-box">
                                            <p className="side-content-box">Contact</p>
                                            <p className="side-content-box">Phone : <a href="tel:+91 869-763-9732" style={{ textDecoration: "none", color: "#fff" }}>
                                                869-763-9732</a>
                                            </p>
                                            <p className="side-content-box">Email : <a href="mailto:secure@gcspro.net">
                                                secure@gcspro.net</a>
                                            </p>
                                            <p className="side-content-box">Web : <a href="gcspro.net">gcspro.net</a> </p>
                                        </div>
                                        <div className="preperd-box">
                                            <p className="preperd-text">Prepared for:</p>
                                            <p className="preperd-text">
                                                {dependent == true ? item.clientName.name : item.clientName.name1}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks">
                                        <p className="Highlight-text">CONFIDENTIAL</p>
                                        {
                                            item.clientName.applicantName == false ?

                                                <div className="text-heading">
                                                    <p className="text-h">APPLICANT:</p>
                                                    <p className="text-h">{item.givenName}</p>
                                                </div>
                                                : ""
                                        }

                                        <div className="text-heading-cx">
                                            <p className="text-h">SUBMITTED:   </p>
                                            <p className="text-h">
                                                {
                                                    item.clientName.dateFormate == "MM/DD/YY" ? `${submitedMonth} ${submitedDay} ${submitedYear}` :
                                                        item.clientName.dateFormate == "DD/MM/YY" ? `${submitedDay} ${submitedMonth} ${submitedYear}` :
                                                            item.clientName.dateFormate == "YY/MM/DD" ? `${submitedYear} ${submitedMonth} ${submitedDay}` : ""
                                                }
                                            </p>
                                        </div>
                                        <div className="text-heading">
                                            <p className="text-h-cx">This report is confidential and is intended to be used solely by the client for whom this report was prepared and to whom it is addressed for information
                                                purposes only and for no other purpose except as may be expressly stated herein. This report may not be copied, reproduced, disseminated,
                                                distributed, or otherwise made available to any third party, in whole or in part, without the express written consent of Global Communications
                                                Solutions (GCSL), which consent may be withheld for any reason. In preparing this report, GCSL has used credible sources and a professional network
                                                of experts and advisors in security and information gathering. However, no representation or warranty, express or implied, is made by GCSL regarding
                                                the accuracy or completeness of the information included in this report. The content of this report is non-binding, as the investigation is continuous
                                                plus {item.clientName.daysCountOnReport} days after submission.
                                            </p>
                                        </div>
                                        <div className="bottom-box">
                                            <img src="./assets/img/top-bg.png" alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks">
                                        <p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>3</h1></div>
                                        <h1 className="cont-h">Contents</h1>
                                        <div className="cover-ul">
                                            <ul style={{ listStyle: "none" }}>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Introduction</span><div></div><span>4</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Summary</span><div></div><span>5</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Derogatory Information</span><div></div><span>5</span></p>
                                                </li>
                                                {item.familyInquiries.length > 0 || item.mostImpPers.length > 0 ?

                                                    <>
                                                        <li>
                                                            <p className="list-p dotted-line"><span>Family Inquiries</span> <div></div><span> 6</span></p>
                                                        </li>
                                                        <li>
                                                            <p className="list-p dotted-line"><span>Most Important Persons/ Companies</span> <div></div><span>6</span></p>
                                                        </li>
                                                    </>

                                                    : ""}

                                                <li>
                                                    <p className="list-p dotted-line"><span>Inquiries</span> – {item.name}<div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Civil Litigation Investigation</span><div></div><span> {item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Criminal Record Investigation</span><div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"} </span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p  dotted-line"><span>Bankruptcy Investigation</span><div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Corporate Interests Investigation</span><div></div><span> {item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>World Compliance Investigation</span><div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Media and Internet Searches</span><div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "7" : "8"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Investigator Report</span> <div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "7" : "8"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Risk Management Review</span> <div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "8" : "9"}</span></p>
                                                </li>
                                                <li>
                                                    <p className="list-p dotted-line"><span>Security Risk Matrix </span><div></div><span>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "9" : "10"}</span></p>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box" >
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks">
                                        <p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>4</h1></div>
                                        <h1 className="cont-h">Introduction</h1>
                                        <p className="list-p-text">St. Kitts and Nevis Citizenship by Investment Programme has retained Global
                                            Communications Solutions Limited (GCSL) to conduct professional security vetting
                                            surrounding the following individual:
                                        </p>
                                        <p className="text-h">{item.givenName}</p>
                                        <p className="list-p-text-child">{item.salutation} {item.lastName} intends to conduct business with St. Kitts and Nevis Citizenship by Investment
                                            Programme. A global security vetting was recommended to determine the applicant's suitability
                                            to do business. GCSL commissioned an international security check on the subject name and
                                            the other information provided to satisfy the recommendation.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks">
                                        <p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>5</h1></div>
                                        <h1 className="cont-h">Summary</h1>
                                        <div className="pdf-table">
                                            <div className="boxer">
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">	</div>
                                                    <div className="box cust-cx-table">Candidate Provided Details</div>
                                                    <div className="box cust-cx-table">	Verified</div>
                                                    <div className="box cust-cx-table">	Issues</div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Name
                                                    </div>
                                                    <div className="box tabl-cx tl-width">{item.name}</div>
                                                    <div className="box tabl-cx">	{item.isNameVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.nameComment} </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Place of Birth
                                                    </div>
                                                    <div className="box tabl-cx tl-width">{item.placeOfBirth}</div>
                                                    <div className="box tabl-cx">	{item.isPlaceOfBirthVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.placeOfBirthComment}</div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Date of Birth
                                                    </div>
                                                    <div className="box tabl-cx tl-width">

                                                        {
                                                            item.clientName.dateFormate == "MM/DD/YY" ? `${item.birthOfMonth} ${item.birthOfDay} ${item.birthOfYear}` :
                                                                item.clientName.dateFormate == "DD/MM/YY" ? `${item.birthOfDay} ${item.birthOfMonth} ${item.birthOfYear}` :
                                                                    item.clientName.dateFormate == "YY/MM/DD" ? `${item.birthOfYear} ${item.birthOfMonth} ${item.birthOfDay}` : ""
                                                        }


                                                    </div>
                                                    <div className="box tabl-cx">	{item.isDateOfBirthVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.dateOfBirthComment} </div>
                                                </div>
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        P.P. ID
                                                    </div>
                                                    <div className="box tabl-cx tl-width">	{item.ppid}</div>
                                                    <div className="box tabl-cx">	{item.isPpidVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.ppidComment} </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        ID Card
                                                    </div>
                                                    <div className="box tabl-cx tl-width">{item.idCard}</div>
                                                    <div className="box tabl-cx">{item.isIdVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.idComment}</div>
                                                </div>
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Known Address
                                                    </div>
                                                    <div className="box tabl-cx tl-width">{item.knowAddress}</div>
                                                    <div className="box tabl-cx">{item.isKnownAddressVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.knownAddressComment}</div>
                                                </div>

                                                {/* <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Corporate Affiliations
                                                    </div>
                                                    <div className="box tabl-cx tl-width">{item.corporateAffiliations}</div>
                                                    <div className="box tabl-cx">{item.isCorporateAffiliationsVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box tabl-cx">{item.corporateAffiliationsComment}</div>
                                                </div> */}



                                            </div>
                                            <br />
                                            <div className="boxer">
                                                <div className="box-row">
                                                    <div className="box cust-cx-table" style={{ width: "17%" }}>
                                                        Corporate Affiliations
                                                    </div>
                                                    <div className="box tabl-cx tl-width">
                                                        <ul style={{ marginBottom: "0" }}>
                                                            {item.corporateDependentAffiliation.map((item) =>
                                                                <li>{item.corporateAffiliation}</li>
                                                            )}

                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <h1 className="cont-h">Derogatory Information
                                        </h1>
                                        <div className="pdf-table">
                                            <p className="tble-cx">Types of Inquiries</p>
                                            <div className="boxer">
                                                <div className="box-row">

                                                    <div className="box cust-cx-table">Sources searched</div>
                                                    <div className="box cust-cx-table">	Clear</div>
                                                    <div className="box cust-cx-table">	Comments</div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Civil Litigation
                                                    </div>
                                                    <div className="box tabl-cx ">{item.isCivilLitigationVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.civilLitigationComment}</div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Criminal Record
                                                    </div>
                                                    <div className="box tabl-cx">{item.isCriminalRecordVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.criminalRecordComment}
                                                    </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Bankruptcy records
                                                    </div>
                                                    <div className="box tabl-cx">{item.isBankruptcyRecordsVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.bankruptcyRecordsComment}
                                                    </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Global Sanctions and Watchlists:
                                                        <ul className="table-ul">
                                                            <li>Global Sanction Database (GSD)</li>
                                                            <li>Office of Foreign Access Control (OFAC)</li>
                                                            <li>Excluded Parties List System (EPLS)</li>
                                                            <li>U.S. Department of State</li>
                                                        </ul>
                                                    </div>
                                                    <div className="box tabl-cx">{item.isGlobalSanctionsVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.globalSanctionsComment}
                                                    </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Global Law Enforcement Data
                                                    </div>
                                                    <div className="box tabl-cx">{item.isGlobalLawVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.globalLawComment}
                                                    </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        International Embassies/Mission
                                                    </div>
                                                    <div className="box tabl-cx">{item.isInternationalEmbassiesMissionVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.internationalEmbassiesMissionComment}
                                                    </div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Global Regulatory Data
                                                    </div>
                                                    <div className="box tabl-cx">{item.isGlobalRegulatoryDataVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.globalRegulatoryDataComment}</div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Global P.E.P/High-Profile persons/ Foreign Government Official Data
                                                    </div>
                                                    <div className="box tabl-cx">{item.isGlobalPepVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.globalPepComment}</div>
                                                </div>

                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Adverse Media and Internet Searches
                                                    </div>
                                                    <div className="box tabl-cx">{item.isAdverseMediaVerified == true ? "Yes" : "No"}</div>
                                                    <div className="box ebay">{item.adverseMediaComment} </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            {item.familyInquiries.length > 0 || item.mostImpPers.length > 0 ?

                                <div className="warpper-box">
                                    <img src={bg} className='bg-waterMark' />
                                    <div className="cover-border">
                                        <div className="water-marks"><p className="Highlight-text">CONFIDENTIAL</p>
                                            <div class="text-ctx">  <h1>6</h1></div>

                                            <div className="cover-wra-text">
                                                <h1 className="cont-sec">Family Inquiries</h1>
                                                <div className="boxer">
                                                    <div className="box-row">
                                                        <div className="box cust-cx-table">Family Member</div>
                                                        <div className="box cust-cx-table">Relation</div>
                                                        <div className="box cust-cx-table">	Date of Birth</div>
                                                        <div className="box cust-cx-table">	Passport Number</div>
                                                        <div className="box cust-cx-table">	Place of Birth </div>
                                                        <div className="box cust-cx-table">	Comments</div>
                                                    </div>

                                                    {
                                                        item.familyInquiries.map((items) =>
                                                            <div className="box-row">
                                                                <div className="box cust-cx-table">{items.familyMember}</div>
                                                                <div className="box tabl-cx ">{items.relation}</div>
                                                                <div className="box tabl-cx">{items.dateOfBirth}

                                                                </div>
                                                                <div className="box tabl-cx">{items.passportNumber} </div>
                                                                <div className="box tabl-cx">{items.placeOfBirth}</div>
                                                                <div className="box tabl-cx">{items.comments} </div>
                                                            </div>
                                                        )
                                                    }



                                                </div>
                                                <h1 className="cont-sec">Most Important Persons/ Companies</h1>
                                                <div className="boxer">
                                                    <div className="box-row">
                                                        <div className="box cust-cx-table">Name</div>
                                                        <div className="box cust-cx-table">Location</div>
                                                        <div className="box cust-cx-table">	Comments</div>
                                                    </div>
                                                    {item.mostImpPers.map((items) =>
                                                        <div className="box-row">
                                                            <div className="box cust-cx-table">{items.name}</div>
                                                            <div className="box tabl-cx tl-width">{items.location}</div>
                                                            <div className="box tabl-cx">{items.comments}</div>
                                                        </div>
                                                    )}




                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">

                                    <div className="water-marks"><p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "6" : "7"}</h1></div>

                                        <div className="pdf-table">
                                            <h1 className="cont-sec">Inquiries – {item.name}
                                            </h1>
                                            <p className="tble-cx">Date of Birth, Nationality, and Address Details</p>
                                            <div className="boxer">
                                                <div className="box-row">
                                                    <div className="box cust-cx-table"></div>
                                                    <div className="box cust-cx-table">	Details provided by the candidate</div>
                                                    <div className="box cust-cx-table">	Findings by GCSL</div>
                                                </div>
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Date of Birth
                                                    </div>
                                                    <div className="box tabl-cx">
                                                        {
                                                            item.clientName.dateFormate == "MM/DD/YY" ? `${item.birthOfMonth} ${item.birthOfDay} ${item.birthOfYear}` :
                                                                item.clientName.dateFormate == "DD/MM/YY" ? `${item.birthOfDay} ${item.birthOfMonth} ${item.birthOfYear}` :
                                                                    item.clientName.dateFormate == "YY/MM/DD" ? `${item.birthOfYear} ${item.birthOfMonth} ${item.birthOfDay}` : ""
                                                        }
                                                    </div>
                                                    <div className="box ebay tabl-cx">
                                                        {item.dateofBirthFindingsbyGCSL}
                                                    </div>
                                                </div>
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Place of Birth
                                                    </div>
                                                    <div className="box tabl-cx">{item.placeOfBirth}</div>
                                                    <div className="box ebay tabl-cx">
                                                        {item.placeofBirthFindingsbyGCSL}
                                                    </div>
                                                </div>
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Nat. ID
                                                    </div>
                                                    <div className="box tabl-cx">{item.natID}</div>
                                                    <div className="box ebay tabl-cx">
                                                        {item.natIDFindingsbyGCSL}
                                                    </div>
                                                </div>
                                                <div className="box-row">
                                                    <div className="box cust-cx-table">
                                                        Current Address
                                                    </div>
                                                    <div className="box tabl-cx"> {item.currentAddress}</div>
                                                    <div className="box ebay tabl-cx">{item.currentAddressFindingsbyGCSL}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cover-wra-text">
                                            <h1 className="cont-sec">Civil Litigation Investigation </h1>
                                            <div id="civilLitigationInvestigation"></div>
                                            <h1 className="cont-sec">Criminal Record Investigation </h1>
                                            <div id='criminalRecordInvestigation'></div>
                                            <h1 className="cont-sec">Bankruptcy Investigation </h1>
                                            <div id='bankruptcyInvestigation'></div>

                                            <h1 className="cont-sec">Corporate Interests Investigation
                                            </h1>
                                            <div id='corporateInterestsInvestigation'></div>
                                            <h1 className="cont-sec">World Compliance Investigation</h1>
                                            <div id='worldComplianceInvestigation'></div>
                                            <h1 className="cont-sec">Blacklist Investigation </h1>
                                            <div id='blacklistInvestigation'></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks"><p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "7" : "8"}</h1></div>

                                        <div className="cover-wra-text">
                                            <h1 className="cont-sec">Media and Internet Searches </h1>
                                            <div id='mediaandInternetSearches'></div>

                                            <h1 className="cont-sec">Investigator Report </h1>
                                            <div id="investigatorReport"></div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks"><p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "8" : "9"}</h1></div>

                                        <div className="cover-wra-text">
                                            <h1 className="cont-sec">Risk Management Review</h1>
                                            <div id="riskManagementReview"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks"><p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "9" : "10"}</h1></div>

                                        <div className="cover-wra-text">
                                            <h1 className="cont-sec">Security Risk Matrix </h1>
                                            <div className="chiting-img">
                                                <img src={tableChart} alt="" style={{ width: '100%' }} />
                                            </div>
                                            <h1 className="text-dangers">Based on our evaluation of the information surrounding the applicant. The National Security
                                                risk to the country is {item.securityRiskMatrixComment}.
                                            </h1>
                                            {/* <div className="bottom-module">
                                                <h1 className="text-dangers">References</h1>
                                                <div className="main-px">
                                                    <div className="cx-chid">
                                                        <h1 className="text-lefts">Background Information: </h1>
                                                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: `'Roboto', sans-serif`, color: '#000', }}>{item.backgroundInformation}</p>
                                                    </div>

                                                </div>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                                <div className="cover-border">
                                    <div className="water-marks"><p className="Highlight-text">CONFIDENTIAL</p>
                                        <div class="text-ctx">  <h1>{item.familyInquiries.length == 0 || item.mostImpPers.length == 0 ? "10" : "11"}</h1></div>
                                        <div className="cover-wra-text-d">
                                            {/* <div className="main-px-cs">
                                                <div className="cx-chid">
                                                    <h1 className="text-lefts">Derogatory Information:</h1>
                                                </div>
                                                <div className="cx-chid">
                                                    <h1 className="text-rights">{item.derogatoryInformation} {item.isDerogatoryInformationVerified ? <span className="text-dangerd">CONFIRMED</span> : <span className="text-dangerd">NOT CONFIRMED</span>}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="main-px-cs">
                                                <div className="cx-chid">
                                                    <h1 className="text-lefts">Employment Status and Sources of Income
                                                        :
                                                    </h1>
                                                </div>
                                                <div className="cx-chid">

                                                    <h1 className="text-rights">{item.employmentStatusSourcesIncome}</h1>
                                                    
                                                </div>
                                            </div>

                                            <div className="main-px-cs">
                                                <div className="cx-chid">
                                                    <h1 className="text-lefts">Most important persons/companies with whom the applicant does business
                                                    </h1>
                                                </div>
                                                <div className="cx-chid">
                                                    <h1 className="text-rights">{item.mostImportantPersonsC}

                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="main-px-cs">
                                                <div className="cx-chid">
                                                    <h1 className="text-lefts">Documents Submitted
                                                    </h1>
                                                </div>
                                                <div className="cx-chid">
                                                    <h1 className="text-rights"> {item.documentsSubmitted}</h1>
                                                  
                                                </div>
                                            </div> */}
                                            {/* <h1 className="text-lefts"> Source - <a href="https://www.sos.state.co.us/notary/pages/public/verifyNotaryResult.xhtml">https://www.sos.state.co.us/notary/pages/public/verifyNotaryResult.xhtml</a></h1>
                                            <h1 className="text-lefts"> Translation Services – Venice Translation Office -<br /> <a href="https://translationservicesegypt.com/translation-offices/28-translation-offices-in-heliopolis/466-venicetranslation-office">https://translationservicesegypt.com/translation-offices/28-translation-offices-in-heliopolis/466-venicetranslation-office</a></h1>
                                            <h1 className="text-lefts">International bans and “red” or “restrictive lists of international law enforcement bodies.</h1>
                                            <h1 className="text-rights">{item.internationalBansred}</h1>
                                            <h1 className="text-rights">No results were found on the OFAC Sanctions list on the applicant
                                                Source - <a href="https://sanctionssearch.ofac.treas.gov/">https://sanctionssearch.ofac.treas.gov/</a>
                                            </h1> 
                                            <h1 className="tex-cx-d">No other useful information on bankruptcies, litigation, scandal was found. </h1>*/}

                                            <div className='secb'>
                                                <div className="greenL">Risk Categories to Determine Matrix</div>
                                                <div className="boxer">
                                                    <div className="box-row">
                                                        <div className="box cust-cx-table" style={{ backgroundColor: '#9bbb59' }}>Personal Details</div>
                                                        <div className="box cust-cx-table" style={{ backgroundColor: '#9bbb59' }}>Legal / Regulatory Matters</div>
                                                    </div>


                                                    <div className="box-row" style={{ borderLeft: '0' }}>
                                                        <div className="box tabl-cx" style={{ padding: '0' }}>
                                                            <ul class="table-ul lastTable">
                                                                <li>Country of residency</li>
                                                                <li>Undisclosed, incorrect, incomplete personal information</li>
                                                                <li>Unable to validate ID information</li>
                                                            </ul>
                                                            <div className="greenL" style={{ textAlign: 'left', paddingLeft: '5px' }}>Business Details</div>
                                                            <ul class="table-ul lastTable">
                                                                <li>Ties to offshore activity</li>
                                                                <li>Ties to high-risk jurisdictions</li>
                                                                <li>Ties to high-risk industries</li>
                                                                <li>Incomplete business disclosure</li>
                                                            </ul>
                                                            <div className="greenL" style={{ textAlign: 'left', paddingLeft: '5px' }}>Source of Wealth / Source of Funds</div>
                                                            <ul class="table-ul lastTable">
                                                                <li>A person who is the source of wealth/funds not included</li>
                                                                <li>Questions concerning the source of wealth/funds</li>

                                                            </ul>
                                                            <div className="greenL" style={{ textAlign: 'left', paddingLeft: '5px' }}>Additional Risk Factors</div>
                                                            <ul class="table-ul lastTable">
                                                                <li>Additional information that raises the risk</li>

                                                            </ul>
                                                        </div>
                                                        <div className="box tabl-cx" style={{ padding: '0' }}>
                                                            <ul class="table-ul lastTable">
                                                                <li>Criminal record</li>
                                                                <li>Criminal record involving Integrity</li>
                                                                <li>Subject of litigation</li>
                                                                <li>Affiliated person/entity subject to litigation</li>
                                                                <li>Subject of bankruptcy</li>
                                                                <li>Affiliated person/entity subject to bankruptcy</li>
                                                                <li>Professional license disbarment/revocation</li>
                                                            </ul>
                                                            <div className="greenL" style={{ textAlign: 'left', paddingLeft: '5px' }}>PEP / Sanctions / Watchlist</div>
                                                            <ul class="table-ul lastTable">
                                                                <li>PEP / Close association with PEP</li>
                                                                <li>Listed in sanctions or watchlist</li>
                                                                <li>Affiliated person/entity listed in sanctions or watchlist</li>
                                                            </ul>
                                                            <div className="greenL" style={{ textAlign: 'left', paddingLeft: '5px' }}>Media</div>
                                                            <ul class="table-ul lastTable">
                                                                <li>Negative news</li>
                                                                <li>Negative news on affiliated person/entity</li>
                                                                <li>Negative Social Media activities</li>
                                                            </ul>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <p className='confi'>CONFIDENTIAL
                                                10
                                                GCSL was retained to conduct research into the applicants. GCSL risk and compliance researchers gathered and assessed information
                                                in this document exclusively for the government of St. Lucia and according to the specifications of the terms of engagement between
                                                GCSL and the government of St. Lucia. This document is meant for the Client only. Distribution of this document or its contents, in whole
                                                or in part, to any party outside the Agreement is prohibited except as approved in the Agreement. GCSL accepts no liability for any
                                                decisions or actions made or not made based on the information in this document. All information in this document is based on information
                                                available to GCSL when writing the document. GCSL assumes no responsibility to update the document to include new information
                                                unless otherwise specified in this document, in the Agreement, or in case a new Agreement is created.</p>


                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                            </div>
                            <div className="warpper-box">
                                <img src={bg} className='bg-waterMark' />
                            </div> */}
                        </div>
                    )
                }

            </div>

        </>
    )
}

export default FinalRport