import { useEffect, useState } from "react"
import avtars from './dummy-avatar.jpg'
import api from "../../apiUrl"
import { Link } from "react-router-dom"
//Alert dialog import
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import jwt_decode from "jwt-decode";



function ViewStakeholder() {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    var apis = api()

    //Alert dialog setup
    const [open, setOpen] = useState(false);
    const [deleteItem, setdeleteItem] = useState()

    const handleClickOpen = (e) => {
        setOpen(true);
        setdeleteItem(e)

    };

    const handleClose = () => {
        setOpen(false);
    };
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    const DeleteHandleClose = () => {
        setOpen(false);
        fetch(`${apis}Admin/DeleteStakeHolder/${deleteItem}/${currentUserId}`, {
            method: "POST",
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                // console.log(result.data)
                viewstakeholder()
            })
        })
    };



    function viewstakeholder() {
        setLoad(true)
        fetch(`${apis}Admin/ViewStakeHolder/${currentUserId}`, {
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                // console.log(result.data)
                setData(result.data)
                setLoad(false)

            })
        })
    }
    // function deletViewstakeholder(e) {
    //     fetch(`${apis}Admin/DeleteStakeHolder/${e}`, {
    //         method: "POST",
    //         mode: "cors",
    //     }).then((respons) => {
    //         respons.json().then((result) => {
    //             console.log(result.data)
    //             viewstakeholder()
    //         })
    //     })
    // }
    useEffect(() => {
        viewstakeholder()
    }, [])
    return (
        <>

            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item active">View Clients</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                load ? <div class="spin-a"><div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> :
                    <div className="container-fluid">
                        <div className="table-responsive mt-4">

                            <table id="example" className="table table-striped table-bordered" style={{ width: "100%", }}>

                                <thead>
                                    <tr>
                                        <th width="5%"> Photo </th>
                                        <th width="15%"> Name</th>
                                        <th width="15%"> Mobile Number </th>
                                        <th width="15%"> User Name </th>
                                        <th width="15%"> Address </th>
                                        <th width="20%"> Time Zone </th>
                                        <th width="10%"> Action </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        data.length == 0 ?
                                            <tr>
                                                <td colSpan={7}>

                                                    <div className='records'><p>Records not found</p></div>
                                                </td>
                                            </tr>
                                            : null
                                    }

                                    {
                                        data.map((item) =>
                                            <tr key={item.id}>
                                                <td className=" sorting_1"><img className="rounded-circle header-profile-user"
                                                    src={
                                                        item.photo == '' ? avtars : item.photo
                                                    } alt="User Photo" style={{ width: "36px", height: "36px", objectFit: "cover", objectPosition: "center" }} /></td>
                                                <td className="">{item.firstName} {item.lastName}</ td>
                                                <td className=""> {item.mobileNo}</td>
                                                <td className="">{item.email}</td>
                                                <td className=""><span className="">{item.address}</span>
                                                </td>
                                                <td className="">{item.timeZone}
                                                </td>
                                                <td className="">&nbsp;
                                                    <Link to="/updateStakeholder" className="btn btn-green-outline2 btn-xs"
                                                        state={item}
                                                    >
                                                        <i class="fas fa-pen"></i>
                                                    </Link>&nbsp;
                                                    <button className="btn btn-xs btn-danger" onClick={() => handleClickOpen(item.userId)}><i className="fa fa-trash fa-lg"></i></button>

                                                </td>

                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You won't be able to revert this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={DeleteHandleClose} autoFocus>
                        Yes, delete it!
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewStakeholder