import { Link, useNavigate } from 'react-router-dom';
import './topnav.css';
import api from "../apiUrl";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from 'react';
import avtars from './logo-light.png'

function Topnav(prop) {
	const [notifications, setNotifications] = useState([])
	const [show, setShow] = useState(false)
	const [noteLenth, setNoteLenth] = useState(notifications.length)
	const [photo, setPhoto] = useState(localStorage.getItem('photo'))
	var apis = api()
	var screenWidth = window.innerWidth;
	//	alert(screenWidth)
	function sideMneu() {

		var body = document.querySelector("body");
		var bodycheck = body.classList.contains("vertical-collpsed");
		if (bodycheck == false) {

			if (screenWidth <= 992) {
				body.classList.add("sidebar-enable")
			} else {
				body.classList.add("vertical-collpsed")
			}

		} else {

			if (screenWidth <= 992) {

				body.classList.remove("sidebar-enable")
			} else {
				body.classList.remove("vertical-collpsed")
			}


		}
	}
	var rol = localStorage.getItem("role");
	var name = localStorage.getItem("name");
	const navigate = useNavigate()
	function logout() {
		localStorage.removeItem("token");
		localStorage.removeItem("role");
		localStorage.removeItem("name");
		navigate("/")
	}
	function notification() {
		fetch(
			prop.role == "1" || prop.role == "2" ? `${apis}Admin/GetNotificationsForAdmin` :
				prop.role == "3" || prop.role == "4" ? `${apis}Admin/GetNotificationsForStakeHolder/${prop.currentUserId}` : ""
		).then((respons) => {
			respons.json().then((result) => {
				//console.log("result", result.data)
				setNotifications(result.data)
				setNoteLenth(result.data.length)
				//console.log("notifications", notifications)
				setTimeout(() => {
					notification()
					console.log("Inner setTimeOut notification");
				}, 300000)
			})
		})
	}
	function deleteNotification(e) {
		fetch(prop.role == "1" || prop.role == "2" ? `${apis}Admin/DeleteNotifications/${e}/notdelete` :
			prop.role == "3" || prop.role == "4" ? `${apis}User/DeleteNotificationsforuser/${e}` : "", {
			method: 'POST'
		}).then((respons) => {
			respons.json().then((result) => {
				//console.log("result", result)
				notification()

			})
		})
	}

	// setTimeout(() => {
	// 	notification()
	// 	//console.log("setTimeOut notification");
	// }, 300000)
	useEffect(() => {
		notification()
		var body = document.getElementById("backgroundOverlay");
		body.addEventListener("click", function () {
			//console.log("click")
			setShow(false)
			notification()


		})
		//	console.log("UseEffect notification");

	}, [])
	return (
		<>
			<div className="navbar-header">
				<div className="d-flex">
					<div className="navbar-brand-box">
						<a href="index.html" className="logo logo-dark">
							<span className="logo-sm">
								<img src="assets/images/logo-sm-dark.png" alt="logo-sm-dark" height="22" />
							</span>
							<span className="logo-lg">
								<img src="assets/images/logo-dark.png" alt="logo-dark" height="22" />
							</span>
						</a>

						<Link to="/" className="logo logo-light">
							<span className="logo-sm">
								<img src="assets/images/logo-sm-light.png" alt="logo-sm-light" height="50" />
							</span>
							<span className="logo-lg">
								<img src="assets/images/logo-light.png" alt="logo-light" height="22" />
							</span>
						</Link>
					</div>
					<button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect"
						id="vertical-menu-btn" onClick={sideMneu} >
						<i className="ri-menu-2-line align-middle"></i>
					</button>
					<div className="page-title-box align-items-center justify-content-between pb-0 " style={{ display: 'flex', alignItems: "center", }}>
						<h4 className="mb-0">Welcome back {prop.name} {api}! </h4>
						{/* <p>&nbsp;</p> */}
						{/* <p>Your last login: Today at 02:46 am</p> */}
					</div>
				</div>
				<div className="d-flex">
					<div className="dropdown d-inline-block">
						<button type="button" className="btn header-item noti-icon waves-effect"
							onClick={() => {
								setShow(!show)
								notification()
							}}>
							<div className="top-icon">
								<i className="mdi mdi-bell"></i>
							</div>
							<span className="badge bg-danger rounded-pill">{noteLenth}</span>
						</button>
						<div id="backgroundOverlay" className={`${show == true ? "backgroundOverlayNoti" : ""}`}></div>

						{
							show == true ?
								<div className="dropdown-noti  dropdown-menu-lg dropdown-menu-end p-0 noti show"
									aria-labelledby="page-header-notifications-dropdown">
									<div className="p-3">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="m-0"> Notifications </h6>
											</div>
											<div className="col-auto">
												{/* <a href="#!" className="small"> View All</a> */}
											</div>
										</div>
									</div>
									<div className='notiheight'>
										{
											notifications.length > 0 ?

												notifications.map((item, i) =>
													<div style={{ cursor: "pointer" }} id={`ids${item.id}`} onClick={() => {
														//	document.getElementById(`ids${item.id}`).remove()
														setNoteLenth(noteLenth - 1)
														deleteNotification(item.id)
													}} key={i} to="" className="text-reset notification-item">
														<div className="d-flex">
															<img src={item.photo}
																className="me-3 rounded-circle avatar-xs" alt="user-pic" />
															<div className="flex-1">
																{prop.role == "1" || prop.role == "2" ? <h6 className="mt-0 mb-1 noti-title">{item.username}</h6> : ""}

																<h6 className="mt-0 mb-1">{item.notifications}</h6>
																<div className="font-size-12 text-muted">
																	<p className="mb-1">{item.message}</p>
																	<p className="mb-0"><i className="mdi mdi-clock-outline"></i> {item.distance}</p>
																</div>
															</div>
														</div>
													</div>
												)

												:

												<p style={{ textAlign: 'center' }}>No Notifications</p>

										}

									</div>
								</div>
								:
								""
						}
					</div>
					<div className="dropdown d-inline-block user-dropdown">
						<button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
							data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img className="rounded-circle header-profile-user" src={photo === 'https://gcsl.api.digitalnoticeboard.biz/GcslProfile//' ? avtars : photo}
								alt="Header Avatar" />
							<span className="d-none d-xl-inline-block ms-1">{prop.name}</span>
							<i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
						</button>
						<div className="dropdown-menu dropdown-menu-end">

							{/* <a className="dropdown-item" href="#"><i className="ri-user-line align-middle me-1"></i> Profile</a>
							<a className="dropdown-item" href="#"><i className="ri-wallet-2-line align-middle me-1"></i> My
								Wallet</a>
							<a className="dropdown-item d-block" href="#"><span
								className="badge bg-success float-end mt-1">11</span><i
									className="ri-settings-2-line align-middle me-1"></i> Settings</a>
							<a className="dropdown-item" href="#"><i className="ri-lock-unlock-line align-middle me-1"></i> Lock
								screen</a> 
							<div className="dropdown-divider"></div>*/}
							<button onClick={() => {
								logout();
								prop.logout()
							}} className="dropdown-item text-danger"><i
								className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout</button>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default Topnav