import React, { useEffect, useState } from 'react'
import api from '../../apiUrl';
import { Link } from 'react-router-dom';
import Casecompo from '../casecompo';
import jwt_decode from "jwt-decode";
function Stackholderdashboard(prop) {
    var apis = api()
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    const [data, setdata] = useState([]);
    const [search, setSearch] = useState("");
    function getStackHolder() {
        fetch(`${apis}User/StakeHolderDashboard/${currentUserId}`, {
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                console.log(result)
                setdata(result.data)
            })
        })
    }
    useEffect(() => {
        getStackHolder()
    }, [])
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12 p-0">
                            <div className="page-title-box align-items-center justify-content-between pb-0">
                                {/* <h4 className="mb-0">Welcome back {prop.name} !</h4> */}
                                <p>&nbsp;</p>
                                {/* <p>Your last login: Today at 02:46 am</p> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-12 text-right p-0">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-xl-8 col-md-8">
                                <h5 className="mt-3 mb-0">Recent Created Cases</h5>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <form className="app-search d-none d-lg-block px-3 pb-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-transparent" placeholder="Search.."
                                            aria-label="Text input with dropdown button"
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <span className="mdi mdi-magnify"></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <hr />
                        <div className="db-content__body">
                            {
                                data &&
                                data.length > 0 &&
                                data.filter((item) => {
                                    if (search === "") return item; // searchTerm is in scope?
                                    if (item.candidateName.toLowerCase().includes(search.toLowerCase())) {
                                        return item;
                                    } else {
                                        return false;
                                    }

                                }).map((item) =>
                                    <Casecompo data={item} reload={getStackHolder} />
                                )
                            }


                        </div>
                    </div>
                </div>




            </div>
            <div className="modal fade" id="exampleModalToggle-2" aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel">

                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row align-it">
                                <div className="col-md-3 text-center">
                                    <div className=" bg-light p-3 mt-4">
                                        <h5 className="pt-3"> Ahmed Tamer Momtaz Saber Abdelkhalek</h5>
                                        <div className="_8pVqqXr text-center" data-qa-tag="tag_name">
                                            <span className="PHDg5+"><i class="fa fa-circle text-success"
                                                aria-hidden="true"></i>
                                                Verified</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 py-2">
                                    <div className="row align-it">
                                        <div className="col-md-12 text-center d-flex">
                                            <p className="card-text mb-0 px-2">Created On: 25/12/2022</p>
                                        </div>
                                    </div>
                                    <div className="row align-it mt-4">
                                        <div className="col-md-2 col-12 text-center">
                                            <img src="assets/images/doc.png" height="60" />
                                            <p className="mb-0 mt-2">treeddgitpic.doc </p>
                                            <p className="card-text mt-2"><span
                                                className="badge badge-soft-success"><i
                                                    className="mdi mdi-arrow-collapse-down"></i>
                                            </span>
                                                <span className="badge badge-soft-danger"><i
                                                    class="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-md-2 col-12 text-center">
                                            <img src="assets/images/pdf-file.png" height="60" />
                                            <p className="mb-0 mt-2">wedqwegitpic.pdf </p>
                                            <p className="card-text mt-2"><span
                                                className="badge badge-soft-success"><i
                                                    className="mdi mdi-arrow-collapse-down"></i>
                                            </span>
                                                <span className="badge badge-soft-danger"><i
                                                    className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-md-2 col-12 text-center">
                                            <img src="assets/images/image.png" height="60" />
                                            <p className="mb-0 mt-2">ddfpic.png </p>
                                            <p className="card-text mt-2"><span
                                                className="badge badge-soft-success"><i
                                                    className="mdi mdi-arrow-collapse-down"></i>
                                            </span>
                                                <span className="badge badge-soft-danger"><i
                                                    className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-md-2 col-12 text-center">
                                            <img src="assets/images/doc.png" height="60" />
                                            <p className="mb-0 mt-2">demographicgic.doc </p>
                                            <p className="card-text mt-2"><span
                                                className="badge badge-soft-success"><i
                                                    className="mdi mdi-arrow-collapse-down"></i>
                                            </span>
                                                <span className="badge badge-soft-danger"><i
                                                    className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-md-2 col-12 text-center">
                                            <img src="assets/images/image.png" height="60" />
                                            <p className="mb-0 mt-2">dghwegitpic.png </p>
                                            <p className="card-text mt-2"><span
                                                className="badge badge-soft-success"><i
                                                    className="mdi mdi-arrow-collapse-down"></i>
                                            </span>
                                                <span className="badge badge-soft-danger"><i
                                                    className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-md-2 col-12 text-center">
                                            <img src="assets/images/pdf-file.png" height="60" />
                                            <p className="mb-0 mt-2">gitpemographic.pdf </p>
                                            <p className="card-text mt-2"><span
                                                className="badge badge-soft-success"><i
                                                    className="mdi mdi-arrow-collapse-down"></i>
                                            </span>
                                                <span className="badge badge-soft-danger"><i
                                                    className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" data-bs-target="#exampleModalToggle2"
                                data-bs-toggle="modal" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Stackholderdashboard