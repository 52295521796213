import { useEffect, useState } from "react";
import api from "../../apiUrl";
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';

function CorporateAffiliations(prop) {
    var apis = api()
    const caseId = prop.caseId
    const [value, setValue] = useState("")
    const [valueData, setValueData] = useState([])
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const dependentSatus = prop.dependentSatus
    console.log("dependentSatus", dependentSatus);
    const dependentId = prop.dependentId


    //-----get Item Data------
    if (dependentSatus == true) {

    }
    function getAPIData() {
        if (dependentSatus == true) {
            console.log('ViewCorporateAffiliationsForDependent', prop)
            fetch(`${apis}Admin/ViewCorporateAffiliationsForDependent/${caseId}/${dependentId}`).then((respons) => {
                respons.json().then((result) => {
                    setValueData(result.data)
                    console.log("setValue", valueData)
                    // navigate('/')
                })
            })
        } else {
            fetch(`${apis}Admin/ViewCorporateAffiliations/${caseId}`).then((respons) => {
                respons.json().then((result) => {
                    setValueData(result.data)
                    console.log("setValue", valueData)
                    // navigate('/')
                })
            })
        }

    }
    useEffect(() => {
        getAPIData()
    }, [])
    //-----get Item Data------
    //-----Add MIPC Data------
    if (dependentSatus == true) {
        var addData = {
            "CaseId": caseId,
            "CaseType": "D",
            "corporateAffiliation": value,
            "dependentId": dependentId
        }
        // var addData = {
        //     "CaseId": caseId,
        //     "CaseType": "D",
        //     "corporateAffiliation": value,
        // }
    } else {
        var addData = {
            "CaseId": caseId,
            "CaseType": "C",
            "corporateAffiliation": value,
        }
    }

    function addObjIn() {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/AddCorporateAffiliationsForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addData),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("Add Data", result)
                    getAPIData()
                })
            })
        } else {
            fetch(`${apis}Admin/AddCorporateAffiliations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(addData),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("Add Data", result)
                    getAPIData()
                })
            })
        }

    }


    //-----Add MIPC Data------


    //-------UPdate----------------
    const [updatevalue, setUpdatevalue] = useState("")
    const [updateShow, setUpdateShow] = useState(false);
    const [updateId, setUpdateID] = useState(false);
    const [updateValidated, setUpdateValidated] = useState(false);

    const updatehandleClose = () => setUpdateShow(false);
    const updatehandleShow = () => {
        setUpdateShow(true);
        setUpdatevalue("")
    }

    if (dependentSatus == true) {
        var updateData = {
            "CaseId": caseId,
            "CaseType": "D",
            "corporateAffiliation": updatevalue,
            "id": updateId,
            "dependentId": dependentId
        }
    } else {
        var updateData = {
            "CaseId": caseId,
            "CaseType": "C",
            "corporateAffiliation": updatevalue,
            "id": updateId
        }
    }

    function updateItem() {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/UpdateCorporateAffiliationsForDependent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(updateData),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("getMipcAPIData", result)
                    getAPIData()
                })
            })
        } else {
            fetch(`${apis}Admin/UpdateCorporateAffiliations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify(updateData),
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("getMipcAPIData", result)
                    getAPIData()
                })
            })
        }

    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else if (!value == false) {
            addObjIn()
            setShow(false);
            setTimeout(() => {
                setValidated(false);
                setValue(null)
                event.target.reset();
            }, 1000);
        }
        setValidated(true);
    };
    const updatehandleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        updateItem()
        console.log(updateData)
        setUpdateShow(false);
    };

    //----- Detele MIPC Data-----

    function deleteData(e) {
        if (dependentSatus == true) {
            fetch(`${apis}Admin/DeleteCorporateAffiliationsForDependent/${e}/${dependentId}`, {
                method: "POST",
            }).then((respons) => {
                respons.json().then((result) => {

                    console.log("deletefamilyData ", result)
                    getAPIData()
                })
            })
        } else {
            fetch(`${apis}Admin/DeleteCorporateAffiliations/${e}`, {
                method: "POST",
            }).then((respons) => {
                respons.json().then((result) => {
                    console.log("deletefamilyData ", result)
                    getAPIData()
                })
            })
        }

        console.log("deletefamilyData ", e)
    }
    //----- Detele MIPC Data-----

    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr className='mipc'>
                        <th width="85%">Corporate Affiliations</th>
                        <th className='action'>Action  &nbsp;
                            <Button variant="primary" onClick={handleShow}>
                                <i class="mdi mdi-plus float-end accor-plus-icon"></i>
                            </Button>

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Corporate Affiliations</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="mipcForm" noValidate validated={validated}>
                                        <Form.Group controlId="validationCustom01">
                                            <Form.Control
                                                required
                                                type="text"
                                                onChange={(e) => setValue(e.target.value)}
                                            // maxLength={50}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        valueData.map((items) =>
                            <tr>
                                <td>{items.corporateAffiliation}</td>
                                <td><div className="btn btn-xs btn-green-outline2"
                                    onClick={() => {
                                        updatehandleShow()
                                        setUpdatevalue(items.corporateAffiliation)
                                        setUpdateID(items.id)
                                    }}
                                ><i class="fas fa-pen"></i></div> <span>&nbsp;</span>
                                    <Modal show={updateShow} onHide={updatehandleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Update  Corporate Affiliations</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form id="CaForm" noValidate validated={updateValidated}>
                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Control
                                                        value={updatevalue}
                                                        type="text"
                                                        onChange={(e) => setUpdatevalue(e.target.value)}
                                                    // maxLength={50}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>

                                            </Form>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={updatehandleClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={updatehandleSubmit}>
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div className="btn btn-xs btn-danger" onClick={() => deleteData(items.id)}><i className="fa fa-trash fa-lg"></i></div></td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
}

export default CorporateAffiliations;