import { useEffect, useState } from "react"

import api from "../../apiUrl"
import { Link } from "react-router-dom"
//Alert dialog import
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';





function EventLog() {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    var apis = api()

    //Alert dialog setup
    const [open, setOpen] = useState(false);
    const [deleteItem, setdeleteItem] = useState()


    const columns = [
        {
            field: 'screenName',
            headerName: 'Screen Name',
            flex: 1,
            editable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'propertyName',
            headerName: 'Property Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        // {
        //     field: 'oldValues',
        //     headerName: 'Old Values',
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: 'newValues',
        //     headerName: 'New Values',
        //     width: 150,
        //     editable: false,
        // },
        {
            field: 'dateAndTime',
            headerName: 'Date And Time',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'userName',
            headerName: 'User Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'actionName',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            width: 330,
            editable: false,
        },
    ];


    function eventLogApi() {
        setLoad(true)
        fetch(`${apis}Admin/GetLog`, {
            mode: "cors",
        }).then((respons) => {
            respons.json().then((result) => {
                console.log(result.data)
                setData(result.data)
                setLoad(false)

            })
        })
    }
    // function deletViewstakeholder(e) {
    //     fetch(`${apis}Admin/DeleteStakeHolder/${e}`, {
    //         method: "POST",
    //         mode: "cors",
    //     }).then((respons) => {
    //         respons.json().then((result) => {
    //             console.log(result.data)
    //             viewstakeholder()
    //         })
    //     })
    // }
    useEffect(() => {
        eventLogApi()
    }, [])
    return (
        <>

            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Dashboard
                                        </li>
                                        <li className="breadcrumb-item active">Event Log Module </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3">
                <Box sx={{
                    height: 650, width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: 'rgba(210, 210, 210, 1)',
                    },
                }}>
                    <DataGrid
                        rows={data}
                        columns={columns}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },

                            },
                        }}
                        pageSizeOptions={[50]}
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>


        </>
    )
}

export default EventLog