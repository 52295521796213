import { GRID_COLUMN_MENU_SLOT_PROPS } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import api from '../../apiUrl';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function AddNewFile(prop) {
    var apis = api()
    // console.log(prop)
    const dependentStatus = prop.dependentStatus
    const dependentId = prop.dependentId
    const [upload, setUpload] = useState();
    const [load, setLoad] = useState(false)
    const [fileSize, setfileSize] = useState();
    const [caseName, setCaseName] = useState();
    const [message, setMessage] = useState("");
    const [opens, setOpens] = useState(false);
    const [color, setColor] = useState("");
    const [validated, setValidated] = useState(false);
    const [fileNames, setFileNames] = useState([])
    function uploadCheck() {
        console.log("upload state", upload);
    }
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);




    // const currentUserId = jwtDecode.Id

    //Drag and Drop
    setTimeout(() => {
        // File Upload
        // 
        function ekUpload() {
            function Init() {

                console.log("Upload Initialised");

                var fileSelect = document.getElementById('file-upload'),
                    fileDrag = document.getElementById('file-drag'),
                    submitButton = document.getElementById('submit-button');

                fileSelect.addEventListener('change', fileSelectHandler, false);

                // Is XHR2 available?
                var xhr = new XMLHttpRequest();
                if (xhr.upload) {
                    // File Drop
                    fileDrag.addEventListener('dragover', fileDragHover, false);
                    fileDrag.addEventListener('dragleave', fileDragHover, false);
                    fileDrag.addEventListener('drop', fileSelectHandler, false);
                }
            }

            function fileDragHover(e) {
                var fileDrag = document.getElementById('file-drag');

                e.stopPropagation();
                e.preventDefault();

                fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
            }

            function fileSelectHandler(e) {

                // Fetch FileList object
                var files = e.target.files || e.dataTransfer.files;

                // Cancel event and hover styling
                fileDragHover(e);
                parseFile(files);
                uploadFile(files);
                // Process all File objects
                // for (var i = 0, f; f = files[i]; i++) {
                //     parseFile(f);
                //     uploadFile(f);
                // }
            }

            // Output
            function output(msg) {
                // Response
                var m = document.getElementById('messages');
                m.innerHTML = msg + "dfbdf";
            }


            function parseFile(file) {

                // console.log(file.name);

                //                console.log(" filess", file.files[0]);
                setUpload(file)

                output(
                    '<strong>' + encodeURI(file.name) + '</strong>'
                );

                // var fileType = file.type;
                // console.log(fileType);



                var imageName = file.name;
                console.log("file.name", file.name);
                // var isGood = (/\.(?=zip|rar|pdf|doc|docx)/gi).test(imageName);
                // console.log(isGood)
                // if (isGood) {
                //     document.getElementById('start').classList.add("hidden");
                //     document.getElementById('response').classList.remove("hidden");
                //     document.getElementById('notimage').classList.add("hidden");
                //     // Thumbnail Preview
                //     document.getElementById('file-image').classList.remove("hidden");
                //     //document.getElementById('file-image').src = URL.createObjectURL(file);
                // }
                // else {
                //     document.getElementById('file-image').classList.add("hidden");
                //     document.getElementById('notimage').classList.remove("hidden");
                //     document.getElementById('start').classList.remove("hidden");
                //     document.getElementById('response').classList.add("hidden");
                //     document.getElementById("file-upload-form").reset();
                // }
            }

            function setProgressMaxValue(e) {

                var pBar = document.getElementById('file-progress');

                if (e.lengthComputable) {
                    pBar.max = e.total;
                }
            }

            function updateFileProgress(e) {
                var pBar = document.getElementById('file-progress');

                if (e.lengthComputable) {
                    pBar.value = e.loaded;
                }
            }

            function uploadFile(file) {
                setfileSize(file.size)


                var xhr = new XMLHttpRequest(),
                    fileInput = document.getElementById('class-roster-file'),
                    pBar = document.getElementById('file-progress'),
                    fileSizeLimit = 200; // In MB
                if (xhr.upload) {
                    // Check if file is less than x MB
                    if (file.size <= fileSizeLimit * 1024 * 1024) {
                        // Progress bar
                        // pBar.style.display = 'inline';
                        xhr.upload.addEventListener('loadstart', setProgressMaxValue, false);
                        xhr.upload.addEventListener('progress', updateFileProgress, false);

                        // File received / failed
                        xhr.onreadystatechange = function (e) {
                            if (xhr.readyState == 4) {
                                // Everything is good!

                                // progress.className = (xhr.status == 200 ? "success" : "failure");
                                // document.location.reload(true);
                            }
                        };

                        // Start upload
                        xhr.open('POST', document.getElementById('file-upload-form').action, true);
                        xhr.setRequestHeader('X-File-Name', file.name);
                        xhr.setRequestHeader('X-File-Size', file.size);
                        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                        xhr.send(file);
                    } else {
                        output('Please upload a smaller file (< ' + fileSizeLimit + ' MB).');
                        document.getElementById('file-image').classList.add("hidden");
                        document.getElementById("file-upload-form").reset();
                    }
                }
            }

            // Check for the various File API support.
            if (window.File && window.FileList && window.FileReader) {
                Init();
            } else {
                document.getElementById('file-drag').style.display = 'none';
            }
        }
        ekUpload();

        // Zip Reader

    }, [1000])

    const crossClose = () => {

        setShow(false)
    };


    var arrEpty = []
    if (upload == undefined) {
        console.log(`upload for undefinde`)
    } else if (upload.length > 0) {
        console.log(`upload for definde`)

        for (let i = 0; i < upload.length; i++) {
            arrEpty.push(upload[i].name)
            console.log(`upload for ${i}`, upload[i].name)
            // setFileNames([...fileNames, [upload[i].name]])
            console.log(`arrEpty`, arrEpty)

        }
        // setFileNames([...fileNames, arrEpty])
    }



    function upLoad(e) {

        setLoad(true)
        const formData = new FormData();
        for (let i = 0; i < upload.length; i++) {
            formData.append('file', upload[i])
            console.log(`upload for ${i}`, upload[i])

        }
        if (dependentStatus == true) {
            formData.append('DependentId', dependentId);
        }
        formData.append('CaseId', prop.caseId);
        formData.append('CurrentUserId', prop.currentUserId);
        // console.log("upload", upload)
        console.log("apis", formData)
        if (dependentStatus == true) {
            fetch(`${apis}User/UpdateCaseForDependent/`, {
                method: 'POST',
                body: formData,
                mode: "cors",
            }).then((response) => response.json()).then((result) => {
                console.log('Success:', result);
                setLoad(false)
                setMessage("Case Has been Created")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("success")//set in value in useState
                setCaseName(null)
                setUpload(null)
                setShow(false)
                arrEpty = []
                crossClose()
                prop.reload()

            }).catch((error) => {
                console.error('Error:', error);
                setLoad(false)
            });
        } else {
            fetch(`${apis}User/UpdateCase/`, {
                method: 'POST',
                body: formData,
                mode: "cors",
            }).then((response) => response.json()).then((result) => {
                console.log('Success:', result);
                setLoad(false)
                setMessage("Case Has been Created")//set in value in tMessage useState for Toster Message
                setOpens(true);//set in value in useState
                setColor("success")//set in value in useState
                setCaseName(null)
                setUpload(null)
                prop.reload()
                setShow(false)
                arrEpty = []

            }).catch((error) => {
                console.error('Error:', error);
                setLoad(false)
            });
        }

    }

    const handleClose = () => {

        upLoad()
        console.log(fileSize);
    };


    const AlerthandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpens(false);
    };


    return (
        <>
            <Link onClick={handleShow} >
                <span className="B1piuH">
                    <i class="mdi mdi-arrow-collapse-down"></i>
                </span>
                <span className="rglmnm">Add More File</span>
            </Link>

            <Modal show={show} onHide={crossClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formFile" id="file-upload-form" className="uploader">
                        <Form.Control required type="file"
                            //  value={upLoad}
                            id="file-upload" name="fileUpload"
                            // accept="zip/*"
                            multiple
                            onChange={(e) => {
                                // setUpload(e.target.files[0])
                                alert("ok")
                                // console.log("upload multi Docss", e.target.files)
                            }} />
                        <Form.Label for="file-upload" id="file-drag">
                            <img id="file-image"
                                //src={iconzip} 
                                alt="Preview" className="hidden" />
                            <div id="start">
                                <i className="fa fa-download" aria-hidden="true"></i>
                                <div>Select File or Drag Here</div>
                                <div id="notimage" className="hidden">Please select Zip File</div>
                                <span id="file-upload-btn" className="btn btn-primary">Select a file</span>
                            </div>
                            <ul id='fileList'>
                                {arrEpty.length > 0 ?
                                    arrEpty.map((item) =>
                                        <li>{item}</li>
                                    )
                                    : null
                                }
                            </ul>

                            <div id="response" className="hidden">
                                <div id="messages"></div>
                            </div>
                        </Form.Label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={crossClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        {
                            load ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Uploading...</div> : "Upload"
                        }

                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <Snackbar open={opens} autoHideDuration={6000} onClose={AlerthandleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={AlerthandleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar> */}
        </>
    );
}

export default AddNewFile;